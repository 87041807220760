import { useEffect, useRef, useState } from "react";
import { RemoveObjectsManualService, ReplaceImgEnhance } from "../../../Services/Enhance";
import Fullscreen from "../../Generate/Fullscreen";
import Upgrade from "../../Settings/upgrade";
import DownloadImage from "../../download-image";
import ViewCurl from "../../view-curl";
import { SelectProperty } from "../../../Services/Generate";
import { propertieImages } from "../../../Services/Properties";
import { Autocomplete, Slider, TextField } from "@mui/material";
import CanvasDraw from 'react-canvas-draw';


const RemoveObjectsManual: React.FunctionComponent<{}> = (props) => {


    const [properties, setProperties] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [enhancedImage, setEnhancedImage] = useState("");
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"id":"665f32fc5a6a16ee10d15801","img_org":[{"id":4,"src":"https://www.api-v1.coralytics.com/api/images/665f32fc5a6a16ee10d15801-4_1.jpeg"}],"img_remove":"https://www.api-v1.coralytics.com/api/images/665f32fc5a6a16ee10d15801-4_1.jpeg"});
    const [curlValue, setCurlValue] = useState<any>("remove-object-manualy");
    const [isDisabledReplace, setIsDisabledReplace] = useState<boolean>(false);
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [imgId, setImgId] = useState<any>("");
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [selectImages, setSelectImages] = useState<any>([]);
    const [listImages, setListImages] = useState<any>();
    const [refValue, setRefValue] = useState<any>()
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);
    const [upload, setUpload] = useState(true);
    const [drawingImage, setDrawingImage] = useState<any>('');
    const canvasRef = useRef<any>(null);
    const [brushRadius, setBrushRadius] = useState<any>(5);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });
    }, [])

    const handleSelectChangeProperty = (event: any, newValue: any) => {

        if (newValue) {
            setUpload(true)
            setIsDisabledUpload(!isDisabledUpload)
            setSelectImages([])
            setRefValue(newValue.ref)
            setSelectProperty(newValue.id);
            propertieImages(newValue.id).then((data) => {
                setListImages(data)
            });
        }

    }

    const HandleChangeUpload = (event: any) => {
        setListImages(null)
        setSelectImages([]);
        setRefValue("")
        setSelectProperty("")
        setUpload(false)
        setIsDisabledSelect(!isDisabledSelect)
        const numberOfFiles = event.target.files.length;
        for (let i = 0; i < numberOfFiles; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i + 1,
                    "src": base_64,
                    "ext": ext[1]
                }
                setSelectImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );
            };
        }
    }

    const handleSelectImages = async (img: any) => {
        setDrawingImage("");
        setSelectImages((prevSelectedImages: any) => {
            if (prevSelectedImages.includes(img)) {
                return [];
            } else {
                return [img];
            }
        });
    }

    const ConvertBase64 = async (img: any): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            fetch(img)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = () => {
                        reject(new Error('Error reading file'));
                    };
                })
                .catch(error => reject(error));
        });
    };


    const handleSaveDrawing = async () => {
        return new Promise(async (resolve, reject) => {
            if (canvasRef.current) {
                const tempCanvas = document.createElement('canvas');
                const tempCtx = tempCanvas.getContext('2d');
                const canvasWidth = canvasRef.current.canvas.drawing.width;
                const canvasHeight = canvasRef.current.canvas.drawing.height;
                if (!tempCtx) {
                    console.error("2D context not supported or canvas already initialized");
                    reject(new Error("2D context not supported or canvas already initialized"));
                    return;
                }

                tempCanvas.width = canvasWidth;
                tempCanvas.height = canvasHeight;

                // Draw the background image
                const img = new Image();
                const base64Img = await ConvertBase64(selectImages[0].src);
                if (typeof base64Img === 'string') {
                    img.src = base64Img;
                }
                img.onload = () => {
                    tempCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
                    // Draw the drawings on top of the background image
                    tempCtx.drawImage(canvasRef.current.canvas.drawing, 0, 0);
                    // Get the data URL of the combined image
                    const dataURL = tempCanvas.toDataURL('image/png');
                    resolve(dataURL);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            } else {
                reject(new Error("Canvas reference is not available"));
            }
        });
    };

    const EnhanceImage = async () => {
        try {
            const savedImage = await handleSaveDrawing();
            setIsSpinner(true);
            setIsUpgrade(false);
            setIsDisabledReplace(false);
            setShowMsgUpgrade("");
            setEnhancedImage('');
            const Obj = {
                "id": selectProperty,
                "img_org": selectImages,
                "img_remove": savedImage
            };
            RemoveObjectsManualService(Obj).then((data) => {
                setIsDisabledReplace(true);
                
                console.log(data.data[0].src);
                setEnhancedImage(data.data[0].src);
                setIsSpinner(false);
                setShowMessage('Enhanced Successfully !');
                setIsSuccess(true);
            }).catch(err => {
                if (err.response.status === 401) {
                    setIsSpinner(false);
                    setShowMsgUpgrade(err.response.data.message);
                    setIsUpgrade(true);
                } else {
                    setIsSpinner(false);
                    setShowMessage("Error");
                    setIsError(true);
                }
            });
            setTimeout(() => {
                setIsSuccess(false);
                setIsError(false);
            }, 3000);
        } catch (error) {
            console.error("Error during image enhancement", error);
            setIsSpinner(false);
            setShowMessage("Error");
            setIsError(true);
        }
    };


    const replace = () => {
        const replaceObj = {
            "property_id": selectProperty,
            "src": enhancedImage,
            "id": imgId
        }
        ReplaceImgEnhance(replaceObj).then((data) => {
            setShowMessage('Replaced !')
            setIsSuccess(true)
        }).catch(err => {
            setShowMessage('Error')
            setIsError(true)
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    }


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        Remove Objects Manual
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {properties == null ? <>Loading ...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body padd-24">
                                <label className="form-label" htmlFor="selectTypeOpt">Select Property</label>
                                <a type="button" style={{ float: "right", height: "15px", color: "#000000" }}
                                    className={[selectProperty != "" ? '' : "disabled", "btn p-0  hide-arrow"].join(" ")}
                                    href={`/properties/${selectProperty}`} target="_blank" rel="noopener noreferrer">{refValue}
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </a>

                                <Autocomplete
                                    options={properties}
                                    id="combo-box-d"
                                    getOptionLabel={(property: any) => {
                                        const bedroomsLabel = property.bedrooms > 1 ? `${property.bedrooms} bedrooms` : `${property.bedrooms} bedroom`;
                                        return `${property.ref} | ${property.location} | ${bedroomsLabel}`;
                                    }}
                                    disablePortal
                                    renderInput={(params) => <TextField {...params} onChange={(value) => {
                                        setSelectProperty(value.target.value)
                                    }} />}
                                    onChange={handleSelectChangeProperty}
                                />

                                <div className="row mt-3">

                                    <div className={`container_image `}>
                                        <input
                                            type="file"
                                            id="input-file"
                                            name="input-file"
                                            accept="image/*"
                                            onChange={HandleChangeUpload}
                                            hidden
                                        />
                                        <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                                            <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                            Upload Images
                                        </label>
                                    </div>
                                    {upload && selectImages ? <></>
                                        : <>
                                            <div className="selected-images" style={{ marginBottom: "0px" }} >
                                                {selectImages.map((image: any, index: any) => (
                                                    <div key={image.id}>
                                                        <label htmlFor={image.id} className="label1">
                                                            <img src={image.src} className="image" key={image.id} /></label>
                                                    </div>
                                                ))}
                                            </div>
                                        </>}
                                </div>

                                {listImages == null ? <></> : <><label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>Selected Image</label>
                                </>}
                                {listImages == null ? <div></div> : (
                                    <div className="image-list">
                                        {listImages.map((image: any, index: any) => (
                                            <div key={image.id + "_" + index}>
                                                <input
                                                    type="checkbox"
                                                    id={image.id + "_" + index}
                                                    value={image.src}
                                                    onChange={() => { handleSelectImages(image) }}
                                                    checked={selectImages.includes(image)}
                                                />
                                                <label htmlFor={image.id + "_" + index} className="label">
                                                    <img src={image.src} className="image" key={image.id + "_" + index} />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">
                                <h5 className="generate-h5">Paint Over Object</h5>
                                {selectImages.length == 0 ? <div className="selected-images"></div> :
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="card card-image-gener mb-2">
                                                <CanvasDraw
                                                    ref={canvasRef}
                                                    imgSrc={selectImages[0].src}
                                                    brushColor={"#d20033"}
                                                    className="canvas_draw"
                                                    brushRadius={brushRadius}
                                                    lazyRadius={1}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1 slider-container">
                                            <Slider
                                                aria-label="Brush Radius"
                                                valueLabelDisplay="auto"
                                                value={brushRadius}
                                                max={50}
                                                min={0}
                                                style={{width:"12px"}}
                                                onChange={(e, newValue) => setBrushRadius(newValue)}
                                                orientation="vertical"
                                                className="slider-vertical"
                                            />
                                        </div>
                                        <div className="col-md-1 slider-container-1">
                                            <Slider
                                                aria-label="Brush Radius"
                                                valueLabelDisplay="auto"
                                                value={brushRadius}
                                                max={50}
                                                min={0}
                                                style={{height:"12px"}}
                                                onChange={(e, newValue) => setBrushRadius(newValue)}
                                                className="slider-vertical"
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="d-flex mb-2" style={{ justifyContent: "end" }}>
                                    <button type="button" style={{ backgroundColor: " #716BE3", color: "#FFF" }} className={`btn mb-2 api-button ${selectImages.length !== 0 ? '' : 'disabled'}`} onClick={EnhanceImage}>Remove</button>
                                </div>

                                <div className="generate-h5" style={{ height: "30px" }}>Enhanced Image
                                    {isSpinner && enhancedImage == "" && <div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}
                                </div>
                                <div className="card card-image-gener" >
                                    {enhancedImage == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> : <div className="card">
                                        <img src={`${enhancedImage}?${new Date().getTime()}`} alt="Fullscreen Image" id='fullscreen-image' />

                                        <Fullscreen /></div>}
                                </div>
                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <DownloadImage image={enhancedImage} isDisabledReplace={isDisabledReplace} />
                                    <button type="button" className={`btn mb-2 button-1 ${isDisabledReplace && selectProperty ? '' : 'disabled'}`} onClick={replace}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3333 10.0001C18.3333 14.6001 14.6 18.3334 9.99996 18.3334C5.39996 18.3334 2.59163 13.7001 2.59163 13.7001M2.59163 13.7001H6.35829M2.59163 13.7001V17.8667M1.66663 10.0001C1.66663 5.40008 5.36663 1.66675 9.99996 1.66675C15.5583 1.66675 18.3333 6.30008 18.3333 6.30008M18.3333 6.30008V2.13341M18.3333 6.30008H14.6333" stroke="#716BE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Replace
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default RemoveObjectsManual;