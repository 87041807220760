import ReactWordcloud, { Word } from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";



const WordCloud: React.FunctionComponent<{}> = (props) => {

  const data= [
    {
        "text": "TheSpecialOne_\",\"@switchfoot",
        "value": 2
    },
    {
        "text": "http://twitpic.com/2y1zl",
        "value": 2
    },
    {
        "text": "-",
        "value": 2
    },
    {
        "text": "Awww,",
        "value": 2
    },
    {
        "text": "bummer.",
        "value": 2
    },
    {
        "text": "shoulda",
        "value": 2
    },
    {
        "text": "got",
        "value": 2
    },
    {
        "text": "David",
        "value": 2
    },
    {
        "text": "Carr",
        "value": 2
    },
    {
        "text": "Third",
        "value": 2
    },
    {
        "text": "Day",
        "value": 2
    },
    {
        "text": "it.",
        "value": 2
    },
    {
        "text": "pmlojnn",
        "value": 1
    }
]
  
  return <div>
   {/* <div className="wordcloud">
      <ReactWordcloud options={{
        colors: ['#3961C9','#E1C371','#E88CAF','#A16D39','#608373','#A66A6E','#715F9B','#3CB991'],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [16, 40],
        fontStyle: "normal",
        fontWeight: "400",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
      }} words={data as any} />
    </div> */}
     </div>
 
}
export default WordCloud;