import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const UnderstandMain: React.FunctionComponent<{}> = (props) => {
    
    const pathname = useLocation().pathname.split('/')
    const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));

    return (
     
   <ul className="nav nav-tabs flex-wrap" role="tablist">
   <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Image Content') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
       <Link to="/ar/understand/image-content" className={[pathname[3] == "image-content" ? "active" : "", "nav-link"].join(" ")}
           role="tab">
        محتوى الصورة
       </Link>
   </li>
   <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Image Compliance') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
       <Link to="/ar/understand/image-compliance" className={[pathname[3] == "image-compliance" ? "active" : "", "nav-link"].join(" ")}
           role="tab"
       >
             الامتثال للصورة
       </Link>
   </li>
   <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Text Content and Compliance') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
       <Link to="/ar/understand/text" className={[pathname[3] == "text" ? "active" : "", "nav-link"].join(" ")}
           role="tab" >
           نص
       </Link>

   </li>
   <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Document Content and Compliance') || platform?.understand?.includes('all') || platform?.understand?.includes('Emirates ID - Content (UAE)'))) ? "" : "disabled"} `}>
       <Link to="/ar/understand/document" className={[pathname[3] == "document" ? "active" : "", "nav-link"].join(" ")}
           role="tab"
       >
           وثيقة
       </Link>
   </li>
   <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Audio Content and Compliance')||platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
       <Link to="/ar/understand/audio" className={[pathname[3] == "audio" ? "active" : "", "nav-link"].join(" ")}
           role="tab"
       >
           صوت
       </Link>
   </li>
</ul>
    )
}

export default UnderstandMain