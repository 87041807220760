import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const GetAccount = () => {
  const apiUrl = Url + "/get_account";
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error.data)
  })
}

export const UpdateProfileService = (profile: any) => {
  const apiUrl = Url + "/updateprofile";
  return axios.put(apiUrl, profile).then((response) => {
    return response.data;
  })

}

export const GetCompanyProfile = () => {
  const apiUrl = Url + "/company";
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const UpdateCompanyProfile = (profile: any, id: any) => {
  const apiUrl = Url + "/company";
  return axios.put(apiUrl + "/" + id, profile, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })

}

export const GetCompanyDesc = async () => {
  const apiUrl = Url + "/getcompanydesc";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const UpdateCompanyDesc = async (value: any) => {
  const apiUrl = Url + "/updatecompanydesc";
  const response = await axios.post(apiUrl,value, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}