import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";

const LayoutAr: React.FunctionComponent<{}> = (props) => {
 
    return ( 
        <>
            <div className="page_arabic" dir="auto">
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Sidebar />
                    <div className="layout-page">
                        <Header />
                            <Outlet />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default LayoutAr