import React from "react";
import Header from "./header";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";

const Layout: React.FunctionComponent<{}> = (props) => {

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Sidebar />
                    <div className="layout-page">
                        <Header />
                            <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout