import React, { useEffect, useState } from "react";
import SelectInviteUsers from "./select-invite-users";
import { User } from "../../Models/User";
import { DropUser, UpdateUser, addUser, getusers } from "../../Services/Users";
import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getProjects } from "../../Services/Projects";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import CreditConsumptionUser from "../Settings/credit-consumption-user";
import ChangePassword from "./change-password";

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const Users: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [change, setChange] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccessUser, setIsSuccessUser] = useState<boolean>(false);
    const [isErrorUser, setIsErrorUser] = useState(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [projectId, setProjectId] = useState<any>();
    const [userValue, setUserValue] = useState<User>();

    useEffect(() => {
        getusers().then((data) => {
            setLoading(false)
            setUsers(data)
        }).catch(err => {
            console.log("Error");
        });

    }, [change])


    const handleChangeUserName = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            lastename: event.target.value,
        }));
    }

    const handleChangeUserEmail = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            email: event.target.value,
        }));
    }


    const handleChangeRule = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            rule: event.target.value,
        }));
    }

    const InviteUserHandler = (user: User) => {

        const userObj = {
            "accountId": localStorage.getItem('id'),
            "firstName": "",
            "lastename": user.lastename,
            "statu": false,
            "rule": user.rule,
            "email": user.email,
            "password": "",
            "projects": '',
            "productAccess": '',
            "creditScore": user.creditScore
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailPattern.test(user.email as string);
        if (isValidEmail) {
            addUser(userObj).then((data) => {
                setChange(!change)
                setIsSuccess(true)
                setShowMessage("تمت دعوة المستخدم بنجاح!")
            }).catch(err => {
                console.log(err)
                setIsError(true)
                setShowMessage("Error")
            });
        } else {
            setIsError(true)
            setShowMessage("عنوان بريد إلكتروني غير صالح. الرجاء إدخال عنوان بريد إلكتروني صالح.")
        }
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 3000);
    }

    const Update = (user: any) => {
        setUserValue(user)
    }
    const handleChangeCredit = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            creditScore: event.target.value,
        }));
    };

    const convertDate = (date: any) => {
        const date_ = date.split("T");
        const dateNew = date_[1].split(":");
        const dateValue = (date_[0].replace(/-/g, '/')) + ", " + dateNew[0] + ":" + dateNew[1]
        return dateValue;
    }
    const Save = () => {
        const userObj = {
            "_id": userValue?._id,
            "firstName": "",
            "lastename": userValue?.lastename,
            "statu": false,
            "rule": userValue?.rule,
            "email": userValue?.email,
            "password": "",
            "projects": '',
            "creditScore": userValue?.creditScore,
            "productAccess": ''
        }
        UpdateUser(userObj).then((data) => {
            setIsSuccessUser(true)
            setUserValue({})
            setShowMessage("تم تحديث المستخدم بنجاح!");
            setChange(!change)
        }).catch(() => {
            setIsErrorUser(true)
            setShowMessage("فشل المستخدم في التحديث بواسطة ID");
        })
        setTimeout(() => {
            setIsSuccessUser(false)
            setIsErrorUser(false)
        }, 3000);
    }

    const drop = () => {
        DropUser(projectId).then((data) => {
            setIsSuccessUser(true)
            setShowMessage("تم حذف المستخدم بنجاح!");
            setChange(!change)
        }).catch(() => {
            setIsErrorUser(true)
            setShowMessage("فشل في حذف المستخدم بواسطة ID");
        })
        setTimeout(() => {
            setIsSuccessUser(false)
            setIsErrorUser(false)
        }, 3000);
    }
    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> ...تحميل</div>) : (
        <>
         <ChangePassword />
         
            <div className="row users-mobile">
                <div className="card mb-3">
                    <div className="card-body padd-24">
                        <div className="row mb-4">
                            <div className="col-md-12 moderate-title">
                                دعوة المستخدمين
                            </div>
                        </div>
                        {isSuccess && <div className="alert alert-success alert-dismissible mt-4" role="alert">
                            {showMessage}
                        </div>}
                        {isError && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                            {showMessage}
                        </div>}
                        <SelectInviteUsers onInviteUser={InviteUserHandler} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="card mb-3 display-property">
                    <div className="card-body padd-24">
                        <div className="row ">
                            <div className="col-md-12 moderate-title">
                                المستخدمين
                            </div>
                        </div>
                        {isSuccessUser && <div className="alert alert-success alert-dismissible mt-4" role="alert">
                            {showMessage}
                        </div>}
                        {isErrorUser && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                            {showMessage}
                        </div>}
                        <div className="margin-35">
                            <div className="table-responsive text-nowrap ">
                                <table className="table" style={{ minHeight: "150px" }}>
                                    <thead>
                                        <tr>
                                            <th>اسم المستخدم</th>
                                            <th>عنوان البريد الإلكتروني</th>
                                            <th>دور المستخدم </th>
                                            <th> ائتمان</th>
                                            <th>لائتمان المكتسب</th>
                                            <th>حالة</th>
                                            <th>إجراءات</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        {users && users.map((user: any, index: any) => {
                                            return <tr key={"line_" + index}>
                                                <td> {user.lastename.charAt(0).toUpperCase() + user.lastename.slice(1)} {" "} {user.firstName}
                                                </td>
                                                <td>
                                                    {user.email}
                                                </td>
                                                <td>
                                                    {user.rule.charAt(0).toUpperCase() + user.rule.slice(1)}
                                                </td>
                                                <td>{user.creditScore ? <span style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#creditModal" onClick={() => Update(user)}> {user.creditScore} <i className="fa fa-share" style={{ height: "15px" }} aria-hidden="true"></i></span> : 0}</td>
                                                <td>{user.acquiredCredit ? user.acquiredCredit : 0}</td>

                                                <td>
                                                    {user.statu == true && <div style={{ marginTop: "8px" }}><span className="badge bg-label-success">فعّال</span></div>}
                                                    {user.statu == false && <div style={{ marginTop: "8px" }}><span className="badge bg-label-secondary">قيد الانتظار</span></div>}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn p-0  hide-arrow" data-bs-target="#UpdateModal" data-bs-toggle="modal" onClick={() => Update(user)} >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#8C8D96" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="btn p-0  hide-arrow" style={{ marginRight: "10px" }} data-bs-toggle="modal" onClick={(e) => { setProjectId(user._id) }}
                                                        data-bs-target="#dropModal" >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                {users && users.length == 0 && <h3 className="empty"> لا يوجد أي مستخدمين</h3>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rule-list">
                <div className=" row property-mobile ">
                    <div className="row mb-3">
                        <div className="col-md-12 moderate-title">
                            المستخدمين
                        </div>
                    </div>
                    {isSuccessUser && <div className="alert alert-success alert-dismissible mt-4" role="alert">
                        {showMessage}
                    </div>}
                    {isErrorUser && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                        {showMessage}
                    </div>}
                    {users && users.map((user: any, index: any) => {
                        return <div className="card mb-3" key={index}>
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col d-flex align-items-center" >
                                        <h3 className="title">{user.lastename.charAt(0).toUpperCase() + user.lastename.slice(1)} {" "} {user.firstName}</h3>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn p-0  hide-arrow float-start" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: "pointer" }}>
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 11.5C11 10.66 11.675 10 12.5 10C13.325 10 14 10.675 14 11.5C14 12.325 13.325 13 12.5 13C11.675 13 11 12.34 11 11.5Z" fill="#282828" />
                                                <path d="M11 17.5C11 16.66 11.675 16 12.5 16C13.325 16 14 16.675 14 17.5C14 18.325 13.325 19 12.5 19C11.675 19 11 18.34 11 17.5Z" fill="#282828" />
                                                <path d="M11 5.5C11 4.66 11.675 4 12.5 4C13.325 4 14 4.675 14 5.5C14 6.325 13.325 7 12.5 7C11.675 7 11 6.34 11 5.5Z" fill="#282828" />
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" data-bs-target="#UpdateModal" data-bs-toggle="modal" onClick={() => Update(user)} >
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#8C8D96" />
                                                    </svg>
                                                    <span className="align-middle" style={{ marginRight: "10px" }}>تحديث</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" data-bs-toggle="modal" onClick={(e) => { setProjectId(user._id) }}
                                                    data-bs-target="#dropModal" >
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                    </svg>
                                                    <span className="align-middle" style={{ marginRight: "10px" }}>حذف</span>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col mb-2">
                                        <p className="rule-name" style={{ color: "#6C6C6C" }}>
                                            {user.email}
                                            <svg width="8" height="8" className="rectangle" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="8" height="8" rx="4" fill="#8C8D96" />
                                            </svg>
                                            {user.rule.charAt(0).toUpperCase() + user.rule.slice(1)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="float-end"> الائتمان  :  {user.creditScore ? <span style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={`#creditModal`} onClick={() => Update(user)}> {user.creditScore} <i className="fa fa-share" style={{ height: "15px" }} aria-hidden="true"></i></span> : 0}</p>
                                    </div>
                                </div>
                                <div className="row date-create">
                                    <div className="col mb-2">
                                        <p className="float-end" style={{ marginTop: "12px" }}>{convertDate(user.createdAt)}</p>
                                    </div>
                                    <div className="col mb-2">
                                        {user.statu == true && <div style={{ marginTop: "8px" }} className="float-start"><span className="badge bg-label-success">فعّال</span></div>}
                                        {user.statu == false && <div style={{ marginTop: "8px" }} className="float-start"><span className="badge bg-label-secondary">قيد الانتظار</span></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    }
                    {users && users.length == 0 && <div className="card mb-3">
                        <div className="card-body">
                            <div className="row mb-2 mt-2">
                                <h3 className="empty"> لا يوجد أي مستخدمين</h3>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            {/* drop user */}
            <div className="modal fade" id="dropModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">تأكيد</h5>

                        </div>
                        <div className="modal-body">
                            <p>هل أنت متأكد من أنك تريد حذف هذه الخاصية ؟</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" style={{ float: "right" }} data-bs-dismiss="modal">
                                لا
                            </button>
                            <button type="button" data-bs-dismiss="modal" style={{ float: "right" }} className="btn btn-danger" onClick={drop}>نعم</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* update user */}
            <div className="modal fade" id="UpdateModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">المستخدم</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">

                            <div className="row col-users">
                                <div className="col-md-6">
                                    <label htmlFor="User Name" className="form-label ">اسم المستخدم</label>
                                    <input type="text" id="Rule Name" className="form-control mb-3" value={userValue?.lastename || ""} onChange={handleChangeUserName} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="User Email" className="form-label ">البريد الإلكتروني للمستخدم</label>
                                    <input type="text" id="Rule Name" className="form-control mb-3" value={userValue?.email || ""} onChange={handleChangeUserEmail} />
                                </div>
                            </div>
                            <div className="row col-users">
                                <div className="col-md-6  mb-3">
                                    <label htmlFor="User Rule" style={{ marginBottom: "9px" }} className="form-label ">دور مستخدم</label>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <FormControl fullWidth>
                                                {userValue?.rule && <Select
                                                    className="custom-select"
                                                    displayEmpty
                                                    value={userValue?.rule || ""}
                                                    inputProps={{ 'aria-label': 'Select' }}
                                                    onChange={handleChangeRule}>
                                                    <MenuItem value="" disabled>
                                                        اختر
                                                    </MenuItem>
                                                    <MenuItem value="Admin" >
                                                        مشرف
                                                    </MenuItem>
                                                    <MenuItem value="Agent" >
                                                        عميل
                                                    </MenuItem>
                                                    <MenuItem value="Moderator" >
                                                        وسيط
                                                    </MenuItem>
                                                </Select>}
                                            </FormControl>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </div>
                                <div className="col-md-6  mb-3">
                                    <label htmlFor="project" className="form-label ">تعيين الائتمان</label>
                                    <input type="number" min={0} className="form-control " placeholder="Credit" value={userValue?.creditScore} onChange={handleChangeCredit} />

                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-md-4">
                                    <button type="button" data-bs-dismiss="modal" className="btn save-modal" onClick={Save}>حفظ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div className="modal fade" id="creditModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">  استهلاك الائتمان</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {userValue && <CreditConsumptionUser user={userValue} />
                            }

                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Users
