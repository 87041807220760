import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const GenrateImage = async (id: any, selectedimages: any) => {
  const apiUrl = Url + "/generateimage_desc";
  const response = await axios.post(apiUrl, { id: id, selectedimages: selectedimages }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}


export const GenrateImageagent = async () => {
  const apiUrl = Url + "/agent-profiles";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const generateDescScenes = async (scenes: any) => {
  const apiUrl = Url + "/";
  const response = await axios.post(apiUrl, scenes, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const generateAgentProfile = async (obj : any) => {
  const apiUrl = Url + "/";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const DeleteRecentVideosService = async (url: any) => {
  const apiUrl = Url + "/recent_videos/";
  const response = await axios.delete(`${apiUrl}${url}`, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GenerateAgentVideo = async (data: any) => {
  const apiUrl = Url + "/generate_video_v2_new";
  const response = await axios.post(apiUrl, data, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response;
}

export const UploadVideoService = async (dataSend: any) => {
  const apiUrl = Url + "/send_images";
  const response = await axios.post(apiUrl, dataSend, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const RecentVideosService = async (type: string) => {
  const apiUrl = Url + "/recent_videos";
  const response = await axios.post(apiUrl, { "type": type }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}


export const FetchDownloadLink = async (src: string) => {
  const apiUrl = Url + "/fetch_download_link";
  const response = await axios.post(apiUrl, { "src": src }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}


export const FetchSocialVideo = async (template_name: any, size: any) => {
  const apiUrl = Url + "/fetch_social_video";
  const response = await axios.post(apiUrl, { template_name: template_name, size: size }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response;
}


export const GenerateVideo = async (data_images: any, size: any, selectTemplate: any, selectProperty: any, refValue: any) => {
  const apiUrl = Url + "/generate_video";
  const response = await axios.post(apiUrl, { data_images: data_images.data_images, size: size, template_name: selectTemplate, id: selectProperty, ref: refValue }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response;
}

export const ListingVideoServ = async (listings: any) => {
  const apiUrl = Url + "/generate_videov2";
  const response = await axios.post(apiUrl, listings, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response;
}

export const GenerateCaptionsServ = async (images: any) => {
  const apiUrl = Url + "/generatecaption";
  const response = await axios.post(apiUrl, images, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetVideos = async () => {
  const apiUrl = Url + "/videos";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetAgentTemplate = async () => {
  const apiUrl = Url + "/agent-video/templates";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetAgentMusics = async () => {
  const apiUrl = Url + "/agent-video/musics";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetAgentScenes = async () => {
  const apiUrl = Url + "/agent-video/scenes";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}