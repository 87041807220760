import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import CircleProgressChart from "../Charts/CircleProgressChart";
import { GetPropertyStats } from "../../Services/Moderate";
import { getCircleProgress } from "../../Services/Chart";
import LineChartModerate from "../Charts/LineChartModerate";
import ModerateMain from "./moderate-main";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Overview: React.FunctionComponent<{}> = (props) => {

    const [propertyStats, setPropertyStats] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [scoreChanges, setScoreChanges] = useState<any>();
    const [monthValue, setMonthValue] = useState<any>(2024)

    useEffect(() => {
        getCircleProgress().then((response) => {
            setScoreChanges(response.scoreChanges);
            setLoading(false)
        })
            .catch(err => {
                console.log("Error");
            })

        GetPropertyStats().then((resp) => {
            setPropertyStats(resp)
            setLoading(false)
        })

    }, [])

    const handleChangeMonth = (event: any) => {
        setMonthValue(event.target.value)
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate" >
            <ModerateMain change={false} />
            <div className="tab-content">
                {loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...    </div>) :
                    <div className="overview-page card-bottom ">
                        <div className="card-body padd-24">

                            <div className="row">
                                <div className="col-md-10 moderate-title padd-0  push-2nd mb-2 ">
                                    نظرة عامة
                                </div>

                            </div>

                            <div className="row margin-35" >
                                <div className="col-md-3 mb-3" style={{ paddingRight: "0px" }}>
                                    <div className="card">
                                        <div className="card-body overview">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="rounded backg-over-blue ">
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20 24.9999C26.2132 24.9999 31.25 20.1497 31.25 14.1666C31.25 8.1835 26.2132 3.33325 20 3.33325C13.7868 3.33325 8.75 8.1835 8.75 14.1666C8.75 20.1497 13.7868 24.9999 20 24.9999Z" fill="#0066FF" />
                                                        <path d="M26.3167 26.0167C26.8667 25.7334 27.5 26.15 27.5 26.7667V34.85C27.5 36.35 26.45 37.0834 25.15 36.4667L20.6833 34.35C20.3 34.1834 19.7 34.1834 19.3167 34.35L14.85 36.4667C13.55 37.0667 12.5 36.3334 12.5 34.8334L12.5333 26.7667C12.5333 26.15 13.1833 25.75 13.7167 26.0167C15.6 26.9667 17.7333 27.5 20 27.5C22.2667 27.5 24.4167 26.9667 26.3167 26.0167Z" fill="#0066FF" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-figures">
                                                <span className="fw-semibold marg-35">الإشراف الكلي</span>
                                                <div className="number-month marg-8">
                                                    <h3 className="card-title mb-2 h3">{propertyStats?.total}</h3>
                                                    <small className="text-success">  0 % مقارنة مع الشهر الماضي
                                                    </small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3" style={{ paddingRight: "0px" }}>
                                    <div className="card">
                                        <a className="card-body overview" href={`/ar/moderate/${"rejected"}`} target="_blank">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="rounded backg-over-danger ">
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M26.0166 9.16678V26.1001C26.0166 26.7668 25.8166 27.4168 25.4499 27.9668L20.8999 34.7334C20.1833 35.8168 18.3999 36.5835 16.8833 36.0168C15.2499 35.4668 14.1666 33.6334 14.5166 32.0001L15.3833 26.5501C15.4499 26.0501 15.3166 25.6001 15.0333 25.2501C14.7499 24.9334 14.3333 24.7334 13.8833 24.7334H7.03328C5.71661 24.7334 4.58328 24.2001 3.91661 23.2668C3.28328 22.3668 3.16661 21.2001 3.58328 20.0168L7.68328 7.53345C8.19994 5.46678 10.4499 3.78345 12.6833 3.78345H19.1833C20.2999 3.78345 21.8666 4.16678 22.5833 4.88345L24.7166 6.53345C25.5333 7.16678 26.0166 8.13345 26.0166 9.16678Z" fill="#D34645" />
                                                        <path d="M31.3167 29.3501H33.0333C35.6167 29.3501 36.6667 28.3501 36.6667 25.8834V9.13341C36.6667 6.66675 35.6167 5.66675 33.0333 5.66675H31.3167C28.7334 5.66675 27.6833 6.66675 27.6833 9.13341V25.9001C27.6833 28.3501 28.7334 29.3501 31.3167 29.3501Z" fill="#D34645" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-figures">
                                                <span className="fw-semibold marg-35">مرفوض</span>
                                                <div className="number-month marg-8">
                                                    <h3 className="card-title mb-2 h3">{propertyStats?.rejected}</h3>
                                                    <small className="text-success"> 0 % مقارنة مع الشهر الماضي
                                                    </small></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 " style={{ paddingRight: "0px" }}>
                                    <div className="card">
                                        <a className="card-body overview" href={`/ar/moderate/${"passive"}`} target="_blank">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="rounded backg-over-mauve ">
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M26.9833 3.33325H13.0166C6.94998 3.33325 3.33331 6.94992 3.33331 13.0166V26.9666C3.33331 33.0499 6.94998 36.6666 13.0166 36.6666H26.9666C33.0333 36.6666 36.65 33.0499 36.65 26.9833V13.0166C36.6666 6.94992 33.05 3.33325 26.9833 3.33325ZM29.75 23.4999C29.6833 23.6499 29.6 23.7833 29.4833 23.8999L24.4166 28.9666C24.1666 29.2166 23.85 29.3333 23.5333 29.3333C23.2166 29.3333 22.9 29.2166 22.65 28.9666C22.1666 28.4832 22.1666 27.6833 22.65 27.1999L25.5833 24.2666H11.4166C10.7333 24.2666 10.1666 23.6999 10.1666 23.0166C10.1666 22.3333 10.7333 21.7666 11.4166 21.7666H28.6C28.7666 21.7666 28.9166 21.7999 29.0833 21.8666C29.3833 21.9999 29.6333 22.2333 29.7666 22.5499C29.8666 22.8499 29.8666 23.1999 29.75 23.4999ZM28.5833 18.2166H11.4166C11.25 18.2166 11.1 18.1833 10.9333 18.1166C10.6333 17.9833 10.3833 17.7499 10.25 17.4333C10.1166 17.1333 10.1166 16.7833 10.25 16.4833C10.3166 16.3333 10.4 16.1999 10.5166 16.0833L15.5833 11.0166C16.0666 10.5333 16.8666 10.5333 17.35 11.0166C17.8333 11.4999 17.8333 12.2999 17.35 12.7833L14.4333 15.7166H28.6C29.2833 15.7166 29.85 16.2833 29.85 16.9666C29.85 17.6499 29.2833 18.2166 28.5833 18.2166Z" fill="#716BE3" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-figures">
                                                <span className="fw-semibold marg-35">غير نشط</span>
                                                <div className="number-month marg-8">
                                                    <h3 className="card-title mb-2 h3">{propertyStats?.passive}</h3>
                                                    <small className="text-success">  0 % مقارنة مع الشهر الماضي
                                                    </small></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3" style={{ paddingRight: "0px" }}>
                                    <div className="card">
                                        <div className="card-body overview">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="rounded backg-blue ">
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.9167 25.6166C21.55 25.0833 20.9 24.7833 20.15 24.7833H16.2667C16.0167 24.7833 15.7667 24.6833 15.6167 24.4833C15.45 24.2833 15.3833 24.0333 15.4167 23.75L15.9 20.65C16.1 19.7333 15.4833 18.6833 14.5667 18.3666C13.7 18.05 12.7 18.4833 12.2833 19.1L8.43334 24.8333V24.1166C8.43334 22.7166 7.83334 22.15 6.36668 22.15H5.40001C3.93334 22.1666 3.33334 22.7333 3.33334 24.1333V33.65C3.33334 35.05 3.93334 35.6166 5.40001 35.6166H6.36668C7.76668 35.6166 8.35001 35.0666 8.40001 33.8L11.3167 36.05C11.7333 36.45 12.6167 36.6666 13.25 36.6666H16.9333C18.2 36.6666 19.4667 35.7166 19.7667 34.55L22.1 27.4666C22.35 26.7833 22.2833 26.1333 21.9167 25.6166Z" fill="#5782C2" />
                                                        <path d="M35.1833 4.35005H34.2167C32.8167 4.35005 32.2333 4.90005 32.1667 6.16672L29.25 3.91672C28.85 3.51672 27.95 3.30005 27.3167 3.30005H23.6333C22.3667 3.30005 21.1 4.25005 20.8 5.41672L18.4667 12.5C18.2167 13.1834 18.3 13.8334 18.65 14.35C19.0167 14.8834 19.6667 15.1834 20.4167 15.1834H24.3C24.55 15.1834 24.8 15.2834 24.95 15.4834C25.1167 15.6834 25.1833 15.9334 25.15 16.2167L24.6667 19.3167C24.4667 20.2334 25.0833 21.2834 26 21.6C26.8667 21.9167 27.8667 21.4834 28.2833 20.8667L32.1333 15.1334V15.85C32.1333 17.25 32.7333 17.8167 34.2 17.8167H35.1667C36.6333 17.8167 37.2333 17.25 37.2333 15.85V6.30005C37.25 4.91672 36.65 4.35005 35.1833 4.35005Z" fill="#5782C2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-figures">
                                                <span className="fw-semibold marg-35">يدوي</span>
                                                <div className="number-month marg-8">
                                                    <h3 className="card-title mb-2 h3">{propertyStats?.manual}</h3>
                                                    <small className="text-success">  0 % مقارنة مع الشهر الماضي
                                                    </small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row line-chart" >
                                <div className="col-md-7 mb-3">
                                    <div className="card padding-chart">
                                        <div className="card-body " style={{ height: "24.6rem" }}>
                                            <div className="row mb-4">
                                                <div className="col-md-9 ">
                                                    <h3 className="title-chart">شهراً بعد شهر</h3>
                                                </div>
                                                <div className="col-md-3 display-select">
                                                    <CacheProvider value={cacheRtl}>
                                                        <ThemeProvider theme={theme}>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="custom-select"
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Sort By' }}
                                                                    value={monthValue}
                                                                    onChange={handleChangeMonth}>
                                                                    <MenuItem value="" disabled>
                                                                        اختر
                                                                    </MenuItem>
                                                                    <MenuItem value={2023} key="2">2023</MenuItem>
                                                                    <MenuItem value={2024} key="3">2024</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </ThemeProvider>
                                                    </CacheProvider>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <LineChartModerate />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 mb-3">
                                    <div className="card donut mb-3">
                                        <div className="card-body padd-10-chart d-flex" >
                                            <div className="d-flex align-items-start justify-content-between">
                                                <CircleProgressChart type={"others_score"} />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.8rem" }}>
                                                <div><h5> نقاط الآخرين</h5>
                                                    <span><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.625 5.54175L9.975 12.1917L7.44167 8.39175L2.375 13.4584" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.4583 5.54175H16.625V8.70841" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                        {scoreChanges && scoreChanges.totalScoreChange != null ? <> {scoreChanges.totalScoreChange}% مقارنة مع الشهر الماضي </>
                                                            : <>0 % مقارنة مع الشهر الماضي
                                                            </>}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card donut mb-16 mb-3">
                                        <div className="card-body padd-10-chart d-flex" >
                                            <div className="d-flex align-items-start justify-content-between">
                                                <CircleProgressChart type={"compliance_score"} />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.8rem" }}>
                                                <div><h5> نقاط الامتثال</h5>
                                                    <span><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.625 5.54175L9.975 12.1917L7.44167 8.39175L2.375 13.4584" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.4583 5.54175H16.625V8.70841" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                        {scoreChanges && scoreChanges.complianceScoreChange != null ? <> {scoreChanges.complianceScoreChange}% مقارنة مع الشهر الماضي </>
                                                            : <>0 % مقارنة مع الشهر الماضي
                                                            </>}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card donut mb-16 mb-3">
                                        <div className="card-body padd-10-chart d-flex" >

                                            <div className="d-flex align-items-start justify-content-between">
                                                <CircleProgressChart type={"quality_score"} />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.8rem" }}>
                                                <div> <h5> نقاط الجودة</h5>
                                                    <span><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.625 5.54175L9.975 12.1917L7.44167 8.39175L2.375 13.4584" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.4583 5.54175H16.625V8.70841" stroke="#46B49E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                        {scoreChanges && scoreChanges.qualityScoreChange != null ? <> {scoreChanges.qualityScoreChange}% مقارنة مع الشهر الماضي </>
                                                            : <>0 % مقارنة مع الشهر الماضي
                                                            </>}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
export default Overview;