import { FormControl, Select, MenuItem, Checkbox, ListItemText, SelectChangeEvent } from "@mui/material";
import { FunctionComponent, useState } from "react";

const PublishProperties: FunctionComponent<{ properties: any, onPublishProperty: any,toogle: any  }> = (props) => {

    const [selectedPlatform, setSelectPlatform] = useState<any[]>([]);
    const [platforms, setPlatforms] = useState<any>([{ name: "Coralytics", src: "/assets/images/icons/Coralytics.png" },{ name: "Propcrm", src: "/assets/images/icons/propcrm.png" }, { name: "Propertyfinder", src: "/assets/images/icons/finder.png" }, { name: "Bayut", src: "/assets/images/icons/bayut.png" }, { name: "Dubizzle", src: "/assets/images/icons/dubizzle.png" }]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);

 
    const handleChangePlatforme = (event: SelectChangeEvent<typeof selectedPlatform>) => {
        const {
            target: { value },
        } = event;

        setSelectPlatform(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handlePublish = async () => {
        const listings = {
            "properties": props.properties,
            "portals": selectedPlatform,
            "publish":true
        }
        props.onPublishProperty(listings)
    };


    return (<>
        <button type="submit" className={`btn publish-butt ${props.properties.length == 0 ? "disabled" : ''}`} data-bs-toggle="modal"
            data-bs-target={`#${props.toogle}`} onClick={()=>setSelectPlatform([])}>
            <i className="fa-solid fa-share"></i>
            <span>Publish</span>
        </button>

        <div className="modal fade" id={props.toogle} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Publish Properties</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <label htmlFor="projectSelect" className="form-label">Select Portal</label>
                            <FormControl >
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedPlatform}
                                    onChange={handleChangePlatforme}
                                    renderValue={(selected) => selected.join(', ')}>
                                    {platforms.map((platform: any, index: any) => (
                                        <MenuItem value={platform.name} key={index}>
                                            <Checkbox checked={selectedPlatform.indexOf(platform.name) > -1} />
                                            <img src={platform.src} style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }} />
                                            <ListItemText primary={platform.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" style={{ float: 'right' }} data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" onClick={handlePublish} style={{ float: 'right' }} data-bs-dismiss="modal" className={`btn btn-primary ${selectedPlatform.length == 0 ? "disabled" : ''}`}>Publish</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default PublishProperties;
