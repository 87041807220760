import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isLogged } from "./Authentication";
import Login from "../Components/login";

interface AuthGuardProps {
  children: React.ReactNode;
}
const PublicPagesGuard : React.FC<AuthGuardProps> = ({  }) => {
  
  return !isLogged() ? <Login/> : <Navigate to="/properties" />;}

 
export default PublicPagesGuard;
