import React, { useState } from "react"
import { Autocomplete, Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: { 
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const ListingBoosterSelect: React.FunctionComponent<{ properties: any, onSendEnhance: any }> = (props) => {


    const [selectProperty, setSelectProperty] = useState<any>("");
    const [selectEnhancement, setSelectEnhancement] = useState<any>([]);
    const [refValue, setRefValue] = useState<any>()
    const enahancement = [
        {name:'All', nameAr : "الكل"},
        {name:'Improve image positionning', nameAr :"تحسين توضيب الصورة" },
       {name: 'Improve description', nameAr : "تحسين الوصف"},
        {name:'Improve title', nameAr : "تحسين العنوان"},
        {name:'Improve Image quality', nameAr : "تحسين جودة الصورة"}
    ];

    const handleSelectChangeProperty = (event: any, newValue: any) => {
        if (newValue) {
            setRefValue(newValue.ref)
            setSelectProperty(newValue.id);
        }
    }

    const handleSelectChangeEnhancement = (event: SelectChangeEvent<typeof selectEnhancement>) => {
        const {
            target: { value },
        } = event;
        if (value.includes('الكل')) {
            setSelectEnhancement(
                value.includes('الكل')
                    ? enahancement.filter((enhance) => enhance.nameAr !== 'الكل').map((enhance) => enhance.nameAr)
                    : value
            );
        } else {
            setSelectEnhancement(value);
        }
    };

    const Enhance = () => {
        let platformsList: any = []
        for (let index = 0; index < enahancement.length; index++) {
            const element = enahancement[index];
            for (let index = 0; index < selectEnhancement.length; index++) {
                if (element.nameAr == selectEnhancement[index]) {
                    platformsList.push(element.name)
                }
            }
        }
        const enhanceObj = {
            "id": selectProperty,
            "enhancement": platformsList
        }
        
        props.onSendEnhance(enhanceObj);
    };


    return (

        <div className="card">
            <div className="card-body padd-24">
            <label className="form-label" htmlFor="selectTypeOpt">اختيار العقار</label>
                <a type="button" style={{ float: "left", height: "15px", color: "#000000" }}
                    className={[selectProperty != "" ? '' : "disabled", "btn p-0  hide-arrow"].join(" ")}
                    href={`/properties/${selectProperty}`} target="_blank" rel="noopener noreferrer">{refValue}
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{marginRight: "10px",  transform:" rotate(270deg)" }} xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a> 

                <Autocomplete
                    options={props.properties}

                    id="combo-box-d"
                    getOptionLabel={(property: any) => {
                        const bedroomsLabel = property.bedrooms > 1 ? `${property.bedrooms} bedrooms` : `${property.bedrooms} bedroom`;
                        return `${property.ref} | ${property.location} | ${bedroomsLabel}`;
                    }}
                    disablePortal
                    renderInput={(params) => <TextField {...params} onChange={(value) => {
                        setSelectProperty(value.target.value)
                    }} />}
                    onChange={handleSelectChangeProperty}
                />
                <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختيار التحسين </label>
                <div className="multi-select"> 
                <FormControl sx={{ m: 1, width: "100%" }}>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectEnhancement}
                        onChange={handleSelectChangeEnhancement}
                        renderValue={(selected) => selected.join(' , ')}
                        MenuProps={MenuProps}>
                        {enahancement.map((enhance: any, index: any) => (
                            <MenuItem value={enhance.nameAr} key={index}>
                                <Checkbox checked={selectEnhancement.indexOf(enhance.nameAr) > -1} />
                                <ListItemText primary={enhance.nameAr} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl></div>
                {(selectEnhancement.length !== 0 && selectProperty !== "") ?<button type="button"  className="btn btn-primary generate marg-top20 " onClick={Enhance} >طوِّر</button> 
               :<button type="button" className="btn btn-primary generate marg-top20 disabled" >طوِّر</button>
               }
            </div>
        
        </div>
    )
}
export default ListingBoosterSelect;