import React, { useEffect, useState } from "react"
import UnderstandMain from "../understand-main"
import { GetEndpoints } from "../../../Services/Account";

const DocumentMain: React.FunctionComponent<{}> = (props) => {

    const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));

    const [documentTypeEndpoints, setDocumentTypeEndpoints] = useState(null);
    const [emiratesIDContentEndpoints, setEmiratesIDContentEndpoints] = useState(null);
    const [passportContentEndpoints, setPassportContentEndpoints] = useState(null);
    const [titleDeedContentEndpoints, setTitleDeedContentEndpoints] = useState(null);
    const [drivingLicenseContentEndpoints, setDrivingLicenseContentEndpoints] = useState(null);
    const [OCREndpoints, setOCREndpoints] = useState(null);


    useEffect(() => {
        fetchEndpoints("Document Type", setDocumentTypeEndpoints);
        fetchEndpoints("Emirates ID - Content (UAE)", setEmiratesIDContentEndpoints);
        fetchEndpoints("Passport - Content (International)", setPassportContentEndpoints);
        fetchEndpoints("Title Deed - Content (UAE)", setTitleDeedContentEndpoints);
        fetchEndpoints("Driving License Content (UAE)", setDrivingLicenseContentEndpoints);
        fetchEndpoints("OCR ", setOCREndpoints);

    }, []);

    const fetchEndpoints = async (name: any, setter: any) => {
        try {
            const data = await GetEndpoints(name);
            console.log(data);
            setter(data);
        } catch (error) {
            console.error("Error fetching endpoints:", error);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate"  >
            <UnderstandMain />
            <div className="tab-content">
                <div className="card-bottom">
                    <div className="row">
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/document-type" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.3333 2.66699H10.6667C6 2.66699 4 5.33366 4 9.33366V22.667C4 26.667 6 29.3337 10.6667 29.3337H21.3333C26 29.3337 28 26.667 28 22.667V9.33366C28 5.33366 26 2.66699 21.3333 2.66699ZM10.6667 16.3337H16C16.5467 16.3337 17 16.787 17 17.3337C17 17.8803 16.5467 18.3337 16 18.3337H10.6667C10.12 18.3337 9.66667 17.8803 9.66667 17.3337C9.66667 16.787 10.12 16.3337 10.6667 16.3337ZM21.3333 23.667H10.6667C10.12 23.667 9.66667 23.2137 9.66667 22.667C9.66667 22.1203 10.12 21.667 10.6667 21.667H21.3333C21.88 21.667 22.3333 22.1203 22.3333 22.667C22.3333 23.2137 21.88 23.667 21.3333 23.667ZM24.6667 12.3337H22C19.9733 12.3337 18.3333 10.6937 18.3333 8.66699V6.00033C18.3333 5.45366 18.7867 5.00033 19.3333 5.00033C19.88 5.00033 20.3333 5.45366 20.3333 6.00033V8.66699C20.3333 9.58699 21.08 10.3337 22 10.3337H24.6667C25.2133 10.3337 25.6667 10.787 25.6667 11.3337C25.6667 11.8803 25.2133 12.3337 24.6667 12.3337Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        نوع المستند
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تحديد نوع المستند الموجود في الصورة، مثل بطاقة الهوية الإماراتية (الإمارات)، جواز السفر (دولي)، سند الملكية (الإمارات)، أو رخصة القيادة (الإمارات).</p>
                                    <br /><p className="end_points" dir="auto">{documentTypeEndpoints} {documentTypeEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/emirates-id" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance') || platform.understand.includes('Emirates ID - Content (UAE)'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.3599 4.46634C23.7333 4.37301 23.0133 4.33301 21.9999 4.33301H9.99992C8.99992 4.33301 8.26659 4.37301 7.67992 4.45301C3.21325 4.94634 2.33325 7.59967 2.33325 11.9997V19.9997C2.33325 24.3997 3.21325 27.053 7.63992 27.533C8.26659 27.6263 8.98659 27.6663 9.99992 27.6663H21.9999C22.9999 27.6663 23.7333 27.6263 24.3199 27.5463C28.7866 27.053 29.6666 24.413 29.6666 19.9997V11.9997C29.6666 7.59967 28.7866 4.94634 24.3599 4.46634ZM13.4533 11.9997C14.1999 11.9997 14.7999 12.5997 14.7999 13.333C14.7999 14.0663 14.1999 14.6663 13.4666 14.6663C12.7333 14.6663 12.1333 14.0663 12.1333 13.333C12.1333 12.5997 12.7199 11.9997 13.4533 11.9997ZM9.45325 11.9997C10.2133 11.9997 10.7999 12.5997 10.7999 13.333C10.7999 14.0663 10.1999 14.6663 9.46659 14.6663C8.73325 14.6663 8.13325 14.0663 8.13325 13.333C8.13325 12.5997 8.71992 11.9997 9.45325 11.9997ZM22.6666 21.6663H9.35992C8.81325 21.6663 8.34659 21.213 8.34659 20.6663C8.34659 20.1197 8.78659 19.6663 9.33325 19.6663H22.6666C23.2133 19.6663 23.6666 20.1197 23.6666 20.6663C23.6666 21.213 23.2133 21.6663 22.6666 21.6663ZM22.6666 14.333H17.9999C17.4533 14.333 16.9999 13.8797 16.9999 13.333C16.9999 12.7863 17.4533 12.333 17.9999 12.333H22.6666C23.2133 12.333 23.6666 12.7863 23.6666 13.333C23.6666 13.8797 23.2133 14.333 22.6666 14.333Z" fill="#46B49E" />
                                        </svg>

                                    </div>
                                    <h2>
                                        بطاقة الهوية الإماراتية - المحتوى (الإمارات)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تحديد وتنظيم محتوى بطاقة الهوية الإماراتية في الإمارات.</p>
                                    <br /><p className="end_points" dir="auto">{emiratesIDContentEndpoints} {emiratesIDContentEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/passport-content" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 2.66699H8C5.78667 2.66699 4 4.44033 4 6.62699V21.1737C4 23.3603 5.78667 25.147 8 25.147H9.01333C10.0667 25.147 11.0933 25.5603 11.84 26.307L14.12 28.5603C15.16 29.587 16.84 29.587 17.88 28.5603L20.16 26.307C20.9067 25.5603 21.9333 25.147 22.9867 25.147H24C26.2133 25.147 28 23.3603 28 21.1737V6.62699C28 4.44033 26.2133 2.66699 24 2.66699ZM16 7.40033C17.44 7.40033 18.6 8.57366 18.6 10.0003C18.6 11.4137 17.48 12.547 16.0933 12.6003C16.04 12.6003 15.96 12.6003 15.8933 12.6003C14.4933 12.547 13.3867 11.4137 13.3867 10.0003C13.4 8.57366 14.56 7.40033 16 7.40033ZM19.6667 19.587C17.6533 20.9337 14.3467 20.9337 12.3333 19.587C10.56 18.4137 10.56 16.467 12.3333 15.2803C14.36 13.9337 17.6667 13.9337 19.6667 15.2803C21.44 16.467 21.44 18.4003 19.6667 19.587Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        جواز السفر - المحتوى (دولي)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تحديد وتنظيم محتوى جميع جوازات السفر الدولية.</p>
                                    <br /><p className="end_points" dir="auto">{passportContentEndpoints} {passportContentEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/title-deed" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance') || platform.understand.includes('Title Deed - Content (UAE)'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.3599 4.46634C23.7333 4.37301 23.0133 4.33301 21.9999 4.33301H9.99992C8.99992 4.33301 8.26659 4.37301 7.67992 4.45301C3.21325 4.94634 2.33325 7.59967 2.33325 11.9997V19.9997C2.33325 24.3997 3.21325 27.053 7.63992 27.533C8.26659 27.6263 8.98659 27.6663 9.99992 27.6663H21.9999C22.9999 27.6663 23.7333 27.6263 24.3199 27.5463C28.7866 27.053 29.6666 24.413 29.6666 19.9997V11.9997C29.6666 7.59967 28.7866 4.94634 24.3599 4.46634ZM13.4533 11.9997C14.1999 11.9997 14.7999 12.5997 14.7999 13.333C14.7999 14.0663 14.1999 14.6663 13.4666 14.6663C12.7333 14.6663 12.1333 14.0663 12.1333 13.333C12.1333 12.5997 12.7199 11.9997 13.4533 11.9997ZM9.45325 11.9997C10.2133 11.9997 10.7999 12.5997 10.7999 13.333C10.7999 14.0663 10.1999 14.6663 9.46659 14.6663C8.73325 14.6663 8.13325 14.0663 8.13325 13.333C8.13325 12.5997 8.71992 11.9997 9.45325 11.9997ZM22.6666 21.6663H9.35992C8.81325 21.6663 8.34659 21.213 8.34659 20.6663C8.34659 20.1197 8.78659 19.6663 9.33325 19.6663H22.6666C23.2133 19.6663 23.6666 20.1197 23.6666 20.6663C23.6666 21.213 23.2133 21.6663 22.6666 21.6663ZM22.6666 14.333H17.9999C17.4533 14.333 16.9999 13.8797 16.9999 13.333C16.9999 12.7863 17.4533 12.333 17.9999 12.333H22.6666C23.2133 12.333 23.6666 12.7863 23.6666 13.333C23.6666 13.8797 23.2133 14.333 22.6666 14.333Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        سند الملكية - المحتوى (الإمارات)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تحديد وتنظيم محتوى سند الملكية في الإمارات.</p>
                                    <br /><p className="end_points" dir="auto">{titleDeedContentEndpoints} {titleDeedContentEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>

                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/driving-license-content" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.3334 5.66699C21.3334 7.32033 19.9867 8.66699 18.3334 8.66699H13.6667C12.8401 8.66699 12.0934 8.33366 11.5467 7.78699C11.0001 7.24033 10.6667 6.49366 10.6667 5.66699C10.6667 4.01366 12.0134 2.66699 13.6667 2.66699H18.3334C19.1601 2.66699 19.9067 3.00033 20.4534 3.54699C21.0001 4.09366 21.3334 4.84033 21.3334 5.66699Z" fill="#46B49E" />
                                            <path d="M25.1066 6.70662C24.7999 6.45328 24.4533 6.25328 24.0799 6.10662C23.6933 5.95995 23.3066 6.26662 23.2266 6.66662C22.7733 8.94662 20.7599 10.6666 18.3333 10.6666H13.6666C12.3333 10.6666 11.0799 10.1466 10.1333 9.19995C9.43992 8.50662 8.95992 7.62662 8.77325 6.67995C8.69325 6.27995 8.29325 5.95995 7.90659 6.11995C6.35992 6.74662 5.33325 8.15995 5.33325 10.9999V23.9999C5.33325 27.9999 7.71992 29.3333 10.6666 29.3333H21.3333C24.2799 29.3333 26.6666 27.9999 26.6666 23.9999V10.9999C26.6666 8.82662 26.0666 7.49328 25.1066 6.70662ZM10.6666 16.3333H15.9999C16.5466 16.3333 16.9999 16.7866 16.9999 17.3333C16.9999 17.8799 16.5466 18.3333 15.9999 18.3333H10.6666C10.1199 18.3333 9.66659 17.8799 9.66659 17.3333C9.66659 16.7866 10.1199 16.3333 10.6666 16.3333ZM21.3333 23.6666H10.6666C10.1199 23.6666 9.66659 23.2133 9.66659 22.6666C9.66659 22.1199 10.1199 21.6666 10.6666 21.6666H21.3333C21.8799 21.6666 22.3333 22.1199 22.3333 22.6666C22.3333 23.2133 21.8799 23.6666 21.3333 23.6666Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        رخصة القيادة - المحتوى (الإمارات)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تحديد وتنظيم محتوى رخص القيادة في الإمارات.</p>
                                    <br /><p className="end_points" dir="auto">{drivingLicenseContentEndpoints} {drivingLicenseContentEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/document/OCR" className={`card-body generate-card image-content-height ${storedPlatforms.some((platform: any) => platform.understand && (platform?.understand?.includes('all') || platform.understand.includes('Document Content and Compliance'))) ? "" : "disabled"}`}>
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.3334 5.66699C21.3334 7.32033 19.9867 8.66699 18.3334 8.66699H13.6667C12.8401 8.66699 12.0934 8.33366 11.5467 7.78699C11.0001 7.24033 10.6667 6.49366 10.6667 5.66699C10.6667 4.01366 12.0134 2.66699 13.6667 2.66699H18.3334C19.1601 2.66699 19.9067 3.00033 20.4534 3.54699C21.0001 4.09366 21.3334 4.84033 21.3334 5.66699Z" fill="#46B49E" />
                                            <path d="M25.1066 6.70662C24.7999 6.45328 24.4533 6.25328 24.0799 6.10662C23.6933 5.95995 23.3066 6.26662 23.2266 6.66662C22.7733 8.94662 20.7599 10.6666 18.3333 10.6666H13.6666C12.3333 10.6666 11.0799 10.1466 10.1333 9.19995C9.43992 8.50662 8.95992 7.62662 8.77325 6.67995C8.69325 6.27995 8.29325 5.95995 7.90659 6.11995C6.35992 6.74662 5.33325 8.15995 5.33325 10.9999V23.9999C5.33325 27.9999 7.71992 29.3333 10.6666 29.3333H21.3333C24.2799 29.3333 26.6666 27.9999 26.6666 23.9999V10.9999C26.6666 8.82662 26.0666 7.49328 25.1066 6.70662ZM10.6666 16.3333H15.9999C16.5466 16.3333 16.9999 16.7866 16.9999 17.3333C16.9999 17.8799 16.5466 18.3333 15.9999 18.3333H10.6666C10.1199 18.3333 9.66659 17.8799 9.66659 17.3333C9.66659 16.7866 10.1199 16.3333 10.6666 16.3333ZM21.3333 23.6666H10.6666C10.1199 23.6666 9.66659 23.2133 9.66659 22.6666C9.66659 22.1199 10.1199 21.6666 10.6666 21.6666H21.3333C21.8799 21.6666 22.3333 22.1199 22.3333 22.6666C22.3333 23.2133 21.8799 23.6666 21.3333 23.6666Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        التعرف الضوئي على النصوص (أي مستند)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p></p>
                                    <br /><p className="end_points" dir="auto">{OCREndpoints} {OCREndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 وثيقة </p>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default DocumentMain;