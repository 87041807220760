import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react"
import { createProject, getProjects } from "../../../Services/Projects";

const UploadAudio: React.FunctionComponent<{ onSendAudio: any }> = (props) => {

    const [selectedProjectId, setSelectedProjectId] = useState<any>("");
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [projectValue, setprojectValue] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [upload, setUpload] = useState(true);
    const [listAudios, setListAudios] = useState<any>([]);

    useEffect(() => {
        const type: any = "audio";
        getProjects(type).then((data) => {
            setProjects(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        });
    }, [change]);

    const handleFileChange = (event: any) => {
        let files: any = " "
        setListAudios([])
        setUpload(false)
        files = Array.from(event.target.files);
        setSelectedFiles(files);
        setUpload(false)
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (event) => {
                setListAudios((prevSelectedAudios: any) =>
                    [...prevSelectedAudios, event?.target?.result]
                );
            };
        }

    };

    const handleSelectChangeProject = (event: any) => {
        setSelectedProjectId(event.target.value)
    }

    const handleCreateProject = (event: any) => {
        setprojectValue(event.target.value);
    }

    const Understand = () => {
        const understandObj = {
            "audio": selectedFiles
        }
        const formData = new FormData();
        formData.append('project', selectedProjectId);
        selectedFiles.forEach((file: any) => {
            formData.append(`audio`, file);
        });
        // setSelectedFiles([]);
        // setSelectedProjectId("")
        // setListAudios([])
        props.onSendAudio(formData, understandObj);
    };

    const create = () => {
        const project = {
            "accountId": "64c8ef881cfccb7ae502c64e",
            "projectName": projectValue,
            "projectType": "audio"
        }
        createProject(project).then((data) => {
            setChange(!change)
        }).catch(err => {
            console.log("Error");
        })

    }

    return loading ? <div className="card"></div> : (
        <div className="card">
            <div className="card-body padd-24">
                <label className="form-label" htmlFor="selectTypeOpt">Select Project</label>
                <FormControl fullWidth>
                    <Select
                        className="custom-select"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Choose' }}
                        value={selectedProjectId}
                        onChange={handleSelectChangeProject}
                    >
                        <MenuItem value="" disabled>
                            Select Project
                        </MenuItem>
                        {projects.map((project: any) => (
                            <MenuItem value={project._id} key={project._id}>{project.projectName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="row mt-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <a className=" mb-3" id="button-addnew" onClick={(e) => { setShowInput(!showInput) }}><i className="fa-solid fa-plus"></i> Create New</a>
                        <div className="container_image mb-3" style={{ marginLeft: "20px" }}>
                            <input
                                type="file"
                                id="input-file"
                                name="input-file"
                                accept="audio/*"
                                onChange={handleFileChange}
                                hidden
                            />
                            <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                                <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                Upload Audio
                            </label>
                        </div>
                    </div>
                    {upload ? <></>
                        : <>
                            <div className="selected-audios" style={{ height: 'auto' }}>
                                {listAudios && listAudios.map((audio: any, index: any) => (
                                    <div key={index}>
                                        <input type="checkbox" id={index} value={index} />
                                        <label htmlFor={index} className="">
                                            <audio controls >
                                                <source src={audio} type="audio/mp3" key={index} />
                                            </audio>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </>}
                    {showInput && <div className="input-group  mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Create a project"
                            aria-label="Create a project"
                            aria-describedby="button-addnew"
                            onChange={handleCreateProject}
                        />
                        <button className="btn btn-outline-primary" onClick={create} type="button" id="button-addon2">Create</button>
                    </div>}
                </div>

                <button type="button" className={`btn btn-primary generate marg-top20 ${selectedFiles.length !== 0 && selectedProjectId !== "" ? "" : "disabled"}`} onClick={Understand} >Understand</button>

            </div>
        </div>
    )
}
export default UploadAudio;