import React, { useState } from "react"
import { Autocomplete, TextField } from "@mui/material";
import { propertieImages } from "../../../Services/Properties";

const EnhanceImageSelect: React.FunctionComponent<{ properties: any, onEnhancedImage: any }> = (props) => {

    const [selectProperty, setSelectProperty] = useState<any>("");
    const [selectImages, setSelectImages] = useState<any>([]);
    const [listImages, setListImages] = useState<any>();
    const [refValue, setRefValue] = useState<any>()
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState(false);
    const [upload, setUpload] = useState(true);

    const handleSelectChangeProperty = (event: any, newValue: any) => {

        if (newValue) {
            setUpload(true)
            setIsDisabledUpload(!isDisabledUpload)
            setSelectImages([])
            setRefValue(newValue.ref)
            setSelectProperty(newValue.id);
            propertieImages(newValue.id).then((data) => {
                setListImages(data)
            });
        }

    }

    const HandleChangeUpload = (event: any) => {
        setListImages(null)
        setSelectImages([]);
        setRefValue("")
        setSelectProperty("")
        setUpload(false)
        setIsDisabledSelect(!isDisabledSelect)
        const numberOfFiles = event.target.files.length;
        for (let i = 0; i < numberOfFiles; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i + 1,
                    "src": base_64,
                    "ext": ext[1]
                }
                setSelectImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );
            };
        }
    }

    const handleSelectImages = (image: any) => {
        setSelectImages((prevSelectedImages: any) =>
            prevSelectedImages.includes(image)
                ? prevSelectedImages.filter((selectedImage: any) => selectedImage !== image)
                : [...prevSelectedImages, image]
        );
    }
    const handChangeSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectImages("")
        listImages.map((image: any) => (
            setSelectImages((prevSelectedImages: any) =>
                prevSelectedImages.includes(image)
                    ? prevSelectedImages.filter((selectedImage: any) => selectedImage !== image)
                    : [...prevSelectedImages, image]
            )
        ));
    }

    const Enhance = () => {
        const enhanceObj = {
            "id": selectProperty,
            "images": selectImages
        }
        setIsDisabledSelect(false)
        setIsDisabledUpload(false)
        props.onEnhancedImage(enhanceObj);
    };

    return (

        <div className="card">
            <div className="card-body padd-24">
                <label className="form-label" htmlFor="selectTypeOpt">Select Property</label>
                <a type="button" style={{ float: "right", height: "15px", color: "#000000" }}
                    className={[selectProperty != "" ? '' : "disabled", "btn p-0  hide-arrow"].join(" ")}
                    href={`/properties/${selectProperty}`} target="_blank" rel="noopener noreferrer">{refValue}
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>

                <Autocomplete
                    options={props.properties}
                    id="combo-box-d"
                    getOptionLabel={(property: any) => {
                        const bedroomsLabel = property.bedrooms > 1 ? `${property.bedrooms} bedrooms` : `${property.bedrooms} bedroom`;
                        return `${property.ref} | ${property.location} | ${bedroomsLabel}`;
                    }}
                    disablePortal
                    renderInput={(params) => <TextField {...params} onChange={(value) => {
                        setSelectProperty(value.target.value)
                    }} />}
                    onChange={handleSelectChangeProperty}
                />

                <div className="row mt-3">

                    <div className={`container_image `}>
                        <input
                            type="file"
                            id="input-file"
                            name="input-file"
                            accept="image/*"
                            multiple
                            onChange={HandleChangeUpload}
                            hidden
                        />
                        <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                            <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                            Upload Images
                        </label>
                    </div>
                    {upload && selectImages ? <></>
                        : <>
                            <div className="selected-images" style={{ marginBottom: "0px" }} >
                                {selectImages.map((image: any, index: any) => (
                                    <div key={image.id}>
                                        <label htmlFor={image.id} className="label1">
                                            <img src={image.src} className="image" key={image.id} /></label>
                                    </div>
                                ))}
                            </div>
                        </>}
                </div>


                {listImages == null ? <></> : <><label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>Selected Images</label>
                    <a className="select-all" onClick={handChangeSelectAll}>Select All</a></>}
                {listImages == null ? <div></div> : <div className="image-list">
                    {listImages.map((image: any, index: any) => (
                        <div key={image.id + "_" + index}><input type="checkbox" id={image.id + "_" + index} value={image.src} onChange={() => { handleSelectImages(image) }} checked={selectImages.includes(image)} />
                            <label htmlFor={image.id + "_" + index} className="label">
                                <img src={image.src} className="image" key={image.id + "_" + index} /></label>
                        </div>))}
                </div>}
                <button type="button" className={`btn btn-primary generate marg-top20 ${selectImages.length == 0 ? 'disabled' : ''}`} onClick={Enhance}>Enhance</button>
            </div>

        </div>
    )
}
export default EnhanceImageSelect;