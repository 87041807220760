
import { useEffect, useState } from 'react';
import { acceptInvit } from '../../Services/Users';
import { useNavigate, useParams } from 'react-router-dom'

const Invitation: React.FunctionComponent<{}> = (props) => {

    const { id } = useParams()
    const [firstName, setFirstName] = useState<any>();
    const [lastName, setLasteName] = useState<any>();
    const [password, setPassword] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        const user = "";
        acceptInvit(user, id).then((data) => {
            setLoading(false)
            setEmail(data.email)
        }).catch(err => {
            console.log("Error");
        });
    }, [])


    const handleChangeLastName = (event: any) => {
        setLasteName(event.target.value)
    }
    const handleChangeFirstName = (event: any) => {
        setFirstName(event.target.value)
    }
    const handleChangePassword = (event: any) => {
        setPassword(event.target.value)
    }

    const SignUp = () => {

        const user = {
            "firstName": firstName,
            "lastename": lastName,
            "statu": true,
            "password": password,
            "email": email 
        }

        acceptInvit(user, id).then((data) => {
            navigate('/login')
        }).catch(err => {
            console.log("Error");
        });
    }

    return loading ? <div className="">تحميل...    </div> : (

        <div className="app-account1 " dir="auto"> 
            <div className="login-marg">
              <div className="row">
                    <img src="/assets/images/icons/Coralytics.png" className='invit'style={{marginLeft:"0px", marginRight:"7rem"}} />
                </div> 
                <div className="title" style={{ color: " black", fontSize: "20px" }}>لقد تمت دعوتك إلى Coralytics، يرجى إعداد حسابك عن طريق إدخال التفاصيل التالية. </div>
                <div className="form">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label ">عنوان البريد الإلكتروني</label>
                        <input type="text" className="form-control" name="email" placeholder=' البريد الإلكتروني' value={email} disabled />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="firstname" className="form-label ">الاسم الأول</label>
                        <input type="text" className="form-control" name="firstname" placeholder='الاسم الأول'
                            onChange={handleChangeFirstName} />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastname" className="form-label ">اسم العائلة</label>
                        <input type="text" className="form-control" name="lastname" placeholder='اسم العائلة'
                            onChange={handleChangeLastName} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">كلمة المرور</label>
                        <input type="password" className="form-control" name="password" placeholder='كلمة المرور'
                            onChange={handleChangePassword} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confpassword" className="form-label">تأكيد كلمة المرور</label>
                        <input type="password" className="form-control" name="confpassword" placeholder='كلمة المرور' />
                    </div>
                    
                        <button type="submit" className='btn btn-primary' style={{ width: "100%" }} onClick={SignUp}>اشتراك</button>
                   
                   
                    <div className='row mt-3'>
                        <p>من خلال الانضمام، فإنك توافق على <span>شروط الخدمة</span> و<span>سياسة الخصوصية</span> في Coralytics</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Invitation