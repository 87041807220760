import React, { useEffect, useState } from "react"
import DialogActions from '@mui/material/DialogActions';
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContractCreator from "./contract-creator";
import { UpdateContractsService, UploadEmaratesIdService, UploadTermsService, UploadTitleDeedService } from "../../../Services/GenerateLease";
import ContractInformation from "./contract-information";
const Url = process.env.REACT_APP_URl;

const UpdateContract: React.FunctionComponent<{ content: any, contractId: any, properties: any, onUpdateContract: any }> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isSpinnerUpdate, setIsSpinnerUpdate] = useState<boolean>(false);
    const [contractValue, setContractValue] = useState<any>("")
    const [dateValue, setDateValue] = useState<any>("")
    const [propertyUsage, setPropertyUsage] = useState<any>("")
    const [contractNo, setContractNo] = useState<any>("")
    const [ownerNameValue, setOwnerNameValue] = useState<any>("")
    const [landlordName, setLandlordName] = useState<any>("")
    const [emiratesIDValue, setEmiratesIDValue] = useState<any>("")
    const [licenseNoValue, setLicenseNoValue] = useState<any>("")
    const [licensingAuthority, setLicensingAuthority] = useState<any>("")
    const [lessorEmail, setLessorEmail] = useState<any>("")
    const [landlordPhone, setLandlordPhone] = useState<any>("")
    const [isSpinnerOwner, setIsSpinnerOwner] = useState<boolean>(false);
    const [isErrorOwner, setIsErrorOwner] = useState(false);

    const [isErrorTenant, setIsErrorTenant] = useState(false);
    const [isSpinnerTenant, setIsSpinnerTenant] = useState<boolean>(false);
    const [tenantName, setTenantName] = useState<any>("")
    const [passportNumber, setPassportNumber] = useState<any>("")
    const [emiratesIDTenant, setEmiratesIDTenant] = useState<any>("")
    const [licenseNoTenant, setLicenseNoTenant] = useState<any>("")
    const [licensingAuthorityTenant, setLicensingAuthorityTenant] = useState<any>("")
    const [tenantEmail, setTenantEmail] = useState<any>("")
    const [tenantPhone, setTenantPhone] = useState<any>("")

    const [plotNoValue, setPlotNoValue] = useState<any>("")
    const [location, setLocation] = useState<any>("")
    const [buildingName, setBuildingName] = useState<any>("")
    const [propertyNo, setPropertyNo] = useState<any>("")
    const [propertyType, setPropertyType] = useState<any>("")
    const [propertySize, setPropertySize] = useState<any>("")
    const [makaniNo, setMakaniNo] = useState<any>("")
    const [premisesNo, setPremisesNo] = useState<any>("")
    const [isSpinnerProperty, setIsSpinnerProperty] = useState<boolean>(false);
    const [isErrorProperty, setIsErrorProperty] = useState<boolean>(false);

    const [additionalTerms1, setAdditionalTerms1] = useState<any>("")
    const [additionalTerms2, setAdditionalTerms2] = useState<any>("")
    const [additionalTerms3, setAdditionalTerms3] = useState<any>("")
    const [additionalTerms4, setAdditionalTerms4] = useState<any>("")
    const [additionalTerms5, setAdditionalTerms5] = useState<any>("")
    const [pdfUpload, setPdfUpload] = useState<any>("")
    const [isSpinnerTerms, setIsSpinnerTerms] = useState<boolean>(false);
    const [isErrorTerms, setIsErrorTerms] = useState<boolean>(false);

    useEffect(() => {

        setOwnerNameValue(props.content[0] ? props.content[0]?.owner?.owner_name : "")
        setLandlordName(props.content[0] ? props.content[0]?.owner?.lessor_name : "")
        setEmiratesIDValue(props.content[0] ? props.content[0]?.owner?.lessor_emirates_id : "")
        setLicenseNoValue(props.content[0] ? props.content[0]?.owner?.lessor_license_no : "")
        setLicensingAuthority(props.content[0] ? props.content[0]?.owner?.lessor_licensing_authority : "")
        setLessorEmail(props.content[0] ? props.content[0]?.owner?.lessor_email : "")
        setLandlordPhone(props.content[0] ? props.content[0]?.owner?.lessor_phone : "")

        setTenantName(props.content[0] ? props.content[0]?.tenant?.tenant_name : "")
        setPassportNumber(props.content[0] ? props.content[0]?.tenant?.number_of_co_occupants : "")
        setEmiratesIDTenant(props.content[0] ? props.content[0]?.tenant?.tenant_emirates_id_ : "")
        setLicenseNoTenant(props.content[0] ? props.content[0]?.tenant?.tenant_license_no : "")
        setLicensingAuthorityTenant(props.content[0] ? props.content[0]?.tenant?.tenant_licensing_authority : "")
        setTenantEmail(props.content[0] ? props.content[0]?.tenant?.tenant_email : "")
        setTenantPhone(props.content[0] ? props.content[0]?.tenant?.tenant_phone : "")

        setPlotNoValue(props.content[0] ? props.content[0]?.property?.plot_no : "")
        setLocation(props.content[0] ? props.content[0]?.property?.location : "")
        setBuildingName(props.content[0] ? props.content[0]?.property?.building_name : "")
        setPropertyNo(props.content[0] ? props.content[0]?.property?.property_no : "")
        setPropertyType(props.content[0] ? props.content[0]?.property?.property_type : "")
        setPropertySize(props.content[0] ? props.content[0]?.property?.property_area : "")
        setMakaniNo(props.content[0] ? props.content[0]?.property?.makani_no : "")
        setPremisesNo(props.content[0] ? props.content[0]?.property?.premises_no : "")

        setAdditionalTerms1(props.content[1] ? props.content[1]?.additional_terms?.term1 : "")
        setAdditionalTerms2(props.content[1] ? props.content[1]?.additional_terms?.term2 : "")
        setAdditionalTerms3(props.content[1] ? props.content[1]?.additional_terms?.term3 : "")
        setAdditionalTerms4(props.content[1] ? props.content[1]?.additional_terms?.term4 : "")
        setAdditionalTerms5(props.content[1] ? props.content[1]?.additional_terms?.term5 : "")
        setPdfUpload(props.content[1] ? props.content[1]?.additional_terms?.pdf : "")
    }, [props.content]);

    const edit = () => {
        setOpen(true);
    }
    const formatDate = (inputDate: any) => {
        if (!inputDate) {
            return "";
        }
        const date = new Date(inputDate);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`
        return formattedDate;
    };

    const GenerateContractHandler = (property_id: any, contract_date: any, contract_no: any) => {
        setDateValue(contract_date);
        setPropertyUsage(property_id);
        setContractNo(contract_no);
    }
    const ContractInformationHandler = (obj: any) => {
        setContractValue(obj)

    }
    const FUpdateContract = () => {
        setIsSpinnerUpdate(true)
        const objData: any = [
            {
                "header":
                {
                    'date': formatDate(dateValue),
                    'contract_number': contractNo
                },
                "owner": {
                    'owner_name': ownerNameValue,
                    'lessor_name': landlordName,
                    'lessor_emirates_id': emiratesIDValue,
                    'lessor_license_no': licenseNoValue,
                    'lessor_licensing_authority': licensingAuthority,
                    'lessor_email': lessorEmail,
                    'lessor_phone': landlordPhone
                },
                "tenant": {
                    'tenant_name': tenantName,
                    'tenant_emirates_id_': emiratesIDTenant,
                    'tenant_license_no': licenseNoTenant,
                    'tenant_licensing_authority': licensingAuthorityTenant,
                    'tenant_email': tenantEmail,
                    'tenant_phone': tenantPhone,
                    'number_of_co_occupants': passportNumber
                },
                "property":
                {
                    'property_usage': {
                        propertyUsage
                    },
                    'plot_no': plotNoValue,
                    'building_name': buildingName,
                    'property_type': propertyType,
                    'location': location,
                    'makani_no': makaniNo,
                    'property_no': propertyNo,
                    'property_area': propertySize,
                    'premises_no': premisesNo
                },
                "contract": contractValue
            },
            {
                "additional_terms": {
                    "term1": additionalTerms1,
                    "term2": additionalTerms2,
                    "term3": additionalTerms3,
                    "term4": additionalTerms4,
                    "term5": additionalTerms5,
                    "pdf": pdfUpload
                }
            }
        ]
        UpdateContractsService(objData, props.contractId).then((data) => {
            setOpen(false);
            setIsSpinnerUpdate(false)
            props.onUpdateContract(true);

        }).catch(() => {
            setOpen(false);
            setIsSpinnerUpdate(false)
            props.onUpdateContract(false);
        })

    }

    //   Owner / Lessor Information
    const handleOwnerName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOwnerNameValue(event.target.value);
    }
    const handleLandlordName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLandlordName(event.target.value);
    }
    const handleEmiratesID = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmiratesIDValue(event.target.value);
    }
    const handleLicenseNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicenseNoValue(event.target.value);
    }
    const handleLicensingAuthority = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicensingAuthority(event.target.value);
    }
    const handleLessorEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLessorEmail(event.target.value);
    }
    const handleLandlordPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLandlordPhone(event.target.value);
    }
    const HandleChangeUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("No files selected.");
            return;
        }
        setIsSpinnerOwner(true);
        setIsErrorOwner(false);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };

            UploadEmaratesIdService([image]).then((data) => {
                setIsSpinnerOwner(false);
                setOwnerNameValue(data.data[0].content['name'] ? data.data[0].content['name'] : "");
                setLandlordName(data.data[0].content['name'] ? data.data[0].content['name'] : "");
                setEmiratesIDValue(data.data[0].content["id_num"] ? data.data[0].content["id_num"] : "")
            })
                .catch((err) => {
                    setIsErrorOwner(true);
                    setIsSpinnerOwner(false);
                    console.log("error", err);
                });
        };

        reader.onerror = () => {
            setIsErrorOwner(true);
            setIsSpinnerOwner(false);
            console.log("Error reading file");
        };

        setTimeout(() => {
            setIsErrorOwner(false);
        }, 5000);
    }

    // Tenant Information
    const handleTenantName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTenantName(event.target.value);
    }
    const handlePassportNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassportNumber(event.target.value);
    }
    const handleEmiratesIDTenant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmiratesIDTenant(event.target.value);
    }
    const handleLicenseNoTenant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicenseNoTenant(event.target.value);
    }
    const handleLicensingAuthorityTenant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicensingAuthorityTenant(event.target.value);
    }
    const handleTenantEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTenantEmail(event.target.value);
    }
    const handleLandlordPhoneTenant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTenantPhone(event.target.value);
    }
    const HandleChangeUploadTenant = (event: any) => {
        const file = event.target.files[0];
        setIsSpinnerTenant(true);
        setIsErrorTenant(false);
        if (!file) {
            console.log("No files selected.");
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };
            UploadEmaratesIdService([image]).then((data) => {
                setIsSpinnerTenant(false)
                setTenantName(data.data[0].content['name'] ? data.data[0].content['name'] : "");
                setEmiratesIDTenant(data.data[0].content["id_num"] ? data.data[0].content["id_num"] : "")
            })
                .catch((err) => {
                    setIsErrorTenant(true);
                    setIsSpinnerTenant(false);
                    console.log("error", err);
                });
        };

        reader.onerror = () => {
            setIsErrorTenant(true);
            setIsSpinnerTenant(false);
            console.log("Error reading file");
        };

        setTimeout(() => {
            setIsErrorTenant(false);
        }, 5000);
    }

    // Additional Terms

    const handleAdditionalTerms1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalTerms1(event.target.value);
    }
    const handleAdditionalTerms2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalTerms2(event.target.value);
    }
    const handleAdditionalTerms3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalTerms3(event.target.value);
    }
    const handleAdditionalTerms4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalTerms4(event.target.value);
    }
    const handleAdditionalTerms5 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalTerms5(event.target.value);
    }

    const HandleChangeUploadTerms = (event: any) => {
        setPdfUpload("")
        const file = event.target.files[0];

        if (!file) {
            console.log("No files selected.");
            return;
        }
        setIsErrorTerms(false)
        setIsSpinnerTerms(true)

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const image = {
                "id": 1,
                "src": base64,
            }
            UploadTermsService([image]).then((data) => {
                setIsSpinnerTerms(false)
                setPdfUpload(Url + data.additional_terms ? Url + data.additional_terms : "")
            }).catch((err) => {
                setIsErrorTerms(true);
                setIsSpinnerTerms(false);
                console.log("Error downloading file :", err);
            });
        };

        reader.onerror = () => {
            setIsErrorTerms(true);
            setIsSpinnerTerms(false);
            console.log("Error reading file.");
        };

        setTimeout(() => {
            setIsErrorTerms(false);
        }, 5000);
    };

    const view = () => {
        window.open(pdfUpload, '_blank');
    };

    //  Property Information
    const handlePlotNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlotNoValue(event.target.value);
    }
    const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocation(event.target.value);
    }
    const handleBuildingName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBuildingName(event.target.value);
    }
    const handlePropertyNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertyNo(event.target.value);
    }
    const handlePropertyType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertyType(event.target.value);
    }
    const handlePropertySize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertySize(event.target.value);
    }
    const handleMakaniNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMakaniNo(event.target.value);
    }
    const handlePremisesNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPremisesNo(event.target.value);
    }

    const HandleChangeUploadProperty = (event: any) => {

        const file = event.target.files[0]
        setIsSpinnerProperty(true)
        setIsErrorProperty(false)

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };
            UploadTitleDeedService([image]).then((data) => {
                setIsSpinnerProperty(false)
                setPlotNoValue(data.data[0].content['Plot No'] ? data.data[0].content['Plot No'] : "");
                setBuildingName(data.data[0].content['Building Name'] ? data.data[0].content['Building Name'] : "");
                setPropertyNo(data.data[0].content['Property No'] ? data.data[0].content['Property No'] : "")
                setPropertyType(data.data[0].content['Property Type'] ? data.data[0].content['Property Type'] : "")
                setLocation(data.data[0].content['Community'] ? data.data[0].content['Community'] : "")
                setPropertySize(data.data[0].content['Area Sq Meter'] ? data.data[0].content['Area Sq Meter'] : "")
            }).catch((err) => {
                setIsErrorProperty(true);
                setIsSpinnerProperty(false);
                console.log("Error downloading file :", err);
            });
        };

        reader.onerror = () => {
            setIsErrorProperty(true);
            setIsSpinnerProperty(false);
            console.log("Error reading file");
        };

        setTimeout(() => {
            setIsErrorProperty(false);
        }, 5000);
    };



    return (
        <>
            <button type="button" className="btn p-0  hide-arrow display-property" style={{ marginRight: "10px" }} onClick={edit} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#8C8D96" />
                </svg>
            </button>
            <li className="property-mobile">
                <a className="dropdown-item " style={{ display: "flex" }} onClick={edit}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#8C8D96" />
                    </svg>
                    <span className="align-middle" style={{ marginLeft: "10px" }}>Edit</span>
                </a>
            </li>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="customized-dialog-title">
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {props.content.length > 0 && <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Lease Contract Creator
                                                </div>
                                            </div>
                                        </div>
                                        <ContractCreator contentContract={props.content} onGenerateContract={GenerateContractHandler} properties={props.properties} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" >
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Owner / Lessor Information
                                                </div>
                                            </div>
                                        </div>
                                        {isErrorOwner && <div className="alert alert-danger alert-dismissible" role="alert">
                                            Upload failed
                                        </div>}
                                        <div className="row mt-4 padd-30 mb-3 ">
                                            <div className="container_image">
                                                <input
                                                    type="file"
                                                    id="upload-emi-id1"
                                                    name="upload-emi-id1"
                                                    accept="image/*" onChange={HandleChangeUpload}
                                                    hidden
                                                />
                                                <label className="btn-upload" id="button-addnew" htmlFor="upload-emi-id1" role="button">
                                                    <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                                    Upload Emirates ID
                                                </label>
                                                {isSpinnerOwner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </span>}
                                            </div>

                                        </div>

                                        <div className="row padd-30 mb-4">
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Owner Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3 "
                                                    value={ownerNameValue}
                                                    placeholder="Owner Name"
                                                    onChange={handleOwnerName}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Landlord Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3 "
                                                    value={landlordName}
                                                    placeholder="Landlord Name"
                                                    onChange={handleLandlordName}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Landlord Phone</label>
                                                <input
                                                    type="phone"
                                                    className="form-control input mb-3 "
                                                    value={landlordPhone}
                                                    placeholder="Landlord Phone"
                                                    onChange={handleLandlordPhone}
                                                />
                                            </div>
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Lessor's Emirates ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3 "
                                                    value={emiratesIDValue}
                                                    placeholder="Lessor's Emirates ID"
                                                    onChange={handleEmiratesID}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">License No. (Incase of a company)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3 "
                                                    value={licenseNoValue}
                                                    placeholder="License No."
                                                    onChange={handleLicenseNo}
                                                />
                                            </div>
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Lessor's Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control input mb-3 "
                                                    value={lessorEmail}
                                                    placeholder="Lessor's Email"
                                                    onChange={handleLessorEmail}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Licensing Authority (Incase of a company)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3 "
                                                    value={licensingAuthority}
                                                    placeholder="Licensing Authority"
                                                    onChange={handleLicensingAuthority}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" >
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Tenant Information
                                                </div>
                                            </div>
                                        </div>
                                        {isErrorTenant && <div className="alert alert-danger alert-dismissible" role="alert">
                                            Upload failed
                                        </div>}
                                        <div className="row mt-4 mb-3 padd-30" >
                                            <div className="container_image">
                                                <input
                                                    type="file"
                                                    id="upload-emi-id-tenant2"
                                                    name="upload-emi-id-tenant2"
                                                    accept="image/*" onChange={HandleChangeUploadTenant}
                                                    hidden
                                                />
                                                <label className="btn-upload" id="button-addnew" htmlFor="upload-emi-id-tenant2" role="button">
                                                    <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                                    Upload Emirates ID
                                                </label>
                                                {isSpinnerTenant == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </span>}
                                            </div>

                                        </div>

                                        <div className="row padd-30 mb-4" >
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Tenant Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={tenantName}
                                                    placeholder="Tenant Name"
                                                    onChange={handleTenantName}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Tenant Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={tenantPhone}
                                                    placeholder="Tenant Phone"
                                                    onChange={handleLandlordPhoneTenant}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Passport Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={passportNumber}
                                                    placeholder="Passport Number"
                                                    onChange={handlePassportNumber}
                                                />
                                            </div>
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Tenant's Emirates ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={emiratesIDTenant}
                                                    placeholder="Tenant's Emirates ID"
                                                    onChange={handleEmiratesIDTenant}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">License No. (Incase of a company)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={licenseNoTenant}
                                                    placeholder="License No."
                                                    onChange={handleLicenseNoTenant}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="selectTypeOpt">Tenant Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={tenantEmail}
                                                    placeholder="Tenant Email"
                                                    onChange={handleTenantEmail}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Licensing Authority (Incase of a company)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={licensingAuthorityTenant}
                                                    placeholder="Licensing Authority"
                                                    onChange={handleLicensingAuthorityTenant}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" >
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Property Information
                                                </div>
                                            </div>
                                        </div>
                                        {isErrorProperty && <div className="alert alert-danger alert-dismissible" role="alert">
                                            Upload failed
                                        </div>}
                                        <div className="row mb-3 mt-4 padd-30">
                                            <div className="container_image">
                                                <input
                                                    type="file"
                                                    id="upload-title1"
                                                    name="upload-title1"
                                                    accept="image/*" onChange={HandleChangeUploadProperty}
                                                    hidden
                                                />
                                                <label className="btn-upload" id="button-addnew" htmlFor="upload-title1" role="button">
                                                    <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                                    Upload Title Deed
                                                </label>
                                                {isSpinnerProperty == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </span>}
                                            </div>

                                        </div>

                                        <div className="row padd-30 mb-4 ">
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt" >Plot No.</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={plotNoValue}
                                                    placeholder="Plot No."
                                                    onChange={handlePlotNo}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt" >Property Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={propertyType}
                                                    placeholder="Property Type"
                                                    onChange={handlePropertyType}
                                                />

                                                <label className="form-label" htmlFor="selectTypeOpt" >Location</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={location}
                                                    placeholder="Location"
                                                    onChange={handleLocation}
                                                />
                                            </div>
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt" >Building Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={buildingName}
                                                    placeholder="Building Name"
                                                    onChange={handleBuildingName}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt" >Property No.</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={propertyNo}
                                                    placeholder="Property No."
                                                    onChange={handlePropertyNo}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt" >Premises No (DEWA)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={premisesNo}
                                                    placeholder="Premises No"
                                                    onChange={handlePremisesNo}
                                                />
                                            </div>
                                            <div className="col-md-4 ">

                                                <label className="form-label" htmlFor="selectTypeOpt" >Makani No.</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={makaniNo}
                                                    placeholder="Makani No."
                                                    onChange={handleMakaniNo}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt" >Property Size (S.M)</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={propertySize}
                                                    placeholder="Property Size"
                                                    onChange={handlePropertySize}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" >
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Contract Information
                                                </div>
                                            </div>
                                        </div>
                                        <ContractInformation contentContract={props.content} onGenerateContractInformation={ContractInformationHandler} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" >
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <div className="title-properties">
                                                    Additional Terms
                                                </div>
                                            </div>
                                        </div>
                                        {isErrorTerms && <div className="alert alert-danger alert-dismissible" role="alert">
                                            Upload failed
                                        </div>}
                                        <div className="row mt-4 padd-30 mb-3 ">
                                            <div className="container_image">
                                                <input
                                                    type="file"
                                                    id="input-file1"
                                                    name="input-file1"
                                                    accept=".pdf"

                                                    onChange={HandleChangeUploadTerms}
                                                    hidden
                                                />
                                                <label className="btn-upload" id="button-addnew" htmlFor="input-file1" role="button">
                                                    <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                                    Upload Additional Terms
                                                </label>
                                                {isSpinnerTerms == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </span>}
                                            </div>
                                        </div>
                                        {pdfUpload !== "" && <div className="row mb-3" style={{ paddingLeft: "35px", paddingRight: "30px" }}>
                                            <button type="button" style={{ float: "left", textAlign: "left", color: "#000000" }}
                                                className="btn p-0  hide-arrow" onClick={view}>Uploded File
                                                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "5px" }} xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                        </div>}

                                        <div className="row padd-30 mb-4 ">
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 1</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={additionalTerms1}
                                                    placeholder="Additional Terms 1"
                                                    onChange={handleAdditionalTerms1}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 4</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={additionalTerms4}
                                                    placeholder="Additional Terms 4"
                                                    onChange={handleAdditionalTerms4}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 2</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={additionalTerms2}
                                                    placeholder="Additional Terms 2"
                                                    onChange={handleAdditionalTerms2}
                                                />
                                                <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 5</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={additionalTerms5}
                                                    placeholder="Additional Terms 5"
                                                    onChange={handleAdditionalTerms5}
                                                />
                                            </div>
                                            <div className="col-md-4 ">
                                                <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 3</label>
                                                <input
                                                    type="text"
                                                    className="form-control input mb-3"
                                                    id="defaultFormControlInput"
                                                    value={additionalTerms3}
                                                    placeholder="Additional Terms 3"
                                                    onChange={handleAdditionalTerms3}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </DialogContent>
                <DialogActions>
                    <button type="button" onClick={FUpdateContract} className="btn btn-primary" style={{ margin: " 20px 20px 20px 0px" }} >Save</button>
                    {isSpinnerUpdate == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "0px", marginRight: "20px", marginBottom: "5px" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>}
                </DialogActions>
            </Dialog>
        </>
    )
}
export default UpdateContract;