import axios from "axios";

const Url = process.env.REACT_APP_URl;

/*image content */

export const ResidentialTypeService = async (obj: any) => {
  const apiUrl = Url + "/detect_room_type_residencial";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const CommercialTypeService = async (obj: any) => {
  const apiUrl = Url + "/detect_room_type_commercial";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const UnfurnishedFurnishedService = async (obj: any) => {
  const apiUrl = Url + "/detect_unfurnished_furnished";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const IndoorService = async (obj: any) => {
  const apiUrl = Url + "/detect_inside_outside";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ResidPropertyService = async (obj: any) => {
  const apiUrl = Url + "/detect_residential_commercial_property";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const PropertyViewService = async (obj: any) => {
  const apiUrl = Url + "/detect_property_view";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImageTaggerService = async (obj: any) => {
  const apiUrl = Url + "/image_tagger";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const BuildingService = async (obj: any) => {
  const apiUrl = Url + "/building_facility";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const WatermarkService = async (obj: any) => {
  const apiUrl = Url + "/detect_watermark";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

/*image compliance */

export const ImageNSFWContentService = async (obj: any) => {
  const apiUrl = Url + "/detect_room_nsfw_content";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const PeoplePresentService = async (obj: any) => {
  const apiUrl = Url + "/detect_People_present";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const PhoneNumberService = async (obj: any) => {
  const apiUrl = Url + "/detect_phone_number";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ImageRotatedService = async (obj: any) => {
  const apiUrl = Url + "/detect_image_rotated";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ImageBoxedService = async (obj: any) => {
  const apiUrl = Url + "/detect_image_boxed";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImageAIGeneratedService = async (obj: any) => {
  const apiUrl = Url + "/ai_generated";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImageComputerService = async (obj: any) => {
  const apiUrl = Url + "/computer_generated";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImageBrightnessService = async (obj: any) => {
  const apiUrl = Url + "/Image_Brightness_Score";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImageQualityService = async (obj: any) => {
  const apiUrl = Url + "/detect_image_quality";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
/* text */
export const OCRService = async (obj: any) => {
  const apiUrl = Url + "/OCR";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const DocumentTypeService = async (obj: any) => {
  const apiUrl = Url + "/detect_doc_type";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const DocumentContentService = async (obj: any) => {
  const apiUrl = Url + "/detect_card_content";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const EmiratesContentService = async (obj: any) => {
  const apiUrl = Url + "/detect_card_content";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const NSFWTextService = async (obj: any) => {
  const apiUrl = Url + "/nsfw_content_text";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const PassportContentTextService = async (obj: any) => {
  const apiUrl = Url + "/detect_passport_content";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const PersonalyIdentifiableService = async (obj: any) => {
  const apiUrl = Url + "/Personal_indentifable_information";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const RacialSpeechTextService = async (obj: any) => {
  const apiUrl = Url + "/hate_speech_racial_text";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}
export const TitleDeedContentService = async (obj: any) => {
  const apiUrl = Url + "/detect_titel_content";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);

  return response.data;
}

// export const HateSpeechRacialTextService = async (obj: any) => {
//   const apiUrl = Url + "/text_hate_speech_racial";
//   const response = await axios.post(apiUrl, obj, {
//     headers: {
//       "Authorization": "Bearer " + localStorage.getItem("usertoken")
//     }
//   })
//   localStorage.setItem('curl', apiUrl);
//   return response.data;
// }
export const SentimentAnalysisTextService= async (obj: any) => {
  const apiUrl = Url + "/text_sentiment_analysis";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const FeedbackAnalysisTextService = async (obj: any) => {
  const apiUrl = Url + "/text_feedback_analysis";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ToneTextService = async (obj: any) => {
  const apiUrl = Url + "/text_tone_friendly_unfriendly";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const DetectConvesrationPredefinedTopicsService= async (obj: any) => {
  const apiUrl = Url + "/text_Assign_Predefined_Topics";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const DetectConvesrationAutoIdentifService = async (obj: any) => {
  const apiUrl = Url + "/text_detect_convesration_topics";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
/*audio*/

export const ConvesrationTopicsService = async (obj: any) => {
  const apiUrl = Url + "/detect_convesration_topics";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const TopicAutoIdentificationService = async (obj: any) => {
  const apiUrl = Url + "/";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const TopTopicsService = async (obj: any) => {
  const apiUrl = Url + "/Detect_Top_Topics";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const PredefinedTopicsService = async (obj: any) => {
  const apiUrl = Url + "/Assign_Predefined_Topics";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const SentimentAnalysisService = async (obj: any) => {
  const apiUrl = Url + "/sentiment_analysis";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const FeedbackAnalysisService = async (obj: any) => {
  const apiUrl = Url + "/feedback_analysis";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ToneService = async (obj: any) => {
  const apiUrl = Url + "/tone_friendly_unfriendly";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const VoiceFeaturesService = async (obj: any) => {
  const apiUrl = Url + "/voice_features";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const HateSpeechService = async (obj: any) => {
  const apiUrl = Url + "/hate_speech_racial";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const TranscribeAudioService = async (obj: any) => {
  const apiUrl = Url + "/transcribe_audio";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}