import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { UpgradeCredit } from "../../Services/Account";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Upgrade: React.FunctionComponent<{}> = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        UpgradeCredit().then((data : any)=>{
            setOpen(true);
        }).catch(error=>{
            console.log("error")
        })
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
       <>
        <button type="button" onClick={handleClickOpen} className="btn upgrade-com">ترقية</button>

       <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Upgrade Request Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    تم تسجيل طلب الترقية الخاص بك بنجاح. أحد أعضاء فريقنا سيتواصل قريباً
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose}>حسنا</Button>
                </DialogActions>
            </Dialog></>
    )
}
export default Upgrade
