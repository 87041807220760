import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const SelectProperty = async () => {
    const apiUrl=Url+"/selectproperty";
    const response= await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  
  //generate GPT
  export const GenerateText = async (prompt:any) => {
    const apiUrl=Url+"/generateGPT";
    const response= await axios.post(apiUrl,{"prompt": prompt},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    localStorage.setItem('curl', apiUrl);
      return response.data;
    }
  
  //generate Description
  export const GenerateDesc = async (generateObj:any) => {
    const apiUrl=Url+"/generateDesc";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

export const ReplaceDesc = async (replaceObj:any) => {
    const apiUrl=Url+"/replaceDesc";
   const response= await axios.post(apiUrl,replaceObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
    return response.data;
  }

//generate title
  export const GenerateTitle = async (generateObj:any) => {
    const apiUrl=Url+"/generateTitle";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

export const ReplaceTitle = async (replaceObj:any) => {
    const apiUrl=Url+"/replaceTitle";
   const response= await axios.post(apiUrl,replaceObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
    return response.data;
  }
  


  // generate image
  export const GenerateRoomStaging = async (generateObj:any) => {
    const apiUrl=Url+"/generateimage";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

  export const FloorPlanService = async (generateObj:any) => {
    const apiUrl=Url+"/2dto3d";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

  export const Generateremodeling = async (generateObj:any) => {
    const apiUrl=Url+"/generateimagemodeling";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

  export const GenerateRoomImage = async (generateObj:any) => {
    const apiUrl=Url+"/roomgenerate";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }
  
  export const GenerateQRCodeService = async (generateObj:any) => {
    const apiUrl=Url+"/generate_QR";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

  export const DownloadEnhancedImage = async (generateObj:any) => {
    const apiUrl=Url+"/";
   const response= await axios.post(apiUrl,generateObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
    return response.data;
  }

export const ReplaceImage = async (replaceObj:any) => {
  const apiUrl=Url+"/replaceImage";
  const response= await axios.post(apiUrl,replaceObj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
    return response.data;
  }
   