import React from 'react'
import axios from "axios";

const Url = process.env.REACT_APP_URl;

export interface TokenPayload {
    password: string
    email: string
}

export const login = (user: TokenPayload) => {
    return axios.post(Url+"/login", user).then((response) => {
            localStorage.setItem('id', response.data.user._id)
            localStorage.setItem('usertoken', response.data.token)
        return response.data;
    });
};

export const getAccessPlatforms = () => {
    return axios.get(Url+"/platforms",{
        headers: {
          "Authorization": "Bearer "+localStorage.getItem("usertoken")
        }
      } ).then((response) => {
       
        return response.data;
    });
};

export const saveToken = (token: any) => {
    localStorage.setItem('usertoken', token)
}

export const logout = () => {
    isLogged();
    localStorage.clear();
    // localStorage.removeItem('usertoken');
    // localStorage.removeItem('profile');
    // localStorage.removeItem('platforms');
    // localStorage.removeItem('get-platforms');
    // localStorage.removeItem('total');
    // localStorage.removeItem('curl');
    // localStorage.removeItem('notification');
    // localStorage.removeItem('profile');
    // localStorage.removeItem('id');
}

export const isLogged = () => {
    let token = localStorage.getItem('usertoken')
    return !!token
}
