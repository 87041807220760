import axios from "axios";
import { Rule } from "../Models/Rule";
import { PropertiesObj } from "../Models/PropertiesObj";

const Url = process.env.REACT_APP_URl;

export const getTypes = async () => {
    const apiUrl=Url+"/gettypes";
    const response= await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }

export const getConditions = async (type : any)=>{
    const apiUrl= Url+"/getconditions";
    const response= await axios.post(apiUrl,{"type" : type},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data
}

export const getCategories = async (type : any, condition : any)=>{
    const apiUrl= Url+"/getvalues";
    const response= await axios.post(apiUrl,{"type" : type, "condition" : condition},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data
}
  
export const CreateRule = async (obj : Rule)=>{
    const apiUrl= Url+"/rules";
    const response= await axios.post(apiUrl,obj,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data
}

export const getRules = async () => {
    const apiUrl=Url+"/rules";
    const response= await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    const rules: Rule = response.data

    return rules;
  }

  export const getItemsStatus = async (status :any, page:any) => {
    const apiUrl=Url+"/properties";
    const response= await axios.post(`${apiUrl}/${status}`,{"page":page,"perPage": 8 },{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }

  export const DropRule = async (ruleId: any) => {
    const apiUrl = Url + "/rules";
    const response = await axios.delete(`${apiUrl}/${ruleId}`,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
 
  export const ChangeStatus = async (id: any,status:any) => {
    const apiUrl = Url + "/rules/status";
    const response = await axios.put(apiUrl,{ "_id":id,"statu":status},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }

  export const getItems = async (filterOptions:any,page: number) => {
    const apiUrl = Url + "/propertiesfilter";
    const obj = { "page": page, "perPage": 8 , "filterOptions":filterOptions}
    const response = await axios.post(apiUrl, obj,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    const properties: PropertiesObj = { total: response.data.total, properties: response.data.properties }
    return properties;
  }
  export const DropItems = async (id: any) => {
    const apiUrl = Url + "/properties";
    const response = await axios.delete(`${apiUrl}/${id}`,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }

  export const GetDuplicateService = () => {
    const apiUrl=Url+"/duplicates";
     return axios.get(apiUrl,{
       headers: {
         "Authorization": "Bearer "+localStorage.getItem("usertoken")
       }}).then((response) => {
     return response.data;
     })
  }
  
  export const GetPropertyStats = () => {
    const apiUrl=Url+"/property-stats";
     return axios.get(apiUrl,{
       headers: {
         "Authorization": "Bearer "+localStorage.getItem("usertoken")
       }}).then((response) => {
     return response.data;
     })
  }

  export const UpdateDuplicate = (duplicate :any,id: any) => {
    const apiUrl=Url+"/duplicates";
     return axios.put(apiUrl+"/"+id,duplicate,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }}).then((response) => {
    return response.data;
     })
   
  }
  
  export const PropertiesFiltertoModerate = (filterOptionsValue :any) => {
    const apiUrl=Url+"/propertiesfiltertomoderate";
     return axios.post(apiUrl,filterOptionsValue,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }}).then((response) => {
    return response.data;
     })
   
  }