import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { logout } from '../../Services/Authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAccount } from '../../Services/Profile';
import Notifications from '../Notification/Notifications';

const Header: React.FunctionComponent<{}> = () => {

    const navigate = useNavigate();
    const [profileValue, setProfileValue] = useState<any>(JSON.parse(localStorage.getItem('profile') || '{}'));
    const [loading, setLoading] = useState<boolean>(true);
    const location = useLocation().pathname
    const replacedLocation = location.replace(location, '/ar'+location);

    useEffect(() => {
        GetAccount().then((data: any) => {
            setProfileValue(data);
            localStorage.setItem('profile', JSON.stringify({
                "profile_picture":data.profile_picture,
                "name":data.name ,
                "family_name": data.family_name
              }));
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
    }, [])


    
    const getSidebar = (event: any) => {
        event.preventDefault();
        $("html").toggleClass("layout-menu-expanded");
    }

    const logoutUser = () => {
        logout(); 
        navigate("/login");
    }
 
    return (
        <nav
            className="layout-navbar " id="layout-navbar">
            <div className="navbar-nav-right" id="navbar-collapse">
                <div className="navbar-nav align-items-center media-nav">
                    <button className='button_retour' onClick={() => navigate(-1)}>
                    <i className="fa-solid fa-angle-left"></i>
                    </button>
                </div>
                <ul className="navbar-nav flex-row align-items-center media-nav ms-auto">
                    <li className="nav-item lh-1 me-3 nav-notif">
                        <Notifications />
                    </li>
                    <li className="nav-item lh-1   me-3 dropdown dropdown-authentication">
                        <a className="nav-link" data-bs-toggle="dropdown"
                            aria-expanded="false" style={{ cursor: "pointer" }}>
                            {profileValue ? <div className="media">
                                <div className="media-img-wrap">
                                    <div className="avatar">
                                        <img src={profileValue.profile_picture == "" ? "/assets/images/logo-user.jpg" : profileValue.profile_picture} alt="user" className="avatar-img" />
                                    </div>
                                </div>
                                <div className="media-body">
                                    <span>{profileValue.family_name == "" ? "Admin Admin" : profileValue.family_name + " " + profileValue.name}
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 14C9.41667 14 8.83333 13.775 8.39167 13.3333L2.95833 7.89997C2.71667 7.6583 2.71667 7.2583 2.95833 7.01664C3.2 6.77497 3.6 6.77497 3.84167 7.01664L9.275 12.45C9.675 12.85 10.325 12.85 10.725 12.45L16.1583 7.01664C16.4 6.77497 16.8 6.77497 17.0417 7.01664C17.2833 7.2583 17.2833 7.6583 17.0417 7.89997L11.6083 13.3333C11.1667 13.775 10.5833 14 10 14Z" fill="#292D32" />
                                        </svg>
                                    </span>
                                </div>
                            </div> :  <div className="media">
                                <div className="media-img-wrap">
                                    <div className="avatar">
                                        <img src={"/assets/images/logo-user.jpg"} alt="user" className="avatar-img" />
                                    </div>
                                </div>
                                <div className="media-body">
                                    <span>{"Admin Admin"}
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 14C9.41667 14 8.83333 13.775 8.39167 13.3333L2.95833 7.89997C2.71667 7.6583 2.71667 7.2583 2.95833 7.01664C3.2 6.77497 3.6 6.77497 3.84167 7.01664L9.275 12.45C9.675 12.85 10.325 12.85 10.725 12.45L16.1583 7.01664C16.4 6.77497 16.8 6.77497 17.0417 7.01664C17.2833 7.2583 17.2833 7.6583 17.0417 7.89997L11.6083 13.3333C11.1667 13.775 10.5833 14 10 14Z" fill="#292D32" />
                                        </svg>
                                    </span>
                                </div>
                            </div>}
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href="/profile">
                                    <i className="fa-solid fa-user me-2"></i>
                                    <span className="align-middle">My Profile</span>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" onClick={logoutUser}>
                                    <i className="fa-solid fa-power-off me-2"></i>
                                    <span className="align-middle">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item lh-1 me-3 lang-cora dropdown" >
                      <a data-bs-toggle="dropdown">
                        <i className="fa-solid fa-globe" style={{marginRight:"10px"}}></i>EN</a>
                        <ul className="dropdown-menu" style={{minWidth:"50px"}}>
                            <li>
                                <a className="dropdown-item" href={replacedLocation} >AR
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="layout-menu-toggle1 navbar-nav">
                    <a href="/properties" >
                        <img src="/assets/images/icons/Coralytics.png" />
                    </a>
                </div>
                <div className="layout-menu-toggle3 navbar-nav  ">
                    <button className='button_retour' onClick={() => navigate(-1)}>
                    <i className="fa-solid fa-angle-left"></i>
                    </button>
                </div>
                <div className="layout-menu-toggle2 navbar-nav">
                    <a className="nav-item nav-link notification-mobile"><Notifications /></a>
                </div>
                <div className="layout-menu-toggle navbar-nav">
                    <a className="nav-item nav-link" onClick={getSidebar}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 6.75H3C2.59 6.75 2.25 6.41 2.25 6C2.25 5.59 2.59 5.25 3 5.25H21C21.41 5.25 21.75 5.59 21.75 6C21.75 6.41 21.41 6.75 21 6.75Z" fill="#282828" />
                            <path d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z" fill="#282828" />
                            <path d="M21 18.75H3C2.59 18.75 2.25 18.41 2.25 18C2.25 17.59 2.59 17.25 3 17.25H21C21.41 17.25 21.75 17.59 21.75 18C21.75 18.41 21.41 18.75 21 18.75Z" fill="#282828" />
                        </svg>
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Header