import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { SelectProperty } from "../../../Services/Generate";
import { propertieImages } from "../../../Services/Properties";
import DownloadVideo from "./download-video";
import ShareVideo from "./share-video";
import { Stream } from "@cloudflare/stream-react";
import RecentVideos from "./recent-videos";
import { useParams } from "react-router-dom";
import { FetchSocialVideo, GenerateCaptionsServ, GenerateVideo, GenrateImage, ListingVideoServ } from "../../../Services/GenerateVideo";
import Upgrade from "../../Settings/upgrade";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { CompProfile } from "../../../Models/CompProfile";
import { ProfileUser } from "../../../Models/ProfileUser";
import { GetAccount, GetCompanyProfile } from "../../../Services/Profile";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import ViewCurl from "../../view-curl";

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Url = process.env.REACT_APP_URl;

const ListingVideo: React.FunctionComponent<{}> = (props) => {
    const [properties, setProperties] = useState<any>("");
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [listImages, setListImages] = useState<any>();
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isSpinnert, setIsSpinnert] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [refValue, setRefValue] = useState<any>()
    const [selectTemplate, setSelectTemplate] = useState<any>("");
    const [change, setChange] = useState(false);
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({
        "id": "6687a7a2d607b72a81204a3f",
        "list_captions": [
            {
                "image": {
                    "id": 4,
                    "src": "https://www.api-v1.coralytics.com/api/images/6687a7a2d607b72a81204a3f-4_1.jpeg"
                },
                "caption": "Charming 4-bedroom villa in Dubai Investment Park.",
                "motion": true
            },
            {
                "image": {
                    "id": 3,
                    "src": "https://www.api-v1.coralytics.com/api/images/6687a7a2d607b72a81204a3f-3_1.jpeg"                },
                "caption": "Spacious living area with warm wooden flooring.",
                "motion": false
            },
            {
                "image": {
                    "id": 1,
                    "src": "https://www.api-v1.coralytics.com/api/images/6687a7a2d607b72a81204a3f-1_1.jpeg"
                },
                "caption": "Stunning Marina view from the villa.",
                "motion": false
            }
        ],
        "music": "https://www.api-v1.coralytics.com/api/audios/666075d01ea3d5f6304967a2-Tattoo.mp3",
        "agent": {
            "logo": "https://www.api-v1.coralytics.com/api/images/profile-1.jpg",
            "photo": "https://www.api-v1.coralytics.com/api/images/profile-0.jpg",
            "name": " Admin",
            "adress": "admin@coralytics.com",
            "phone": "1234567890"
        },
        "company": {
            "logo": "https://www.api-v1.coralytics.com/api/images/profile-0.jpg",
            "name": "real estate ",
            "adress": "admin@coralytics.com",
            "phone": "457993765389",
            "description": "REAL ESTATE"
        }
    });
    const [curlValue, setCurlValue] = useState<any>("generate_videov2");
    const [isDisabledApi, setIsDisabledApi] = useState<boolean>(true);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [agentValue, setAgentValue] = useState<ProfileUser>();
    const [companyValue, setCompanyValue] = useState<CompProfile>();
    const [agentDescription, setAgentDescription] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [language, setLanguage] = useState<any>();
    const [showAgent, setShowAgent] = useState<boolean>(false);
    const [showCompany, setShowCompany] = useState<boolean>(false);
    const [otherDesc, setOtherDesc] = useState<any>();
    const [audioSrc, setAudioSrc] = useState("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        GetCompanyProfile().then((data: any) => {
            setCompanyValue(data);
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        GetAccount().then((data: any) => {
            setAgentValue(data);
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })

    }, [])


    const handleChangeAudio = (event: any) => {
        const selectedValue = event.target.value;
        // Update the audio source based on the selected value
        switch (selectedValue) {
            case "music 1":
                setSelectTemplate("music 1")
                setAudioSrc("https://www.api-v1.coralytics.com/api/audios/666075d01ea3d5f6304967a2-Tattoo.mp3");
                break;
            case "music 2":
                setSelectTemplate("music 2")
                setAudioSrc("https://www.api-v1.coralytics.com/api/audios/666075d01ea3d5f6304967a2-for-her-chill-upbeat-summel-travel-vlog-and-ig-music-royalty-free-use-202298.mp3");
                break;
            case "music 3":
                setSelectTemplate("music 3")
                setAudioSrc("https://www.api-v1.coralytics.com/api/audios/666075d01ea3d5f6304967a2-flow-211881.mp3");
                break;
            default:
                setAudioSrc("https://www.api-v1.coralytics.com/api/audios/666075d01ea3d5f6304967a2-Tattoo.mp3");
                break;
        }
    };

    const handleSelectChangeProperty = (event: any, newValue: any) => {
        if (newValue) {
            setListImages([])
            setSelectImages([]);
            setRefValue(newValue.ref);
            setSelectProperty(newValue.id);
            propertieImages(newValue.id).then((data) => {
                const newImages = data.map((image: any) => ({
                    image,
                    caption: '',
                    color: '#000000',
                    motion: false,
                }));
                setListImages(newImages);
            });
        }
    }

    const handleCaptionChange = (index: any, event: any) => {
        const newImageData = [...selectImages];
        newImageData[index].caption = event.target.value;
        setSelectImages(newImageData);
    };

    const handleAIMotionChange = (index: any, event: any) => {
        const newImageData = [...selectImages];
        newImageData[index].motion = event.target.checked;
        setSelectImages(newImageData);
    };

    const handleChangeLogoAgent = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setAgentValue(prevOptions => ({
                ...prevOptions,
                portrait_image: base_64,
            }));
        }
    }

    const handleChangePhotoAgent = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setAgentValue(prevOptions => ({
                ...prevOptions,
                profile_picture: base_64,
            }));
        }
    }

    const handleChangeNameAgent = (event: any) => {
        setAgentValue(prevOptions => ({
            ...prevOptions,
            name: event.target.value,
        }));
    }

    const handleChangeEmailAgent = (event: any) => {
        setAgentValue(prevOptions => ({
            ...prevOptions,
            email: event.target.value,
        }));
    }

    const handleChangePhoneNumberAgent = (event: any) => {
        setAgentValue(prevOptions => ({
            ...prevOptions,
            phone_number: event.target.value,
        }));
    }

    const handleChangeDescAgent = (event: any) => {
        setAgentDescription(event.target.value)
    }

    const handleChangeLogoCompany = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;
            setCompanyValue(prevOptions => ({
                ...prevOptions,
                companyLogo: base_64,
            }));
        }
    }

    const handleChangeNameCompany = (event: any) => {
        setCompanyValue(prevOptions => ({
            ...prevOptions,
            companyName: event.target.value,
        }));
    }

    const handleChangeEmailCompany = (event: any) => {
        setCompanyValue(prevOptions => ({
            ...prevOptions,
            companyEmail: event.target.value,
        }));
    }

    const handleChangePhoneNumberCompany = (event: any) => {
        setCompanyValue(prevOptions => ({
            ...prevOptions,
            companyPhone: event.target.value,
        }));
    }

    const handleChangeDescCompany = (event: any) => {
        setCompanyValue(prevOptions => ({
            ...prevOptions,
            companyDescription: event.target.value,
        }));
    }

    const handleSelectImages = (image: any) => {
        setSelectImages((prevSelectedImages: any) => {
            const isAlreadySelected = prevSelectedImages.some((selectedImage: any) => selectedImage.image.id === image.image.id);
            if (isAlreadySelected) {
                return prevSelectedImages.filter((selectedImage: any) => selectedImage.image.id !== image.image.id);
            } else {
                return [...prevSelectedImages, image];
            }
        });
    };

    const handChangeSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectImages("")
        const updatedImages = listImages.filter((image: any) => !selectImages.some((selectedImage: any) => selectedImage.image.id === image.image.id));
        setSelectImages((prevSelectedImages: any) => [...prevSelectedImages, ...updatedImages]);
    }

    const GenerateCaptions = () => {
        const images = selectImages.map((image: any) => (
            {
                src: image.image.src,
                id: image.image.id
            }
        ));
        const obj = {
            list_images: images,
            lang: language,
            other_desc: otherDesc,
            id: selectProperty
        }
        setOpen(false)
        setIsSpinnert(true)
        GenerateCaptionsServ(obj).then((data) => {
            const updatedImages = selectImages.map((element2: any) => {
                const matchingElement = data.data.data.find((element1: any) => element1.src === element2.image.src);
                if (matchingElement) {
                    return {
                        ...element2, // Spreading the existing properties
                        caption: matchingElement.desc, // Updating only the caption
                    };
                }
                return element2; // If no match is found, retain the original element
            });
            setLanguage("")
            setOtherDesc("")
            setSelectImages(updatedImages);
            setIsSpinnert(false)
        }).catch((error) => {
            setIsSpinnert(false)
            setShowMessage("Error")
            setIsError(true)
        })
    }

    const HandleChangeUpload = (event: any) => {
        setListImages(null)
        setSelectImages([]);
        setSelectProperty("")
        const numberOfFiles = event.target.files.length;
        for (let i = 0; i < numberOfFiles; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i + 1,
                    "src": base_64,
                    "ext": ext[1]
                }
                setSelectImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, { image, caption: '', color: '#000000', motion: false }]
                );
            };
        }
    }

    const handleSelectChangeLanguage = (event: any) => {
        setLanguage(event.target.value);
    }

    const divRef = useRef(null);
    const handleCopyApi = async () => {
        const div: any = divRef.current;
        if (div) {
            try {
                await navigator.clipboard.writeText(div.innerText);
                console.log("Content copied successfully!");
            } catch (err) {
                console.log("Unable to copy content");
            }
        }
    };

    const handleChangeDesc = (event: any) => {
        setOtherDesc(event.target.value);
    }

    const base64ToBlob = (base64: any, mimeType: any) => {
        const byteChars = atob(base64);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    };

    const formatDuration = (seconds: any) => {
        if (seconds > 60) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes} ${minutes > 1 ? "دقائق" : "دقيقة"} ${remainingSeconds} ثواني`;
        }
        else {
            return ` ${seconds} ثواني`;
        }
    };

    const generate = async () => {
        const obj = {
            "id": selectProperty,
            "list_captions": selectImages,
            "music": audioSrc,
            "agent": {
                "logo": agentValue?.portrait_image,
                "photo": agentValue?.profile_picture,
                "name": agentValue?.name,
                "adress": agentValue?.email,
                "phone": agentValue?.phone_number,
                "description": agentDescription
            },
            "company": {
                "logo": companyValue?.companyLogo,
                "name": companyValue?.companyName,
                "adress": companyValue?.companyEmail,
                "phone": companyValue?.companyPhone,
                "description": companyValue?.companyDescription
            }
        }
        setIsSpinner(true)
        setVideoUrl("")
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        ListingVideoServ(obj).then((response) => {
            if (response.status === 200) {
                const data = response.data;
                setChange(true)
                setIsDisabledApi(false)
                const blob = base64ToBlob(data, 'video/mp4');
                // Create Object URL from Blob
                const url = URL.createObjectURL(blob);
                // Set the Object URL to state
                console.log("url", url)
                setVideoUrl(url);
                setIsSpinner(false)
            } if (response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade("You have insufficient credit. Please click on 'Upgrade' to request more.")
                setIsUpgrade(true)
            }
        }).catch((error: any) => {
            if (error.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(error.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("Error")
                setIsError(true)
            }
        })

    };


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="fixed-listing-w" >
                <div className=" fixed-listing-v">
                    <div className="card">
                        <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="title-card">
                            قائمة الفيديو 
                            </div>
                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    style={{ marginLeft: "20px" }}
                                    className={`btn btn-primary generate ${(selectImages.length !== 0 && audioSrc) ? '' : 'disabled'}`}
                                    onClick={generate}
                                >
                                    أنشئ
                                </button>
                            </div>
                            <div className="p-header" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "20px", marginRight: "20px", alignSelf: "center", marginBottom: "10px" }}>
                                <p>مدة الفيديو : <span>{formatDuration(10 + (selectImages.filter((img: any) => !img.motion).length * 10) + (selectImages.filter((img: any) => img.motion).length * 2))} </span></p>
                                <p>الوقت المقدر للإنشاء: <span>{formatDuration(10 + (selectImages.filter((img: any) => !img.motion).length * 10) + (selectImages.filter((img: any) => img.motion).length * 120))} </span></p>
                                <p>استهلاك الاعتمادات :  <span>{300 + (selectImages.filter((img: any) => img.motion).length * 100)} اعتماد</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-listing-m" >
                <div className="fixed-listing-v">
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3 mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="title-card">
                                    قائمة الفيديو
                                </div>
                                <div className="d-flex align-items-center" style={{ float: "right" }}>
                                    <button
                                        type="button"
                                        style={{ marginLeft: "20px" }}
                                        className={`btn btn-primary generate ${(selectImages.length !== 0 && audioSrc) ? '' : 'disabled'}`}
                                        onClick={generate}
                                    >
                                        أنشئ
                                    </button>
                                </div>
                            </div>

                            <div className="p-header mt-3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginRight: "20px", alignSelf: "center", marginBottom: "10px" }}>
                                <p>مدة الفيديو : <span>{formatDuration(10 + (selectImages.filter((img: any) => !img.motion).length * 10) + (selectImages.filter((img: any) => img.motion).length * 2))} </span></p>
                                <p>الوقت المقدر للإنشاء: <span>{formatDuration(10 + (selectImages.filter((img: any) => !img.motion).length * 10) + (selectImages.filter((img: any) => img.motion).length * 120))} </span></p>
                                <p>استهلاك الاعتمادات :  <span>{300 + (selectImages.filter((img: any) => img.motion).length * 100)} اعتماد</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <RecentVideos type="none" Change={change} />
            </div>
            {properties == null ? <></> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body padd-24">
                                <label className="form-label" htmlFor="selectTypeOpt">اختيار العقار</label>
                                <a style={{ float: "left", height: "15px", color: "#000000" }}
                                    className={[selectProperty != "" ? '' : "disabled", "btn p-0  hide-arrow"].join(" ")}
                                    href={`/properties/${selectProperty}`} target="_blank" rel="noopener noreferrer">{refValue}
                                    <svg width="20" height="20" viewBox="0 0 20 20" style={{ marginRight: "10px", transform: " rotate(270deg)" }} fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </a>
                                <Autocomplete
                                    options={properties}
                                    id="combo-box-d"
                                    getOptionLabel={(property: any) => {
                                        const bedroomsLabel = property.bedrooms > 1 ? `${property.bedrooms} bedrooms` : `${property.bedrooms} bedroom`;
                                        return `${property.ref} | ${property.location} | ${bedroomsLabel}`;
                                    }}
                                    disablePortal
                                    renderInput={(params) => <TextField {...params} onChange={(value) => {
                                        setSelectProperty(value.target.value)
                                    }} />}
                                    onChange={handleSelectChangeProperty}
                                />
                                <div className="row mt-3">
                                    <div className="container_image">
                                        <input
                                            type="file"
                                            id="input-file"
                                            name="input-file"
                                            accept="image/*"
                                            multiple
                                            onChange={HandleChangeUpload}
                                            hidden
                                        />
                                        <label className="btn-upload disabled" id="button-addnew" htmlFor="input-file" role="button">
                                            <i className="fa-solid fa-upload" style={{ marginLeft: "5px" }}></i>
                                            تحميل الصور
                                        </label>
                                    </div></div>
                                {listImages == null ? <></> : <>   <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختر صورة</label>
                                    <a className="select-all" onClick={handChangeSelectAll}>اختر الكل</a></>}
                                {listImages == null ? <div></div> : <div className="image-list">
                                    {listImages.map((image: any, index: any) => (
                                        <div key={image.image.id + "_" + index}>
                                            <input
                                                type="checkbox"
                                                id={image.image.id + "_" + index}
                                                value={image.image.src}
                                                onChange={() => { handleSelectImages(image) }}
                                                checked={selectImages.some((selectedImage: any) => selectedImage.image.id === image.image.id)}
                                            />
                                            <label htmlFor={image.image.id + "_" + index} className="label">
                                                <img src={image.image.src} className="image" key={image.image.id + "_" + index} />
                                            </label>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">
                                <div className="row mb-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-8" style={{ display: 'flex' }}>
                                        {isSpinnert && <div className="spinner-border text-primary" role="status" style={{ marginLeft: '16px', textAlign: 'center' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                        <button type="button" className={`btn mb-2 api-button ${selectImages.length !== 0 && selectProperty !== "" ? "" : "disabled"}`} style={{ float: "left" }} data-bs-toggle="modal" data-bs-target="#generateModal"  >
                                            إنشاء التسميات التوضيحية
                                        </button></div>
                                    <div className="card card-image-gener selected-img-videos">
                                        {selectImages.length !== 0 && selectImages.map((image: any, index: any) => (
                                            <div className="card mt-3" key={image.image.id}>
                                                <div className="card-body">
                                                    <div className="row padd-24">
                                                        <div className="col-md-4 mb-2">
                                                            <div>
                                                                <label htmlFor={image.image.id} className="label-img">
                                                                    <img src={image.image.src} className="image" alt="" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 mb-2">
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="تسمية توضيحية"
                                                                    value={image.caption}
                                                                    onChange={(e) => handleCaptionChange(index, e)}
                                                                />

                                                            </div>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`motion-${index}`}
                                                                    onChange={(e) => handleAIMotionChange(index, e)}
                                                                    checked={image.motion}
                                                                    disabled={
                                                                        selectImages.filter((img: any) => img.motion).length === selectImages.length - 2 &&
                                                                        !image.motion
                                                                    }
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`motion-${index}`}
                                                                    style={{ color: "#716BE3" }}
                                                                >
                                                                    أضف حركة الذكاء الاصطناعي
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {showAgent && <div className="card mb-3 mt-3">
                                            <div className="card-body padd-24">
                                                <div className="row col-users">
                                                    <div className="generate-h5 mb-3">الملف الشخصي للوكيل</div>
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label htmlFor="Portrait Image" className="form-label mt-3">صورة الملف الشخصي</label>
                                                            <div className='profile-upload'>
                                                                <div><img src={agentValue?.portrait_image == "" ? "/assets/images/LogoUser.jfif" : agentValue?.portrait_image} style={{ display: "inline" }} />
                                                                    Logo.PNG</div>
                                                                <label htmlFor="Portrait1">
                                                                    <input type="file" id="Portrait1" style={{ display: 'none' }} accept=" image/*" onChange={handleChangeLogoAgent} />
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                                                        <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                                                        <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <label htmlFor="Name" className="form-label mt-3 ">اسم</label>
                                                        <input type="text" className="form-control " placeholder="اسم" value={agentValue?.name ? agentValue?.name : " "} onChange={handleChangeNameAgent} />

                                                        <label htmlFor="Email Address" className="form-label mt-3">عنوان البريد الإلكتروني </label>
                                                        <input type="email" id="Email Address" className="form-control " placeholder="Email" value={agentValue?.email ? agentValue?.email : ""} onChange={handleChangeEmailAgent} />

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label htmlFor="Standing Potrait" className="form-label mt-3">صورة واقفة</label>
                                                            <div className='profile-upload '>
                                                                <div>
                                                                    <img src={agentValue?.profile_picture == "" ? "/assets/images/LogoUser.jfif" : agentValue?.profile_picture} style={{ display: "inline" }} />
                                                                    Photo.PNG</div>
                                                                <label htmlFor="Portrait2">
                                                                    <input type="file" id="Portrait2" style={{ display: 'none' }} accept=" image/*" onChange={handleChangePhotoAgent} />
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                                                        <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                                                        <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <label htmlFor="Phone Number" className="form-label mt-3">رقم الهاتف</label>
                                                        <input type="text" className="form-control " placeholder="رقم الهاتف" value={agentValue?.phone_number ? agentValue?.phone_number : ""} onChange={handleChangePhoneNumberAgent} />

                                                        <label htmlFor="Description" className="form-label mt-3">وصف</label>
                                                        <input type="text" className="form-control " placeholder="وصف" style={{ height: "40px" }} value={agentDescription ? agentDescription : ""} onChange={handleChangeDescAgent} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {showCompany && <div className="card mb-3 mt-3">
                                            <div className="card-body padd-24">
                                                <div className="generate-h5 mb-3">ملف الشركة</div>
                                                <div className="row col-users">
                                                    <div className="col-md-6">

                                                        <label htmlFor="Name" className="form-label mt-3">اسم</label>
                                                        <input type="text" className="form-control" placeholder="اسم" value={companyValue?.companyName ? companyValue?.companyName : " "} onChange={handleChangeNameCompany} />

                                                        <label htmlFor="Email Address" className="form-label mt-3 ">عنوان البريد الإلكتروني</label>
                                                        <input type="email" id="Email Address" className="form-control" placeholder="عنوان البريد الإلكتروني" value={companyValue?.companyEmail ? companyValue?.companyEmail : ""} onChange={handleChangeEmailCompany} />
                                                        <div>
                                                            <label htmlFor="Portrait Image" className="form-label mt-3">شعار الشركة</label>
                                                            <div className='profile-upload mb-3'>
                                                                <div><img src={companyValue?.companyLogo == "" ? "/assets/images/LogoUser.jfif" : companyValue?.companyLogo} style={{ display: "inline" }} />
                                                                    Logo.PNG</div>
                                                                <label htmlFor="Portrait1-9">
                                                                    <input type="file" id="Portrait1-9" style={{ display: 'none' }} accept=" image/*" onChange={handleChangeLogoCompany} />
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                                                        <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                                                        <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">

                                                        <label htmlFor="Phone Number" className="form-label mt-3">رقم الهاتف</label>
                                                        <input type="text" className="form-control" placeholder="رقم الهاتف" value={companyValue?.companyPhone ? companyValue?.companyPhone : ""} onChange={handleChangePhoneNumberCompany} />

                                                        <label htmlFor="Description" className="form-label mt-3">وصف</label>
                                                        <input type="text" className="form-control " placeholder="وصف" style={{ height: "40px" }} value={companyValue?.companyDescription ? companyValue?.companyDescription : ""} onChange={handleChangeDescCompany} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {selectImages.length !== 0 && <div style={{ display: "flex", justifyContent: "end" }} className="mt-3">
                                            <a id="button-addnew" onClick={(e) => { setShowAgent(!showAgent) }}><i className="fa-solid fa-plus" style={{ marginLeft: "10px" }}></i>إضافة ملف تعريف الوكيل</a>
                                            <a id="button-addnew" style={{ marginRight: "20px" }} onClick={(e) => { setShowCompany(!showCompany) }}><i className="fa-solid fa-plus" style={{ marginLeft: "10px" }}></i>إضافة ملف تعريف الشركة</a>
                                        </div>
                                        }
                                        {selectImages.length !== 0 && <div className="card mb-3 mt-3">
                                            <div className="card-body padd-24"> <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختر الموسيقى</label>

                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={selectTemplate || ""}
                                                                onChange={handleChangeAudio}>
                                                                <MenuItem value="" disabled>اختر</MenuItem>
                                                                <MenuItem value="music 1">موسيقى 1</MenuItem>
                                                                <MenuItem value="music 2">موسيقى 2</MenuItem>
                                                                <MenuItem value="music 3">موسيقى 3</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                                {audioSrc && (
                                                    <div className="mb-3 mt-3" dir="rtl">
                                                        <AudioPlayer
                                                            autoPlay
                                                            src={audioSrc}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "16px" }}>
                                    <div className="generate-h5" style={{ height: "30px" }}>الفيديو المُنشئ
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                        {isUpgrade ? <Upgrade /> : <></>}
                                    </div>
                                    {(isSpinner && videoUrl == "") ?
                                        <div className="card card-image-gener">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="spinner-border text-primary" role="status" style={{ marginLeft: '16px', textAlign: 'center' }}>
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                <span className="para" style={{ padding: '20px' }}> </span>
                                            </div>
                                        </div> : <div className="card card-image-gener">
                                            {videoUrl == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> : (
                                                <video controls>
                                                    <source src={videoUrl} type='video/mp4' />
                                                </video>
                                            )}</div>}
                                </div>
                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <DownloadVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                    <ShareVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            
            <div className="modal fade" id="generateModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختر اللغة                            </label>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <FormControl fullWidth>
                                        <Select
                                            className="custom-select"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select' }}
                                            value={language}
                                            onChange={handleSelectChangeLanguage}>
                                            <MenuItem value="" disabled>
                                                اختر
                                            </MenuItem>
                                            <MenuItem value={"arabic"}>اللغة العربية</MenuItem>
                                            <MenuItem value={"english"}>اللغة الإنجليزية</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" style={{ float: "right" }} className={`btn btn-primary ${language ? "" : "disabled"}`} onClick={GenerateCaptions}>أنشئ</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
export default ListingVideo;