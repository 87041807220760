import React, { useState } from 'react'
import { LoginForm } from '../Models/LoginForm';
import { login } from '../Services/Authentication';
import { useNavigate } from 'react-router-dom';


const LoginAr: React.FunctionComponent<{}> = (props) => {

    const [formData, setFormData] = useState<LoginForm>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<Partial<LoginForm>>({});
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();


    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'الرجاء إدخال عنوان البريد الإلكتروني الخاص بك',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'يرجى إدخال بريدك الإلكتروني بالتنسيق الصحيح',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                password: 'الرجاء إدخال كلمة السر الخاصة بك',
            }));
        } else {
            login(formData).then(res => {
                navigate('/ar/properties')
            })
                .catch(err => {
                    
                    console.log(err.response.data.error)
                    setErrorMessage(err.response.data.error);
                })

        }

    }
    return (
        <div className="">
            <div className="header">
            <img className="logo" style={{marginRight: "45px",float: "right"}} src="/assets/images/icons/coralytics-logo.png"/>       
            </div>
            <div className="app">
                <div className="login-form" dir="auto"> 
                    <div className="title">تسجيل دخول</div>
                    <div className="form">
                        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                        <form onSubmit={handleLogin}>
                            <div className="mb-3">
                                <input type="text" name="email" className="form-control" placeholder='البريد الإلكتروني'
                                    value={formData.email}
                                    onChange={handleChangeEmail} />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" name="pass" placeholder='كلمة مرور'
                                    value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error">{errors.password}</span>}
                            </div>
                            <div className="button-container">
                                <button type="submit" className='btn btn-primary'>تسجيل</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default LoginAr