import React, { useState } from 'react'
import { AccountObj } from '../Models/Account';
import { AccountService } from '../Services/Account';
import { useNavigate } from 'react-router-dom';


const Account: React.FunctionComponent<{}> = (props) => {

    const [errorMessage, setErrorMessage] = useState<string>();
    const [errors, setErrors] = useState<Partial<AccountObj>>({});
    const [formData, setFormData] = useState<AccountObj>({
        accountType: "",
        email: "",
        password: "",
        phone: "",
        companyName: "",
        subscriptionName: "",
        creditScore: "",
    });
    let navigate = useNavigate();

    const handleChangeCompanyName = (event: any) => {
        const companyNameValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            companyName: companyNameValue,
        }));
    }
    const handleChangeAccountType = (event: any) => {
        const accountTypeValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            accountType: accountTypeValue,
        }));
    }
    const handleChangeCreditScore = (event: any) => {
        const creditScoreValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            creditScore: creditScoreValue,
        }));
    }
    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }
    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }
    const handleChangePhone = (event: any) => {
        const phoneValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: phoneValue,
        }));
    }
    const handleChangeSubName = (event: any) => {
        const subNameValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            subscriptionName: subNameValue,
        }));
    }
    const handleAccount = (event: any) => {
        event.preventDefault();
        //validation company name
        if (!formData.companyName) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                companyName: 'Please enter your company name.',
            }));
        }
        //validation phone
        if (!formData.phone) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                phone: 'Please enter your telephone number.',
            }));
        }
        // validation account types
        if (!formData.accountType) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                accountType: 'Please enter your account types.',
            }));
        }
        // validation Subscription
        if (!formData.subscriptionName) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                subscriptionName: 'Please enter your subscription.',
            }));
        }
        // validation email
        if (!formData.email) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'Please enter your email address.',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format.',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                password: 'Please enter your password.',
            }));
        } else {
            AccountService(formData).then(res => {
                navigate('/')
            })
                .catch(err => {
                    setErrorMessage(err.response.data.error);
                })
        }
    }
    return (
        <div className="">
            <div className="header">
                <img className="logo" src='assets/images/icons/coralytics-logo.png'></img>
            </div>
            <div className="app-account">
                <div className="login-form">
                    <div className="title">CREATE ACCOUNT</div>
                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                    <div className="form">
                        <form onSubmit={handleAccount}>
                            <div className="mb-3">
                                <input type="text" className="form-control" name="companyname" placeholder='CompanyName' value={formData.companyName}
                                    onChange={handleChangeCompanyName} />
                                {errors.companyName && <span className="error">{errors.companyName}</span>}

                            </div>
                            <div className="mb-3">
                                <input type="text" className="form-control" name="phone" placeholder='Phone' value={formData.phone}
                                    onChange={handleChangePhone} />
                                {errors.phone && <span className="error">{errors.phone}</span>}

                            </div>
                            <div className="mb-3">
                                <input type="text" className="form-control" name="email" placeholder='Email' value={formData.email}
                                    onChange={handleChangeEmail} />
                                {errors.email && <span className="error">{errors.email}</span>}

                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" name="password" placeholder='Password' value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error">{errors.password}</span>}

                            </div>
                            <div className="mb-3">
                                <select className="form-select" aria-label=".form-select-lg example" value={formData.accountType} onChange={handleChangeAccountType}>
                                    <option >Account Types</option>
                                    <option value="portals_MLS" >Portals / MLS</option>
                                    <option value="regulators">Regulators</option>
                                    <option value="agencies">Agencies</option>
                                    <option value="developers">Developers</option>
                                </select>
                                {errors.accountType && <span className="error">{errors.accountType}</span>}
                            </div>
                            <div className="mb-3">
                                <select className="form-select" aria-label=".form-select-lg example" value={formData.subscriptionName} onChange={handleChangeSubName}>
                                    <option >Subscription</option>
                                    <option value="Starter" >STARTER</option>
                                    <option value="Silver">SILVER</option>
                                    <option value="Gold">GOLD</option>
                                    <option value="AGENT">AGENT</option>
                                </select>
                                {errors.subscriptionName && <span className="error">{errors.subscriptionName}</span>}
                            </div>
                            <div className="mb-3">
                                {formData.subscriptionName && <input type="text" className="form-control" name="credit_score" placeholder='Credit Score' value={formData.creditScore} onChange={handleChangeCreditScore} />}
                            </div>
                            <div className="button-container">
                                <button type="submit" className='btn btn-primary'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account