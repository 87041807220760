import React, { useEffect, useState } from "react";
import { getVersions } from "../../Services/Properties";
import { useNavigate } from "react-router-dom";

const AdDetails: React.FunctionComponent<{ property: any }> = (props) => {

    const [versions, setVersions] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getVersions(props.property.id).then((data) => {
            setVersions(data)
        }).catch(()=>{
            console.log("error")
        })
    }, [])

    const view = (id: any) => {
        navigate(`/ar/properties/${id}`)
    }
    return props.property ?(
        <div className="card padd-24-20 margin-20">
            <div className="card-body ad-details">
                <h5>تفاصيل الإعلان </h5>
                <div className="row">
                    <div>
                        <p style={{ float: "right", display: "inline-block" }}>حالة </p>
                        {props.property.status && props.property.status.published == false && <span className="badge bg-label-danger">مسودة</span>}
                        {props.property.status && props.property.status.published == true  && <span className="badge bg-label-success">نشرت</span>}
                    </div>
                </div>
                <hr className="hr"></hr>
                <div className="row">
                    <div>
                        <p style={{ float: "right", display: "inline-block" }}>قرار التعديل</p>
                        {props.property.taken_action_name && props.property.taken_action_name == "" && <span style={{ display: "inline-block" }}>لم تتم معالجتها</span>}
                        {props.property.taken_action_name && props.property.taken_action_name == "manual" && <span className="badge bg-label-info">يدوي</span>}
                        {props.property.taken_action_name && props.property.taken_action_name == "passive" && <span className="badge bg-label-primary">غير نشيط</span>}
                        {props.property.taken_action_name && props.property.taken_action_name == "approved" && <span className="badge bg-label-success">وافق </span>}
                        {props.property.taken_action_name && props.property.taken_action_name == "refused" && <span className="badge bg-label-danger">مرفوض</span>}
                        {props.property.taken_action_name && props.property.taken_action_name == "Processing" && <span className="badge bg-label-success">معالجة</span>}
                    </div>
                </div>
                <hr className="hr"></hr>
                <div className="row rules-list">
                    <div className="col-md-6">
                        <p className="marg-left-7">
                        القوانين المطبقة
                        </p>
                    </div>
                    {props.property.applied_rules ? <div className="col-md-6 ">
                        <ul style={{float: "left" ,textAlign:"left"}}>
                        {props.property.applied_rules.map((m: any, index: any) => {
                            return  <li key={index} style={{ color: "#282828", float: "left" }}><span style={{ color: "#282828", float: "right" }}>{m.rulename + " "+'(' + m.condition + ')'}</span></li>
                            
                        })}
                        </ul>
                    </div> :<></>}
                </div>
                <hr className="hr"></hr>
                <div className="row">
                    <div>
                        <p style={{ float: "right", display: "inline-block" }}>النسخة   </p><span style={{ display: "inline-block", color: "#282828" }}>{props.property.version ? props.property.version : ""} </span>
                    </div>
                    <div className="row">
                        <div className="version-list" style={{marginLeft:"13px" , paddingBottom:"12px"}}>
                            {versions && versions.map((version: any, index: any) => (
                                <a key={index} href={`/ar/properties/${version._id}`} target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
                                    {version.version.charAt(0).toUpperCase() + version.version.slice(1)}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) :<></>
}
export default AdDetails
