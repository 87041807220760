import { useEffect, useState } from "react";
import { SelectProperty } from "../../../Services/Generate";
import {  ReplaceImgEnhance, SkyReplacementBlueSkyService } from "../../../Services/Enhance";
import EnhanceImageSelect from "./enhance-image-select";
import DownloadImage from "../../download-image";
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";
import Fullscreen from "../../../Components/Generate/Fullscreen";



const SkyReplacementBlueSky: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSelectImage, setIsSelectImage] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [enhancedImages, setEnhancedImages] = useState<any>('');
    const [checkedId, setCheckedId] = useState<any>(null);
    const [enhancedImage, setEnhancedImage] = useState("");
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"id":"66910e207d9051b9c39a04fa","images":[{"id":3,"src":"https://www.api-v1.coralytics.com/api/images/66910e207d9051b9c39a04fa-3_1.jpeg"},{"id":1,"src":"https://www.api-v1.coralytics.com/api/images/66910e207d9051b9c39a04fa-1_1.jpeg"}]});
    const [curlValue, setCurlValue] = useState<any>("sky_enhancement");
    const [isDisabledReplace, setIsDisabledReplace] = useState<boolean>(false);
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [imgId, setImgId] = useState<any>("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });
        

        
    }, [])

    const handleSelectImages = (event: any) => {
        setIsSelectImage(!isSelectImage)
        const image: any = enhancedImages.find((element: any) => Number.parseInt(element.id) == event.target.value)
        if (image) {
            setImgId(image.id)
            setEnhancedImage(image.src);
        } else {
            console.log("Image not found")
        } 
    }
  
    const handleCheckboxChange = (id: any) => {
        setCheckedId(id === checkedId ? null : id);
    };

    


    const EnhandedImagecHandler = (enhanceObj: any) => {
        setSelectProperty(enhanceObj.id)
        setIsSpinner(true)
        setIsUpgrade(false)
        setIsDisabledReplace(false)
        setShowMsgUpgrade("")
        setEnhancedImage("")
        setEnhancedImages([]);
        setSelectImages(enhanceObj.images)
        
        const Obj = {
            "id": enhanceObj.id,
            "images": enhanceObj.images
        }
        SkyReplacementBlueSkyService(Obj).then((data) => {
            setIsDisabledReplace(true)
            
            setEnhancedImages(data.data)
              for (let index = 0; index < data.data.length; index++) {
                const element = data.data[index];
                if(Obj.images[0].id==element.id){
                    setCheckedId(Obj.images[0].id)
                    setEnhancedImage(element.src)
                    setImgId(element.id)
                    break;
                }
            }
            setIsSpinner(false)
            setShowMessage('تم التطوير بنجاح!')
            setIsSuccess(true)
        }).catch(err => {
            if(err.response.status===401){
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            }else{
                setIsSpinner(false)
                setShowMessage("فشل الحصول على البيانات")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };

    const replace = () => {
        const replaceObj = {
            "property_id": selectProperty,
            "src": enhancedImage,
            "id":imgId
        }
        ReplaceImgEnhance(replaceObj).then((data) => {
            setShowMessage('تم استبداله بنجاح!')
            setIsSuccess(true)
        }).catch(err => {
            setShowMessage('تم فشل في استبداله !')
            setIsError(true)
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    }

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                    استبدال السماء - السماء الزرقاء
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {properties == null ? <>تحميل...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <EnhanceImageSelect properties={properties} onEnhancedImage={EnhandedImagecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">
                                <h5 className="generate-h5">الصورة المحددة</h5>
                               {selectImages.length >1 ?<div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <input type="checkbox" id={image.id} value={image.id} checked={checkedId === image.id} onChange={(event) => { handleSelectImages(event); handleCheckboxChange(image.id); }} />
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                     </div>
                                     : <div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                </div>
                            }
 
                                <div className="generate-h5" style={{ height: "30px" }}>صورة محسنة
                                    {isSpinner && enhancedImage == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">تحميل...</span>
                                    </div>}
                                     <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                     {isUpgrade ? <Upgrade/> :<></>}
                                </div>

                                <div className="card card-image-gener">
                                    {enhancedImage == "" ? <p className="para" style={{padding:"20px",textAlign:"center"}}>{showMsgUpgrade}</p> : <div className="card"><img src={`${enhancedImage}?${new Date().getTime()}`} alt="Fullscreen Image" id='fullscreen-image' />

                                         <Fullscreen/></div>}
                                </div>
                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <DownloadImage image={enhancedImage} isDisabledReplace={isDisabledReplace}/>
                                    <button type="button" className={`btn mb-2 button-1 ${isDisabledReplace && selectProperty ? '' : 'disabled'}`} onClick={replace}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3333 10.0001C18.3333 14.6001 14.6 18.3334 9.99996 18.3334C5.39996 18.3334 2.59163 13.7001 2.59163 13.7001M2.59163 13.7001H6.35829M2.59163 13.7001V17.8667M1.66663 10.0001C1.66663 5.40008 5.36663 1.66675 9.99996 1.66675C15.5583 1.66675 18.3333 6.30008 18.3333 6.30008M18.3333 6.30008V2.13341M18.3333 6.30008H14.6333" stroke="#716BE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        استبدال
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
         
        </div>
    )
}
export default SkyReplacementBlueSky;