import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { FilterOptions } from "../../Models/FilterOptions";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


const SortByProperties: React.FunctionComponent<{ OnSelectChangeSortBy: any }> = (props) => {
    const [filterOptionsValue, setFilterOptionsValue] = useState<FilterOptions>({
        "id": "",
        "brokerage": "",
        "broker": "",
        "bedroomsCount": "",
        "projectId": "",
        "location": "",
        "minPrice": "",
        "maxPrice": "",
        "date": "",
        "minSize": "",
        "maxSize": "",
        "sortBy": ""
    });
    const handleSelectChangeSortBy = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            sortBy: event.target.value,
        }));
        props.OnSelectChangeSortBy(event.target.value)
    }
 
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
            <FormControl fullWidth>
                <Select
                    className="custom-select"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Sort By' }}
                    value={filterOptionsValue.sortBy}
                    onChange={handleSelectChangeSortBy}>
                    <MenuItem value="">
                    ترتيب حسب
                    </MenuItem>
                    <MenuItem value={"price"}>السعر - منخفض إلى مرتفع</MenuItem>
                    <MenuItem value={"pricehtol"}>السعر - مرتفع إلى منخفض</MenuItem>
                    <MenuItem value={"size"}>الحجم - صغير إلى كبير</MenuItem>
                    <MenuItem value={"sizehtol"}>الحجم - كبير إلى صغير</MenuItem>
                </Select>
            </FormControl>
        </ThemeProvider>
	</CacheProvider>
    )
}
export default SortByProperties;