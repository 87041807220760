import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
       labels: {
         usePointStyle: true,
       },
    },
  },
  tension: 0.3,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];

export const data = {
  type: 'line',
  labels,
  datasets: [
    {
      label: 'Positive',
      data: [100, 200, 145, 34, 205, 122, 200, 145,120,145,200,100],
      borderColor: ['#46B49E'],
      backgroundColor: '#46B49E',
      radius: 4,
      fill: "origin", 
      lineTension: 0.4,
      pointRadius: 0
    },
    {
      label: 'Negative',
      data: [200, 100, 150, 184, 125, 123, 150,67,203,198,120,134,200],
      borderColor: ['#FF6F61'],
      backgroundColor: '#FF6F61',
       radius: 4,
      fill: "origin",
      lineTension: 0.4,
      pointRadius: 0
    },
    {
      label: 'Neutral',
      data: [190, 10, 200, 184, 125, 100, 150,98,176,120,145,230],
      borderColor: ['#C3C5D4'],
      backgroundColor: '#C3C5D4',
       radius: 4,
      fill: "origin",
      lineTension: 0.4,
      pointRadius: 0
    }
  ],
};
 

const LineChartDelight: React.FunctionComponent<{}> = (props) => { 
  return <div className="line-chart-container">
    {/* <div style={{ display: 'flex', justifyContent: 'start' }}>
        {data.datasets.map((label, index) => (
          <div key={index} style={{ margin: '5px',textAlign: 'center'}}>
            <span
              style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: label.backgroundColor,
                marginRight: '5px',
              }}
            ></span>
            {label.label}
          </div>
        ))}
      </div> */}
    <Line options={options} data={data} />
    </div>
}

export default LineChartDelight;
