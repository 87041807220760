import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const AccountService = (accountObject: any) => {
  console.log(accountObject)
  const apiUrl = Url + "/accounts";
  return axios.post(apiUrl, accountObject, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const getUserId = () => {
  const user: any = getCurrentUser();
  return user.user_id;
}

export const getCurrentUser = () => {
  const token = localStorage.getItem("usertoken");
  let payload
  if (token) {
    payload = token.split('.')[1]

    try {
      payload = window.atob(payload)
    } catch (err) {
      return null
    }
    return JSON.parse(payload).sub
  } else {
    return null
  }
};

export const getUserDetails = () => {
  const apiUrl = Url + "/get_user_details";
  const user = {}
  return axios.post(apiUrl, user, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}
export const GetUserLogs = (platforms: any, grouped: any, page: any) => {
  const apiUrl = Url + `/userLogSummary/${platforms}/${grouped}`;
  return axios.post(apiUrl, { page: page, limit: 10 }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const GetCreditScore = () => {
  const apiUrl = Url + "/credit_score";
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}
export const UserConsumption = () => {
  const apiUrl = Url + "/user-consumption";
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const GetAgentLogs = (platforms: any, grouped: any, page: any, id: any) => {
  const apiUrl = Url + `/userLogSummary/${id}/${platforms}/${grouped}`;
  return axios.post(apiUrl, { page: page, limit: 10 }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const GetCreditScoreAgent = (id: any) => {
  const apiUrl = Url + `/credit_score/${id}`;
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}
export const AgentConsumption = (id: any) => {
  const apiUrl = Url + `/user-consumption/${id}`;
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const UpgradeCredit = () => {
  const apiUrl = Url + "/api/upgradecredit";
  return axios.post(apiUrl, "", {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}
export const ServiceHasAcsess = () => {
  const apiUrl = Url + "/hasAcsess";
  return axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
}

export const GetEndpoints = (name: any) => {
  const apiUrl = Url + "/endpoints";
  return axios.get(`${apiUrl}/${name}`, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  }).then((response) => {
    return response.data;
  })
} 