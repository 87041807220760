import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const CreatePortals = async (portals :any)=>{
    const apiUrl = Url + "/storeprotals";
   
    const response = await axios.post(apiUrl,portals, {
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return  response.data;
  }
  export const GetPortals = async ()=>{
    const apiUrl = Url + "/storeprotals";
   
    const response = await axios.get(apiUrl, {
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return  response.data;
  }

  export const DropPortals = async (protal :any)=>{
    const apiUrl = Url + "/storeprotals/";
   
    const response = await axios.delete(`${apiUrl}${protal}`, {
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return  response.data;
  }

  export const PublishPropertiesServ = async (listings :any)=>{
    const apiUrl = Url + "/publish";
   
    const response = await axios.put(apiUrl,listings, {
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return  response.data;
  }