import React, { useState, useEffect } from 'react';
import { ChangePasswordService } from '../../Services/Users';

export interface Password {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const ChangePassword = () => {


  const [formData, setFormData] = useState<Password>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showMessage, setShowMessage] = useState<any>("");
  const [errors, setErrors] = useState<Partial<Password>>({});


  const validateForm = (event: any) => {

    const newErrors: Password = {};
    if (!formData.currentPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        currentPassword: 'Old password is required',
      }));
    }

    if (!formData.newPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        newPassword: 'New password is required',
      }));
    }
    // else if (formData.newPassword.length < 6) {
    //   setErrors((prevFormData) => ({
    //     ...prevFormData,
    //     newPassword: 'New password must be at least 6 characters',
    //   }));
    // }

    if (!formData.confirmPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        confirmPassword: 'Please confirm your new password',
      }));
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        confirmPassword: 'Passwords do not match',
      }));
    }
    else {
      ChangePasswordService(formData).then(res => {
        setIsSuccess(true)
        setShowMessage('Password changed successfully!');
      })
        .catch(error => {
          if (error.response.status === 400) {
            setShowMessage(error.response.data.error)
            setIsError(true)
          } else {
            setIsError(true)
            setShowMessage("Error");
          }

        })
    }
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false)
    }, 3000)
  };



  return (
    <div className="row users-mobile">
      <div className="card mb-3">
        <div className="card-body padd-24">
          <div className="row mb-4">
            <div className="col-md-12 moderate-title">
              Change Password
            </div>
          </div>
          {isSuccess && <div className="alert alert-success alert-dismissible mt-4" role="alert">
            {showMessage}
          </div>}
          {isError && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
            {showMessage}
          </div>}
          <div className="change-password">
            <div className="row col-users ">
              <div className="col-4 mb-3">
                <div className="form-group">
                  <label className="form-label " htmlFor="currentPassword">Old Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="currentPassword"
                    value={formData.currentPassword}
                    onChange={(e) => setFormData((prevFormData) => ({
                      ...prevFormData,
                      currentPassword: e.target.value,
                    }))}
                  />
                  {errors.currentPassword !== "" && (
                    <div className="error">{errors.currentPassword}</div>
                  )}
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="form-group">
                  <label className="form-label " htmlFor="newPassword">New Password</label>
                  <input
                    className="form-control "
                    type="password"
                    id="newPassword"
                    value={formData.newPassword}
                    onChange={(e) => setFormData((prevFormData) => ({
                      ...prevFormData,
                      newPassword: e.target.value,
                    }))}
                  />
                  {errors.newPassword !== "" && (
                    <div className="error">{errors.newPassword}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row col-users ">
              <div className="col-4 mb-3">
                <div className="form-group">
                  <label className="form-label " htmlFor="confirmPassword">Confirm New Password</label>
                  <input
                    className="form-control "
                    type="password"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData((prevFormData) => ({
                      ...prevFormData,
                      confirmPassword: e.target.value,
                    }))}
                  />
                  {errors.confirmPassword !== "" && (
                    <div className="error">{errors.confirmPassword}</div>
                  )}
                </div>
              </div>
            </div>
            <button type="submit" className={`btn btn-primary generate mb-3 ${formData.newPassword && formData.currentPassword && formData.confirmPassword ? "" :"disabled"}`} onClick={validateForm}>Change</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
