import React, { useState } from 'react'
import { LoginForm } from '../Models/LoginForm';
import { login } from '../Services/Authentication';
import { useNavigate } from 'react-router-dom';


const Login: React.FunctionComponent<{}> = (props) => {

    const [formData, setFormData] = useState<LoginForm>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<Partial<LoginForm>>({});
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();

    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            login(formData).then(res => {
                navigate('/properties')
            })
                .catch(err => {
                    console.log(err.response.data.error)
                    setErrorMessage(err.response.data.error);
                })
        }
    }

    return (
        <div className="">
            <div className="header">
                <img className="logo" src='assets/images/icons/coralytics-logo.png'></img>
            </div>
            <div className="app">
                <div className="login-form">
                    <div className="title"> MEMBER LOGIN</div>
                    <div className="form">
                        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                        <form onSubmit={handleLogin}>
                            <div className="mb-3">
                                <input type="text" name="email" className="form-control" placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChangeEmail} />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" name="pass" placeholder='Password'
                                    value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error">{errors.password}</span>}
                            </div>
                            <div className="button-container">
                                <button type="submit" className='btn btn-primary'>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login