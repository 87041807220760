import React, { useEffect, useState } from "react";
import { getProjects } from "../../Services/Projects";
import { User } from "../../Models/User";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const SelectInviteUsers: React.FunctionComponent<{ onInviteUser: any }> = (props) => {

    const [userValue, setUserValue] = useState<User>();
    const [loading, setLoading] = useState(false);

    const handleChangeUserName = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            lastename: event.target.value,
        }));
    }

    const handleChangeUserEmail = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            email: event.target.value,
        }));
    }


    const handleChangeCredit = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            creditScore: event.target.value,
        }));
    };

    const handleChangeRule = (event: any) => {
        setUserValue(prevOptions => ({
            ...prevOptions,
            rule: event.target.value,
        }));
    }


    const invite = (event: any) => {
        props.onInviteUser(userValue);
    }

    return loading ? (<div> ... تحميل</div>) : (
        <>
            <div className="row col-users ">
                <div className="col-4 mb-3">
                    <label htmlFor="User Name" className="form-label ">اسم المستخدم</label>
                    <input type="text" id="Rule Name" className="form-control " placeholder="اسم" value={userValue?.lastename || ""} onChange={handleChangeUserName} />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="User Email" className="form-label ">البريد الإلكتروني للمستخدم</label>
                    <input type="email" id="Rule Name" className="form-control " placeholder="البريد الإلكتروني" value={userValue?.email || ""} onChange={handleChangeUserEmail} />
                </div>
            </div>
            <div className="row col-users">
                <div className="col-4  mb-3">
                    <label htmlFor="User Rule" style={{ marginBottom: "9px" }} className="form-label">دور المستخدم</label>
                    <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme}>
                            <FormControl fullWidth>
                                <Select
                                    className="custom-select"
                                    displayEmpty

                                    value={userValue?.rule || ''}
                                    inputProps={{ 'aria-label': 'Select' }}
                                    onChange={handleChangeRule}>
                                    <MenuItem value="" disabled>
                                        اختر
                                    </MenuItem>
                                    <MenuItem value="Admin" >
                                        مشرف
                                    </MenuItem>
                                    <MenuItem value="Agent" >
                                        عميل
                                    </MenuItem>
                                    <MenuItem value="Moderator" >
                                        وسيط
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </ThemeProvider>
                    </CacheProvider>
                </div>
                <div className="col-4  mb-3">
                    <label htmlFor="project" className="form-label ">تعيين الائتمان</label>
                    <input type="number" min={0} className="form-control " placeholder="0" value={userValue?.creditScore} onChange={handleChangeCredit} />

                </div>
            </div>

            <button type="button" className={`btn btn-primary generate mb-3  ${userValue?.email && userValue?.lastename && userValue?.rule && userValue?.creditScore ? "" : 'disabled'}`} onClick={invite}>قم بدعوة</button>
        </>
    )
}
export default SelectInviteUsers
