import React, { useState } from "react"
import { GenerateText } from "../../../Services/Generate";
import CopyToClipboard from "react-copy-to-clipboard";
import { Typewriter } from "react-simple-typewriter";
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";

const CoraGPT: React.FunctionComponent<{}> = (props) => {

    const [generateText, setGenerateText] = useState<any>("")
    const [promptValue, setPromptValue] = useState<any>("")
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"prompt": "Dubai"});
    const [curlValue, setCurlValue] = useState<any>("generateGPT");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isDisabledReplace, setIsDisabledReplace] = useState<boolean>(false);

    const handeleChangePrompt = (event: any) => {
        setPromptValue(event.target.value)
    }

    const handleEdit = (event: any) => {
        setIsEdit(!isEdit);
    }

    const handleChangeEdit = (event: any) => {
        setGenerateText(event.target.value)

    }

    const handleCopy = () => {
        setIsSuccess(true)
        setShowMessage("تم نسخ المحتوى بنجاح!")
        setTimeout(() => {
            setIsSuccess(false)
        }, 3000);
    };

    const genarateText = () => {
        setIsSpinner(true)
        setIsSuccess(false)
        setIsDisabledReplace(false)
        setGenerateText("")
        setShowMsgUpgrade("")
        setIsUpgrade(false)
        GenerateText(promptValue).then((data) => {
            setIsDisabledReplace(true)
            setGenerateText(data.text.result)
            setShowMessage("تم الإنشاء بنجاح!")
            setIsSuccess(true)
            setIsSpinner(false)

        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("تم فشل في الإنشاء!")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <h5 className="h5-CoraGPT">تواصل معنا</h5>
            </div>
            {isSuccess && <div className="alert alert-success alert-dismissible mt-2" role="alert">
                {showMessage}
            </div>}
            {isError && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                {showMessage}
            </div>}
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card  CoraGPT">
                        <div className="card-body padd-24">

                            <div className="row">
                                <div className="col-md-10 mb-3">
                                    <input
                                        type="text"
                                        className="form-control input"
                                        id="defaultFormControlInput"
                                        placeholder="رسالة"
                                        onChange={handeleChangePrompt}
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    {promptValue !== "" ? <button type="button" className="btn btn-primary generate " onClick={genarateText} >أنشئ</button>
                                        : <button type="button" className="btn btn-primary generate disabled" >أنشئ</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row card-bottom">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-body padd-24">

                            <div className="generate-h5" style={{ height: "30px" }}>نص تم إنشاؤه
                                {isSpinner && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                    <span className="visually-hidden">تحميل...</span>
                                </div>}
                                 <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                {isUpgrade ? <Upgrade /> : <></>}
                            </div>
                            <div className="card card-text-gener" >
                                <div className="card-body padd-24">

                                        {isEdit == false ? <>
                                            {generateText == "" ? <p className="para">{showMsgUpgrade}</p> :
                                               <p className="para_generate"><Typewriter words={[generateText]} typeSpeed={12} /> </p>}
                                        </> : <textarea className="form-control" id="generateText" value={generateText} onChange={handleChangeEdit}>{generateText}</textarea>}
                                </div>
                            </div>
                            <div className="generate-edit" style={{ marginTop: "16px" }}>
                                <button type="button"  className={`btn mb-2 button-1 ${isDisabledReplace ? '' : 'disabled'}`} onClick={handleEdit} >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#716BE3" />
                                        <path d="M14.45 9.12504C14.4333 9.12504 14.4083 9.12504 14.3916 9.12504C11.7916 8.8667 9.69996 6.8917 9.29996 4.30837C9.24996 3.9667 9.48329 3.65004 9.82496 3.5917C10.1666 3.5417 10.4833 3.77504 10.5416 4.1167C10.8583 6.13337 12.4916 7.68337 14.525 7.88337C14.8666 7.9167 15.1166 8.22504 15.0833 8.5667C15.0416 8.88337 14.7666 9.12504 14.45 9.12504Z" fill="#716BE3" />
                                        <path d="M17.5 18.9583H2.5C2.15833 18.9583 1.875 18.6749 1.875 18.3333C1.875 17.9916 2.15833 17.7083 2.5 17.7083H17.5C17.8417 17.7083 18.125 17.9916 18.125 18.3333C18.125 18.6749 17.8417 18.9583 17.5 18.9583Z" fill="#716BE3" />
                                    </svg>تحرير
                                </button>
                                <CopyToClipboard text={generateText} onCopy={handleCopy}>

                                    <button type="button"  className={`btn mb-2 button-2 ${isDisabledReplace ? '' : 'disabled'}`}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.49996 18.9583H6.33329C2.67496 18.9583 1.04163 17.3249 1.04163 13.6666V10.4999C1.04163 6.84159 2.67496 5.20825 6.33329 5.20825H8.83329C9.17496 5.20825 9.45829 5.49159 9.45829 5.83325C9.45829 6.17492 9.17496 6.45825 8.83329 6.45825H6.33329C3.34996 6.45825 2.29163 7.51659 2.29163 10.4999V13.6666C2.29163 16.6499 3.34996 17.7083 6.33329 17.7083H9.49996C12.4833 17.7083 13.5416 16.6499 13.5416 13.6666V11.1666C13.5416 10.8249 13.825 10.5416 14.1666 10.5416C14.5083 10.5416 14.7916 10.8249 14.7916 11.1666V13.6666C14.7916 17.3249 13.1583 18.9583 9.49996 18.9583Z" fill="#716BE3" />
                                            <path d="M14.1667 11.7916H11.5C9.15837 11.7916 8.20837 10.8416 8.20837 8.49991V5.83324C8.20837 5.58324 8.35837 5.34991 8.59171 5.25824C8.82504 5.15824 9.09171 5.21658 9.27504 5.39158L14.6084 10.7249C14.7834 10.8999 14.8417 11.1749 14.7417 11.4082C14.65 11.6416 14.4167 11.7916 14.1667 11.7916ZM9.45837 7.34158V8.49991C9.45837 10.1582 9.84171 10.5416 11.5 10.5416H12.6584L9.45837 7.34158Z" fill="#716BE3" />
                                            <path d="M13 2.29175H9.66663C9.32496 2.29175 9.04163 2.00841 9.04163 1.66675C9.04163 1.32508 9.32496 1.04175 9.66663 1.04175H13C13.3416 1.04175 13.625 1.32508 13.625 1.66675C13.625 2.00841 13.3416 2.29175 13 2.29175Z" fill="#716BE3" />
                                            <path d="M5.83337 4.79175C5.49171 4.79175 5.20837 4.50841 5.20837 4.16675C5.20837 2.44175 6.60837 1.04175 8.33337 1.04175H10.5167C10.8584 1.04175 11.1417 1.32508 11.1417 1.66675C11.1417 2.00841 10.8584 2.29175 10.5167 2.29175H8.33337C7.30004 2.29175 6.45837 3.13341 6.45837 4.16675C6.45837 4.50841 6.17504 4.79175 5.83337 4.79175Z" fill="#716BE3" />
                                            <path d="M15.9917 14.7917C15.65 14.7917 15.3667 14.5084 15.3667 14.1667C15.3667 13.8251 15.65 13.5417 15.9917 13.5417C16.9417 13.5417 17.7084 12.7667 17.7084 11.8251V6.66675C17.7084 6.32508 17.9917 6.04175 18.3334 6.04175C18.675 6.04175 18.9584 6.32508 18.9584 6.66675V11.8251C18.9584 13.4584 17.625 14.7917 15.9917 14.7917Z" fill="#716BE3" />
                                            <path d="M18.3334 7.29174H15.8334C13.6167 7.29174 12.7084 6.38341 12.7084 4.16674V1.66674C12.7084 1.41674 12.8584 1.18341 13.0917 1.09174C13.325 0.991739 13.5917 1.05007 13.775 1.22507L18.775 6.22507C18.95 6.40007 19.0084 6.67507 18.9084 6.9084C18.8167 7.14174 18.5834 7.29174 18.3334 7.29174ZM13.9584 3.17507V4.16674C13.9584 5.69174 14.3084 6.04174 15.8334 6.04174H16.825L13.9584 3.17507Z" fill="#716BE3" />
                                        </svg>
                                        نسخ
                                    </button>
                                </CopyToClipboard>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    

        </div>
    )
}
export default CoraGPT;