import React, { useEffect, useState } from "react"
import { UploadEmaratesIdService } from "../../../Services/GenerateLease"


const TenantInformation: React.FunctionComponent<{ onGenerateTenantInformation: any }> = (props) => {

    const [tenantName, setTenantName] = useState<any>("")
    const [passportNumber, setPassportNumber] = useState<any>("")
    const [emiratesIDValue, setEmiratesIDValue] = useState<any>("")
    const [licenseNoValue, setLicenseNoValue] = useState<any>("")
    const [licensingAuthority, setLicensingAuthority] = useState<any>("")
    const [tenantEmail, setTenantEmail] = useState<any>("")
    const [tenantPhone, setTenantPhone] = useState<any>("")
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {

        const obj = {
            'tenant_name': tenantName,
            'tenant_emirates_id_': emiratesIDValue,
            'tenant_license_no': licenseNoValue,
            'tenant_licensing_authority': licensingAuthority,
            'tenant_email': tenantEmail,
            'tenant_phone': tenantPhone,
            'number_of_co_occupants': passportNumber
        }
        props.onGenerateTenantInformation(obj);
    }, [props.onGenerateTenantInformation])


    const handleTenantName = (event: any) => {
        setTenantName(event.target.value);
    }
    const handlePassportNumber = (event: any) => {
        setPassportNumber(event.target.value);
    }
    const handleEmiratesID = (event: any) => {
        setEmiratesIDValue(event.target.value);
    }
    const handleLicenseNo = (event: any) => {
        setLicenseNoValue(event.target.value);
    }
    const handleLicensingAuthority = (event: any) => {
        setLicensingAuthority(event.target.value);
    }
    const handleTenantEmail = (event: any) => {
        setTenantEmail(event.target.value);
    }
    const handleLandlordPhone = (event: any) => {
        setTenantPhone(event.target.value);
    }
    const HandleChangeUpload = (event: any) => {
        const file = event.target.files[0];

        if (!file) {
            console.log("No files selected.");
            return;
        }

        setIsSpinner(true);
        setIsError(false);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };
            UploadEmaratesIdService([image]).then((data) => {
                setIsSpinner(false)
                setTenantName(data.data[0].content['name'] ? data.data[0].content['name'] : "");
                setEmiratesIDValue(data.data[0].content["id_num"] ? data.data[0].content["id_num"] : "")
            })
                .catch((err) => {
                    setIsError(true);
                    setIsSpinner(false);
                    console.log("Error downloading file :", err);
                });
        };

        reader.onerror = () => {
            setIsError(true);
            setIsSpinner(false);
            console.log("Error reading file");
        };

        setTimeout(() => {
            setIsError(false);
        }, 5000);
    };

    return (
        <>
            {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                Upload failed
            </div>}
            <div className="row mt-4 mb-3 padd-30" >
                <div className="container_image">
                    <input
                        type="file"
                        id="upload-emi-id-tenant"
                        name="upload-emi-id-tenant"
                        accept="image/*" onChange={HandleChangeUpload}
                        hidden
                    />
                    <label className="btn-upload" id="button-addnew" htmlFor="upload-emi-id-tenant" role="button">
                        <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                        Upload Emirates ID
                    </label>
                    {isSpinner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>}
                </div>

            </div>

            <div className="row padd-30 mb-4" >
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">Tenant Name</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={tenantName}
                        placeholder="Tenant Name"
                        onChange={handleTenantName}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">Tenant Phone</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={tenantPhone}
                        placeholder="Tenant Phone"
                        onChange={handleLandlordPhone}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">Passport Number</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={passportNumber}
                        placeholder="Passport Number"
                        onChange={handlePassportNumber}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">Tenant's Emirates ID</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={emiratesIDValue}
                        placeholder="Tenant's Emirates ID"
                        onChange={handleEmiratesID}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">License No. (Incase of a company)</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={licenseNoValue}
                        placeholder="License No."
                        onChange={handleLicenseNo}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label" htmlFor="selectTypeOpt">Tenant Email</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={tenantEmail}
                        placeholder="Tenant Email"
                        onChange={handleTenantEmail}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">Licensing Authority (Incase of a company)</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={licensingAuthority}
                        placeholder="Licensing Authority"
                        onChange={handleLicensingAuthority}
                    />

                </div>

            </div>
        </>
    )
}
export default TenantInformation;