import { useEffect, useState } from "react";

const EnhancedImages: React.FunctionComponent<{ selectImages: any, checkedId: any, SelectedImagecHandler: any, CheckboxChangeHandler: any }> = (props) => {


    useEffect(() => {

    }, [])

    const handleSelectImages = (event: any) => {
        props.SelectedImagecHandler(event)
    }

    const handleCheckboxChange = (id: any) => {
        props.CheckboxChangeHandler(id)
    };

    return (
        <>
            {props.selectImages.length > 1 ? <div className="selected-images" >
                {props.selectImages.map((image: any, index: any) => (
                    <div key={image.id}>
                        <input type="checkbox" id={image.id} value={image.id} checked={props.checkedId === image.id} onChange={(event) => { handleSelectImages(event); handleCheckboxChange(image.id); }} />
                        <label htmlFor={image.id} className="label1">
                            <img src={image.src} className="image" /></label>
                    </div>
                ))}
            </div>
                : <div className="selected-images" >
                    {props.selectImages.map((image: any, index: any) => (
                        <div key={image.id}>
                            <label htmlFor={image.id} className="label1">
                                <img src={image.src} className="image" /></label>
                        </div>
                    ))}
                </div>
            }
        </>
    )
}
export default EnhancedImages;