import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ContractInformation: React.FunctionComponent<{ onGenerateContractInformation: any ,contentContract: any}> = (props) => {

    const [contractPeriodFrom, setContractPeriodFrom] = useState<any>(props.contentContract[0]?.contract?.contract_period?.from ? new Date(props.contentContract[0]?.contract?.contract_period?.from) : "")
    const [contractPeriodTo, setContractPeriodTo] = useState<any>(props.contentContract[0]?.contract?.contract_period?.to ? new Date(props.contentContract[0]?.contract?.contract_period?.to) : "")
    const [securityDeposit, setSecurityDeposit] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.contract?.security_deposit_amount :"")
    const [contractValue, setContractValue] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.contract?.contract_value : "")
    const [modeOfPayment, setModeofPayment] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.contract?.mode_of_payment :"")
    const [annualRent, setAnnualRent] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.contract?.annual_rent : "")

    const formatDate = (inputDate :any) => {
        if (!inputDate) {
            return ""; 
        }
        const date = new Date(inputDate);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`
        return formattedDate;
      };

    useEffect(() => {
        const obj = {
            'contract_period': {'from': formatDate(contractPeriodFrom), 'to': formatDate(contractPeriodTo)},
            'contract_value': contractValue,
            'annual_rent': annualRent,
            'mode_of_payment': modeOfPayment,
            'security_deposit_amount': securityDeposit
        }
        props.onGenerateContractInformation(obj);
    },[props.onGenerateContractInformation])

    const handleContractPeriodFrom = (date: any) => {
        setContractPeriodFrom(date);
    }
    const handleContractPeriodTo = (date: any) => {
        setContractPeriodTo(date);
    }
    const handleSecurityDepositAmount = (event: any) => {
        setSecurityDeposit(event.target.value);
    }
    const handleContractValue = (event: any) => {
        setContractValue(event.target.value);
    }
    const handleModeofPayment = (event: any) => {
        setModeofPayment(event.target.value);
    }
    const handleAnnualRent = (event: any) => {
        setAnnualRent(event.target.value);
    }


    return (
        <>
            <div className="row padd-30 mb-4 mt-4">
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">مدة العقد (من)</label>
                   
                    <div>
                    <DatePicker
                        className="form-control input mb-3"
                        selected={contractPeriodFrom}
                        onChange={handleContractPeriodFrom}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="مدة العقد (من)"
                    />
                    </div>
                    <label className="form-label" htmlFor="selectTypeOpt">إيجار سنوي</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={annualRent}
                        placeholder="إيجار سنوي"
                        onChange={handleAnnualRent}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">مدة العقد (إلى)</label>
                  
                    <div>
                    <DatePicker
                        className="form-control input mb-3"
                        selected={contractPeriodTo}
                        onChange={handleContractPeriodTo}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="مدة العقد (إلى) "
                    />
                    </div>
                   
                    <label className="form-label" htmlFor="selectTypeOpt">مبلغ التأمين الأمني</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={securityDeposit}
                        placeholder="مبلغ التأمين الأمني"
                        onChange={handleSecurityDepositAmount}
                    />

                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">قيمة العقد</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={contractValue}
                        placeholder="قيمة العقد"
                        onChange={handleContractValue}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">وسيلة الدفع</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={modeOfPayment}
                        placeholder="وسيلة الدفع"
                        onChange={handleModeofPayment}
                    />
                </div>
            </div>
        </>
    )
}
export default ContractInformation;