import axios from "axios";
import { PropertiesObj } from "../Models/PropertiesObj";

const Url = process.env.REACT_APP_URl;

export const getProperties = async (page: number) => {
  const apiUrl = Url + "/propertiesversion";
  const obj = {
    "page": page,
    "perPage": 8
  }
  const response = await axios.post(apiUrl, obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  const properties: PropertiesObj = { total: response.data.total, properties: response.data.properties }
  return properties;
}

export const getPropertiesById = async (id: number) => {
  const apiUrl = Url + "/listing/";
  const obj = { "id": id }
  const response = await axios.get(`${apiUrl}${id}`,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  const properties: any = response.data
  return properties;
}


export const DownloadXmlProperties = async (platform:any) => {
  const apiUrl = Url + "/downloadxml";
 
  const response = await axios.post(apiUrl,{platform : platform}, {
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return  response.data;
}
export const DownloadXmlProperty = async (platform:any, idProperty:any) => {
  const apiUrl = Url + "/downloadxmlforone";
 
  const response = await axios.post(apiUrl,{platform : platform, id:idProperty}, {
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return  response.data;
}

export const StatusProperties =async (status :any ,id :any)=>{
  const apiUrl = Url + "/listing";
 
  const response = await axios.put(`${apiUrl}/${id}`,{status : status}, {
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return  response.data;
}

export const AssignAgentService = async (obj :any)=>{
  const apiUrl = Url + "/assignAgent ";
 
  const response = await axios.put(apiUrl,obj, {
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return  response.data;
}

export const UploadPropertiesXml = (formData: any) => {
  return axios.post(Url+"/propertiesxml", formData,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  }).then((response) => {
    // if (response.data.message) {
    //   console.log(response)
    // }
    return response.data;
  });
};

export const DropProperties = async (id: any) => {
  const apiUrl = Url + "/listing";
  const response = await axios.delete(`${apiUrl}/${id}`,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const property_update_taken_action= async (id: any,action:any) => {
  const apiUrl = Url + "/properties/update-taken-action";
  const response = await axios.put(apiUrl,{id:id,newTakenActionName:action},{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const nextprepropertie = async (index: any) => {
  const apiUrl = Url + "/properties/manual";
  const response = await axios.post(apiUrl,{index:index},{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const CreateProperty = async (property: any) => {
  const apiUrl = Url + "/processObject";
  const response = await axios.post(apiUrl,property,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const CreatePropertyManual = async (property: any) => {
  const apiUrl = Url + "/create_manualy_property";
  const response = await axios.post(apiUrl,property,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const UpdatePropertyService = async (property: any,id :any) => {
  const apiUrl = Url + "/listing";
  const response = await axios.put(`${apiUrl}/${id}`,property,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const getFilterProperties = async (filterOptions:any,page: number) => {
  const apiUrl = Url + "/propertiesfilter";
  const obj = { "page": page, "perPage": 8 , "filterOptions":filterOptions}
  const response = await axios.post(apiUrl, obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  const properties: PropertiesObj = { total: response.data.total, properties: response.data.properties }
  return properties;
}
export const getListings = async (filterOptions:any,page: number) => {
  const apiUrl = Url + "/listings_filter";
  const obj = { "page": page, "perPage": 8 , "filterOptions":filterOptions}
  const response = await axios.post(apiUrl,obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  const properties: PropertiesObj = { total: response.data.total, properties: response.data.properties }
  return properties;
}

export const getPropertieTitelDesc= async (id:any) => {
  const apiUrl = Url + "/propertieTitelDesc";
  const response = await axios.post(apiUrl, {"id":id},{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
  
export const getAgentListings= async (broker:any) => {
  const apiUrl = Url + "/properties/agent_listings_count";
  const obj = { "broker": broker}
  const response = await axios.post(apiUrl, obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const dropdown_option=async()=>{
  const apiUrl = Url + "/dropdown_option";
  const response = await axios.get(apiUrl,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const getLocations=async(locationName: any)=>{
  const apiUrl = Url + "/locations/autocomplete";
  const response = await axios.post(apiUrl,{locationName :locationName},{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
export const getLocationsFind=async(location:any)=>{
  const apiUrl = Url + "/locations/find";
  const response = await axios.post(apiUrl,{location:location},{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const getVersions=async(ref : any)=>{
  const apiUrl = Url +"/properties";
  const response = await axios.get(`${apiUrl}/${ref}/${"versions"}`,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
export const GetAIsettings=async(obj : any)=>{
  const apiUrl = Url +"/AIsettings";
  const response = await axios.post(apiUrl,obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const UpdateAIsettings=async(obj : any)=>{
  const apiUrl = Url +"/AIsettings";
  const response = await axios.put(apiUrl,obj,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const propertieImages=async(id :any)=>{
  const apiUrl = Url + "/propertieImages";
  const response = await axios.post(apiUrl,{"id": id})
  return response.data;
}