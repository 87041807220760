import React, { useEffect, useRef, useState } from "react";
import { FormControl, Select, MenuItem, ListItemText } from "@mui/material";
import { CreatePortals, DropPortals, GetPortals } from "../../Services/Portals";
import CopyToClipboard from "react-copy-to-clipboard";
const Url = process.env.REACT_APP_URl;


const Portals: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>();
    const [platforms, setPlatforms] = useState<any>([{ name: "Coralytics", src: "/assets/images/icons/Coralytics.png" }, { name: "Propcrm", src: "/assets/images/icons/propcrm.png" }, { name: "Propertyfinder", src: "/assets/images/icons/finder.png" }, { name: "Bayut", src: "/assets/images/icons/bayut.png" }, { name: "Dubizzle", src: "/assets/images/icons/dubizzle.png" }]);
    const [selectedPortals, setSelectedPortals] = useState([{ portal: '', url: '' }]);
    const [listPortals, setListPortals] = useState<any>([]);
    const [change, setChange] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [portalValue, setPortalValue] = useState<any>("");
    const [isCoppiedCurl, setIsCoppiedCurl] = useState<boolean>(true);

    useEffect(() => {
        GetPortals().then((data: any) => {
            setLoading(false)
            if (data.length == 0) {
                setListPortals([]);
            } else {
                setListPortals(data[0].urls);
            }
        })
      
    }, [change])


    const handleCopy = () => {
        setIsSuccess(true)
        setIsCopied(true)
        setShowMessage("Copied Successfully!")
        setTimeout(() => {
            setIsSuccess(false)
        }, 3000);
    };

    const handleChangePortal = (index: any, event: any) => {
        const newSelectedPortals = [...selectedPortals];
        newSelectedPortals[index].portal = event.target.value;
        newSelectedPortals[index].url = `${Url}/xml.feed.publish?platform=${newSelectedPortals[index].portal}&clientid=${localStorage.getItem('id')}`;
        setSelectedPortals(newSelectedPortals);
    };

    const handleChangeURL = (index: any, event: any) => {
        const newSelectedPortals = [...selectedPortals];
        newSelectedPortals[index].url = `${Url}/xml.feed.publish?platform=${newSelectedPortals[index].portal}&clientid=${localStorage.getItem('id')}`;
        setSelectedPortals(newSelectedPortals);
    };

    const addPortal = () => {
        setSelectedPortals([...selectedPortals, { portal: '', url: '' }]);
    };

    const isSaveDisabled = () => {
        return selectedPortals.some((item) => item.portal === '' || item.url === '');
    };

    const openLinkInNewTab = (url: any) => {
        window.open(url, '_blank');
    };
    const Drop = (portal: any) => {
        DropPortals(portal).then((resp: any) => {
            setIsSuccess(true)
            setChange(!change)
            setShowMessage("Deleted Successfully!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("Error Delete Portals")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 4000)
    }

    const divRef = useRef<HTMLDivElement>(null);

    const handleCopyCurl = () => {
        const div = divRef.current;

        if (div) {
            const textToCopy = div.innerText;

            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;

            // Append the text area to the document
            document.body.appendChild(tempTextArea);

            // Select the content
            tempTextArea.select();

            try {
                // Execute the copy command
                document.execCommand('copy');
                setIsCoppiedCurl(false)
                console.log('Content copied successfully!');
            } catch (err) {
                console.log('Unable to copy content');
            } finally {
                // Remove the temporary text area from the document
                document.body.removeChild(tempTextArea);
            }
        }
    };

    const Save = () => {
        CreatePortals(selectedPortals).then((data: any) => {
            setSelectedPortals([{ portal: '', url: '' }]);
            setIsSuccess(true)
            setChange(!change)
            setShowMessage("Created Successfully!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("Error Create Portals")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 4000)
    }

    return loading ? (<div> Loading ...</div>) : (
        <>
            {listPortals.length !== 0 && <div className="row col-users">
                <div className="col-4 mb-2">
                    <label htmlFor="Name-portal" className="form-label">Portals</label>
                    {listPortals.map((portal2: any, index: any) => (

                        <FormControl fullWidth key={index}>
                            <Select
                                className="custom-select disabled mb-3"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select' }}
                                value={portal2.portal}
                                renderValue={(value) => value || 'Select'}
                            >
                                <MenuItem value="" disabled>
                                    Select
                                </MenuItem>
                                {platforms.map((platform: any, idx: any) => (
                                    <MenuItem value={platform.name} key={idx}>
                                        <img
                                            src={platform.src}
                                            style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }}
                                            alt={platform.name}
                                        />
                                        <ListItemText primary={platform.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ))}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="Name-urls" className="form-label">Urls</label>

                    {listPortals.map((portal2: any, index: any) => (
                        <div className="input-group mb-3" key={index}>
                            <input
                                type="text"
                                id={`URL-portal-${index}`}
                                className="form-control"
                                placeholder="http://..."
                                value={portal2.url}
                                readOnly
                            />
                            <div className="btn-group">

                                <button
                                    className="input-group-text"
                                    id={`delete-${index}`}
                                    onClick={() => Drop(portal2.portal)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa fa-trash" aria-hidden="true" style={{ color: "#716BE3", opacity: "1" }}></i>
                                </button>
                                <CopyToClipboard text={portal2.url} onCopy={() => handleCopy}>
                                    {isCopied ? <button className="input-group-text" id={`URL-copy-${index}`}>
                                        <i className="fa-solid fa-check" style={{ color: "#46B49E", opacity: "1" }}></i>
                                    </button>
                                        : <button className="input-group-text" id={`URL-copy-${index}`}>
                                            <i className="fa-solid fa-copy" style={{ color: "#716BE3", opacity: "1" }}></i>
                                        </button>}
                                </CopyToClipboard>
                                <button
                                    className="input-group-text"
                                    id={`URL-open-${index}`}
                                    onClick={() => openLinkInNewTab(portal2.url)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa-solid fa-up-right-from-square" style={{ color: "#716BE3", opacity: "1" }}></i>
                                </button>

                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="Name-urls" className="form-label"></label>
                    {listPortals.map((portal3: any, index: any) => (
                        <a className="mb-3" id="button-addnew" style={{ height: "45px", marginTop: "9px" }} onClick={()=>setPortalValue(portal3.portal)}  data-bs-toggle="modal" data-bs-target="#api">
                            API
                        </a>
                    ))}
                </div>
            </div>}

            <div className="row col-users">
                {selectedPortals.map((item, index) => (
                    <div className="row col-users" key={index}>
                        <div className="col-4 mb-2">
                            <label htmlFor={`Name-${index}`} className="form-label">Select Portal</label>
                            <FormControl fullWidth>
                                <Select
                                    className="custom-select"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select' }}
                                    value={item.portal}
                                    onChange={(e) => handleChangePortal(index, e)}
                                    renderValue={(value) => value || 'Select'}
                                >
                                    <MenuItem value="" disabled>
                                        Select
                                    </MenuItem>
                                    {platforms.map((platform: any, idx: any) => (
                                        <MenuItem value={platform.name} key={idx}>
                                            <img
                                                src={platform.src}
                                                style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }}
                                                alt={platform.name}
                                            />
                                            <ListItemText primary={platform.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-4 mb-2">
                            <label htmlFor={`URL-${index}`} className="form-label">URL</label>
                            <input
                                type="text"
                                id={`URL-${index}`}
                                className="form-control mb-3"
                                placeholder="http://..."
                                value={item.url}
                                onChange={(e) => handleChangeURL(index, e)}
                            />
                        </div>
                    </div>
                ))}
                <div className="col-12">
                    <a className="mb-3" id="button-addnew" style={{ width: "100px" }} onClick={addPortal}>
                        <i className="fa-solid fa-plus"></i> Add Portal
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <button type="button" className={`btn btn-primary generate marg-top20 ${isSaveDisabled() ? 'disabled' : ''} `} onClick={Save} >Save</button>
                </div>
                <div className="col-md-6">
                    {isSuccess && <div className="alert alert-success alert-dismissible marg-top20" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible marg-top20" role="alert">
                        {showMessage}
                    </div>}
                </div>
            </div>
            <div className="modal fade" id="api" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-curl">
                                <pre>
                                    <div className="mb-3">
                                        <a id="button-addnew" onClick={handleCopyCurl} style={{ float: "right", color: "white", border: " 1px solid #000000" }}>

                                            <i className="fa-solid fa-copy" style={{ marginRight: "5px" }}></i>
                                            {isCoppiedCurl ? <> copy</> : <>Copied !</>}
                                        </a>
                                    </div>
                                    <code ref={divRef}>
                                        <span style={{ color: 'blue' }}>curl -X GET </span> {Url + "/listing_publish_api?platform=" + portalValue + "&clientid=" + localStorage.getItem('id')} {'\\'} <br />
                                        <span style={{ color: 'blue' }}>-H 'Authorization</span>: Bearer {localStorage.getItem("usertoken") + "'"} {'\\'} <br />
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Portals
