import React, { useEffect, useState } from "react";
import { GetEndpoints } from "../../Services/Account";

const EnhanceMain: React.FunctionComponent<{}> = (props) => {

    const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));
    const [superResolutionEndpoints, setSuperResolutionEndpoints] = useState(null);
    const [removeImageNoiseEndpoints, setRemoveImageNoiseEndpoints] = useState(null);
    const [blurCarPlatesEndpoints, setBlurCarPlatesEndpoints] = useState(null);
    const [blurPeopleFacesEndpoints, setBlurPeopleFacesEndpoints] = useState(null);
    const [imagecontrastEndpoints, setImagecontrastEndpoints] = useState(null);
    const [unboxImageEndpoints, setUnboxImageEndpoints] = useState(null);
    const [replacePIIEndpoints, setReplacePIIEndpoints] = useState(null);
    const [removeWatermarkEndpoints, setRemoveWatermarkEndpoints] = useState(null);
    const [listingBoosterEndpoints, setListingBoosterEndpoints] = useState(null);
    const [resizeImageEndpoints, setResizeImageEndpoints] = useState(null);
    const [removeDuplicatesEndpoints, setRemoveDuplicatesEndpoints] = useState(null);
    const [compressImageEndpoints, setCompressImageEndpoints] = useState(null);
    const [addWatermarkEndpoints, setAddWatermarkEndpoints] = useState(null);
    const [addQRCodeImageEndpoints, setAddQRCodeImageEndpoints] = useState(null);
    const [AddQRCodeVideoEndpoints, setAddQRCodeVideoEndpoints] = useState(null);
    const [AddQRCodeListingEndpoints, setAddQRCodeListingEndpoints] = useState(null);
    const [LowLightEnhancementEndpoints,setLowLightEnhancementEndpoints]= useState(null);
    const [imageResizeToWebpEndpoints,setImageResizeToWebpEndpoints]= useState(null);
    const [removeObjectsEndpoints,setRemoveObjectsEndpoints]= useState(null);
    const [removeObjectsManualEndpoints,setRemoveObjectsManualEndpoints]= useState(null);
    const [skyReplacementBlueSkyEndpoints, setSkyReplacementBlueSkyEndpoints]= useState(null);
    const [skyReplacementDuskShotEndpoints,setSkyReplacementDuskShotEndpoints]= useState(null);

    useEffect(() => {
        fetchEndpoints("Image Super Resolution", setSuperResolutionEndpoints);
        fetchEndpoints("Remove Image Noise", setRemoveImageNoiseEndpoints);
        fetchEndpoints("Blur Car Plates", setBlurCarPlatesEndpoints);
        fetchEndpoints("Blur People Faces", setBlurPeopleFacesEndpoints);
        fetchEndpoints("Improve Image contrast", setImagecontrastEndpoints);
        fetchEndpoints("Unbox Image", setUnboxImageEndpoints);
        fetchEndpoints("Remove Replace PII", setReplacePIIEndpoints);
        fetchEndpoints("Remove Watermark", setRemoveWatermarkEndpoints);
        fetchEndpoints("Listing Booster", setListingBoosterEndpoints);
        fetchEndpoints("Resize Image", setResizeImageEndpoints);
        fetchEndpoints("Remove Duplicates", setRemoveDuplicatesEndpoints);
        fetchEndpoints("Compress Image", setCompressImageEndpoints);
        fetchEndpoints("Add Watermark", setAddWatermarkEndpoints);
        fetchEndpoints("Add QR Code to image", setAddQRCodeImageEndpoints);
        fetchEndpoints("Add QR Code to video", setAddQRCodeVideoEndpoints);
        fetchEndpoints("Add QR Code to listing", setAddQRCodeListingEndpoints);
        fetchEndpoints("Low Light Enhancement", setLowLightEnhancementEndpoints);
        fetchEndpoints("Resize Image To Webp", setImageResizeToWebpEndpoints);
        fetchEndpoints("Remove Objects Detect", setRemoveObjectsEndpoints);
        fetchEndpoints("Remove Objects Manual", setRemoveObjectsManualEndpoints);
        fetchEndpoints("Sky Replacement Blue Sky", setSkyReplacementBlueSkyEndpoints);
        fetchEndpoints("Sky Replacement Dusk Shot", setSkyReplacementDuskShotEndpoints)

    }, []);

    const fetchEndpoints = async (name: any, setter: any) => {
        try {
            const data = await GetEndpoints(name);
            console.log(data);
            setter(data);
        } catch (error) {
            console.error("Error fetching endpoints:", error);
        }
    };


    return (
        <div className="container-xxl flex-grow-1 container-p-y ">
            <div className="row">
                <h5 className="generate-title">صورة</h5>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/image-super-resolution' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Image Super Resolution') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.88 14.2929H19.76V4.69287C19.76 2.45287 18.5467 1.99954 17.0667 3.67954L16 4.89287L6.97335 15.1595C5.73335 16.5595 6.25335 17.7062 8.12002 17.7062H12.24V27.3062C12.24 29.5462 13.4533 29.9995 14.9333 28.3195L16 27.1062L25.0267 16.8395C26.2667 15.4395 25.7467 14.2929 23.88 14.2929Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                تحسين دقة الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتحسين جودة صورك عن طريق زيادة دقتها ووضوحها.</p>
                            <br /><p className="end_points" dir="auto">{superResolutionEndpoints} {superResolutionEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة </p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/remove-image-noise' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Image Noise') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة ضوضاء الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بالتخلص من التشويش البصري غير المرغوب فيه أو حبيبات الضوضاء من صورك، مما يؤدي إلى ظهور أكثر نظافة ووضوحًا.</p>
                            <br /><p className="end_points" dir="auto">{removeImageNoiseEndpoints} {removeImageNoiseEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/blur-car-plates' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Blur Car Plates') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                طمس لوحات السيارات
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بحماية الخصوصية أو المعلومات الحساسة عن طريق طمس لوحات ترخيص السيارات في الصور.</p>
                            <br /><p className="end_points" dir="auto">{blurCarPlatesEndpoints} {blurCarPlatesEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/blur-people-faces' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Blur People Faces') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>

                            </div>
                            <h2>
                                طمس وجوه الأشخاص
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتجهيز الأفراد عن طريق طمس وجوههم في الصور لأسباب الخصوصية أو الأمان.</p>
                            <br /><p className="end_points" dir="auto"> {blurPeopleFacesEndpoints} {blurPeopleFacesEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                {/* <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/ar/enhance/remove-image-bluriness' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة ضبابية الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتحسين وتنقية الصور عن طريق إزالة أي ضبابية أو فقد للتركيز.</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/ar/enhance/defocus-bluriness' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>

                            </div>
                            <h2>
                                إلغاء التركيز وإزالة الضبابية
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتصحيح كل من التمويه والضبابية في الصور، مضمناً أن تكون حادة ومعرّفة بشكل جيد.</p>
                        </a>
                    </div>
                </div> */}
            </div>
            <div className="row">
                {/* <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/ar/enhance/improve-image-white-balance' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>

                            </div>
                            <h2>
                                تحسين توازن اللون الأبيض في الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بضبط درجة حرارة اللون واللون لصورك للحصول على توازن أبيض أكثر دقة وجاذبية.</p>
                        </a>
                    </div>
                </div> */}
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/improve-image-contrast' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Improve Contrast') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>

                            </div>
                            <h2>
                                تحسين تباين الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتعزيز التأثير البصري لصورك عن طريق ضبط التباين بين المناطق الفاتحة والمناطق الداكنة.</p>
                            <br /><p className="end_points" dir="auto">{imagecontrastEndpoints} {imagecontrastEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/unbox-image' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Unbox Image') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.3334 6.66699V9.33366C29.3334 11.7737 28.2267 13.0937 26.0001 13.2937C25.7867 13.3203 25.5601 13.3337 25.3334 13.3337H6.66675C6.44008 13.3337 6.21341 13.3203 6.00008 13.2937C3.77341 13.0937 2.66675 11.7737 2.66675 9.33366V6.66699C2.66675 4.00033 4.00008 2.66699 6.66675 2.66699H25.3334C28.0001 2.66699 29.3334 4.00033 29.3334 6.66699Z" fill="#46B49E" />
                                    <path d="M7.33333 15C6.6 15 6 15.6 6 16.3333V25.3333C6 28 6.66667 29.3333 10 29.3333H22C25.3333 29.3333 26 28 26 25.3333V16.3333C26 15.6 25.4 15 24.6667 15H7.33333ZM18.4267 21H13.5733C13.0267 21 12.5733 20.5467 12.5733 20C12.5733 19.4533 13.0267 19 13.5733 19H18.4267C18.9733 19 19.4267 19.4533 19.4267 20C19.4267 20.5467 18.9733 21 18.4267 21Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                فتح الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بإزالة التغليف أو العوائق من الصور، مما يكشف عن المحتوى بشكل أوضح.</p>
                            <br /><p className="end_points" dir="auto">{unboxImageEndpoints} {unboxImageEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-3 ">
                    <div className="card">
                        <a href="/ar/enhance/resize-image" className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Resize Image') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                تغيير حجم الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> قم بضبط أبعاد صورك بسهولة لتلبية احتياجاتك الخاصة دون تغيير حجم الصور</p>
                            <br /><p className="end_points" dir="auto">{resizeImageEndpoints} {resizeImageEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-3 ">
                    <div className="card">
                        <a href="/ar/enhance/remove-duplicate" className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Duplicates') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة التكرارات
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> اكتشاف وحذف الصور المكررة بسرعة وكفاءة لتنظيم مجموعة الصور الخاصة بك</p>
                            <br /><p className="end_points" dir="auto">{removeDuplicatesEndpoints} {removeDuplicatesEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-3 ">
                    <div className="card">
                        <a href="/ar/enhance/compress-image" className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Compress Image') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.3334 6.66699V9.33366C29.3334 11.7737 28.2267 13.0937 26.0001 13.2937C25.7867 13.3203 25.5601 13.3337 25.3334 13.3337H6.66675C6.44008 13.3337 6.21341 13.3203 6.00008 13.2937C3.77341 13.0937 2.66675 11.7737 2.66675 9.33366V6.66699C2.66675 4.00033 4.00008 2.66699 6.66675 2.66699H25.3334C28.0001 2.66699 29.3334 4.00033 29.3334 6.66699Z" fill="#46B49E" />
                                    <path d="M7.33333 15C6.6 15 6 15.6 6 16.3333V25.3333C6 28 6.66667 29.3333 10 29.3333H22C25.3333 29.3333 26 28 26 25.3333V16.3333C26 15.6 25.4 15 24.6667 15H7.33333ZM18.4267 21H13.5733C13.0267 21 12.5733 20.5467 12.5733 20C12.5733 19.4533 13.0267 19 13.5733 19H18.4267C18.9733 19 19.4267 19.4533 19.4267 20C19.4267 20.5467 18.9733 21 18.4267 21Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                ضغط الصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> قم بضغط الصور لتقليل حجمها دون التأثير على جودة الصورة، مما يجعل من السهل مشاركتها عبر الإنترنت أو توفير
                                مساحة تخزين على الأجهزة.</p>
                            <br /><p className="end_points" dir="auto">{compressImageEndpoints} {compressImageEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/add-watermrk' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Add Watermark') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إضافة العلامة المائية
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> قم بإضافة علامة مائية أو شعار إلى صورك لحمايتها من الاستخدام غير المصرح به وتعزيز هويتك العلامة
                                التجارية.</p>
                            <br /><p className="end_points" dir="auto">{addWatermarkEndpoints} {addWatermarkEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/remove-watermrk' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Watermark') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة العلامة المائية (دبي)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>امسح أي علامات مائية أو شعارات من الصور لاستعادة المحتوى الأصلي.</p>
                            <br /><p className="end_points" dir="auto">{removeWatermarkEndpoints} {removeWatermarkEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/remove-watermrk-universal' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Watermark') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة العلامة المائية (عالمية)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>امسح أي علامات مائية أو شعارات من الصور لاستعادة المحتوى الأصلي.</p>
                            <br /><p className="end_points" dir="auto">{removeWatermarkEndpoints} {removeWatermarkEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>


            </div>
            <div className="row">

                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/add-QR-code-to-image' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Add QR Code to Image') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إضافة رمز الاستجابة السريعة للصورة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> ميزة تسمح لك بإدراج رمز الاستجابة السريعة في الصورة لإضافة معلومات إضافية أو
                                روابط. (رمز الاستجابة السريعة)</p>
                            <br /><p className="end_points" dir="auto"> {addQRCodeImageEndpoints} {addQRCodeImageEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/add-QR-code-to-video' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Add QR Code to Video') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إضافة رمز الاستجابة السريعة إلى الفيديو

                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> ميزة تمكنك من إضافة رمز الاستجابة السريعة إلى مقطع الفيديو لجعله أكثر تفاعلية ويسهل
                                مشاركته مع الآخرين. (رمز الاستجابة السريعة)</p>
                            <br /><p className="end_points" dir="auto">{AddQRCodeVideoEndpoints} {AddQRCodeVideoEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 فيديو </p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/ar/enhance/add-QR-code-to-listing' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Add QR Code to Listing') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إضافة رمز الاستجابة السريعة إلى القائمة (دبي)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> ميزة تمكنك من إضافة رمز الاستجابة السريعة إلى قوائمك في دبي، مما يجعلها أكثر تفاعلية وأسهل لمشاركتها مع العملاء والعملاء المحتملين.</p>
                            <br /><p className="end_points">{AddQRCodeListingEndpoints} {AddQRCodeListingEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 قائمة                            </p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/low-light-enhancement' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Low Light Enhancement') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            تحسين الإضاءة المنخفضة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg> 
                            </h2>
                            <p>ميزة تعمل على تحسين الصور الملتقطة في ظروف الإضاءة المنخفضة، وتحسين الرؤية والوضوح دون المساس بالمظهر الطبيعي للصورة.</p>
                            <br /><p className="end_points" dir="auto">{LowLightEnhancementEndpoints} {LowLightEnhancementEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/image-resize-to-webp' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Resize Image To Webp') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.88 14.2929H19.76V4.69287C19.76 2.45287 18.5467 1.99954 17.0667 3.67954L16 4.89287L6.97335 15.1595C5.73335 16.5595 6.25335 17.7062 8.12002 17.7062H12.24V27.3062C12.24 29.5462 13.4533 29.9995 14.9333 28.3195L16 27.1062L25.0267 16.8395C26.2667 15.4395 25.7467 14.2929 23.88 14.2929Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            تغيير حجم الصورة إلى Webp
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>ميزة تعمل على تغيير حجم الصور وتحويلها إلى تنسيق WebP، وتحسينها للحصول على أوقات تحميل أسرع وأداء أفضل على الويب. </p>
                            <br /><p className="end_points">{imageResizeToWebpEndpoints} {imageResizeToWebpEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/remove-objects' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Objects Detect') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            إزالة الأشياء (اكتشاف تلقائي)

                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>ميزة تكتشف تلقائيًا الكائنات غير المرغوب فيها وتزيلها من الصور، مما يضمن مظهرًا نظيفًا واحترافيًا بأقل جهد.</p>
                            <br /><p className="end_points">{removeObjectsEndpoints} {removeObjectsEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/remove-objects-manual' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove Objects Manual') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            إزالة الأشياء يدويا
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>ميزة تتيح لك تحديد الكائنات غير المرغوب فيها وإزالتها يدويًا من الصور، مما يوفر تحكمًا دقيقًا في النتيجة النهائية.</p>
                            <br /><p className="end_points">{removeObjectsManualEndpoints} {removeObjectsManualEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/sky-replacement-blue-sky' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Sky Replacement Blue Sky') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            استبدال السماء - السماء الزرقاء
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>ميزة تعمل على تحسين السماء في صورك، مما يجعلها أكثر حيوية وجاذبية بصريًا من خلال ضبط الألوان والتباين والسطوع.</p>
                            <br /><p className="end_points" dir="auto">{skyReplacementBlueSkyEndpoints} {skyReplacementBlueSkyEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/sky-replacement-dusk-shot' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Sky Replacement Dusk Shot') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            استبدال السماء - لقطة الغسق
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>ميزة تعمل على تحسين السماء في صورك، مما يجعلها أكثر حيوية وجاذبية بصريًا من خلال ضبط الألوان والتباين والسطوع.</p>
                            <br /><p className="end_points" dir="auto">{skyReplacementDuskShotEndpoints} {skyReplacementDuskShotEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 صورة</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <h5 className="generate-title marg-30">نص</h5>
            </div>
            <div className="row ">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/remove-replace-PII' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Remove / Replace PII') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.69341 13.3337C3.12008 13.3337 2.66675 12.8803 2.66675 12.307V9.22699C2.66675 5.61366 5.61341 2.66699 9.22675 2.66699H12.3067C12.8801 2.66699 13.3334 3.12033 13.3334 3.69366C13.3334 4.26699 12.8801 4.72033 12.3067 4.72033H9.22675C6.73342 4.72033 4.72008 6.74699 4.72008 9.22699V12.307C4.72008 12.8803 4.25341 13.3337 3.69341 13.3337Z" fill="#46B49E" />
                                    <path d="M28.3067 13.3337C27.7467 13.3337 27.2801 12.8803 27.2801 12.307V9.22699C27.2801 6.73366 25.2534 4.72033 22.7734 4.72033H19.6934C19.1201 4.72033 18.6667 4.25366 18.6667 3.69366C18.6667 3.13366 19.1201 2.66699 19.6934 2.66699H22.7734C26.3867 2.66699 29.3334 5.61366 29.3334 9.22699V12.307C29.3334 12.8803 28.8801 13.3337 28.3067 13.3337Z" fill="#46B49E" />
                                    <path d="M22.7733 29.3329H20.92C20.36 29.3329 19.8933 28.8796 19.8933 28.3062C19.8933 27.7462 20.3466 27.2796 20.92 27.2796H22.7733C25.2666 27.2796 27.28 25.2529 27.28 22.7729V20.9329C27.28 20.3729 27.7333 19.9062 28.3066 19.9062C28.8666 19.9062 29.3333 20.3596 29.3333 20.9329V22.7729C29.3333 26.3862 26.3866 29.3329 22.7733 29.3329Z" fill="#46B49E" />
                                    <path d="M12.3067 29.3337H9.22675C5.61341 29.3337 2.66675 26.387 2.66675 22.7737V19.6937C2.66675 19.1203 3.12008 18.667 3.69341 18.667C4.26675 18.667 4.72008 19.1203 4.72008 19.6937V22.7737C4.72008 25.267 6.74675 27.2803 9.22675 27.2803H12.3067C12.8667 27.2803 13.3334 27.7337 13.3334 28.307C13.3334 28.8803 12.8801 29.3337 12.3067 29.3337Z" fill="#46B49E" />
                                    <path d="M24.6134 14.9736H22.8001H9.20011H7.38677C6.81344 14.9736 6.36011 15.4403 6.36011 16.0003C6.36011 16.5603 6.81344 17.027 7.38677 17.027H9.20011H22.8001H24.6134C25.1868 17.027 25.6401 16.5603 25.6401 16.0003C25.6401 15.4403 25.1868 14.9736 24.6134 14.9736Z" fill="#46B49E" />
                                    <path d="M9.19995 18.5866V19.0266C9.19995 21.24 10.9866 23.0266 13.2 23.0266H18.8C21.0133 23.0266 22.8 21.24 22.8 19.0266V18.5866C22.8 18.4266 22.68 18.3066 22.52 18.3066H9.47995C9.31995 18.3066 9.19995 18.4266 9.19995 18.5866Z" fill="#46B49E" />
                                    <path d="M9.19995 13.4136V12.9736C9.19995 10.7603 10.9866 8.97363 13.2 8.97363H18.8C21.0133 8.97363 22.8 10.7603 22.8 12.9736V13.4136C22.8 13.5736 22.68 13.6936 22.52 13.6936H9.47995C9.31995 13.6936 9.19995 13.5736 9.19995 13.4136Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                إزالة / استبدال المعلومات الشخصية المميزة
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>قم بتجميل كل المعلومات الشخصية في مستنداتك (الاسم، تاريخ الميلاد، العنوان، الرقم الاجتماعي، إلخ).</p>
                            <br /><p className="end_points" dir="auto">{replacePIIEndpoints} {replacePIIEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 نص</p>

                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <h5 className="generate-title marg-30">تعزيز القائمة</h5>
            </div>
            <div className="row card-bottom">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/ar/enhance/listing-booster' className={`card-body generate-card enhance-card-height ${storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Listing Booster') ? " " : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0001 2.66699C8.64008 2.66699 2.66675 8.64033 2.66675 16.0003C2.66675 23.3603 8.64008 29.3337 16.0001 29.3337C23.3601 29.3337 29.3334 23.3603 29.3334 16.0003C29.3334 8.64033 23.3601 2.66699 16.0001 2.66699ZM20.7067 16.0403C20.5067 16.2403 20.2534 16.3337 20.0001 16.3337C19.7467 16.3337 19.4934 16.2403 19.2934 16.0403L17.0001 13.747V20.667C17.0001 21.2137 16.5467 21.667 16.0001 21.667C15.4534 21.667 15.0001 21.2137 15.0001 20.667V13.747L12.7067 16.0403C12.3201 16.427 11.6801 16.427 11.2934 16.0403C10.9067 15.6537 10.9067 15.0137 11.2934 14.627L15.2934 10.627C15.6801 10.2403 16.3201 10.2403 16.7067 10.627L20.7067 14.627C21.0934 15.0137 21.0934 15.6537 20.7067 16.0403Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            {storedPlatforms[1].enhance.includes('all') || storedPlatforms[1].enhance.includes('Listing Booster') ? " تعزيز القائمة" : "تعزيز القائمة (قريبًا)"}

                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>سيقوم تعزيز القائمة بترقية كل جانب من قائمتك، بدءًا من وضع الصور، وجودة الصور، وتحسين وصف العقار، والمزيد لضمان تحقيق التحويل الأمثل.</p>
                            <br /><p className="end_points" dir="auto"> {listingBoosterEndpoints} {listingBoosterEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 قائمة </p>

                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default EnhanceMain