import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { FilterOptions } from "../../Models/FilterOptions";


const SortByProperties: React.FunctionComponent<{ OnSelectChangeSortBy:any }> = (props) => {
    const [filterOptionsValue, setFilterOptionsValue] = useState<FilterOptions>({
        "id": "",
        "brokerage": "",
        "broker": "",
        "bedroomsCount": "",
        "projectId": "",
        "location": "",
        "minPrice": "",
        "maxPrice": "",
        "date": "",
        "minSize": "",
        "maxSize": "",
        "sortBy": ""
    });
    const handleSelectChangeSortBy = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            sortBy: event.target.value,
        }));
       props.OnSelectChangeSortBy(event.target.value)
    }

    return (
        <FormControl fullWidth>
        <Select
            className="custom-select"
            displayEmpty
            inputProps={{ 'aria-label': 'Sort By' }}
            value={filterOptionsValue.sortBy}
            onChange={handleSelectChangeSortBy}>
            <MenuItem value="">
                Sort By
            </MenuItem>
            <MenuItem value={"price"}>Price - Low to High</MenuItem>
            <MenuItem value={"pricehtol"}>Price - High to Low</MenuItem>
            <MenuItem value={"size"}>Size - Small to Large</MenuItem>
            <MenuItem value={"sizehtol"}>Size - Large to Small</MenuItem>
        </Select>
    </FormControl>
    )
}
export default SortByProperties;