import React, { useEffect, useState } from "react";
import { ProfileUser } from "../../Models/ProfileUser";
import { GetAccount, UpdateProfileService } from "../../Services/Profile";


const Profile: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [profileValue, setProfileValue] = useState<ProfileUser>({});

    useEffect(() => {
        GetAccount().then((data: any) => {
            setProfileValue(data);
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })

    }, [])


    const handleChangeName = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            name: event.target.value,
        }));
    }
    const handleChangeEmail = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            email: event.target.value,
        }));
    }

    const handleChangeProfilePicture = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setProfileValue(prevOptions => ({
                ...prevOptions,
                profile_picture: base_64,
            }));
        }
    }

    const handleChangePortraitImage = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setProfileValue(prevOptions => ({
                ...prevOptions,
                portrait_image: base_64,
            }));
        }
    }

    const handleChangeStandingPortrait = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setProfileValue(prevOptions => ({
                ...prevOptions,
                standing_portrait: base_64,
            }));
        }
    }

    const handleChangeFamilyName = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            family_name: event.target.value,
        }));
    }

    const handleChangePhoneNumber = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            phone_number: event.target.value,
        }));
    }

    const UpdateProfile = () => {
        const profile = {
            'id': profileValue?._id,
            'profile_picture': profileValue?.profile_picture,
            'portrait_image': profileValue?.portrait_image,
            'standing_portrait': profileValue?.standing_portrait,
            'name': profileValue?.name,
            'family_name': profileValue?.family_name,
            'email': profileValue?.email,
            'phone_number': profileValue?.phone_number,
        }
        UpdateProfileService(profile).then((data: any) => {
            setIsSuccess(true)
            setShowMessage("تم التحديث بنجاح!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("حدث خطأ أثناء تحديث الملف الشخصي ")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 3000)
    }

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="users-list card-bottom">
                <div className="card  mb-3">
                    <div className="card-body padd-24">
                        <div className="row mb-4">
                            <div className="col-md-12 moderate-title">
                                الملف الشخصي
                            </div>
                        </div>
                       
                        <div className="row col-users">
                            <div className="col-4  mb-3">
                                <label htmlFor="Profile Picture" className="form-label">صورة الملف الشخصي</label><br />
                                <img className="mb-2 profile" src={profileValue?.profile_picture == "" ? "/assets/images/LogoUser.jfif" : profileValue?.profile_picture} /><br />
                                <div >
                                    <input
                                        type="file"
                                        id="input-file"
                                        name="input-file"
                                        accept="image/*"
                                        onChange={handleChangeProfilePicture}
                                        hidden
                                    />
                                    <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button" style={{ textDecoration: "underline", fontSize: "20px" }}>
                                        تغيير
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div className="row col-users">
                            <div className="col-4">
                                <label htmlFor="Portrait Image" className="form-label">صورة شخصية</label>
                                <div className='profile-upload mb-3'>
                                    <div>
                                        <img src={profileValue?.portrait_image == "" ? "/assets/images/LogoUser.jfif" : profileValue?.portrait_image} />
                                        Portrait.PNG</div>
                                    <label htmlFor="Portrait1">
                                        <input type="file" id="Portrait1" style={{ display: 'none' }} accept=" image/*" onChange={handleChangePortraitImage} />
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                            <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                            <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="Standing Potrait" className="form-label ">صورة واقفة </label>
                                <div className='profile-upload mb-3'>
                                    <div>
                                        <img src={profileValue?.standing_portrait == "" ? "/assets/images/LogoUser.jfif" : profileValue?.standing_portrait} />
                                        Standing.PNG
                                    </div>
                                    <label htmlFor="Portrait2">
                                        <input type="file" id="Portrait2" style={{ display: 'none' }} accept=" image/*" onChange={handleChangeStandingPortrait} />
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                            <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                            <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row col-users">
                            <div className="col-4">
                                <label htmlFor="Name" className="form-label ">الاسم</label>
                                <input type="text" id="Name" className="form-control mb-3" placeholder="اسمك" value={profileValue?.name ? profileValue?.name : " "} onChange={handleChangeName} />
                            </div>
                            <div className="col-4">
                                <label htmlFor="Family Name" className="form-label ">الاسم العائلي</label>
                                <input type="text" id="Family Name" className="form-control mb-3" placeholder="الاسم العائلي" value={profileValue?.family_name ? profileValue?.family_name : ""} onChange={handleChangeFamilyName} />
                            </div>
                        </div>
                        <div className="row col-users">
                            <div className="col-4">
                                <label htmlFor="Email Address" className="form-label ">عنوان البريد الإلكتروني</label>
                                <input type="email" id="Email Address" className="form-control mb-3" placeholder="البريد الإلكتروني" value={profileValue?.email ? profileValue?.email : ""} onChange={handleChangeEmail} />
                            </div>
                            <div className="col-4">
                                <label htmlFor="Phone Number" className="form-label ">رقم هاتف</label>
                                <input type="text" id="Phone Number" className="form-control mb-3" placeholder="هاتف" value={profileValue?.phone_number ? profileValue?.phone_number : ""} onChange={handleChangePhoneNumber} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                            <button type="button" className={`btn btn-primary generate marg-top20 `} onClick={UpdateProfile}>حفظ</button>
                            </div>
                            <div className="col-md-6">
                            {isSuccess && <div className="alert alert-success alert-dismissible marg-top20" role="alert">
                            {showMessage}
                        </div>}
                        {isError && <div className="alert alert-danger alert-dismissible marg-top20"  role="alert">
                            {showMessage}
                        </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile
