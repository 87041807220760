import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js';
import { getChartLine } from '../../Services/Chart';
import { LineChart, LineData } from '../../Models/LineChart';

Chart.register(CategoryScale, LinearScale, Title, Tooltip, Legend, Filler);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  tension: 0.3,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};


const LineChartModerate: React.FunctionComponent<{}> = (props) => {

  const [isDataSet, setIsDataSet] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    getChartLine().then((response) => {
      if (response.data.msg = "success") {
        let chartObj = new LineChart(response.data.type, response.data.labels);
        response.data.datasets.map((dataa: any) => {
          let dataObj = new LineData(dataa.label, dataa.data, dataa.backgroundColor, dataa.borderColor, dataa.fill, dataa.radius, dataa.tension, dataa.pointRadius);
          chartObj.pushDataSet(dataObj);
        })
        setIsDataSet(true)
        setIsSpinner(false);
        setIsError(false);
        setData(chartObj);
      }
      else if (response.data.msg = "error") {
        setErrorMessage("error");
        setData("");
      }
    })
      .catch(err => {
        setData("");
        setIsError(true);
        setErrorMessage("Error");
      })
  }, [])

  return (
    <div className="line-chart-container">
      {isSpinner == true && isError == false && <div style={{ marginTop: "50px", textAlign: "center" }}><div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
        <span className="visually-hidden">Loading...</span>
      </div> </div>}
      {isError == true && <h3 style={{ color: 'red', marginTop: "50px", textAlign: "center" }}> {errorMessage}</h3>}
      {isSpinner == false && isDataSet == true && <Line options={options} data={data} />}
    </div>
  );
};

export default LineChartModerate;
