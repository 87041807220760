import { SelectProperty } from "../../../Services/Generate";
import { useEffect, useState } from "react";
import JSONView from 'react-json-view';
import Upgrade from "../../Settings/upgrade";
import { OCRService } from "../../../Services/Understand";
import ViewCurl from "../../view-curl";


const OCR: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [selectImages, setSelectImages] = useState<any>([]);
    const [showImage, setShowImage] = useState<any>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [documentTypeData, setDocumentTypeData] = useState<any>("");
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>([
        {
            "id": 1,
            "src": "https://www.api-v1.coralytics.com/api/images/65958570e6cce9e9935689d1-0.jpeg",
        }
    ]);
    const [curlValue, setCurlValue] = useState<any>("OCR");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);
    const [upload, setUpload] = useState(true);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        })
    }, []);

    const HandleChangeUpload = (event: any) => {
        setSelectImages([]);
        setIsDisabledSelect(!isDisabledSelect)
        setUpload(false)
        for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i + 1,
                    "src": base_64,
                    "ext": ext[1]
                }
                setSelectImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );
            };
        }
    }



    const UnderstandImagecHandler = () => {
        const understandObj = {
            "images": selectImages
        }
        setIsDisabledSelect(false)
        setIsDisabledUpload(false)
        setIsSpinner(true)
        setDocumentTypeData("")
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        const understandList: any = []
        for (let index = 0; index < understandObj.images.length; index++) {
            const element = understandObj.images[index];
            var obj = {
                "id": element.id,
                "src": element.src,
                "ext": element.ext
            };
            understandList.push(obj)
        }
        OCRService(understandList).then((data) => {
            
            setShowImage(data.data[0].image)
            
            setDocumentTypeData(data.data[0].json)
            setIsSpinner(false)
            setShowMessage('Understood Successfully !')
            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("Failed to get data")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };



    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        OCR
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {properties == null ? <>Loading ...</> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-6 mb-3">
                        <div className="card">
                            <div className="card-body padd-24" style={{ height: "700px" }}>
                                <div className="row mt-3">
                                    <div className="container_image" style={{ marginLeft: "10px" }}>
                                        <input
                                            type="file"
                                            id="input-file"
                                            name="input-file"
                                            accept="image/*"
                                            onChange={HandleChangeUpload}
                                            hidden
                                        />
                                        <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                                            <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                                            Upload File
                                        </label>
                                    </div>
                                    {upload ? <></> : <>
                                        {selectImages.map((image: any, index: any) => (
                                            <div key={index} style={{ height: "530px" }} className="mb-2 mt-3">
                                                <img src={image.src} className="image  " style={{ height: "100%", width: "100%" }} key={image.id} />
                                            </div>
                                        ))}
                                    </>}
                                </div>
                                <button type="button" className={`btn btn-primary generate marg-top20 ${selectImages.length == 0 ? 'disabled' : ''}`} onClick={UnderstandImagecHandler}>Understand</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card mb-3">
                            <div className="card-body padd-24" style={{ height: "700px" }}>
                                <div className="generate-h5" style={{ height: "30px" }}>Document  {isSpinner && documentTypeData == "" && <div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>}</div >
                                {showImage && <div style={{ height: "530px" }} className="mb-2 mt-4">
                                    <img src={showImage} className="image  " style={{ height: "100%", width: "100%" }} />
                                </div>}
                            </div>
                        </div>
                        <div className="card ">
                            <div className="card-body padd-24">
                                <div className="generate-h5" style={{ height: "30px" }}>Output
                                    {isSpinner && documentTypeData == "" && <div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}
                                </div>
                                {documentTypeData == "" ? <div className="card card-image-gener">
                                    <p className="para" style={{ padding: "20px" }}>{showMsgUpgrade}</p>
                                </div> :
                                    <div className="card card-image-gener">
                                        <div className="understand-data"><JSONView src={documentTypeData} /></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}
export default OCR;