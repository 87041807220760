import React, { useEffect, useState } from "react";
import Users from "../Users/users";
import CompanyProfile from "./company-profile";
import CreditConsumption from "./credit-consumption";
import Portals from "../../Components/Settings/portals";
import { ServiceHasAcsess } from "../../Services/Account";

const Settings: React.FunctionComponent<{}> = (props) => {

    const [isAccess, setIsAccess] = useState<boolean>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ServiceHasAcsess().then((resp: any) => {
            setIsAccess(resp)
            setLoading(false)
        })
    }, [])

    return loading ? (<div> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y ">
            {isAccess && <>
                <Users />
                <div className="row users-mobile">
                    <div className="card mb-3">
                        <div className="card-body padd-24">
                            <div className="row mb-4">
                                <div className="col-md-12 moderate-title">
                                    Company profile
                                </div>
                            </div>
                            <CompanyProfile />
                        </div>
                    </div>
                </div>
                <div className="row users-mobile">
                    <div className="card mb-3">
                        <div className="card-body padd-24">
                            <div className="row mb-4">
                                <div className="col-md-12 moderate-title">
                                    Portals
                                </div>
                            </div>
                            <Portals />
                        </div>
                    </div>
                </div>
            </>}
            <div className="row card-bottom users-mobile">
                <div className="card mb-3">
                    <div className="card-body padd-24">
                        <div className="row mb-2">
                            <div className="col-md-12 moderate-title">
                                Credit Consumption
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-8 marg-credit">
                                <CreditConsumption />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
