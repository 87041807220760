import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, FormControlLabel, Checkbox, Pagination } from "@mui/material";
import { GetCreditScore, GetUserLogs, UpgradeCredit, UserConsumption } from "../../Services/Account";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Credit from "./display_credit";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import Pricing from "./pricing";

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const CreditConsumption: React.FunctionComponent<{}> = (props) => {


    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [grouped, setGrouped] = useState(false);
    const [credit, setCredit] = useState([]);
    const [platform, setPlatform] = useState("All");
    const [open, setOpen] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [consumption, setConsumption] = useState("");
    const [creditScore, setCreditScore] = useState("");

    useEffect(() => {
        GetUserLogs(platform, grouped, 1).then((data: any) => {
            setPage(Math.ceil(data.total / 8))
            setCredit(data.userLog);
            setIsSpinner(false)
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        GetCreditScore().then((data: any) => {
            setCreditScore(data.creditScore);
            // setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        UserConsumption().then((data: any) => {
            setConsumption(data.totalConsumedScore);
            // setLoading(false)
        }).catch(() => {
            console.log("error")
        })
    }, [change, grouped])

    const handleClickOpen = () => {
        UpgradeCredit().then((data: any) => {
            setOpen(true);
        }).catch(error => {
            console.log("error")
        })
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGrouped(event.target.checked);
    };

    const convertDate = (date: any) => {
        const date_ = date.split("T");
        const dateNew = date_[1].split(":");
        const dateValue = (date_[0].replace(/-/g, '/')) + ", " + dateNew[0] + ":" + dateNew[1]
        return dateValue;
    }
    const handleChangePlatforms = (event: any) => {
        setPlatform(event.target.value);
        setChange(!change)
    }

    /*************Pagination*********** */

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        GetUserLogs(platform, grouped, value).then((data: any) => {
            setPage(Math.ceil(data.total / 8))
            setCredit(data.userLog)
        }).catch(err => {
            console.log("Error");
        })

    };


    return loading ? (<div>... تحميل</div>) : (
        <>
            <div className="card-credit">
                <div className="row padd-20">
                    <div className="col-6 ">
                        <h3 className="number-credit">{creditScore ? <Credit myFloatNumber={parseFloat(creditScore)}></Credit> : "0"}</h3>
                        <p>الرصيد المتاح</p>
                    </div>
                    <div className="col-6">
                        <Pricing idUser={localStorage.getItem("id")} />
                    </div>
                </div>
            </div>

            <div className="row col-users">
                <div className="col-8">
                    <div className="credit-title">
                        تاريخ الإنفاق
                        <p>قمت بإنفاق{consumption ? <Credit myFloatNumber={parseFloat(consumption)}></Credit> : "0"} نقطة ائتمان خلال الـ30 يومًا الماضية. </p>
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme}>
                            <FormControl fullWidth>
                                <Select
                                    className="custom-select marg-credit"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Category' }}
                                    value={platform}
                                    onChange={handleChangePlatforms}
                                >
                                    <MenuItem key={0} value="" disabled>
                                        اختر
                                    </MenuItem>
                                    <MenuItem key={11} value={"AI"}>الذكاء الاصطناعي </MenuItem>
                                    <MenuItem key={1} value={"Moderate"}>عدِّل</MenuItem>
                                    <MenuItem key={2} value={"Delight"}>أسعد</MenuItem>
                                    <MenuItem key={3} value={"Understand"}>فهم</MenuItem>
                                    <MenuItem key={4} value={"Enhance"}>طوِّر</MenuItem>
                                    <MenuItem key={5} value={"Generate"}>أنشئ</MenuItem>
                                    <MenuItem key={6} value={"Monitor"}>راقِب</MenuItem>
                                </Select>
                            </FormControl>
                        </ThemeProvider>
                    </CacheProvider>
                    <FormControlLabel style={{ marginLeft: "5px" }} control={<Checkbox
                        checked={grouped}
                        onChange={handleChange}

                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="المجموعة حسب البطاقات" />
                </div>
            </div>
            <div className="table-responsive text-nowrap display-property">
                <hr className="hr" style={{ marginBottom: "0px", marginTop: "20px" }}></hr>
                <table className="table" style={{ minHeight: "150px" }}>
                    <thead>
                        <tr>
                            <th>الوقت</th>
                            <th>الفئة</th>
                            <th>المبلغ</th>

                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {credit ? credit.map((obj: any, index: any) => {
                            return <tr key={"line_" + index}>
                                <td>
                                    {convertDate(obj.action_datetime)}
                                </td>
                                <td>
                                    {obj.action_name}
                                </td>
                                <td>
                                    <Credit myFloatNumber={parseFloat(obj.score_consumed)}></Credit>

                                </td>

                            </tr>
                        }) : <></>}
                    </tbody>
                </table>
                {isSpinner == true && <div className="d-flex justify-content-center">
                    <div className="col-auto">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                }
                {isSpinner == false && <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />}

                {credit.length == 0 && isSpinner == false && <h3 className="empty" > القائمة فارغة</h3>}
                {/* {credit.length !== 0 && credit.length > 4 && <button type="button" className="btn btn-outline-primary button-rule mt-3 maxwidth-credit" >عرض المزيد</button>} */}
            </div>
            <div className="rule-list">
                <div className="property-mobile" style={{ marginLeft: "11px" }}>
                    {credit && credit.map((obj: any, index: any) => {
                        return <div className="card mb-3" key={index}>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col d-flex align-items-center" >
                                        <h3 className="title" style={{ fontSize: "20px" }}>{obj.score_consumed}</h3>
                                    </div>
                                </div>
                                <div className="row date-create" >
                                    <div className="col mb-3">
                                        <p className="float-end">{obj.action_name}</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="float-start"> {convertDate(obj.action_datetime)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    {isSpinner == true && <div className="d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    }
                    {isSpinner == false && <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />}
                    {credit.length == 0 && isSpinner == false && <h3 className="empty" > القائمة فارغة</h3>}
                    {/* {credit.length !== 0 && <button type="button" className="btn btn-outline-primary button-rule mt-3 mb-3 maxwidth-credit" >عرض المزيد</button>} */}

                </div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ textAlign: "right" }}>{"تأكيد طلب التحديث"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        تم تسجيل طلب الترقية الخاص بك بنجاح. أحد أعضاء فريقنا سيتواصل قريباً.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose}>حسنا</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CreditConsumption
