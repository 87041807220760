import axios from "axios";
import { PropertiesObj } from "../Models/PropertiesObj";

const Url = process.env.REACT_APP_URl;


export const createProject = async (project: any) => {
   const apiUrl=Url+"/projects";
   const response= await axios.post(apiUrl,project,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
   return response.data;
  }

 
export const getProjects = async(type:"type")=>{
    const apiUrl=Url+"/getprojects";
   const response= await axios.post(apiUrl,{"type":type},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
   return response.data;
}