import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { FacebookIcon, TelegramIcon, WhatsappIcon } from "react-share";
import { FacebookShareButton, WhatsappShareButton, TelegramShareButton } from "react-share";
import { FetchDownloadLink } from '../../../Services/GenerateVideo';

const ShareVideo: React.FunctionComponent<{ videoUrl: any, isDisabled: boolean }> = (props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [videoValue, setVideoValue] = useState<any>('');

    const handleClick = () => {
        FetchDownloadLink(props.videoUrl).then(async (resp) => {
            setVideoValue(resp);

        }).catch((error) => {
            console.error('Error Download:', error);

        })
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <button type="button" aria-describedby={id} className={`btn mb-2 button-2 ${props.isDisabled ? "disabled" : ""}`} onClick={handleClick}>
                <img src="/assets/images/icons/SHARE 20.20 PNG.png" width={18} height={18} />
                Share
            </button>
            {videoValue != "" && <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 1 }}>
                    <div className="btn-group social-group social-colors">
                        <FacebookShareButton url={`data:video/mp4;base64,${videoValue}`}>
                            <FacebookIcon round={true} size={40} style={{ marginRight: "10px" }}></FacebookIcon>
                        </FacebookShareButton>
                        <WhatsappShareButton url={`data:video/mp4;base64,${videoValue}`}>
                            <WhatsappIcon round={true} size={40} style={{ marginRight: "10px" }}></WhatsappIcon>
                        </WhatsappShareButton>
                        <TelegramShareButton url={`data:video/mp4;base64,${videoValue}`}>
                            <TelegramIcon round={true} size={40}></TelegramIcon>
                        </TelegramShareButton>
                    </div>
                </Typography>

            </Popover>
            }
        </>
    )
}
export default ShareVideo;