import React, { useEffect, useState } from "react";

const AgentDetails: React.FunctionComponent<{ propertyValue: any, agentListingsCount: any }> = (props) => {

    return props.propertyValue.content ? (
        <div className="card padd-20">
            <div className="card-body">
                <div className="d-flex desc-agent">
                    <div className="d-flex align-items-start justify-content-between" >
                        <img src={props.propertyValue.content.company && props.propertyValue.content.company.logo ? props.propertyValue.content.company.logo.src : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNkSceN7Yy0h33pjiGTEK34jilQGsBGwOMr798npqgg&s"} className="image-agent" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.4rem" }}>
                        <div>  <h5>
                            {props.propertyValue.content.company && props.propertyValue.content.company.name ? props.propertyValue.content.company.name : ""}
                        </h5>
                            <p>{props.agentListingsCount ? props.agentListingsCount : 0} القوائم </p>
                            {/* <a>عرض الملف</a> */}
                        </div>
                    </div>
                </div>
                <hr className="hr"></hr>
                <div className=" d-flex agent">
                    <div className="d-flex align-items-start justify-content-between img-agent " >
                        <img src={props.propertyValue.content.agent ? props.propertyValue.content.agent.photo.src : "https://cdn-icons-png.freepik.com/256/3135/3135715.png?semt=ais_hybrid"} />
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.8rem" }} >
                        <div>   <h5>
                            {props.propertyValue.content.agent && props.propertyValue.content.agent.name ? props.propertyValue.content.agent.name : ""}
                        </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) :<></>
}
export default AgentDetails;