import { useEffect, useState } from "react";
import { FloorPlanService, SelectProperty } from "../../../Services/Generate";
import DownloadImage from "../../download-image";
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";
import SelectFloorPlan from "./select-floor-plan";
import Fullscreen from "../../../Components/Generate/Fullscreen";
import { EnhanceImageResolution2D } from "../../../Services/Enhance";


const FloorPlan3D: React.FunctionComponent<{}> = (props) => {
 

    const [properties, setProperties] = useState<any>();
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSelectImage, setIsSelectImage] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [enhancedImages, setEnhancedImages] = useState<any>('');
    const [checkedId, setCheckedId] = useState<any>(null);
    const [enhancedImage, setEnhancedImage] = useState("");
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"id":"666c414616b3494ca0cc144a","images":[{"id":2,"src":"https://www.api-v1.coralytics.com/api/images/666c414616b3494ca0cc144a-2_1.jpeg"}]});
    const [curlValue, setCurlValue] = useState<any>("2dto3d");
    const [isDisabledReplace, setIsDisabledReplace] = useState<boolean>(false);
    const [isDisabledApi, setIsDisabledApi] = useState<boolean>(true);
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [imgId, setImgId] = useState<any>("");
    const [isSpinnerDown, setIsSpinnerDown] = useState<boolean>(false);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });
    }, [])

    const handleSelectImages = (event: any) => {
        setIsSelectImage(!isSelectImage)
        const image: any = enhancedImages.find((element: any) => Number.parseInt(element.id) == event.target.value)
        if (image) {
            setEnhancedImage(image.src);
            setImgId(image.id)
        } else {
            console.log("Image not found")
        }
    }



    const handleCheckboxChange = (id: any) => {
        setCheckedId(id === checkedId ? null : id);
    };



    const GeneratedImagecHandler = (enhanceObj: any) => {
        setSelectProperty(enhanceObj.id)
        setIsSpinner(true)
        setIsUpgrade(false)
        setIsDisabledReplace(false)
        setIsDisabledApi(true)
        setEnhancedImage("")
        setShowMsgUpgrade("")
        setEnhancedImages([]);
        setSelectImages(enhanceObj.images)
       
        const Obj = {
            "id": enhanceObj.id,
            "images": enhanceObj.images
        }
        FloorPlanService(Obj).then((data) => {
            setIsDisabledReplace(true)
            
            
            setEnhancedImages(data.data)
            for (let index = 0; index < data.data.length; index++) {
                const element = data.data[index];
                if (Obj.images[0].id == element.id) {
                    setCheckedId(Obj.images[0].id)
                    setEnhancedImage(element.src)
                    setImgId(element.id)
                    break;
                }
            }
            setIsDisabledApi(false)
            setIsSpinner(false)
            setShowMessage('تم الإنشاء بنجاح!')
            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("خطأ في إنشاء الصورة!")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };
    
    const handleDownloadEnhance = () => {
        setIsSpinnerDown(true)
        const Obj = {
            "id": "",
            "images": [{
                "id": 1,
                "src": enhancedImage,
                "ext": "png"
            }]
        }
        EnhanceImageResolution2D(Obj).then(async (resp) => {
            try {
                const response = await fetch(resp.data[0].src);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `image-enhanced.${resp.data[0].src.split('.').pop().split(/\#|\?/)[0]}`;
                a.click();
                setIsSpinnerDown(false)
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        })

    }


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        إنشاء مخطط أرضي ثلاثي الأبعاد
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {properties == null ? <>تحميل ...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <SelectFloorPlan properties={properties} onGeneratedImage={GeneratedImagecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                <h5 className="generate-h5">الصورة المحددة</h5>
                                {selectImages.length > 1 ? <div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <input type="checkbox" id={image.id} value={image.id} checked={checkedId === image.id} onChange={(event) => { handleSelectImages(event); handleCheckboxChange(image.id); }} />
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                </div>
                                    : <div className="selected-images" >
                                        {selectImages.map((image: any, index: any) => (
                                            <div key={image.id}>
                                                <label htmlFor={image.id} className="label1">
                                                    <img src={image.src} className="image" key={image.id} /></label>
                                            </div>
                                        ))}
                                    </div>}
                                <div className="generate-h5" style={{ height: "30px" }}>الصورة المنشأة
                                    {isSpinner && enhancedImage == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}

                                </div>
                                <div className="card card-image-gener" >
                                    {enhancedImage == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> :
                                        <div className="card"><img src={`${enhancedImage}?${new Date().getTime()}`} alt="Fullscreen Image" id='fullscreen-image' />
                                            <Fullscreen /></div>}
                                </div>
                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                <button type="button" className={`btn mb-2 button-1 ${isDisabledApi ? "disabled" : ""}`} onClick={handleDownloadEnhance}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H12.5003C17.0253 1.04175 18.9587 2.97508 18.9587 7.50008V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29175C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V7.50008C17.7087 3.65841 16.342 2.29175 12.5003 2.29175H7.50033Z" fill="#716BE3" />
                                            <path d="M9.99987 12.7167C9.84154 12.7167 9.6832 12.6583 9.5582 12.5333L7.0582 10.0333C6.81654 9.79166 6.81654 9.39167 7.0582 9.15C7.29987 8.90833 7.69987 8.90833 7.94153 9.15L9.99987 11.2083L12.0582 9.15C12.2999 8.90833 12.6999 8.90833 12.9415 9.15C13.1832 9.39167 13.1832 9.79166 12.9415 10.0333L10.4415 12.5333C10.3165 12.6583 10.1582 12.7167 9.99987 12.7167Z" fill="#716BE3" />
                                            <path d="M10 12.7167C9.65833 12.7167 9.375 12.4334 9.375 12.0917V5.42505C9.375 5.08338 9.65833 4.80005 10 4.80005C10.3417 4.80005 10.625 5.08338 10.625 5.42505V12.0917C10.625 12.4417 10.3417 12.7167 10 12.7167Z" fill="#716BE3" />
                                            <path d="M9.99971 15.1916C8.24138 15.1916 6.47471 14.9083 4.79971 14.35C4.47471 14.2416 4.29971 13.8833 4.40804 13.5583C4.51638 13.2333 4.86637 13.05 5.19971 13.1666C8.29971 14.2 11.708 14.2 14.808 13.1666C15.133 13.0583 15.4914 13.2333 15.5997 13.5583C15.708 13.8833 15.533 14.2416 15.208 14.35C13.5247 14.9166 11.758 15.1916 9.99971 15.1916Z" fill="#716BE3" />
                                        </svg>
                                        راقي و تحميل
                                        {isSpinnerDown && <div className="spinner-border text-primary" role="status" style={{ width: "20px", height: "20px", textAlign: "center" }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                    </button>
                                    <DownloadImage image={enhancedImage} isDisabledReplace={isDisabledReplace} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default FloorPlan3D;