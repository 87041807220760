import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getProjects } from "../../Services/Projects";
import { dropdown_option, getFilterProperties } from "../../Services/Properties";
import { PropertiesFiltertoModerate, getItems, getRules } from "../../Services/Moderate";
import { Link, useLocation } from "react-router-dom";

const ModerateMain: React.FunctionComponent<{ change: boolean }> = (props) => {

  const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));

  const [dropdownOption, setDropdownOption] = useState<any>("");
  const [projects, setProjects] = useState([]);
  const [rules, setRules] = useState<any>('');
  const [listing, setListing] = useState<any>();
  const pathname = useLocation().pathname.split('/')
  const [filterOptionsValue, setFilterOptionsValue] = useState<any>({
    "id": "",
    "brokerage": "",
    "broker": "",
    "bedroomsCount": "",
    "projectId": "",
    "location": "",
    "minPrice": "",
    "maxPrice": "",
    "date": "",
    "minSize": "",
    "maxSize": "",
    "sortBy": ""
  });

  useEffect(() => {
    const type: any = "property"
    getProjects(type).then((data) => {
      setProjects(data)
    }).catch(() => {
      console.log("error")
    })
    getRules().then((data) => {
      const rules_list: any = data
      setRules(data)
    }).catch(() => {
      console.log("error")
    })

    dropdown_option().then((data) => {
      setDropdownOption(data)

    }).catch(() => {
      console.log("error")
    })

    getFilterProperties(filterOptionsValue, 1).then((data) => {
      setListing(data.total)
    });

  }, [filterOptionsValue, props.change])
  /**************filters********************** */

  const handleSelectChangeProject = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      projectId: event.target.value,
    }));
  };

  const handleSelectChangePortal = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      id: event.target.value,
    }));
  }
  const handleSelectChangeBroker = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      broker: event.target.value,
    }));
  }

  const handleSelectChangeBrokerage = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      brokerage: event.target.value,
    }));
  }
  const handleSelectChangeBedrooms = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      bedroomsCount: event.target.value,
    }));
  }
  const handleSelectChangeLocation = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      location: event.target.value,
    }));
  }
  const handleSelectChangeMinPrice = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      minPrice: event.target.value,
    }));
  }
  const handleSelectChangeMaxPrice = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      maxPrice: event.target.value,
    }));
  }
  const handleSelectChangeMinSize = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      minSize: event.target.value,
    }));
  }
  const handleSelectChangeMaxSize = (event: any) => {
    setFilterOptionsValue((prevOptions: any) => ({
      ...prevOptions,
      maxSize: event.target.value,
    }));
  }

  const StartModeration = () => {
    PropertiesFiltertoModerate(filterOptionsValue).then((data) => {
      console.log(data)
    }).catch(() => {
      console.log("error")
    })
  }
  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <ul className="nav nav-tabs flex-wrap" role="tablist">
            <li className={`nav-item ${storedPlatforms.some((platform: any) => platform.moderate && (platform?.moderate?.includes('all'))) ? "" : "disabled"} `}>
              <Link to="/moderate/overview" className={[pathname[2] == "overview" ? "active" : "", "nav-link"].join(" ")}
                role="tab">
                Overview
              </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform: any) => platform.moderate && (platform?.moderate?.includes('all'))) ? "" : "disabled"} `}>
              <Link to="/moderate/items" className={[pathname[2] == "items" ? "active" : "", "nav-link"].join(" ")}
                role="tab">
                Items
              </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform: any) => platform.moderate && (platform?.moderate?.includes('all'))) ? "" : "disabled"} `}>
              <Link to="/moderate/rules" className={[pathname[2] == "rules" ? "active" : "", "nav-link"].join(" ")}
                role="tab">
                Rules
              </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform: any) => platform.moderate && (platform?.moderate?.includes('all') || platform?.moderate?.includes('manual'))) ? "" : "disabled"} `}>
              <Link to="/moderate/manual" className={[pathname[2] == "manual" ? "active" : "", "nav-link"].join(" ")}
                role="tab">
                Manual
              </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform: any) => platform.moderate && (platform?.moderate?.includes('all'))) ? "" : "disabled"} `}>
              <Link to="/moderate/duplicate" className={[pathname[2] == "duplicate" ? "active" : "", "nav-link"].join(" ")}
                role="tab">
                Duplicate
              </Link>
            </li>

          </ul>
        </div>
        <div className="col-md-2  mb-2">
          <button type="button" className="btn btn-outline-primary button-rule start-modera" data-bs-toggle="modal" data-bs-target="#moderateModal">Start Moderation</button>
        </div>
      </div>

      <div className="modal fade" id="moderateModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">Filters</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-2">
                <div className="col-md-6">
                  <label htmlFor="portalSelect" className="form-label">Portal</label>
                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.id}
                      onChange={handleSelectChangePortal}>
                      <MenuItem value="">
                        Select
                      </MenuItem>
                      {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.id.map((portal: any, index: any) => (
                        <MenuItem key={portal} value={portal}>
                          {portal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <label htmlFor="brokerSelect" className="form-label ">Broker</label>
                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.broker}
                      onChange={handleSelectChangeBroker}>
                      <MenuItem value="">
                        Select
                      </MenuItem>
                      {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.broker.map((broker: any, index: any) => (
                        <MenuItem key={broker + "_" + index} value={broker}>
                          {broker}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <label htmlFor="projectSelect" className="form-label ">Project</label>

                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.projectId}
                      onChange={handleSelectChangeProject}>
                      <MenuItem value="">
                        Select
                      </MenuItem>

                      {projects.map((project: any) => (
                        <MenuItem key={project._id} value={project._id}>
                          {project.projectName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
                <div className="col-md-6">

                  <label htmlFor="BrokerageSelect" className="form-label ">Brokerage</label>
                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.brokerage}
                      onChange={handleSelectChangeBrokerage}>
                      <MenuItem value="">
                        Select
                      </MenuItem>

                      {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.company.map((brokerage: any, index: any) => (
                        <MenuItem key={brokerage + "_" + index} value={brokerage}>
                          {brokerage}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <label htmlFor="BedroomsSelect" className="form-label ">Bedrooms</label>
                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.bedroomsCount}
                      onChange={handleSelectChangeBedrooms}>
                      <MenuItem value="">
                        Select
                      </MenuItem>

                      {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.bedroomsCount.map((bedroom: any) => (
                        <MenuItem key={bedroom} value={bedroom}>
                          {bedroom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <label htmlFor="LocationSelect" className="form-label ">Location</label>
                  <FormControl fullWidth>
                    <Select
                      className="custom-select mb-3"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select' }}
                      value={filterOptionsValue.location}
                      onChange={handleSelectChangeLocation}>
                      <MenuItem value="">
                        Select
                      </MenuItem>
                      {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.location.map((location: any, index: any) => (
                        <MenuItem key={location + "_" + index} value={location}>
                          {location}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-3">
                <div className="col-6">
                  <label htmlFor="Minimum Price" className="form-label ">Minimum Price</label>
                  <input type="text" id="Minimum Price" onChange={handleSelectChangeMinPrice} className="form-control mb-3" placeholder="AED 0" />

                  <label htmlFor="Minimum Size" className="form-label ">Minimum Size</label>
                  <input type="text" id="Minimum Size" onChange={handleSelectChangeMinSize} className="form-control " placeholder="0 Sqft" />
                </div>
                <div className="col-6">
                  <label htmlFor="Minimum Price" className="form-label ">Maximum Price</label>
                  <input type="text" id="Maximum Price" onChange={handleSelectChangeMaxPrice} className="form-control mb-3" placeholder="AED 0" />
                  <label htmlFor="Maximum Size" className="form-label ">Maximum Size</label>
                  <input type="text" id="Maximum Size" className="form-control" onChange={handleSelectChangeMaxSize} placeholder="0 Sqft" />
                </div>
              </div>
              <div className="row g-2">
                <label htmlFor="RuleSelect" className="form-label ">Rule</label>
                <div className="version-list mb-3" style={{ marginLeft: "3px", paddingBottom: "10px ", color: "#282828" }}>
                  {rules.length == 0 ? (<div> </div>) : rules.map((rule: any, index: any) => (
                    rule.statu === true ? <p style={{ marginRight: "10px" }} key={index}>
                      {rule.rulename.charAt(0).toUpperCase() + rule.rulename.slice(1)}
                    </p> : null
                  ))}
                </div>
              </div>
              <div className="row g-2">
                <div className="col-md-12">
                  <h5 className="modal-title" id="exampleModalLabel1">{listing}</h5>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <button type="button" onClick={StartModeration} data-bs-dismiss="modal" className="btn save-modal" style={{ width: "100%" }}>Start Moderation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ModerateMain