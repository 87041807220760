import { useRef, useState } from "react";

const Url = process.env.REACT_APP_URl;

const ViewCurl: React.FunctionComponent<{headersValue:any , inputData :any, curl :any}> = (props) => {

    const [curlValue, setCurlValue] = useState<any>("");
    const [isCoppied, setIsCoppied] = useState<boolean>(true);


    const headersToCurl = () => {
        setIsCoppied(true)
        if (props.headersValue) {
            const curlCommand = `curl -X POST `;
            const headers = Object.entries(props.headersValue).map(([key, value]) => {
                return `-H "${key}:${value}" \\\n `;
            }).join('');
            const objCurl: any = {
                "curlCommand": curlCommand,
                "headers": headers
            }
            setCurlValue(objCurl)
        }
    }
    
    const divRef = useRef<HTMLDivElement>(null);

    const handleCopy = () => {
        const div = divRef.current;

        if (div) {
            const textToCopy = div.innerText;

            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;

            // Append the text area to the document
            document.body.appendChild(tempTextArea);

            // Select the content
            tempTextArea.select();

            try {
                // Execute the copy command
                document.execCommand('copy');
                setIsCoppied(false)
                console.log('Content copied successfully!');
            } catch (err) {
                console.log('Unable to copy content');
            } finally {
                // Remove the temporary text area from the document
                document.body.removeChild(tempTextArea);
            }
        }
    };

    return (
        <>
            <button type="button" onClick={headersToCurl} data-bs-toggle="modal" data-bs-target="#api"
             className={`btn mb-2 api-button `} style={{ float: "left" }}>
                API
            </button>
            <div className="modal fade" id="api" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-curl">

                                {curlValue && <pre>
                                    <div className="mb-3">
                                        <a id="button-addnew" onClick={handleCopy} style={{ float: "right", color: "white", border: " 1px solid #000000" }}>
                                   
                                        {isCoppied ? <> نسخ</> : <>! تم النسخ </> }
                                        <i className="fa-solid fa-copy" style={{marginLeft:"5px"}}></i> 
                                        </a>
                                    </div>
                                    <code ref={divRef}>
                                    <span style={{ color: 'blue' }}>{curlValue.curlCommand}</span>{`${Url}/${props.curl} \\\n`}
                                        {curlValue.headers.split(' \\\n').map((header: any, index: any) => {
                                            const firstColonIndex = header.indexOf(':');
                                            if (firstColonIndex !== -1) {
                                                const firstPart = header.slice(0, firstColonIndex);
                                                const rest = header.slice(firstColonIndex + 1).trim();
                                                return (
                                                    <div key={index}>
                                                        <span style={{ color: 'blue' }}>{firstPart}</span>: {rest} {'\\'}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}

                                        <span style={{ color: 'blue' }}>-H 'Authorization</span>: Bearer {localStorage.getItem("usertoken") + "'"} {'\\'} <br />

                                        <span style={{ color: 'blue' }}>-d</span> '{JSON.stringify(props.inputData)}' 
                                    </code>
                                </pre>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewCurl;