import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const addUser = async (user: any) => {
  const apiUrl = Url + "/users";
  const response = await axios.post(apiUrl, user,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  const properties: any = response.data
  return properties;
}
  
export const getusers = async () => {
    const apiUrl = Url + "/allusers";
    const id =localStorage.getItem('id')
    const response = await axios.get(`${apiUrl}/${id}`,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    const properties: any = response.data
    return properties;
}

export const ChangePasswordService= async (formData: any) => {
  const apiUrl = Url + "/change_password";
  const response = await axios.put(apiUrl,formData,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}


export const DropUser = async (id: any) => {
  const apiUrl = Url + "/users";
  const response = await axios.delete(`${apiUrl}/${id}`,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  })
  return response.data;
}


export const UpdateUser = (user :any) => {
  const apiUrl=Url+"/users";
   return axios.put(`${apiUrl}/${user._id}`,user,{
    headers: {
      "Authorization": "Bearer "+localStorage.getItem("usertoken")
    }
  }).then((response) => {
   return response.data;
   })
 
}
export const acceptInvit = async (user: any,id: any) => {
    const apiUrl = Url + "/users";
    const response = await axios.put(`${apiUrl}/${id}`,user,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    const properties: any = response.data
    return properties;
  }

  