import React, { useState } from "react"
import UploadAudio from "./upload-audio";
import { FeedbackAnalysisService } from "../../../Services/Understand";
import JSONView from 'react-json-view';
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";

const FeedbackAnalysis: React.FunctionComponent<{}> = (props) => {
    const [loading, setLoading] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [audioData, setAudioData] = useState<any>("");
    const [selectAudios, setSelectAudios] = useState<any>([]);
    const [headersValue, setHeadersValue] = useState<any>({ "content-type": "application/json" });
    const [inputData, setInputData] = useState<any>({ "audio": "https://www.chosic.com/wp-content/uploads/2021/02/6-Happy-Commercial-Piano(chosic.com).mp3" });
    const [curlValue, setCurlValue] = useState<any>("feedback_analysis");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");

    const AudioHandler = (formData: any, understandObj: any) => {
        setSelectAudios([])
        setAudioData("")
        for (let i = 0; i < understandObj.audio.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(understandObj.audio[i]);
            reader.onload = (event) => {

                setSelectAudios((prevSelectedImages: any) =>
                    [...prevSelectedImages, event?.target?.result]
                );
            };

        }
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        setIsSpinner(true)
        FeedbackAnalysisService(formData).then((data) => {
            setAudioData(data.data)
           
            setIsSpinner(false)
            setShowMessage('Understood Successfully !')
            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("Failed to get data")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        Feedback Analysis
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row card-bottom">
                {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                    {showMessage}
                </div>}
                {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                    {showMessage}
                </div>}
                <div className="col-md-5 mb-3">

                    <UploadAudio onSendAudio={AudioHandler} />
                </div>

                <div className="col-md-7 mb-3">
                    <div className="card ">
                        <div className="card-body padd-24">
                            <h5 className="generate-h5">Selected Audio</h5>
                            <div className="selected-audios" >
                                {selectAudios && selectAudios.map((audio: any, index: any) => (
                                    <div key={index}>
                                        <input type="checkbox" id={index} value={index} />
                                        <label htmlFor={index} className="">
                                            <audio controls>
                                                <source src={audio} type="audio/mp3" key={index} />
                                            </audio>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="generate-h5" style={{ height: "30px" }}>Output
                                {isSpinner && audioData == "" && <div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                                <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                {isUpgrade ? <Upgrade /> : <></>}
                            </div>
                            {audioData == "" ? <div className="card card-image-gener">
                                <p className="para" style={{ padding: "20px", textAlign: 'center' }}>{showMsgUpgrade}</p>

                            </div> :
                                <div className="card card-image-gener">
                                    <div className="understand-data"><JSONView src={audioData} /></div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default FeedbackAnalysis;