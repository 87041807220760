import React, { useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useSpring, useSprings, animated, config } from '@react-spring/web';
import DownloadVideo from "./download-video";
import ShareVideo from "./share-video";
import RecentVideos from "./recent-videos";
import Upgrade from "../../Settings/upgrade";
import { GenerateAgentVideo, UploadVideoService } from "../../../Services/GenerateVideo";
import { GetAccount } from "../../../Services/Profile";
import { ProfileUser } from "../../../Models/ProfileUser";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import { useLocation } from "react-router-dom";
import ViewCurl from "../../view-curl";

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Url = process.env.REACT_APP_URl;

const AgentProfileNew: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    //const [videoUrl, setVideoUrl] = useState("");
    const [selectTemplate, setSelectTemplate] = useState<any>("");
    const [selectContact, setSelectContact] = useState<any>("");
    const [change, setChange] = useState(false);
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({
        "inputs": {
            "title_text": " Admin",
            "sub_text": "With a passion for real estate and a commitment to exceptional service, [Name] have helped numerous clients find their dream homes.",
            "review": "“BEST REALTOR”",
            "signature": "John D.",
            "location": "Dubai Marina, Dubai",
            "image_url": "https://www.api-v1.coralytics.com/api/images/profile-2.jpg"
        },
        "first_video_url": "https://www.api-v1.coralytics.com/api/images/templates-template_1.mp4",
        "audio_url": "https://www.chosic.com/wp-content/uploads/2021/02/6-Happy-Commercial-Piano(chosic.com).mp3",
        "template_type": "Feed",
        "company_name": "coralytics"
    });
    const [curlValue, setCurlValue] = useState<any>("generate_video_v2_new");
    const [isDisabledApi, setIsDisabledApi] = useState<boolean>(true);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [agentValue, setAgentValue] = useState<ProfileUser>({ "name": "اسم", "standing_portrait": "/assets/images/LogoUser.jfif" });
    const [agentDescription, setAgentDescription] = useState<any>("بفضل شغفها بالعقارات والتزامها بتقديم خدمة استثنائية، ساعدت [اسم] العديد من العملاء في العثور على منازل أحلامهم.");
    const [text1Value, setText1Value] = useState<any>("“أفضل سمسار عقارات”");
    const [text2Value, setText2Value] = useState<any>("جون د.");
    const [location, setLocation] = useState<any>("دبي مارينا، دبي");
    const [nameAgency, setNameAgency] = useState<any>();
    const videoRef = useRef<HTMLDivElement>(null);
    const [videoUrl, setVideoUrl] = useState("");
    const locationValue =useLocation().pathname.split("/");

    const elements = [

        { className: "v13_7" },
        { className: "v13_10", text: agentDescription },
        { className: "v13_11", text: text1Value },
        { className: "v15_13", text: agentValue?.name },
        { className: "v13_12", text: location },
        { className: "v15_14", text: text2Value }
    ];

    const [springs, api] = useSprings(elements.length, index => ({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: async next => {
            while (1) {
                await next({ opacity: 1, transform: 'translateY(0)' });
                await next({ opacity: 1, transform: 'translateY(0)', delay: 2000 });
                await next({ opacity: 0, transform: 'translateY(-20px)' });
            }
        },
        config: config.molasses,
        delay: index * 500 // Delay each animation by 500ms more than the previous one
    }));

    useEffect(() => {
        GetAccount().then((data: any) => {
            setAgentValue(data);
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
    }, [])

    useEffect(() => {
        api.start();
    }, [api]);

    const handleChangeContactTemplate = (event: any) => {
        setSelectContact(event.target.value)
    }
    const handeleChangeTemplate = async (event: any) => {
        setSelectTemplate(event.target.value)
    };
    const handleChangeText1 = (event: any) => {
        setText1Value(event.target.value)
    }
    const base64ToBlob = (base64: any, mimeType: any) => {
        const byteChars = atob(base64);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    };
    const handleChangeText2 = (event: any) => {
        setText2Value(event.target.value)
    }
    const handleChangeLocation = (event: any) => {
        setLocation(event.target.value)
    }
    const handleChangeNameAgency = (event: any) => {
        setNameAgency(event.target.value)
    }
    const handleChangeDescAgent = (event: any) => {
        setAgentDescription(event.target.value)
    }
    const handleChangePhotoAgent = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;
            setAgentValue(prevOptions => ({
                ...prevOptions,
                standing_portrait: base_64,
            }));
        }
    }
    const handleChangeNameAgent = (event: any) => {
        setAgentValue(prevOptions => ({
            ...prevOptions,
            name: event.target.value,
        }));
    }
    const generate = async () => {
        setIsSpinner(true)
        setVideoUrl("")
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        const obj = {
            "inputs": {
                "title_text": agentValue?.name,
                "sub_text": agentDescription,
                "review": text1Value,
                "signature": text2Value,
                "location": location,
                "image_url": agentValue?.standing_portrait
            },
            "first_video_url": "https://www.api-v1.coralytics.com/api/images/templates-template_1.mp4",
            "audio_url": "https://www.chosic.com/wp-content/uploads/2021/02/6-Happy-Commercial-Piano(chosic.com).mp3",
            "template_type": "Post",
            "company_name": nameAgency
        }
        GenerateAgentVideo(obj).then((response) => {
            if (response.status === 200) {
                const data = response.data;
                setCurlValue(Url + "/generate_video_v2_new")
                setInputData(JSON.stringify(obj));
                setChange(true)
                setIsDisabledApi(false)
                const blob = base64ToBlob(data, 'video/mp4');
                // Create Object URL from Blob
                const url = URL.createObjectURL(blob);
                // Set the Object URL to state
                console.log("url", data)
                setVideoUrl(url);
                setIsSpinner(false)
            } if (response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade("لديك ائتمان غير كاف. يرجى النقر فوق «الترقية» لطلب المزيد.")
                setIsUpgrade(true)
            }
        }).catch((error: any) => {
            if (error.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(error.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("Error")
                setIsError(true)
            }
        })

    };

    const divRef = useRef(null);
    const handleCopyApi = async () => {
        const div: any = divRef.current;
        if (div) {
            try {
                await navigator.clipboard.writeText(div.innerText);
                console.log("Content copied successfully!");
            } catch (err) {
                console.log("Unable to copy content");
            }
        }
    };



    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row desc-property">
                <div className="card ">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <div className="title-card">
                                {locationValue[3]=="agent-profile-video-reel" && " فيديو ملف الوكيل (Reel)" }
                                {locationValue[3]=="agent-profile-video-post" && " فيديو ملف الوكيل (Post)" }
                                </div>
                            </div>
                            <div className="col-md-5 mb-3 padd-24 search-generate">
                                {/* <SearchProperty /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <RecentVideos type="agent" Change={change} />
            </div>
            {properties == null ? <></> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body padd-24">
                                <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختر النموذج</label>
                                <CacheProvider value={cacheRtl}>
                                    <ThemeProvider theme={theme}>
                                        <FormControl fullWidth>
                                            <Select
                                                className="custom-select"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select' }}
                                                value={selectTemplate}
                                                onChange={handeleChangeTemplate}>
                                                <MenuItem value="" disabled>
                                                    اختر
                                                </MenuItem>
                                                <MenuItem value={"template_agent1"}>عرض الوكيل</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </ThemeProvider>
                                </CacheProvider>
                                <label htmlFor="Portrait Image" className="form-label mt-3">اختر نموذج جهة الاتصال </label>
                                <CacheProvider value={cacheRtl}>
                                    <ThemeProvider theme={theme}>
                                        <FormControl fullWidth>
                                            <Select
                                                className="custom-select"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select' }}
                                                value={selectContact}
                                                onChange={handleChangeContactTemplate}>
                                                <MenuItem value="" disabled>
                                                    اختر
                                                </MenuItem>
                                                <MenuItem value={"template-post"}>نموذج 1</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </ThemeProvider>
                                </CacheProvider>
                                {selectContact == "template-post" && <>
                                    <label htmlFor="Standing Potrait" className="form-label mt-3">صورة واقفة</label>
                                    <div>
                                        <div className='profile-upload  '>
                                            <div>
                                                <img src={agentValue?.standing_portrait == "" ? "/assets/images/LogoUser.jfif" : agentValue?.standing_portrait} style={{ display: "inline" }} />
                                                Photo.PNG</div>
                                            <label htmlFor="Portrait2">
                                                <input type="file" id="Portrait2" style={{ display: 'none' }} accept=" image/*" onChange={handleChangePhotoAgent} />
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                                    <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                                    <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <label htmlFor="Name Agent" className="form-label mt-3">اسم الوكيل</label>
                                    <input type="text" className="form-control " placeholder="Name Agent" style={{ height: "40px" }} value={agentValue?.name ? agentValue?.name : " "} onChange={handleChangeNameAgent} />

                                    <label htmlFor="Description" className="form-label mt-3">وصف</label>
                                    <input type="text" className="form-control " placeholder="وصف" style={{ height: "40px" }} value={agentDescription ? agentDescription : ""} onChange={handleChangeDescAgent} />

                                    <label htmlFor="Text 1" className="form-label mt-3">النص 1</label>
                                    <input type="text" className="form-control " placeholder="النص 1" style={{ height: "40px" }} value={text1Value ? text1Value : ""} onChange={handleChangeText1} />

                                    <label htmlFor="Text 2" className="form-label mt-3">النص 2</label>
                                    <input type="text" className="form-control " placeholder="النص 2" style={{ height: "40px" }} value={text2Value ? text2Value : ""} onChange={handleChangeText2} />

                                    <label htmlFor="Location" className="form-label mt-3">موقع</label>
                                    <input type="text" className="form-control " placeholder="موقع" style={{ height: "40px" }} value={location ? location : ""} onChange={handleChangeLocation} />

                                    <label htmlFor="Name Agency" className="form-label mt-3">اسم الوكالة</label>
                                    <input type="text" className="form-control " placeholder="اسم الوكالة" style={{ height: "40px" }} value={nameAgency ? nameAgency : ""} onChange={handleChangeNameAgency} />
                                </>}
                                <button type="button" className={`btn btn-primary generate marg-top20 ${(agentValue?.standing_portrait && agentValue?.name && text1Value && text2Value && location && nameAgency && agentDescription) ? "" : "disabled"}`} onClick={generate}>أنشئ</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">
                                <div className="row mb-3">
                                    <h5 className="generate-h5">النموذج </h5>
                                     {(selectContact == "template-post") ? 
                                        <div className="card-image-template" >
                                            <div className="v13_8" ref={videoRef}>
                                                <animated.div key={"stable"} className="v13_9" ></animated.div>
                                                {springs.map((styles, index) => {
                                                    const element = elements[index];
                                                    return element.text ? (
                                                        <animated.span key={index} className={element.className} style={styles}>
                                                            {element.text}
                                                        </animated.span>
                                                    ) : (
                                                        <animated.img key={index} className="v13_7" src={agentValue?.standing_portrait == "" ? "/assets/images/LogoUser.jfif" : agentValue?.standing_portrait} style={styles}></animated.img>
                                                    );
                                                })}
                                            </div>

                                        </div>
                                       : <div className="card-image-gener">

                                        </div>}
                                </div>
                                <div className="row" style={{ marginTop: "16px" }}>
                                    <div className="generate-h5" style={{ height: "30px" }}>الفيديو المُنشئ
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                        {isUpgrade ? <Upgrade /> : <></>}
                                    </div>
                                    {(isSpinner && videoUrl == "") ?
                                        <div className="card card-image-gener">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="spinner-border text-primary" role="status" style={{ marginRight: '16px', textAlign: 'center' }}>
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                <span className="para" style={{ padding: '20px' }}> </span>
                                            </div>
                                        </div> : <div className="card card-image-gener">
                                            {videoUrl == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> : (
                                                <video controls>
                                                    <source src={videoUrl} type='video/mp4' />
                                                </video>
                                            )}</div>}
                                </div>

                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <DownloadVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                    <ShareVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            
        </div>
    )
}
export default AgentProfileNew;