import React, { useState } from "react"
import { getPropertieTitelDesc, } from "../../../Services/Properties";

const DocumentTypeSelect: React.FunctionComponent<{ properties: any, onSendDocumentType: any }> = (props) => {

    const [selectProperty, setSelectProperty] = useState<any>("");
    const [idText, setIdText] = useState<any>("");
    const [selectText, setSelectText] = useState<any>("");
    const [textValue, setTextValue] = useState<any>("")
    const [textCopy, setTextCopy] = useState<any>("")
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);



    const HandleChangeText = (event: any) => {
        setTextCopy(event.target.value);
    }

    const Save = () => {
        setTextValue(textCopy)
        setIsDisabledSelect(!isDisabledSelect)
    }

    const Understand = () => {
        const Obj = {
            "id": 0,
            "text": textValue,
            "type": selectText
        }
        setIsDisabledSelect(false)

        props.onSendDocumentType(Obj);
    };

    return (

        <div className="card">
            <div className="card-body padd-24">
                <div className="row mt-3">
                    <a id="button-addnew" data-bs-toggle="modal" data-bs-target="#uploadText" className={`float-end ${isDisabledUpload ? 'disabled' : ''}`}><i className="fa-solid fa-upload"></i> Upload Text</a>
                    {textValue == '' ? <></> : <>
                        <div className="select-text mt-3" style={{ minHeight: "100px", height: "100%", maxHeight: "200px" }}>
                            {textValue}
                        </div>
                    </>}
                </div>
                <button type="button" className={`btn btn-primary generate marg-top20 ${textValue.length == 0 ? 'disabled' : ''}`} onClick={Understand}>Understand</button>
            </div>

            <div className="modal fade" id="uploadText" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onChange={() => { setTextCopy(" ") }}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ minHeight: "200px" }}>
                                <textarea className="form-control" id="textValue" value={textCopy} onChange={HandleChangeText} >{textCopy}</textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" style={{ float: 'right' }} data-bs-dismiss="modal" onClick={Save}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DocumentTypeSelect;