import { useEffect, useState } from "react";
import { SelectProperty } from "../../../Services/Generate";
import ListingBoosterSelect from "./listing-booster-select";
import { ListingBoosterService } from "../../../Services/Enhance";
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";

const ListingBooster: React.FunctionComponent<{}> = (props) => {
    const [properties, setProperties] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [enhanceValue, setEnhanceValue] = useState<any>('');
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"id":"666bd79516b3494ca0cbee88","enhancement":["Improve image positionning","Improve description","Improve title","Improve Image quality"]});
    const [curlValue, setCurlValue] = useState<any>("boosting_listing");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });

    }, [])

  

    const EnhancecHandler = (enhanceObj: any) => {
        setIsSpinner(true)
        setIsUpgrade(false)
        setInputData(enhanceObj);
        setEnhanceValue("")
        setShowMsgUpgrade("")
        ListingBoosterService(enhanceObj).then((data) => {
            
            setEnhanceValue(data.data)
            setIsSpinner(false)
            setShowMessage('تم التطوير بنجاح!')

            setIsSuccess(true)
        }).catch(err => {
            if(err.response.status===401){ 
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            }else{
                setIsSpinner(false)
                setShowMessage("فشل الحصول على البيانات")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };

 

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
               <div className="row">
            <div className="col-md-12 desc-property">
                <div className="card "> 
                    <div className="card-body">
                        <div className="row " style={{ marginBottom: "30px" }}>
                            <div className="col-md-12">
                                <div className="title-card">
                                تعزيز القائمة
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {properties == null ? <>تحميل...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <ListingBoosterSelect properties={properties} onSendEnhance={EnhancecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                <div className="generate-h5" style={{ height: "30px" }}>نتيجة
                                    {isSpinner && enhanceValue == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">تحميل...</span>
                                    </div>}
                                     <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade/> :<></>}
                                </div>
                                {enhanceValue == "" ? <div className="card card-image-gener"><p className="para" style={{padding:"20px",textAlign:"center"}}>{showMsgUpgrade}</p></div> :
                                    <div className="card card-image-gener padd-24" style={{maxHeight:"110px",minHeight:'110px'}}>
                                        <p className="understand-data" style={{ direction: "rtl" }}>اكتمل تعزيز القائمة <a type="button" style={{ height: "15px", color: "#000000" }}
                                            href={`/ar/properties/${enhanceValue._id}`} target="_blank" rel="noopener noreferrer">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: "10px",transform:" rotate(270deg)",marginBottom:"4px"  }} xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a></p>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}
         
        </div>
    )
}
export default ListingBooster;