import React, { useEffect, useState } from "react"
import UnderstandMain from "../understand-main";
import { GetEndpoints } from "../../../Services/Account";

const AudioMain: React.FunctionComponent<{}> = (props) => {

    const [VoiceFeaturesEndpoints, setVoiceFeaturesEndpoints] = useState(null);
    const [racialSpeechEndpoints, setRacialSpeechEndpoints] = useState(null);
    const [detectConvTopicsEndpoints, setDetectConvTopicsEndpoints] = useState(null);
    const [detectPredefinedTopicsEndpoints, setDetectPredefinedTopicsEndpoints] = useState(null);
    const [sentimentAnalysisEndpoints, setSentimentAnalysisEndpoints] = useState(null);
    const [feedbackAnalysisEndpoints, setFeedbackAnalysisEndpoints] = useState(null);
    const [toneEndpoints, setToneEndpoints] = useState(null);
    const [TranscribeAudioEndpoints, setTranscribeAudioEndpoints] = useState(null);

    useEffect(() => {
        fetchEndpoints("Voice Features", setVoiceFeaturesEndpoints);
        fetchEndpoints("Transcribe Audio", setTranscribeAudioEndpoints);
        fetchEndpoints("Hate Racial Speech Audio", setRacialSpeechEndpoints);
        fetchEndpoints("Detect Convesration Topics Audio", setDetectConvTopicsEndpoints);
        fetchEndpoints("Detect Convesration Predefined Topics Audio", setDetectPredefinedTopicsEndpoints);
        fetchEndpoints("Sentiment Analysis Audio", setSentimentAnalysisEndpoints);
        fetchEndpoints("Feedback Analysis Audio", setFeedbackAnalysisEndpoints);
        fetchEndpoints("Tone Audio(Friendly Unfriendly)", setToneEndpoints);
    }, []);

    const fetchEndpoints = async (name: any, setter: any) => {
        try {
            const data = await GetEndpoints(name);
            console.log(data);
            setter(data);
        } catch (error) {
            console.error("Error fetching endpoints:", error);
        }
    };


    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate"  >
            <UnderstandMain />
            <div className="tab-content">
                <div className="card-bottom">
                    <div className="row">
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/detect-convesration-topics-auto-identification" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5868 2.66699H10.4134C5.56008 2.66699 2.66675 5.56033 2.66675 10.4137V21.5737C2.66675 26.4403 5.56008 29.3337 10.4134 29.3337H21.5734C26.4268 29.3337 29.3201 26.4403 29.3201 21.587V10.4137C29.3334 5.56033 26.4401 2.66699 21.5868 2.66699ZM9.00008 18.8537C9.00008 19.4003 8.54675 19.8537 8.00008 19.8537C7.45341 19.8537 7.00008 19.4003 7.00008 18.8537V13.147C7.00008 12.6003 7.45341 12.147 8.00008 12.147C8.54675 12.147 9.00008 12.6003 9.00008 13.147V18.8537ZM13.0001 20.7603C13.0001 21.307 12.5467 21.7603 12.0001 21.7603C11.4534 21.7603 11.0001 21.307 11.0001 20.7603V11.2403C11.0001 10.6937 11.4534 10.2403 12.0001 10.2403C12.5467 10.2403 13.0001 10.6937 13.0001 11.2403V20.7603ZM17.0001 22.667C17.0001 23.2137 16.5467 23.667 16.0001 23.667C15.4534 23.667 15.0001 23.2137 15.0001 22.667V9.33366C15.0001 8.78699 15.4534 8.33366 16.0001 8.33366C16.5467 8.33366 17.0001 8.78699 17.0001 9.33366V22.667ZM21.0001 20.7603C21.0001 21.307 20.5467 21.7603 20.0001 21.7603C19.4534 21.7603 19.0001 21.307 19.0001 20.7603V11.2403C19.0001 10.6937 19.4534 10.2403 20.0001 10.2403C20.5467 10.2403 21.0001 10.6937 21.0001 11.2403V20.7603ZM25.0001 18.8537C25.0001 19.4003 24.5467 19.8537 24.0001 19.8537C23.4534 19.8537 23.0001 19.4003 23.0001 18.8537V13.147C23.0001 12.6003 23.4534 12.147 24.0001 12.147C24.5467 12.147 25.0001 12.6003 25.0001 13.147V18.8537Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        اكتشاف مواضيع الحديث - التعرف التلقائي
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>هذه الميزة تحليل الصوت لتحديد المواضيع الرئيسية التي يتم مناقشتها خلال المحادثة. يساعد ذلك في تصنيف المحتوى ويوفر رؤى حول سياق المحادثة.</p>
                                    <br /><p className="end_points" dir="auto">{detectConvTopicsEndpoints} {detectConvTopicsEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/detect-convesration-topics-predefined-topics" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3333 4.66699V2.66699C10.3333 2.12033 9.87992 1.66699 9.33325 1.66699C8.78659 1.66699 8.33325 2.12033 8.33325 2.66699V4.74699C8.66659 4.70699 8.97325 4.66699 9.33325 4.66699H10.3333Z" fill="#46B49E" />
                                            <path d="M21 4.74699V2.66699C21 2.12033 20.5467 1.66699 20 1.66699C19.4533 1.66699 19 2.12033 19 2.66699V4.66699H20C20.36 4.66699 20.6667 4.70699 21 4.74699Z" fill="#46B49E" />
                                            <path d="M28.3867 19.9603C27.3601 19.147 26.0667 18.667 24.6667 18.667C23.2667 18.667 21.9467 19.1603 20.9201 19.987C19.5334 21.0803 18.6667 22.787 18.6667 24.667C18.6667 25.787 18.9867 26.867 19.5334 27.747C19.9601 28.4403 20.5067 29.0537 21.1601 29.5203C22.1467 30.2403 23.3467 30.667 24.6667 30.667C26.1867 30.667 27.5601 30.107 28.6134 29.1737C29.0801 28.787 29.4801 28.307 29.8001 27.7603C30.3467 26.867 30.6667 25.787 30.6667 24.667C30.6667 22.7603 29.7734 21.0537 28.3867 19.9603ZM24.6667 27.5203C24.6667 25.947 23.3867 24.667 21.8134 24.667C23.3867 24.667 24.6667 23.387 24.6667 21.8137C24.6667 23.387 25.9467 24.667 27.5201 24.667C25.9467 24.667 24.6667 25.947 24.6667 27.5203Z" fill="#46B49E" />
                                            <path d="M21.0001 4.74699V6.66699C21.0001 7.21366 20.5467 7.66699 20.0001 7.66699C19.4534 7.66699 19.0001 7.21366 19.0001 6.66699V4.66699H10.3334V6.66699C10.3334 7.21366 9.88008 7.66699 9.33342 7.66699C8.78675 7.66699 8.33342 7.21366 8.33342 6.66699V4.74699C4.40008 5.10699 2.66675 7.64033 2.66675 11.3337V22.667C2.66675 26.667 4.66675 29.3337 9.33342 29.3337H14.8401C15.8534 29.3337 16.5334 28.2003 16.2801 27.2137C16.0934 26.4937 16.0001 25.747 16.0001 25.0003C16.0001 22.227 17.2401 19.667 19.3867 17.9737C20.9467 16.707 22.9467 16.0003 25.0001 16.0003H25.0534C25.8934 16.0003 26.6667 15.387 26.6667 14.547V11.3337C26.6667 7.64033 24.9334 5.10699 21.0001 4.74699ZM12.0001 22.3337H9.33342C8.78675 22.3337 8.33342 21.8803 8.33342 21.3337C8.33342 20.787 8.78675 20.3337 9.33342 20.3337H12.0001C12.5467 20.3337 13.0001 20.787 13.0001 21.3337C13.0001 21.8803 12.5467 22.3337 12.0001 22.3337ZM16.0001 15.667H9.33342C8.78675 15.667 8.33342 15.2137 8.33342 14.667C8.33342 14.1203 8.78675 13.667 9.33342 13.667H16.0001C16.5467 13.667 17.0001 14.1203 17.0001 14.667C17.0001 15.2137 16.5467 15.667 16.0001 15.667Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        اكتشاف مواضيع الحديث - مواضيع محددة مسبقًا
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>هذه الميزة تخصص مواضيع أو فئات محددة مسبقًا لمحتوى الصوت استنادًا إلى خصائصه وكلماته الرئيسية. وهي تبسط تنظيم المحتوى واسترجاعه.</p>
                                    <br /><p className="end_points" dir="auto">{detectPredefinedTopicsEndpoints} {detectPredefinedTopicsEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/hate-speech-racial" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5868 2.66699H10.4134C5.56008 2.66699 2.66675 5.56033 2.66675 10.4137V21.5737C2.66675 26.4403 5.56008 29.3337 10.4134 29.3337H21.5734C26.4268 29.3337 29.3201 26.4403 29.3201 21.587V10.4137C29.3334 5.56033 26.4401 2.66699 21.5868 2.66699ZM8.62675 12.3737C8.24008 11.987 8.24008 11.347 8.62675 10.9603C10.3467 9.24033 13.1601 9.24033 14.8934 10.9603C15.2801 11.347 15.2801 11.987 14.8934 12.3737C14.6934 12.5737 14.4401 12.667 14.1867 12.667C13.9334 12.667 13.6801 12.5737 13.4801 12.3737C12.5334 11.427 10.9867 11.427 10.0401 12.3737C9.65342 12.7737 9.01341 12.7737 8.62675 12.3737ZM20.8001 24.107H11.2001C10.2667 24.107 9.50675 23.347 9.50675 22.4003C9.50675 18.8137 12.4267 15.8937 16.0134 15.8937C19.6001 15.8937 22.5201 18.8137 22.5201 22.4003C22.5067 23.3337 21.7334 24.107 20.8001 24.107ZM23.3734 12.3737C22.9867 12.7603 22.3467 12.7603 21.9601 12.3737C21.0134 11.427 19.4667 11.427 18.5201 12.3737C18.3201 12.5737 18.0667 12.667 17.8134 12.667C17.5601 12.667 17.3067 12.5737 17.1067 12.3737C16.7201 11.987 16.7201 11.347 17.1067 10.9603C18.8267 9.24033 21.6401 9.24033 23.3734 10.9603C23.7601 11.3603 23.7601 11.987 23.3734 12.3737Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        خطاب الكراهية/العنصرية
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>ميزة تعمل على تحليل المحتوى الصوتي للكشف عن حالات خطاب الكراهية والتمييز العنصري والإبلاغ عنها، مما يساعد في الحفاظ على بيئة محترمة وشاملة.</p>
                                    <br /><p className="end_points" dir="auto">{racialSpeechEndpoints} {racialSpeechEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/transcribe-audio" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M29.3334 5H2.66675C2.12008 5 1.66675 4.54667 1.66675 4C1.66675 3.45333 2.12008 3 2.66675 3H29.3334C29.8801 3 30.3334 3.45333 30.3334 4C30.3334 4.54667 29.8801 5 29.3334 5Z" fill="#46B49E" />
                                            <path d="M14.6667 13H2.66675C2.12008 13 1.66675 12.5467 1.66675 12C1.66675 11.4533 2.12008 11 2.66675 11H14.6667C15.2134 11 15.6667 11.4533 15.6667 12C15.6667 12.5467 15.2134 13 14.6667 13Z" fill="#46B49E" />
                                            <path d="M10.6667 21H2.66675C2.12008 21 1.66675 20.5467 1.66675 20C1.66675 19.4533 2.12008 19 2.66675 19H10.6667C11.2134 19 11.6667 19.4533 11.6667 20C11.6667 20.5467 11.2134 21 10.6667 21Z" fill="#46B49E" />
                                            <path d="M8.00008 29H2.66675C2.12008 29 1.66675 28.5467 1.66675 28C1.66675 27.4533 2.12008 27 2.66675 27H8.00008C8.54675 27 9.00008 27.4533 9.00008 28C9.00008 28.5467 8.54675 29 8.00008 29Z" fill="#46B49E" />
                                            <path d="M29.1465 10.2396C28.3599 9.63963 27.2799 9.51963 26.0132 9.86629L20.2132 11.4396C18.6532 11.8663 17.6932 13.133 17.6932 14.733V18.133V23.0396C17.1332 22.7196 16.4799 22.5196 15.7865 22.5196C13.6399 22.5196 11.8799 24.2663 11.8799 26.4263C11.8799 28.573 13.6265 30.333 15.7865 30.333C17.9465 30.333 19.6932 28.5863 19.6932 26.4263V18.893L28.3332 16.533V21.1063C27.7732 20.7863 27.1199 20.5863 26.4265 20.5863C24.2799 20.5863 22.5199 22.333 22.5199 24.493C22.5199 26.6396 24.2665 28.3996 26.4265 28.3996C28.5865 28.3996 30.3332 26.653 30.3332 24.493V15.2263V13.1596C30.3332 11.813 29.9332 10.8263 29.1465 10.2396ZM15.7865 28.333C14.7332 28.333 13.8799 27.4796 13.8799 26.4263C13.8799 25.373 14.7332 24.5196 15.7865 24.5196C16.8399 24.5196 17.6932 25.373 17.6932 26.4263C17.6932 27.4796 16.8399 28.333 15.7865 28.333ZM26.4265 26.3996C25.3732 26.3996 24.5199 25.5463 24.5199 24.493C24.5199 23.4396 25.3732 22.5863 26.4265 22.5863C27.4799 22.5863 28.3332 23.4396 28.3332 24.493C28.3332 25.5463 27.4799 26.3996 26.4265 26.3996Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        كتابة نصية للصوت
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p> ميزة تقوم بتحويل الكلمات المنطوقة في الملفات الصوتية إلى نص مكتوب، مما يسهل البحث عن المحتوى وتحريره ومشاركته.</p>
                                    <br /><p className="end_points" dir="auto">{TranscribeAudioEndpoints} {TranscribeAudioEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/sentiment-analysis" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5334 20.4935C17.2401 20.0668 16.7201 19.8268 16.1201 19.8268H13.0134C12.8134 19.8268 12.6134 19.7468 12.4934 19.5868C12.3601 19.4268 12.3067 19.2268 12.3334 19.0002L12.7201 16.5202C12.8801 15.7868 12.3867 14.9468 11.6534 14.6935C10.9601 14.4402 10.1601 14.7868 9.82675 15.2802L6.74675 19.8668V19.2935C6.74675 18.1735 6.26675 17.7202 5.09341 17.7202H4.32008C3.14675 17.7335 2.66675 18.1868 2.66675 19.3068V26.9202C2.66675 28.0402 3.14675 28.4935 4.32008 28.4935H5.09341C6.21341 28.4935 6.68008 28.0535 6.72008 27.0402L9.05341 28.8402C9.38675 29.1602 10.0934 29.3335 10.6001 29.3335H13.5467C14.5601 29.3335 15.5734 28.5735 15.8134 27.6402L17.6801 21.9735C17.8801 21.4268 17.8267 20.9068 17.5334 20.4935Z" fill="#46B49E" />
                                            <path d="M28.1468 3.47965H27.3734C26.2534 3.47965 25.7868 3.91965 25.7334 4.93298L23.4001 3.13298C23.0801 2.81298 22.3601 2.63965 21.8534 2.63965H18.9068C17.8934 2.63965 16.8801 3.39965 16.6401 4.33298L14.7734 9.99965C14.5734 10.5463 14.6401 11.0663 14.9201 11.4796C15.2134 11.9063 15.7334 12.1463 16.3334 12.1463H19.4401C19.6401 12.1463 19.8401 12.2263 19.9601 12.3863C20.0934 12.5463 20.1468 12.7463 20.1201 12.973L19.7334 15.453C19.5734 16.1863 20.0668 17.0263 20.8001 17.2796C21.4934 17.533 22.2934 17.1863 22.6268 16.693L25.7068 12.1063V12.6796C25.7068 13.7996 26.1868 14.253 27.3601 14.253H28.1334C29.3068 14.253 29.7868 13.7996 29.7868 12.6796V5.03965C29.8001 3.93298 29.3201 3.47965 28.1468 3.47965Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        تحليل المشاعر
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تقوم هذه الميزة بتحديد المشاعر أو النغمة العاطفية المعبر عنها في الصوت، مثل الإيجابية، أو السلبية، أو الحيادية. وتوفر رؤى حول المشاعر العامة التي يعبر عنها المتحدثون.</p>
                                    <br /><p className="end_points" dir="auto">{sentimentAnalysisEndpoints} {sentimentAnalysisEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/feedback-analysis" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.6667 3.24023H9.33341C5.33341 3.24023 2.66675 5.9069 2.66675 9.9069V17.9069C2.66675 21.9069 5.33341 24.5736 9.33341 24.5736V27.4136C9.33341 28.4802 10.5201 29.1202 11.4001 28.5202L17.3334 24.5736H22.6667C26.6667 24.5736 29.3334 21.9069 29.3334 17.9069V9.9069C29.3334 5.9069 26.6667 3.24023 22.6667 3.24023ZM16.0001 19.4669C15.4401 19.4669 15.0001 19.0136 15.0001 18.4669C15.0001 17.9202 15.4401 17.4669 16.0001 17.4669C16.5601 17.4669 17.0001 17.9202 17.0001 18.4669C17.0001 19.0136 16.5601 19.4669 16.0001 19.4669ZM17.6801 13.9336C17.1601 14.2802 17.0001 14.5069 17.0001 14.8802V15.1602C17.0001 15.7069 16.5467 16.1602 16.0001 16.1602C15.4534 16.1602 15.0001 15.7069 15.0001 15.1602V14.8802C15.0001 13.3336 16.1334 12.5736 16.5601 12.2802C17.0534 11.9469 17.2134 11.7202 17.2134 11.3736C17.2134 10.7069 16.6667 10.1602 16.0001 10.1602C15.3334 10.1602 14.7867 10.7069 14.7867 11.3736C14.7867 11.9202 14.3334 12.3736 13.7867 12.3736C13.2401 12.3736 12.7867 11.9202 12.7867 11.3736C12.7867 9.60023 14.2267 8.16023 16.0001 8.16023C17.7734 8.16023 19.2134 9.60023 19.2134 11.3736C19.2134 12.8936 18.0934 13.6536 17.6801 13.9336Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        تحليل التعليقات
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>تقوم هذه الميزة بتحليل الصوت لاستخراج التعليقات أو الآراء التي أعرب عنها المتحدثون.</p>
                                    <br /><p className="end_points" dir="auto">{feedbackAnalysisEndpoints} {feedbackAnalysisEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/tone" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3333 1.33301H5.33325C3.11992 1.33301 1.33325 3.11967 1.33325 5.33301V13.333C1.33325 15.5463 3.11992 17.333 5.33325 17.333H11.3333C11.3333 14.0263 14.0266 11.333 17.3333 11.333V5.33301C17.3333 3.11967 15.5466 1.33301 13.3333 1.33301ZM5.02659 5.69301C6.05325 4.95967 7.45325 4.94634 8.50659 5.66634C8.95992 5.97301 9.07992 6.59967 8.77325 7.05301C8.46659 7.50634 7.83992 7.62634 7.38659 7.31967C7.02659 7.06634 6.53325 7.06634 6.17325 7.33301C5.99992 7.45301 5.79992 7.50634 5.59992 7.50634C5.29325 7.50634 4.97325 7.35967 4.78659 7.09301C4.46659 6.63967 4.57325 6.01301 5.02659 5.69301ZM12.3599 10.9863C12.0133 11.4263 11.3866 11.493 10.9599 11.1463C10.4933 10.773 9.93325 10.573 9.33325 10.573C7.99992 10.573 6.89325 11.5863 6.74659 12.893H9.54659C10.0933 12.893 10.5466 13.3463 10.5466 13.893C10.5466 14.4397 10.0933 14.893 9.54659 14.893H6.45325C5.50659 14.893 4.73325 14.1197 4.73325 13.173C4.73325 10.6397 6.79992 8.57301 9.33325 8.57301C10.3733 8.57301 11.3999 8.93301 12.2133 9.58634C12.6399 9.93301 12.7066 10.5597 12.3599 10.9863ZM14.1066 7.05301C13.7999 7.50634 13.1733 7.62634 12.7199 7.31967C12.3599 7.06634 11.8666 7.06634 11.5066 7.33301C11.3333 7.45301 11.1333 7.50634 10.9333 7.50634C10.6266 7.50634 10.3066 7.35967 10.1199 7.09301C9.79992 6.63967 9.90659 6.01301 10.3599 5.69301C11.3866 4.95967 12.7866 4.94634 13.8399 5.66634C14.2933 5.97301 14.4133 6.59967 14.1066 7.05301Z" fill="#46B49E" />
                                            <path d="M24.2934 22.2401C24.2001 22.1334 24.0668 22.0801 23.9201 22.0801H18.7468C18.6001 22.0801 18.4668 22.1334 18.3734 22.2401C18.2801 22.3467 18.2268 22.4934 18.2534 22.6267C18.4268 24.2001 19.7468 25.4001 21.3334 25.4001C22.9201 25.4001 24.2401 24.2134 24.4134 22.6267C24.4268 22.4801 24.3868 22.3467 24.2934 22.2401Z" fill="#46B49E" />
                                            <path d="M25.3333 13.333H17.3333C15.1333 13.333 13.3333 15.133 13.3333 17.333V25.333C13.3333 27.533 15.1333 29.333 17.3333 29.333H25.3333C27.5333 29.333 29.3333 27.533 29.3333 25.333V17.333C29.3333 15.133 27.5333 13.333 25.3333 13.333ZM16.7866 17.5597C17.1066 17.1063 17.7333 16.9997 18.1866 17.3197C18.5466 17.573 19.0266 17.573 19.3866 17.333C19.8399 17.013 20.4666 17.133 20.7733 17.5997C21.0799 18.053 20.9733 18.6797 20.5066 18.9863C19.9866 19.333 19.3866 19.5197 18.7866 19.5197C18.1599 19.5197 17.5466 19.333 17.0266 18.9597C16.5733 18.6263 16.4666 17.9997 16.7866 17.5597ZM21.3333 26.893C18.7999 26.893 16.7333 24.8263 16.7333 22.293C16.7333 21.3463 17.5066 20.573 18.4533 20.573H24.2133C25.1599 20.573 25.9333 21.3463 25.9333 22.293C25.9333 24.8263 23.8666 26.893 21.3333 26.893ZM25.8399 18.973C25.3199 19.3197 24.7199 19.5063 24.1199 19.5063C23.4933 19.5063 22.8799 19.3197 22.3599 18.9463C21.9066 18.6263 21.7999 17.9997 22.1199 17.5463C22.4399 17.093 23.0666 16.9863 23.5199 17.3063C23.8799 17.5597 24.3599 17.5597 24.7199 17.3197C25.1733 16.9997 25.7999 17.1197 26.1066 17.5863C26.4133 18.053 26.2933 18.6663 25.8399 18.973Z" fill="#46B49E" />
                                        </svg>

                                    </div>
                                    <h2>
                                        اللهجة (ودية / غير ودية)
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>هذه الميزة تقييم لهجة المتحدثين في الصوت، تصنيفها كودية أو غير ودية. تساعد في تحليل الديناميات البينية وتقييم الجو العام للمحادثة.</p>
                                    <br /><p className="end_points" dir="auto">{toneEndpoints} {toneEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>

                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 ">
                            <div className="card">
                                <a href="/ar/understand/audio/voice-features" className="card-body generate-card audio-card-height">
                                    <div className="rounded backg-green">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.3867 18.2266C18.9067 18.2266 18.52 18.6132 18.52 19.0932C18.52 19.5732 18.9067 19.9599 19.3867 19.9599C19.8667 19.9599 20.2534 19.5732 20.2534 19.0932C20.24 18.6132 19.8534 18.2266 19.3867 18.2266Z" fill="#46B49E" />
                                            <path d="M12.08 19.5469C11.6 19.5469 11.2134 19.9335 11.2134 20.4135C11.2134 20.8935 11.6 21.2802 12.08 21.2802C12.56 21.2802 12.9467 20.8935 12.9467 20.4135C12.9467 19.9335 12.56 19.5469 12.08 19.5469Z" fill="#46B49E" />
                                            <path d="M21.5868 2.66699H10.4134C5.56008 2.66699 2.66675 5.56033 2.66675 10.4137V21.5737C2.66675 26.4403 5.56008 29.3337 10.4134 29.3337H21.5734C26.4268 29.3337 29.3201 26.4403 29.3201 21.587V10.4137C29.3334 5.56033 26.4401 2.66699 21.5868 2.66699ZM22.5067 12.7337V19.0803C22.5067 20.8003 21.1067 22.2003 19.3867 22.2003C17.6667 22.2003 16.2667 20.8003 16.2667 19.0803C16.2667 17.3603 17.6667 15.9603 19.3867 15.9603C19.6934 15.9603 19.9734 16.0137 20.2534 16.0937V14.2003L15.2134 15.5737V20.4003C15.2134 20.4137 15.2001 20.427 15.2001 20.4403C15.1867 22.147 13.7867 23.5203 12.0801 23.5203C10.3601 23.5203 8.96008 22.1203 8.96008 20.4003C8.96008 18.6803 10.3601 17.2803 12.0801 17.2803C12.3867 17.2803 12.6667 17.3337 12.9467 17.4137V14.707V12.387C12.9467 11.0937 13.7467 10.0403 15.0001 9.70699L18.9867 8.61366C20.2667 8.26699 21.0667 8.60033 21.5201 8.94699C21.9601 9.29366 22.5067 9.97366 22.5067 11.307V12.7337Z" fill="#46B49E" />
                                        </svg>
                                    </div>
                                    <h2>
                                        ميزات الصوت
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                        </svg>
                                    </h2>
                                    <p>ميزة تحدد وتحلل خصائص الصوت المختلفة مثل النغمة وطبقة الصوت والفروق الدقيقة العاطفية، مما يوفر رؤى أعمق للمحتوى الصوتي.</p>
                                    <br /><p className="end_points" dir="auto">{VoiceFeaturesEndpoints} {VoiceFeaturesEndpoints == 1 ? "الائتمان" : "الائتمانات"} / 1 دقيقة صوت</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default AudioMain;