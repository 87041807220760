import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import HorizontalBarChart from "../Charts/HorizontalBarChart";
import WordCloud from "../Charts/WordCloud";
import DonutOverview from "../Charts/Donut-overview";
import LineChart from "../Charts/LineChart";
import LineChartDelight from "../Charts/line-chart-delight";

const Overview: React.FunctionComponent<{}> = (props) => {


    return (<div className="card-bottom">
        <div className="row row-over">
            <div className=" col-md-10 mb-3 moderate-title" >
                Overview
            </div>
            <div className="col-md-2 mb-3">
                <button type="submit" className="filters" >
                    <span>Filters</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 6.45831H2.5C2.15833 6.45831 1.875 6.17498 1.875 5.83331C1.875 5.49165 2.15833 5.20831 2.5 5.20831H17.5C17.8417 5.20831 18.125 5.49165 18.125 5.83331C18.125 6.17498 17.8417 6.45831 17.5 6.45831Z" fill="#282828" />
                        <path d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" fill="#282828" />
                        <path d="M11.6667 14.7917H8.33337C7.99171 14.7917 7.70837 14.5084 7.70837 14.1667C7.70837 13.825 7.99171 13.5417 8.33337 13.5417H11.6667C12.0084 13.5417 12.2917 13.825 12.2917 14.1667C12.2917 14.5084 12.0084 14.7917 11.6667 14.7917Z" fill="#282828" />
                    </svg>
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3  mb-3" >
                <div className="img-background  card-delight height-card-delight " style={{ backgroundImage: "url(assets/images/photo.jfif)" }}>
                    <div className="position-relative z-index-2 padd-10" style={{ backgroundColor: "rgba(64, 64, 64, 0.5)", height: "100%", borderRadius: '12px' }}>
                        <div className="row mb-3 height-para" >
                            <p className="text">The experience was good overall, the agent was responsive and I found what I needed.
                            </p>
                        </div>
                        <div className="row mt-4">
                            <div className="col-7">
                                <h3 className="marg-l title-user mb-2"> Mark Williams </h3>
                                <h6 className=" marg-l mb-2">Marina, Dubai</h6>
                            </div>
                            <div className="col-5">
                                <span className="badge-po float-end mb-3" style={{ display: "inline-flex" }}>Positive</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mb-2">
                <div className="card mb-2">
                    <div className="card-body padd-10 overview height-delight mt-2">
                    <div className="card-title d-flex align-items-center justify-content-between">
                            <div className="rounded backg-green " style={{ width: "56px", height: "56px" }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.9333 5.50699C27.5466 4.94699 27.0533 4.45366 26.4933 4.06699C25.2266 3.14699 23.5733 2.66699 21.5866 2.66699H10.4133C10.1466 2.66699 9.87996 2.68033 9.62663 2.70699C5.25329 2.98699 2.66663 5.82699 2.66663 10.4137V21.587C2.66663 23.5737 3.14663 25.227 4.06663 26.4937C4.45329 27.0537 4.94663 27.547 5.50663 27.9337C6.59996 28.7337 7.98663 29.2003 9.62663 29.307C9.87996 29.3203 10.1466 29.3337 10.4133 29.3337H21.5866C26.44 29.3337 29.3333 26.4403 29.3333 21.587V10.4137C29.3333 8.42699 28.8533 6.77366 27.9333 5.50699ZM15.1866 21.1737H11.36C10.8133 21.1737 10.36 20.7203 10.36 20.1737C10.36 19.627 10.8133 19.1737 11.36 19.1737H12.6933V10.547H9.26663C9.11996 10.547 8.99996 10.667 8.99996 10.8137V11.8537C8.99996 12.4003 8.54663 12.8403 7.99996 12.8403C7.45329 12.8403 6.99996 12.4003 6.99996 11.8403V10.8003C6.99996 9.54699 8.01329 8.53366 9.26663 8.53366H18.12C19.3733 8.53366 20.3866 9.54699 20.3866 10.8003V11.8403C20.3866 12.387 19.9333 12.8403 19.3866 12.8403C18.84 12.8403 18.3866 12.387 18.3866 11.8403V10.8003C18.3866 10.6537 18.2666 10.5337 18.12 10.5337H14.6933V19.1737H15.1866C15.7333 19.1737 16.1866 19.627 16.1866 20.1737C16.1866 20.7203 15.7333 21.1737 15.1866 21.1737ZM25 16.307C25 16.8537 24.5466 17.307 24 17.307C23.4533 17.307 23 16.8537 23 16.307V15.867H20.9333V21.467H21.4C21.9466 21.467 22.4 21.9203 22.4 22.467C22.4 23.0137 21.9466 23.467 21.4 23.467H18.4666C17.92 23.467 17.4666 23.0137 17.4666 22.467C17.4666 21.9203 17.92 21.467 18.4666 21.467H18.9333V15.867H18.2933C17.7466 15.867 17.2933 15.4137 17.2933 14.867C17.2933 14.3203 17.7466 13.867 18.2933 13.867H23.0933C24.1466 13.867 25 14.7203 25 15.7737V16.307Z" fill="#46B49E" />
                                </svg>
                            </div>
                        </div>
                        <div className="card-figures">
                        <span className="fw-semibold marg-35">Text Instances</span>
                        <div className="number-month marg-8 "><h3 className="card-title h3">7,500</h3>
                            <small className="text-success"><svg width="16" height="16" style={{marginBottom :"5px", marginRight:"2px"}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7933 1.33301H5.20665C2.77998 1.33301 1.33331 2.77967 1.33331 5.20634V10.7863C1.33331 13.2197 2.77998 14.6663 5.20665 14.6663H10.7866C13.2133 14.6663 14.66 13.2197 14.66 10.793V5.20634C14.6666 2.77967 13.22 1.33301 10.7933 1.33301ZM11.2533 7.68634C11.2533 7.94634 11.0466 8.15301 10.7866 8.15301C10.5266 8.15301 10.32 7.94634 10.32 7.68634V7.56634L8.50665 9.37967C8.40665 9.47967 8.27331 9.52634 8.13331 9.51301C7.99331 9.49967 7.86665 9.42634 7.79331 9.30634L7.11331 8.29301L5.52665 9.87967C5.43331 9.97301 5.31998 10.013 5.19998 10.013C5.07998 10.013 4.95998 9.96634 4.87331 9.87967C4.69331 9.69967 4.69331 9.40634 4.87331 9.21967L6.85998 7.23301C6.95998 7.13301 7.09331 7.08634 7.23331 7.09967C7.37331 7.11301 7.49998 7.18634 7.57331 7.30634L8.25331 8.31967L9.65998 6.91301H9.53998C9.27998 6.91301 9.07331 6.70634 9.07331 6.44634C9.07331 6.18634 9.27998 5.97967 9.53998 5.97967H10.78C10.84 5.97967 10.9 5.99301 10.96 6.01301C11.0733 6.05967 11.1666 6.15301 11.2133 6.26634C11.24 6.32634 11.2466 6.38634 11.2466 6.44634V7.68634H11.2533Z" fill="#46B49E" />
                            </svg>
                                +6% than last month</small></div>
                    </div>
                    </div>
                </div>
                <div className="card ">
                    <div className="card-body padd-10 overview height-delight mt-2">
                        <div className="card-title d-flex align-items-center justify-content-between">
                            <div className="rounded backg-blue " style={{ width: "56px", height: "56px" }}>
                                <svg width="32" height="32"  viewBox="0 0 32 32"  fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 22.333C23.7867 22.333 23.5867 22.2664 23.4 22.133C22.96 21.7997 22.8667 21.173 23.2 20.733C25.2933 17.9464 25.2933 14.053 23.2 11.2664C22.8667 10.8264 22.96 10.1997 23.4 9.86638C23.84 9.53305 24.4667 9.62638 24.8 10.0664C27.4133 13.5597 27.4133 18.4397 24.8 21.933C24.6 22.1997 24.3067 22.333 24 22.333Z" fill="#5782C2" />
                                    <path d="M26.44 25.6667C26.2267 25.6667 26.0267 25.6 25.84 25.4667C25.4 25.1334 25.3067 24.5067 25.64 24.0667C29.2 19.32 29.2 12.68 25.64 7.93337C25.3067 7.49337 25.4 6.8667 25.84 6.53337C26.28 6.20004 26.9067 6.29337 27.24 6.73337C31.3333 12.1867 31.3333 19.8134 27.24 25.2667C27.0533 25.5334 26.7467 25.6667 26.44 25.6667Z" fill="#5782C2" />
                                    <path d="M18.6933 5.04028C17.2 4.21362 15.2933 4.42695 13.3466 5.64028L9.45329 8.08028C9.18663 8.24028 8.87996 8.33362 8.57329 8.33362H7.33329H6.66663C3.43996 8.33362 1.66663 10.1069 1.66663 13.3336V18.6669C1.66663 21.8936 3.43996 23.6669 6.66663 23.6669H7.33329H8.57329C8.87996 23.6669 9.18663 23.7603 9.45329 23.9203L13.3466 26.3603C14.52 27.0936 15.6666 27.4536 16.7333 27.4536C17.4266 27.4536 18.0933 27.2936 18.6933 26.9603C20.1733 26.1336 21 24.4136 21 22.1203V9.88028C21 7.58695 20.1733 5.86695 18.6933 5.04028Z" fill="#5782C2" />
                                </svg>
                            </div>
                        </div>
                        <div className="card-figures">
                        <span className="fw-semibold marg-35">Audio Instances</span>
                        <div className="number-month marg-8 "><h3 className="card-title h3">2,500</h3>
                            <small className="text-danger"> <svg width="16" height="16" style={{marginBottom :"5px", marginRight:"2px"}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7933 14.667H5.20665C2.77998 14.667 1.33331 13.2203 1.33331 10.7937V5.21366C1.33331 2.78033 2.77998 1.33366 5.20665 1.33366H10.7866C13.2133 1.33366 14.66 2.78033 14.66 5.20699V10.7937C14.6666 13.2203 13.22 14.667 10.7933 14.667ZM11.2533 8.31366C11.2533 8.05366 11.0466 7.84699 10.7866 7.84699C10.5266 7.84699 10.32 8.05366 10.32 8.31366V8.43366L8.50665 6.62033C8.40665 6.52033 8.27331 6.47366 8.13331 6.48699C7.99331 6.50033 7.86665 6.57366 7.79331 6.69366L7.11331 7.70699L5.52665 6.12033C5.43331 6.02699 5.31998 5.98699 5.19998 5.98699C5.07998 5.98699 4.95998 6.03366 4.87331 6.12033C4.69331 6.30033 4.69331 6.59366 4.87331 6.78033L6.85998 8.76699C6.95998 8.86699 7.09331 8.91366 7.23331 8.90033C7.37331 8.88699 7.49998 8.81366 7.57331 8.69366L8.25331 7.68033L9.65998 9.08699H9.53998C9.27998 9.08699 9.07331 9.29366 9.07331 9.55366C9.07331 9.81366 9.27998 10.0203 9.53998 10.0203H10.78C10.84 10.0203 10.9 10.007 10.96 9.98699C11.0733 9.94033 11.1666 9.84699 11.2133 9.73366C11.24 9.67366 11.2466 9.61366 11.2466 9.55366V8.31366H11.2533Z" fill="#D34645" />
                            </svg>
                                +6% than last month</small></div>
                    </div> </div>
                </div>
            </div>
            <div className="col-md-3 mb-3">
                <div className="card height-card-delight">
                    <div className="card-body padd-20">
                    <div className="row">
                        <h3 className="title-charts">Feedback Analysis</h3>
                         </div>
                        <div className="row mt-4">
                        <DonutOverview />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-3">
                <div className="card height-card-delight">
                    <div className="card-body padd-20">
                        <div className="row">
                        <h3 className="title-charts">Net Sentiments Score</h3>
                        </div>
                        <div className="row mt-4">
                        <DonutOverview />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-over">
            <div className="col-md-6 mb-3">
                <div className="card padding-chart">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-md-9 ">
                                <h3 className="title-charts">Sentiment over time</h3>
                            </div>
                            <div className="col-md-3 display-select">
                                <FormControl fullWidth>
                                    <Select
                                        className="custom-select"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Sort By' }}
                                        value={"Sort By"}>
                                        <MenuItem value="" disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2022}>2022</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <LineChartDelight/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card padding-chart">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-md-9 ">
                                <h3 className="title-charts">Top Topics</h3>
                            </div>
                        </div>
                        <div className="row">
                            < HorizontalBarChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-over">
            <div className="col-md-6 mb-3">
                <div className="card padding-chart height-400" >
                    <div className="card-body">
                        <div className="row">
                            <WordCloud />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3 display-property">
                <div className="card padding-chart height-400" >
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-md-9 ">
                                <h3 className="title-charts">Recent Instances</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="table-responsive text-nowrap ">
                                <table className="table" style={{ minHeight: "150px"}}>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Project Name</th>
                                            <th>Instances</th>
                                            <th>Status</th>
                                            <th>Last Update</th>
                                            <th>Sentiment</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <td>Text</td>
                                            <td>Admin Calls - Dubai (ALL)</td>
                                            <td>1200</td>
                                            <td><span className="badge bg-label-success">Processed</span></td>
                                            <td>13 Jun 2023</td>
                                            <td> <span className="text-success fs-14 fw-lh">Positve</span></td>
                                        </tr>
                                        <tr>
                                            <td>Text</td>
                                            <td>Agent Calls - Downtown</td>
                                            <td>1900</td>
                                            <td><span className="badge bg-label-success">Processed</span></td>
                                            <td>25 Oct 2023</td>
                                            <td> <span className="text-success fs-14 fw-lh">Positve</span></td>
                                        </tr>
                                        <tr>
                                            <td>Text</td>
                                            <td>Agent Calls - Dubai Marina</td>
                                            <td>1354</td>
                                            <td><span className="badge bg-label-success">Processed</span></td>
                                            <td>3 Aug 2023</td>
                                            <td> <span className="text-danger fs-14 fw-lh">Negative</span></td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 {/* Mobile */}
 <div className="rule-list ">
        <div className="property-mobile">
        <h3 className="title-charts">Recent Instances </h3>

          <div className="card mb-3">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-12 d-flex align-items-center" >
                  <h3 className="title text-start">Admin Calls - Dubai (ALL) </h3>
                </div>
              </div>
              <div className="row date-create">
                <div className="col mb-3">
                  <p className="float-start">Text
                    <svg width="6" height="6" style={{ margin: "0 8px 0 8px" }} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3" cy="3" r="3" fill="#8C8D96" />
                    </svg>
                    1200 Instance <svg width="6" height="6" viewBox="0 0 6 6" style={{ margin: "0 8px 0 8px" }} fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3" cy="3" r="3" fill="#8C8D96" />
                    </svg> Postive Sentiment
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <span className="version float-start" style={{ marginTop: "8px", opacity: 0.9, color: "#8C8D96" }}>13 Jun 2023</span>
                </div>
                <div className="col mb-3">
                  <div style={{ marginTop: "8px" }}><span className="badge bg-label-success float-end">Postive</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default Overview   