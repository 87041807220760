import React, { useEffect, useState } from "react";
import { FilterOptions } from "../../Models/FilterOptions";
import { DropItems, getItems } from "../../Services/Moderate";
import { PropertiesObj } from "../../Models/PropertiesObj";
import { dropdown_option } from "../../Services/Properties";
import { FormControl, MenuItem, Pagination, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchById from "../Properties/search-by-id";
import { getProjects } from "../../Services/Projects";
import SortByProperties from "../Properties/sort-by-properties";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import ModerateMain from "./moderate-main";

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


const Items: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<number>(1);
    const [change, setChange] = useState(false);
    const [dataValue, setDataValue] = useState<PropertiesObj>();
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [itemId, setItemId] = useState("");
    const [dropdownOption, setDropdownOption] = useState<any>("");
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [filterOptionsValue, setFilterOptionsValue] = useState<FilterOptions>({
        "id": "",
        "brokerage": "",
        "broker": "",
        "bedroomsCount": "",
        "projectId": "",
        "location": "",
        "minPrice": "",
        "maxPrice": "",
        "date": "",
        "minSize": "",
        "maxSize": "",
        "sortBy": ""
    });

    useEffect(() => {
        getItems(filterOptionsValue, 1).then((data) => {
            setPage(Math.ceil(data.total / 8))
            setDataValue(data)
            setLoading(false)
        });
        const type: any = "property"
        getProjects(type).then((data) => {
            setProjects(data)
        })
        dropdown_option().then((data) => {
            setDropdownOption(data)
            setLoading(false)
        });
    }, [filterOptionsValue, change])

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        getItems(filterOptionsValue, value).then((data) => {
            setPage(Math.ceil(data.total / 8))
            setDataValue(data)
        }).catch(err => {
            setIsError(true)
            setShowMessage("Error");
        })
        setTimeout(() => {
            setIsError(false);
        }, 3000);
    };

    const view = (id: any) => {
        window.open(`/ar/moderate/property/${id}`, '_blank');
    }
    /**************filters********************** */

    const handleSelectChangeProject = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            projectId: event.target.value,
        }));
    };

    const handleSelectChangePortal = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            id: event.target.value,
        }));
    }
    const handleSelectChangeBroker = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            broker: event.target.value,
        }));
    }
    const handleSelectChangeBrokerage = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            brokerage: event.target.value,
        }));
    }
    const handleSelectChangeBedrooms = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            bedroomsCount: event.target.value,
        }));
    }
    const handleSelectChangeLocation = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            location: event.target.value,
        }));
    }
    const handleSelectChangeMinPrice = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            minPrice: event.target.value,
        }));
    }
    const handleSelectChangeMaxPrice = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            maxPrice: event.target.value,
        }));
    }
    const handleSelectChangeMinSize = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            minSize: event.target.value,
        }));
    }
    const handleSelectChangeMaxSize = (event: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            maxSize: event.target.value,
        }));
    }

    const handleSelectChangeSortBy = (sortByValue: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            sortBy: sortByValue,
        }));

    }

    const applyFilters = () => {
        setChange(!change)
    }

    const handleSearchById = (idValue: any) => {
        setFilterOptionsValue(prevOptions => ({
            ...prevOptions,
            id: idValue,
        }));
    }
    const drop = () => {
        DropItems(itemId).then((data) => {
            setShowMessage(" حذف بنجاح!");
            setIsSuccess(true)
            setChange(!change)
        }).catch((error) => {
            setShowMessage("فشل الحذف!");
            setIsError(true)
        })
        setTimeout(() => {
            setIsSuccess(false);
            setIsError(false)
        }, 3000);
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate" >
            <ModerateMain change={change} />
            <div className="tab-content">
                {loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...    </div>) :
                    <div className="display-items">
                        <div className="card card-bottom">
                            <div className="card-body padd-24">
                                <div className="row items-list mb-2">
                                    <div className=" col-md-5 moderate-title">

                                        عناصر
                                    </div>
                                    <div className="col-md-3 mb-2">
                                        <SearchById onSearchById={handleSearchById} placeholder="فرز حسب الهوية" />

                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <button type="submit" className="filters" data-bs-toggle="modal"
                                            data-bs-target="#filterModal">
                                            <span>فلاتر</span>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.5 6.45831H2.5C2.15833 6.45831 1.875 6.17498 1.875 5.83331C1.875 5.49165 2.15833 5.20831 2.5 5.20831H17.5C17.8417 5.20831 18.125 5.49165 18.125 5.83331C18.125 6.17498 17.8417 6.45831 17.5 6.45831Z" fill="#282828" />
                                                <path d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" fill="#282828" />
                                                <path d="M11.6667 14.7917H8.33337C7.99171 14.7917 7.70837 14.5084 7.70837 14.1667C7.70837 13.825 7.99171 13.5417 8.33337 13.5417H11.6667C12.0084 13.5417 12.2917 13.825 12.2917 14.1667C12.2917 14.5084 12.0084 14.7917 11.6667 14.7917Z" fill="#282828" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <SortByProperties OnSelectChangeSortBy={handleSelectChangeSortBy} />
                                    </div>
                                </div>
                                {isSuccess && <div className="alert alert-success alert-dismissible mt-4" role="alert">
                                    {showMessage}
                                </div>}
                                {isError && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                                    {showMessage}
                                </div>}
                                <div className="margin-35 display-property">
                                    <div className="table-responsive text-nowrap ">
                                        <table className="table" style={{ minHeight: "150px" }}>
                                            <thead>
                                                <tr>
                                                    <th>هوية</th>
                                                    <th>صورة</th>
                                                    <th>عنوان</th>
                                                    <th>الموقع</th>
                                                    <th>نسخة</th>
                                                    <th>الناشر</th>
                                                    <th>مكررات</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {dataValue?.properties && dataValue?.properties.map((lines: any, index: any) => {
                                                    return <tr key={"line_" + index}>
                                                        <td>{lines.id ? lines.id : ""}</td>
                                                        <td>
                                                           {lines.content.images[0] ? <img src={lines.content.images[0].src} alt="Avatar" className="prop-image" /> : ""}
                                                        </td>
                                                        <td>{lines.content.title_ar ? ((lines.content.title_ar.length > 20) ? lines.content.title_ar.slice(0, 20) + '...' : lines.content.title_ar) : <></>}
                                                            {lines.taken_action_name && lines.taken_action_name == "manual" && <div style={{ marginTop: "8px" }}><span className="badge bg-label-info">يدوي</span></div>}
                                                            {lines.taken_action_name && lines.taken_action_name == "passive" && <div style={{ marginTop: "8px" }}><span className="badge bg-label-primary">غير نشيط</span></div>}
                                                            {lines.taken_action_name && lines.taken_action_name == "approved" && <div style={{ marginTop: "8px" }}><span className="badge bg-label-success">وافق </span></div>}
                                                            {lines.taken_action_name && lines.taken_action_name == "refused" && <div style={{ marginTop: "8px" }}><span className="badge bg-label-danger">مرفوض</span></div>}
                                                            {lines.taken_action_name && lines.taken_action_name == "Processing" && <div style={{ marginTop: "8px" }}><span className="badge bg-label-success">معالجة</span></div>}
                                                        </td>
                                                        <td>
                                                        {lines.content.address && lines.content.address.subCommunity ? ((lines.content.address.subCommunity.length > 20) ? lines.content.address.subCommunity.slice(0, 20) + '...' : lines.content.address.subCommunity) : <></>}
                                                        </td>
                                                        <td>{lines.version ? lines.version : ""}</td>
                                                        <td>{lines.status && lines.status.published && lines.status.published == true ? "صحيح" : "خطأ"}</td>
                                                        <td>{lines.found_duplicates && lines.found_duplicates !== "" ? lines.found_duplicates.length : 0}</td>
                                                        <td>
                                                            <button type="button" className="btn p-0  hide-arrow" onClick={(e) => { view(lines._id) }}>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="btn p-0  hide-arrow" onClick={(e) => { setItemId(lines._id) }} style={{ marginLeft: "10px" }} data-bs-toggle="modal"
                                                                data-bs-target="#dropModal">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {dataValue?.total == 0 && <h3 className="empty"> لا توجد عناصر</h3>}
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />
                                        </ThemeProvider>
                                    </CacheProvider>
                                </div>
                                <div className="property-mobile">
                                    {dataValue?.properties && dataValue?.properties.map((lines: any, index: any) => {
                                        return <div className="card mb-3" key={index} >
                                            <div className="card-body">
                                                <div className="row mb-2" onClick={(e) => { view(lines._id) }}>
                                                    {lines.content.images[0] ? <img src={lines.content.images[0].src} alt="Avatar" className="prop-image" /> : <></>}
                                                </div>
                                                <div className="row">
                                                    <span className="id-prop marg-10 mb-2">هوية <p>{lines.id ? lines.id : ""}</p></span>
                                                    <span className="id-prop marg-10 mb-2">تكرار<p> {lines.found_duplicates && lines.found_duplicates !== "" ? lines.found_duplicates.length : 0}</p></span>
                                                    {lines.taken_action_name && lines.taken_action_name == "manual" && <span className="id-prop bg-label-info  marg-10 mb-2"><p>يدوي</p></span>}
                                                    {lines.taken_action_name && lines.taken_action_name == "passive" && <span className=" id-prop  bg-label-primary marg-10 mb-2"><p>غير نشط</p></span>}
                                                    {lines.taken_action_name && lines.taken_action_name == "approved" && <span className="id-prop bg-label-success marg-10 mb-2"><p>وافق </p></span>}
                                                    {lines.taken_action_name && lines.taken_action_name == "refused" && <span className="id-prop bg-label-danger marg-10 mb-2"><p>مرفوض</p></span>}
                                                    {lines.taken_action_name && lines.taken_action_name == "Processing" && <span className="id-prop bg-label-success marg-10 mb-2"><p>معالجة</p></span>}
                                                    <span className="id-prop  mb-2 float-end" style={{ background: "none" }}>
                                                        <button type="button" className="btn p-0  hide-arrow " data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: "pointer", height: "25px", width: "30px" }}>
                                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11 11.5C11 10.66 11.675 10 12.5 10C13.325 10 14 10.675 14 11.5C14 12.325 13.325 13 12.5 13C11.675 13 11 12.34 11 11.5Z" fill="#282828" />
                                                                <path d="M11 17.5C11 16.66 11.675 16 12.5 16C13.325 16 14 16.675 14 17.5C14 18.325 13.325 19 12.5 19C11.675 19 11 18.34 11 17.5Z" fill="#282828" />
                                                                <path d="M11 5.5C11 4.66 11.675 4 12.5 4C13.325 4 14 4.675 14 5.5C14 6.325 13.325 7 12.5 7C11.675 7 11 6.34 11 5.5Z" fill="#282828" />
                                                            </svg>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a className="dropdown-item" onClick={(e) => { setItemId(lines._id) }} data-bs-toggle="modal" data-bs-target="#dropModal">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                                    </svg>
                                                                    <span className="align-middle" style={{ marginRight: "10px" }}>حذف</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </div>
                                                <div className="row">
                                                {lines.content.title_ar && <h4>{(lines.content.title_ar.length > 20) ? lines.content.title_ar.slice(0, 20) + '...' : lines.content.title_ar}</h4>}
                                                </div>
                                                <div className="row mb-2">
                                                {lines.content.address && lines.content.address.subCommunity ? <h5>{lines.content.address.subCommunity ? lines.content.address.subCommunity :""}, {lines.content.address.Community ? lines.content.address.Community: ""}, {lines.content.address.city ? lines.content.address.city : ""}</h5> :<></>}
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-2">
                                                        <span className="version">نسخة : <p> {lines.version ? lines.version : ""}</p></span>
                                                    </div>
                                                    <div className="col mb-2">
                                                        <span className="version" style={{ float: 'left' }}>	ناشر : <p> {lines.status && lines.status.published && lines.status.published == true ? "صحيح" : "خطأ"}</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    }
                                    {dataValue?.total == 0 && <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row mb-2 mt-2">
                                                <h3 className="empty">لا توجد عناصر</h3>
                                            </div>
                                        </div>
                                    </div>}
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <Pagination count={page} variant="outlined" shape="rounded" onChange={handleChangePagination} />
                                        </ThemeProvider>
                                    </CacheProvider>
                                </div>
                            </div>
                            <div className="modal fade" id="dropModal" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel1">تأكيد</h5>

                                        </div>
                                        <div className="modal-body">
                                            <p>هل أنت متأكد أنك تريد حذف هذا البند؟</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-secondary" style={{ float: "right" }} data-bs-dismiss="modal">
                                                لا
                                            </button>
                                            <button type="button" data-bs-dismiss="modal" style={{ float: "right" }} className="btn btn-danger" onClick={drop}>نعم</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="filterModal" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel1">الفلاتر</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row g-2">
                                            <div className="col-md-6">
                                                <label htmlFor="portalSelect" className="form-label">بوابة</label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.id}
                                                                onChange={handleSelectChangePortal}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>
                                                                {dropdownOption.length === 0 ? (<div> </div>) : dropdownOption.id.map((portal: any, index: any) => (
                                                                    <MenuItem key={portal} value={portal}>
                                                                        {portal}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                                <label htmlFor="brokerSelect" className="form-label ">سمسار</label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.broker}
                                                                onChange={handleSelectChangeBroker}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>
                                                                {dropdownOption.length === 0 ? (<div> </div>) : dropdownOption.broker.map((broker: any, index: any) => (
                                                                    <MenuItem key={broker + "_" + index} value={broker}>
                                                                        {broker}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                                <label htmlFor="projectSelect" className="form-label ">مشروع</label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.projectId}
                                                                onChange={handleSelectChangeProject}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>

                                                                {projects.map((project: any) => (
                                                                    <MenuItem key={project._id} value={project._id}>
                                                                        {project.projectName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="BrokerageSelect" className="form-label ">وساطة</label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.brokerage}
                                                                onChange={handleSelectChangeBrokerage}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>

                                                                {dropdownOption.length === 0 ? (<div> </div>) : dropdownOption.company.map((brokerage: any, index: any) => (
                                                                    <MenuItem key={brokerage + "_" + index} value={brokerage}>
                                                                        {brokerage}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                                <label htmlFor="BedroomsSelect" className="form-label ">غرف نوم </label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.bedroomsCount}
                                                                onChange={handleSelectChangeBedrooms}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>

                                                                {dropdownOption.length === 0 ? (<div> </div>) : dropdownOption.bedroomsCount.map((bedroom: any) => (
                                                                    <MenuItem key={bedroom} value={bedroom}>
                                                                        {bedroom}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                                <label htmlFor="LocationSelect" className="form-label ">الموقع</label>
                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={theme}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                className="custom-select mb-3"
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select' }}
                                                                value={filterOptionsValue.location}
                                                                onChange={handleSelectChangeLocation}>
                                                                <MenuItem value="">
                                                                    اختر
                                                                </MenuItem>
                                                                {dropdownOption.length == 0 ? (<div> </div>) : dropdownOption.location.map((location: any, index: any) => (
                                                                    <MenuItem key={location + "_" + index} value={location}>
                                                                        {location}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </ThemeProvider>
                                                </CacheProvider>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <label htmlFor="Minimum Price" className="form-label ">السعر الأدنى  </label>
                                                <input type="text" id="Minimum Price" onChange={handleSelectChangeMinPrice} className="form-control mb-3" placeholder="درهم إماراتي 0" />

                                                <label htmlFor="Minimum Size" className="form-label ">الحد الأدنى للحجم</label>
                                                <input type="text" id="Minimum Size" onChange={handleSelectChangeMinSize} className="form-control " placeholder="0 قدم مربع" />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="Maximum Price" className="form-label ">السعر الأقصى</label>
                                                <input type="text" id="Maximum Price" onChange={handleSelectChangeMaxPrice} className="form-control mb-3" placeholder="درهم إماراتي 0" />

                                                <label htmlFor="Maximum Size" className="form-label ">الحد الأقصى للحجم</label>
                                                <input type="text" id="Maximum Size" className="form-control" onChange={handleSelectChangeMaxSize} placeholder="0 قدم مربع" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="row">
                                            <button type="button" onClick={applyFilters} data-bs-dismiss="modal" className="btn save-modal" style={{ width: "100%" }}>تطبيق الفلاتر</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
export default Items;