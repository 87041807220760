import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import Duplicates from "../Properties/duplicates";
import AdDetails from "../Properties/ad-details";
import PropertyDesc from "../Properties/property-desc";
import { getAgentListings, nextprepropertie, property_update_taken_action } from "../../Services/Properties";
import { width } from "@mui/system";
import DuplicatesManual from "../Properties/duplicate-manual";
import ModerateMain from "./moderate-main";

const Url = process.env.REACT_APP_URl;

const Manuals: React.FunctionComponent<{}> = (props) => {

    const [index, setIndex] = useState(0);
    const [property, setProperty] = useState<any>();
    const [loading, setLoading] = useState(true)
    const [change, setChange] = useState(true)
    const [agentListingsCount, setAgentListingsCount] = useState<any>();

    useEffect(() => {
        nextprepropertie(index).then((data) => {
            setProperty(data);
            setLoading(false);
            if (data.user) {
                getAgentListings(data.user.companyName).then((data) => {
                    setAgentListingsCount(data.count)
                }).catch(() => {
                    console.log("error")
                })
            }
        })

    }, [index, change])


    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate" >
            <ModerateMain change={change} />
            <div className="tab-content">
                {loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...    </div>) :
                    <>{property.content ? <div className="row card-bottom">
                        <div className="col-md-8">
                            <div className="card mb-4 padd-32 slider-mobile">
                                <div className="card-body">
                                    <div className="row" style={{ margin: "10px" }}>
                                        <div className="col-6" style={{ display: "flex", justifyContent: "center" }}><button className="btn btn-danger" style={{ width: "100px", }} disabled={index == 0 ? true : false} onClick={() => { setIndex(index - 1) }}>السابق</button></div>
                                        <div className="col-6" style={{ display: "flex", justifyContent: "center" }}><button className="btn btn-primary" style={{ width: "100px", }} onClick={() => { setIndex(index + 1) }}>التالي</button></div>
                                    </div>

                                    {property.content.images && property.content.images.length!=0 ? <div className="sliderProp">
                                        <ImageGallery items={property.content.images.map((m: any) => ({ "original": m.src, "thumbnail": m.src }))}
                                            showThumbnails={true}
                                            showPlayButton={false}
                                        />
                                    </div>
                                        : <></>}
                                    {property ? <PropertyDesc property={property} /> : <></>}
                                </div>
                            </div>
                            {property.content.floorPlan && property.content.floorPlan.length!=0 && property.content.floorPlan[0].src!=="" ? <div className="card mb-4 padd-32 slider-mobile">
                                <div className="card-body ad-details" >
                                    <h5>مخطط الطابق</h5>
                                    <div className="view_floorplan">
                                        <img src={property.content.floorPlan[0].src} title="floor plan" />
                                    </div>
                                </div>
                            </div> : <></>}
                            {property.content.videoTourUrl && <div className="card mb-4 padd-32 slider-mobile">
                                <div className="card-body ad-details" >
                                    <h5> فيديو العقار</h5>
                                    <div className="view_video">
                                    <iframe src={property.content.videoTourUrl.src} title="360° Virtual tours"> </iframe>
                                    </div>
                                </div>
                            </div>}
                            {property.content.view360 && <div className="card mb-4 padd-32 slider-mobile">
                                <div className="card-body ad-details" >
                                    <h5> عرض 360</h5>
                                    <div className="view_video">
                                        <iframe src={property.content.view360.src} title="360° Virtual tours"></iframe>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="col-md-4">
                            <div className="card padd-24-20 margin-20">
                                <div className="card-body ad-details">
                                    <h5>الحالة :  {property.taken_action_name ? "يدوي" : property.taken_action_name}</h5>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <button className="btn mb-2 badge bg-label-success" onClick={() => { property_update_taken_action(property._id, "approved").then((data) => { setChange(!change) }) }} style={{ height: "44px", width: "100%", padding: "10px 24px 10px 24px" }}>
                                                الموافقة
                                            </button>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="btn mb-2 badge bg-label-danger" onClick={() => { property_update_taken_action(property._id, "refused").then((data) => { setChange(!change) }) }} style={{ height: "44px", width: "100%", padding: "10px 24px 10px 24px" }}>
                                                رفض
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card padd-20">
                                <div className="card-body">
                                    <div className="d-flex desc-agent">
                                        <div className="d-flex align-items-start justify-content-between" >
                                            <img src={property.content.company ? property.content.company.logo.src : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNkSceN7Yy0h33pjiGTEK34jilQGsBGwOMr798npqgg&s"} className="image-agent" />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.4rem" }}>
                                            <div>  <h5>
                                                {property.content.company ? property.content.company.name : ""}
                                            </h5>
                                                <p>{agentListingsCount ? agentListingsCount : " "} قوائم العقارات</p>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="hr"></hr>
                                    <div className=" d-flex agent">
                                        <div className="d-flex align-items-start justify-content-between img-agent " >
                                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ marginLeft: "0.8rem" }} >
                                            <div> <h5>
                                                {property.user ? property.user.name : " "}
                                            </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="hr"></hr>
                                    <div className="row desc-agent">
                                        <div className="col-6">
                                            <p style={{ float: "right", fontSize: "16px", opacity: 0.7 }}>بوابة</p> </div>
                                        <div className="col-6" style={{ justifyContent: "end", display: "flex" }}>
                                            <h5>
                                                {/* {property.source.name} */}
                                            </h5>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <AdDetails property={property} />
                            <Duplicates duplicate={property.found_duplicates} />

                        </div>
                    </div>
                        : <div className="row card-bottom">
                            <div className="col-md-12">
                                <div className="card mb-4 padd-32 slider-mobile">
                                    <div className="card-body">
                                        <h3 className="empty"> لا توجد قوائم</h3>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </>}
            </div>
        </div>

    )
}
export default Manuals;