import React, { useEffect, useState } from "react";
import { GetCompanyProfile, UpdateCompanyProfile } from "../../Services/Profile";
import { CompProfile } from "../../Models/CompProfile";


const CompanyProfile: React.FunctionComponent<{}> = (props) => {

    const [profileValue, setProfileValue] = useState<CompProfile>();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>();

    useEffect(() => {
        GetCompanyProfile().then((data: any) => {

            setProfileValue(data);
            setLoading(false)
        })
        
    }, [])


    const handleChangeCompanyName = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            companyName: event.target.value,
        }));
    }

    const handleChangeCompanyEmail = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            companyEmail: event.target.value,
        }));
    }
    const handleChangeCompanyAddress = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            companyAddress: event.target.value,
        }));
    }
    const handleChangeCompanyPhone = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            companyPhone: event.target.value,
        }));
    }
    const handleChangeDesc = (event: any) => {
        setProfileValue(prevOptions => ({
            ...prevOptions,
            companyDescription: event.target.value,
        }));
    }
    const handleChangeCompanyLogo = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setProfileValue(prevOptions => ({
                ...prevOptions,
                companyLogo: base_64,
            }));
        }
    }

    const Save = () => {
        const id = profileValue?._id;
        const profile = {
            'accountId': profileValue?.accountId,
            'companyLogo': profileValue?.companyLogo,
            'companyName': profileValue?.companyName,
            'companyEmail': profileValue?.companyEmail,
            'companyAddress': profileValue?.companyAddress,
            'companyDescription': profileValue?.companyDescription,
            'companyPhone': profileValue?.companyPhone,
        }
        UpdateCompanyProfile(profile, id).then((data: any) => {
            setIsSuccess(true)
            setShowMessage("Updated Successfully!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("Error Updating Company")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 3000)
    }

    return loading ? (<div> Loading ...</div>) : (
        <>

            <div className="row col-users">
                <div className="col-4">
                    <label htmlFor="Name" className="form-label ">Company Name</label>
                    <input type="text" id="Name" className="form-control mb-3" placeholder="Company name" value={profileValue?.companyName ? profileValue?.companyName : ""} onChange={handleChangeCompanyName} />
                </div>
                <div className="col-4">
                    <label htmlFor="Family Name" className="form-label ">Company Email</label>
                    <input type="email" id="Family Name" className="form-control mb-3" placeholder="Company Email" value={profileValue?.companyEmail ? profileValue?.companyEmail : ""} onChange={handleChangeCompanyEmail} />
                </div>
            </div>
            <div className="row col-users">
                <div className="col-4">
                    <label htmlFor="Phone Number" className="form-label ">Company Phone</label>
                    <input type="text" id="Phone Number" className="form-control mb-3" placeholder="Company Phone" value={profileValue?.companyPhone ? profileValue?.companyPhone : ""} onChange={handleChangeCompanyPhone} />
                </div>

                <div className="col-4">
                    <label htmlFor="Email Address" className="form-label ">Company Address</label>
                    <input type="email" id="Email Address" className="form-control mb-3" placeholder="Company Address" value={profileValue?.companyAddress ? profileValue?.companyAddress : ""} onChange={handleChangeCompanyAddress} />
                </div>
            </div>
            <div className="row col-users">
                <div className="col-8">
                    <label htmlFor="Description" className="form-label ">Description</label>
                    <textarea id="Description" className="form-control mb-3" placeholder="Description" style={{ minHeight: "100px", maxHeight: "110px" }} value={profileValue?.companyDescription ? profileValue?.companyDescription : ""} onChange={handleChangeDesc}></textarea>
                </div>
            </div>
            <div className="row col-users">
                <div className="col-4">
                    <label htmlFor="Company Logo" className="form-label ">Company Logo</label>
                    <div className='profile-upload mb-3'>
                        <div>
                            <img src={profileValue?.companyLogo ? profileValue?.companyLogo : " "} />
                            Portrait.PNG </div>
                        <label htmlFor="Portrait">
                            <input type="file" id="Portrait" style={{ display: 'none' }} accept=" image/*" onChange={handleChangeCompanyLogo} />
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" fill="#282828" />
                                <path d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z" fill="#282828" />
                                <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#282828" />
                            </svg>
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <button type="button" className={`btn btn-primary generate marg-top20 `} onClick={Save} >Save</button>
                </div>
                <div className="col-md-6">
                    {isSuccess && <div className="alert alert-success alert-dismissible marg-top20"  role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible marg-top20"  role="alert">
                        {showMessage}
                    </div>}
                </div>
            </div>
        </>
    )
}
export default CompanyProfile
