import { FunctionComponent, useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { getAgentListings, getPropertiesById } from "../../Services/Properties";
import { useLocation } from "react-router-dom";
import AdDetails from "./ad-details";
import Duplicates from "./duplicates";
import PropertyDesc from "./property-desc";
import DownoaldXML from "./download-xml";
import CopyToClipboard from "react-copy-to-clipboard";
import { GetQRCodeService } from "../../Services/Enhance";
import AgentDetails from "./agent-details";
import RegulatoryInformation from "./regulatory-information";


const PropertyDetails: FunctionComponent<{}> = (props) => {

    let location = useLocation();
    const idValue = location.pathname.split('/');
    const [propertyValue, setPropertyValue] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [agentListingsCount, setAgentListingsCount] = useState<any>();
    const [QrCode, setQrCode] = useState<any>();


    useEffect(() => {
        const id: any = idValue[3];
        getPropertiesById(id).then((data) => {
            setPropertyValue(data)
            setLoading(false)
            getAgentListings(data.user.companyName).then((data) => {
                setAgentListingsCount(data.count)
            })
        }).catch((error) => {
            console.log("error")
        })

        GetQRCodeService(id).then((data) => {
            setQrCode(data)
        }).catch((error) => {
            console.log("error")
        })
    }, [])

    const shareOnFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://share.coralytics.com/property/" + propertyValue._id)}&amp;src=sdkpreparse`;
        window.open(facebookUrl, '_blank');
    };
    const shareOnWhatsapp = () => {
        const facebookUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent("https://share.coralytics.com/property/" + propertyValue._id)}`;
        window.open(facebookUrl, '_blank');
    };


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row card-bottom">
                <div className="col-md-8">
                    <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body">
                            {propertyValue.content.images && propertyValue.content.images.length != 0 ? <div className="sliderProp" style={{ direction: "ltr" }}>
                                <ImageGallery items={propertyValue.content.images.map((m: any) => ({ "original": m.src, "thumbnail": m.src }))}
                                    showThumbnails={true}
                                    showPlayButton={false}
                                />
                            </div> : <div className="sliderProp"></div>}
                            <PropertyDesc property={propertyValue} />
                           <RegulatoryInformation information={QrCode} />
                        </div>
                    </div>
                    {propertyValue.content.floorPlan && propertyValue.content.floorPlan.length>0 && propertyValue.content.floorPlan[0].src !== "" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5>مخطط الطابق</h5>
                            <div className="view_floorplan">
                                <img src={propertyValue.content.floorPlan[0].src} title="floor plan" />
                            </div>
                        </div>
                    </div> : <></>}
                    {propertyValue.content.videoTourUrl && propertyValue.content.videoTourUrl.src !== "" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> فيديو العقار</h5>
                            <div className="view_video">
                                <iframe src={propertyValue.content.videoTourUrl.src} title="360° Virtual tours"> </iframe>
                            </div>
                        </div>
                    </div> : <></>}
                    {propertyValue.content.view360 && propertyValue.content.view360.src !== "" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> عرض 360</h5>
                            <div className="view_video">
                                <iframe src={propertyValue.content.view360.src} title="360° Virtual tours"></iframe>
                            </div>
                        </div>
                    </div> : <></>}
                </div>
                <div className="col-md-4">

                    <div className="card padd-20">
                        <div className="card-body">
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "50%", marginLeft: "5px" }}><DownoaldXML idProperty={idValue[3]} toogle="download-web" /></div>
                                <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-outline-primary download" style={{ width: "50%", marginRight: "5px" }} >
                                    شارك
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <CopyToClipboard text={"https://share.coralytics.com/property/" + propertyValue._id}>
                                            <button className="dropdown-item">
                                                <i className="fa-solid fa-link" style={{ marginLeft: "5px" }} ></i> رابط
                                            </button>
                                        </CopyToClipboard>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={shareOnFacebook}>
                                            <i className="fa-brands fa-facebook" style={{ marginLeft: "5px" }} ></i> فيسبوك
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={shareOnWhatsapp}>
                                            <i className="fa-brands fa-whatsapp" style={{ marginLeft: "5px" }} ></i> واتس اب
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <AgentDetails propertyValue={propertyValue} agentListingsCount={agentListingsCount} />
                    <AdDetails property={propertyValue} />
                    <Duplicates duplicate={propertyValue.found_duplicates} />
                </div>
            </div>
        </div>
    )

}
export default PropertyDetails;