import React from "react";
import Overview from "./overview";
import Projects from "./projects";
import Instances from "./instances";
import Topics from "./topics";
import Insights from "./insights";

const DelightMain: React.FunctionComponent<{}> = (props) => {


    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate">
            <ul className="nav nav-tabs flex-wrap" role="tablist">
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link active"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-overview"
                        aria-controls="navs-top-overview"
                        aria-selected="true"
                    >
                        نظرة عامة
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-insights"
                        aria-controls="navs-top-insights"
                        aria-selected="false"
                    >
                        رؤى
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-instances"
                        aria-controls="navs-top-instances"
                        aria-selected="false"
                    >
                        حالات
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-projects"
                        aria-controls="navs-top-projects"
                        aria-selected="false"
                    >
                        مشاريع
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-topics"
                        aria-controls="navs-top-topics"
                        aria-selected="false"
                    >
                        مواضيع
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="navs-top-overview" role="tabpanel">
                    <Overview />
                </div>
                <div className="tab-pane fade" id="navs-top-insights" role="tabpanel">
                    <Insights />
                </div>
                <div className="tab-pane fade" id="navs-top-instances" role="tabpanel">
                    <Instances />
                </div>
                <div className="tab-pane fade" id="navs-top-projects" role="tabpanel">
                    <Projects />
                </div>
                <div className="tab-pane fade" id="navs-top-topics" role="tabpanel">
                    <Topics />
                </div>
            </div>
        </div>
    );
    
}
export default DelightMain