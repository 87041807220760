import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const RegulatoryInformation: React.FunctionComponent<{ information: any }> = (props) => {
    return (
        <>

            {props.information ? <div className="mt-6">
            <h5 className="mt-3"> {props.information.SectionTitle ? props.information.SectionTitle : ""}</h5>
            <div className="row mt-3">
                <div className="col-md-8">
                    <table className="table-title mt-3">
                        <tbody>
                            <tr>
                                <td className="preview-td">رقم الترخيص : </td>
                                <td className="preview-td">{props.information.PermitNumber ? props.information.PermitNumber :  ""}</td>
                            </tr>
                            <tr>
                                <td className="preview-td">رقم الترخيص (RERA) :</td>
                                <td className="preview-td">{props.information.RERA ? props.information.RERA : ""}</td>
                            </tr>
                            <tr>
                                <td className="preview-td">رقم الترخيص (DED) :</td>
                                <td className="preview-td">{props.information.DED ? props.information.DED : ""}</td>
                            </tr>
                            <tr>
                                <td className="preview-td">رقم تسجيل الوسيط :</td>
                                <td className="preview-td">{props.information.BRN ? props.information.BRN : ""}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-4 d-flex align-items-center">
                    <div className="card" style={{border:"white",width:"150px", height:"150px"}}>
                     {props.information.Qrcode ?  <img src={props.information.Qrcode}/> : <></>}
                    </div>
                </div>
                </div>
            </div> : <></>}
        </>
    )
}
export default RegulatoryInformation;