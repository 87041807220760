import React, { useState } from "react"
import { Autocomplete, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { getPropertieTitelDesc, } from "../../../Services/Properties";

const EnhanceTextSelect: React.FunctionComponent<{ properties: any, onSendDocumentType: any }> = (props) => {

    const [selectProperty, setSelectProperty] = useState<any>("");
    const [idText, setIdText] = useState<any>("");
    const [selectText, setSelectText] = useState<any>("");
    const [refValue, setRefValue] = useState<any>()
    const [textValue, setTextValue] = useState<any>("")
    const [textCopy, setTextCopy] = useState<any>("")
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);

    const handleSelectChangeProperty = (event: any, newValue: any) => {
        if (newValue) {
            setIsDisabledUpload(!isDisabledUpload)
            setRefValue(newValue.ref)
            setSelectProperty(newValue.id);
        }
    }

    const HandleChangeText = (event: any) => {
        setTextCopy(event.target.value);
    }

    const handleSelectChangeText = (event: any) => {
        setSelectText(event.target.value)
        getPropertieTitelDesc(selectProperty).then((data) => {
            if (event.target.value == "desc") {
                setTextValue(data.desc[0].text)
                setIdText(data.desc[0].id)
            } else {
                setTextValue(data.titel[0].text)
                setIdText(data.titel[0].id)
            }
        }).catch(err => {
            console.log("error")
        })
    }

    const Save = () => {
        setTextValue(textCopy)
        setIsDisabledSelect(!isDisabledSelect)
    }
    const Enhance = () => {
        const Obj = {
            "id": 0,
            "text": textValue,
            "type": selectText
        }
        setIsDisabledSelect(false)
        setIsDisabledUpload(false)
        props.onSendDocumentType(Obj);
    };

    return (

        <div className="card">
            <div className="card-body padd-24">
                <label className="form-label" htmlFor="selectTypeOpt">Select Property</label>
                <a type="button" style={{ float: "right", height: "15px", color: "#000000" }}
                    className={[selectProperty != "" ? '' : "disabled", "btn p-0  hide-arrow"].join(" ")}
                    href={`/properties/${selectProperty}`} target="_blank" rel="noopener noreferrer">{refValue}
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>

                <Autocomplete
                    options={props.properties}
                    id="combo-box-d"
                    getOptionLabel={(property: any) => {
                        const bedroomsLabel = property.bedrooms > 1 ? `${property.bedrooms} bedrooms` : `${property.bedrooms} bedroom`;
                        return `${property.ref} | ${property.location} | ${bedroomsLabel}`;
                    }}
                    disablePortal
                    renderInput={(params) => <TextField {...params} onChange={(value) => {
                        setSelectProperty(value.target.value)
                    }} />}
                    onChange={handleSelectChangeProperty}
                />
                <div className="row mt-3">
                    <a id="button-addnew" data-bs-toggle="modal" data-bs-target="#uploadText" className="float-end "><i className="fa-solid fa-upload"></i> Upload Text</a>
                    {textCopy == "" ? <></> : <>
                        <div className="select-text mt-3" style={{ minHeight: "100px", height: "100%", maxHeight: "200px" }}>
                            {textValue}
                        </div>
                    </>}
                </div>

                {selectProperty && <> <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>Select Text</label>
                    <FormControl fullWidth>
                        <Select
                            className={`custom-select`}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select' }}
                            value={selectText}
                            onChange={handleSelectChangeText}>
                            <MenuItem value="" disabled>
                                Select
                            </MenuItem>
                            <MenuItem value={"desc"}>Description</MenuItem>
                            <MenuItem value={"title"}>Title</MenuItem>
                        </Select>
                    </FormControl></>}
                <button type="button" className={`btn btn-primary generate marg-top20 ${textValue || selectText ? '' : 'disabled'}`} onClick={Enhance}>Enhance</button>
            </div>

            <div className="modal fade" id="uploadText" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onChange={() => { setTextValue('') }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ minHeight: "200px" }}>
                                <textarea className="form-control" id="textValue" value={textCopy} onChange={HandleChangeText} >{textCopy}</textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" style={{ float: 'right' }} data-bs-dismiss="modal" onClick={Save}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnhanceTextSelect;