import React, { useEffect, useState } from "react"
import { Stream } from "@cloudflare/stream-react";
import { useNavigate } from "react-router-dom";
import { GetVideos } from "../../../Services/GenerateVideo";

const Template: React.FunctionComponent<{}> = (props) => {

  const [listVideos, setListVideos] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    GetVideos().then((data) => {
      for (let index = 0; index < data.length; index++) {
        const responseData = data[index];
        setListVideos((prevList: any) => [
          ...prevList,
          {
            name_template: responseData.videoname,
            template: responseData.value,
            category: 'اسم الفئة',
            video: responseData.videoid,
          },
        ]);
      }
    }).catch((error) => {
      console.error('Error fetching video:', error);
    })
  }, []);

  const GenerateVideo = (nameTemp: any) => {
    navigate(`/ar/generate/listing-video/${nameTemp.template}/${nameTemp.video}`)
  }

  return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل...  </div>) : (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-2">
        <h5 className="h5-CoraGPT">نماذج الفيديو</h5>
      </div>

      <div className="row card-bottom">
        {listVideos.map((template: any, index: any) => {
          return <div className="col-md-6 mb-3" key={index} >
            <div className="card ">
              <div className="card-body padd-24" >
                <div className="row">
                  <div className="col-md-12" >
                    <Stream controls width="100" height="100" className="custom-stream" autoplay={false} src={template.video} />
                  </div>
                </div>
                <div className="row mt-3 title-template">
                  <div className="col-md-8 ">
                    <h5 className=" mb-1" >{template.name_template}</h5>
                    <p>{template.category}</p>
                  </div>
                  <div className="col-md-4">
                    <button type="button" className="btn btn-primary generate " style={{ padding: "0px", width: "100%" }} onClick={() => GenerateVideo(template)}>إنشاء فيديو</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}

      </div>

    </div>
  )
}
export default Template;