import React, { useState } from "react"
import { getPropertieTitelDesc, } from "../../../Services/Properties";

const SelectTopics: React.FunctionComponent<{ properties: any, onSendDocumentType: any }> = (props) => {

    const [selectProperty, setSelectProperty] = useState<any>("");
    const [idText, setIdText] = useState<any>("");
    const [selectText, setSelectText] = useState<any>("");
    const [textValue, setTextValue] = useState<any>("")
    const [textCopy, setTextCopy] = useState<any>("")
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);
    const [listTopics, setListTopics] = useState<any>([]);
    const [topicValue, setTopicValue] = useState("");

    const HandleChangeText = (event: any) => {
        setTextCopy(event.target.value);
    }

    const handleCreateTopics = (event: any) => {
        setTopicValue(event.target.value);
    }

    const AddTopics = () => {
        setListTopics((prevState: any) => [...prevState, topicValue]);
        setTopicValue("")
    }

    const Save = () => {
        setTextValue(textCopy)
        setIsDisabledSelect(!isDisabledSelect)
    }

    const Understand = () => {
        const Obj = {
            "id": 0,
            "text": textValue,
            "type": selectText,
            'topics': listTopics
        }
        setIsDisabledSelect(false)
        setListTopics([])
        props.onSendDocumentType(Obj);
    };

    return (

        <div className="card">
            <div className="card-body padd-24">
                <div className="row mt-3">
                    <a id="button-addnew" data-bs-toggle="modal" data-bs-target="#uploadText" className={`float-end ${isDisabledUpload ? 'disabled' : ''}`}><i className="fa-solid fa-upload"></i> Upload Text</a>
                    {textValue == '' ? <></> : <>
                        <div className="select-text mt-3" style={{ minHeight: "100px", height: "100%", maxHeight: "200px" }}>
                            {textValue}
                        </div>
                    </>}
                </div>
                <div className="row mt-3">
                    <label className="form-label" htmlFor="topics">Topics</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Topics"
                            aria-label="Topics"
                            value={topicValue}
                            aria-describedby="button-addnew"
                            onChange={handleCreateTopics}
                        />
                        <button className={`btn btn-outline-primary ${topicValue == "" ? 'disabled' : ''}`} onClick={AddTopics} type="button" id="button-addon2">Add</button>
                    </div>
                    {listTopics.length != 0 && <div className="selected-audios mt-3" style={{ height: 'auto', marginBottom: "0px" }}>
                        {listTopics && listTopics.map((topic: any, index: any) => (
                            <div key={index}>
                                <label htmlFor={index} className="">
                                    <span className="topic">{topic}</span>
                                </label>
                            </div>
                        ))}
                    </div>}
                </div>
                <button type="button" className={`btn btn-primary generate marg-top20 ${textValue.length !== 0 && listTopics.length !== 0 ? '' : 'disabled'}`} onClick={Understand}>Understand</button>
            </div>

            <div className="modal fade" id="uploadText" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onChange={() => { setTextCopy(" ") }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ minHeight: "200px" }}>
                                <textarea className="form-control" id="textValue" value={textCopy} onChange={HandleChangeText} >{textCopy}</textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" style={{ float: 'right' }} data-bs-dismiss="modal" onClick={Save}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SelectTopics;