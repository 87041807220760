import { FunctionComponent, useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { getAgentListings, getPropertiesById } from "../../Services/Properties";
import { useLocation } from "react-router-dom";
import { Dialog } from "@mui/material";
import AdDetails from "./ad-details";
import Duplicates from "./duplicates";
import PropertyDesc from "./property-desc";
import DownoaldXML from "./download-xml";
import CopyToClipboard from "react-copy-to-clipboard";
import RegulatoryInformation from "./regulatory-information";
import { GetQRCodeService } from "../../Services/Enhance";
import AgentDetails from "./agent-details";


const PropertyDetails: FunctionComponent<{}> = (props) => {
    let location = useLocation();
    const idValue = location.pathname.split('/');
    const [propertyValue, setPropertyValue] = useState<any>();
    const [QrCode, setQrCode] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [agentListingsCount, setAgentListingsCount] = useState<any>();
    const [iframeSrc, setIframeSrc] = useState<any>();
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        const id: any = idValue[2];
        getPropertiesById(id).then((data) => {
            setPropertyValue(data)
            setLoading(false)
            const queryParams: any = encodeURIComponent(JSON.stringify(data.content.images));
            setIframeSrc(`http://localhost:3000?images=${queryParams}&title=${data.content.title[0].text}&price=${data.content.price.amount[0]}&currency=${data.content.price.currency[0]}&size=${data.content.size.value[0]}&unit=${data.content.size.unit[0]}&bathrooms=${data.content.bathrooms_count}&bedrooms=${data.content.bedrooms_count}&category=${data.content.category.name}`);
            getAgentListings(data.content.company.name).then((data) => {
                setAgentListingsCount(data.count)
            })
        }).catch((error) => {
            console.log("error")
        })
        GetQRCodeService(id).then((data) => {
            setQrCode(data)
        }).catch((error) => {
            console.log("error")
        })
    }, [])

    const shareOnFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://share.coralytics.com/property/" + propertyValue._id)}&amp;src=sdkpreparse`;
        window.open(facebookUrl, '_blank');
    };
    const shareOnWhatsapp = () => {
        const facebookUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent("https://share.coralytics.com/property/" + propertyValue._id)}`;
        window.open(facebookUrl, '_blank');
    };


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row card-bottom">
                <div className="col-md-8">
                    <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body">
                            {propertyValue.content.images && propertyValue.content.images.length!=0 ? <div className="sliderProp">
                                <ImageGallery items={propertyValue.content.images.map((m: any) => ({ "original": m.src, "thumbnail": m.src }))}
                                    showThumbnails={true}
                                    showPlayButton={false} />
                            </div> : <div className="sliderProp"></div>}
                            <PropertyDesc property={propertyValue} />
                            <RegulatoryInformation information={QrCode} />
                        </div>
                    </div>
                    {propertyValue.content.floorPlan && propertyValue.content.floorPlan.length>0 && propertyValue.content.floorPlan[0].src!=="" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> Floor Plan</h5>
                            <div className="view_floorplan">
                            <img src={propertyValue.content.floorPlan[0].src} title="floor plan"/>
                            </div>
                        </div>
                    </div>:<></>}

                    {propertyValue.content.videoTourUrl && propertyValue.content.videoTourUrl.src!=="" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> Property Video</h5>
                            <div className="view_video">
                            <iframe src={propertyValue.content.videoTourUrl.src} title="360° Virtual tours"> </iframe>
                            </div>
                        </div>
                    </div>:<></>}
                    {propertyValue.content.view360 && propertyValue.content.view360.src!=="" ?<div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> View 360</h5>
                            <div className="view_video">
                            <iframe src={propertyValue.content.view360.src} title="360° Virtual tours"> </iframe>
                            </div>
                        </div>
                    </div>:<></>}
                </div>
                <div className="col-md-4">
                    <div className="card padd-20">
                        <div className="card-body">
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "50%", marginRight: "5px" }}><DownoaldXML idProperty={idValue[2]} toogle="download-web" /></div>
                                <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-outline-primary download" style={{ width: "50%", marginLeft: "5px" }} >
                                    Share
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <CopyToClipboard text={"https://share.coralytics.com/property/" + propertyValue._id}>
                                            <button className="dropdown-item">
                                                <i className="fa-solid fa-link" style={{ marginRight: "5px" }} ></i> Link
                                            </button>
                                        </CopyToClipboard>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={shareOnFacebook}>
                                            <i className="fa-brands fa-facebook" style={{ marginRight: "5px" }} ></i> Facebook
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={shareOnWhatsapp}>
                                            <i className="fa-brands fa-whatsapp" style={{ marginRight: "5px" }} ></i> Whatsapp
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <AgentDetails propertyValue={propertyValue} agentListingsCount={agentListingsCount} />
                    <AdDetails property={propertyValue} />
                    <Duplicates duplicate={propertyValue.found_duplicates} />
                </div>
            </div>
            <Dialog
                open={showModal}
                fullWidth={true}
                maxWidth="xl"
                onClose={handleCloseModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <iframe src={iframeSrc} style={{ height: "600px" }} ></iframe>
            </Dialog>
        </div>
    )

}
export default PropertyDetails;