import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Positive', 'Neutral', 'Passive'],
  datasets: [
    {
      // label: '# of Votes',
      data: [30, 30, 40],
      backgroundColor: [
        'rgba(79, 192, 174, 1)',
        'rgba(195, 197, 212, 1)',
        'rgba(255, 111, 97, 1)'
      ],
      borderColor: [
        'rgba(79, 192, 174, 1)',
        'rgba(195, 197, 212, 1)',
        'rgba(255, 111, 97, 1)'
      ],
      borderWidth: 1,
    },
  ],
};
const DonutOverview: React.FunctionComponent<{}> = (props) => {
 
  return (<div className='donut-delight'>
    <div className='score'>{18}</div>

    <Doughnut data={data as any} options={{
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    }} />

    <div style={{ display: 'flex', justifyContent: 'start' }}>
      {data.labels.map((label, index) => (
        <div key={index} style={{ margin: '10px', textAlign: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: data.datasets[0].backgroundColor[index],
              marginRight: '5px',
            }}
          ></span>
          {label}
        </div>
      ))}
    </div>
  </div>

  )
}
export default DonutOverview
