import React, { useEffect, useState } from "react"
import { UploadEmaratesIdService } from "../../../Services/GenerateLease"


const OwnerLessor: React.FunctionComponent<{ onGenerateOwner: any }> = (props) => {

    const [ownerNameValue, setOwnerNameValue] = useState<any>("")
    const [landlordName, setLandlordName] = useState<any>("")
    const [emiratesIDValue, setEmiratesIDValue] = useState<any>("")
    const [licenseNoValue, setLicenseNoValue] = useState<any>( "")
    const [licensingAuthority, setLicensingAuthority] = useState<any>("")
    const [lessorEmail, setLessorEmail] = useState<any>("")
    const [landlordPhone, setLandlordPhone] = useState<any>("")
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {

        const obj = {
            'owner_name': ownerNameValue,
            'lessor_name': landlordName,
            'lessor_emirates_id': emiratesIDValue,
            'lessor_license_no': licenseNoValue,
            'lessor_licensing_authority': licensingAuthority,
            'lessor_email': lessorEmail,
            'lessor_phone': landlordPhone
        }
        props.onGenerateOwner(obj);
    }, [props.onGenerateOwner])

    const handleOwnerName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOwnerNameValue(event.target.value);
    }
    const handleLandlordName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLandlordName(event.target.value);
    }
    const handleEmiratesID = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmiratesIDValue(event.target.value);
    }
    const handleLicenseNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicenseNoValue(event.target.value);
    }
    const handleLicensingAuthority = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicensingAuthority(event.target.value);
    }
    const handleLessorEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLessorEmail(event.target.value);
    }
    const handleLandlordPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLandlordPhone(event.target.value);
    }
    const HandleChangeUpload = (event: any) => {
        const file = event.target.files[0];
    
        if (!file) {
            console.log("No files selected.");
            return;
        }
    
        setIsSpinner(true);
        setIsError(false);
    
        const reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };
    
            UploadEmaratesIdService([image])
                .then((data) => {
                    setIsSpinner(false);
                    setOwnerNameValue(data.data[0].content['name'] ?? "");
                    setLandlordName(data.data[0].content['name'] ?? "");
                    setEmiratesIDValue(data.data[0].content["id_num"] ?? "");
                })
                .catch((err) => {
                    setIsError(true);
                    setIsSpinner(false);
                    console.log("Error downloading file :", err);
                });
        };
    
        reader.onerror = () => {
            setIsError(true);
            setIsSpinner(false);
            console.log("Error reading file.");
        };
    
        setTimeout(() => {
            setIsError(false);
        }, 5000);
    };

    return (
        <>
            {isError && <div className="alert alert-danger alert-dismissible" role="alert">
           فشل في تحميل الملف
            </div>}
            <div className="row mt-4 padd-30 mb-3 ">
                <div className="container_image">
                    <input
                        type="file"
                        id="upload-emi-id"
                        name="upload-emi-id"
                        accept="image/*" onChange={HandleChangeUpload}
                        hidden
                    />
                    <label className="btn-upload" id="button-addnew" htmlFor="upload-emi-id" role="button">
                        <i className="fa-solid fa-upload" style={{ marginLeft: "5px" }}></i>
                       رفع الهوية الإماراتية
                    </label>
                    {isSpinner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginRight: "10px" }} role="status">
                        <span className="visually-hidden">تحميل...</span>
                    </span>}
                </div>
               
            </div>

            <div className="row padd-30 mb-4">
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">اسم المالك</label>
                    <input
                        type="text"
                        className="form-control input mb-3 "
                        value={ownerNameValue}
                        placeholder="اسم المالك"
                        onChange={handleOwnerName}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">اسم صاحب العقار</label>
                    <input
                        type="text"
                        className="form-control input mb-3 "
                        value={landlordName}
                        placeholder="اسم صاحب العقار"
                        onChange={handleLandlordName}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">هاتف صاحب العقار</label>
                    <input
                        type="phone"
                        className="form-control input mb-3 "
                        value={landlordPhone}
                        placeholder="هاتف صاحب العقار"
                        onChange={handleLandlordPhone}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt"> هوية الإمارات للمؤجر</label>
                    <input
                        type="text"
                        className="form-control input mb-3 "
                        value={emiratesIDValue}
                        placeholder="هوية الإمارات للمؤجر "
                        onChange={handleEmiratesID}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">رقم الترخيص (في حالة الشركة)</label>
                    <input
                        type="text"
                        className="form-control input mb-3 "
                        value={licenseNoValue}
                        placeholder="رقم الترخيص "
                        onChange={handleLicenseNo}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">البريد الإلكتروني للمؤجر </label>
                    <input
                        type="email"
                        className="form-control input mb-3 "
                        value={lessorEmail}
                        placeholder="البريد الإلكتروني للمؤجر"
                        onChange={handleLessorEmail}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">جهة الترخيص (في حالة الشركة)</label>
                    <input
                        type="text"
                        className="form-control input mb-3 "
                        value={licensingAuthority}
                        placeholder="جهة الترخيص"
                        onChange={handleLicensingAuthority}
                    />
                </div>

            </div>
        </>
    )
}
export default OwnerLessor;