import React, { useEffect, useState } from "react"
import { Stream } from "@cloudflare/stream-react";
import { RecentVideosService, DeleteRecentVideosService, FetchDownloadLink } from "../../../../Services/GenerateVideo";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const RecentVideos: React.FunctionComponent<{ type: string, Change: any }> = (props) => {

    const [listVideos, setListVideos] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dataLenght, setDataLenght] = useState<any>();
    const [url, setUrl] = useState<any>("");
    const [propertyId, setPropertyId] = useState<any>("");
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [isChange, setIsChange] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [videoValue, setVideoValue] = useState<any>();

    useEffect(() => {
        RecentVideosService(props.type).then((data) => {
            setListVideos(data)
            setDataLenght(data.length)
            setLoading(false)
        }).catch(err => {
            console.log("error")
        });
    }, [props.Change, isChange]);

    const handleDelete = () => {
        DeleteRecentVideosService(videoValue).then((data) => {
            setShowMessage("Deleted successfully")
            setIsChange(!isChange);
            setIsSuccess(true)
        }).catch(err => {
            setShowMessage("Failed to delete Video !")
            setIsError(true)
        });
        setTimeout(() => {
            setIsError(false);
            setIsSuccess(false);
        }, 3000)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, url: any, idProperty: any) => {
        setPropertyId(idProperty);
        setUrl(url);
        setAnchorEl(event.currentTarget);
    }

    const shareOnWhatsapp = (event: React.MouseEvent<HTMLButtonElement>) => {
        FetchDownloadLink(url).then((resp) => {
            const facebookUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent("https://share.coralytics.com/video/" + url + "/" + propertyId)}`;
            window.open(facebookUrl, '_blank');
            setPropertyId("");
            setUrl("");
        })
    };

    const shareOnFacebook = (event: React.MouseEvent<HTMLButtonElement>) => {
        FetchDownloadLink(url).then((resp) => {
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://share.coralytics.com/video/" + url + "/" + propertyId)}&amp;src=sdkpreparse`;
            window.open(facebookUrl, '_blank');
            setPropertyId("");
            setUrl("");
        })
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPropertyId("");
        setUrl("");
    };

    const handleDownload = async (url: any) => {
        FetchDownloadLink(url).then(async (resp) => {
            try {
                const a = document.createElement('a');
                a.href = resp;
                a.download = 'generated_video.mp4';
                a.click();
            } catch (error) {
                console.error('Error Download:', error);
            }
        }).catch((error) => {
            console.error('Error Download:', error);
        })

    };

    return loading ? (<><div className="card mb-3"><div className="card-body">loading...</div></div></>) : (
        <>
            <div className="videos-res">
                <div className="row  ">
                    {isSuccess && <div className="alert alert-success alert-dismissible mb-3" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible mb-3" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-8">
                        <h2 className="marg-res-videos">Recently Generated Video</h2>
                    </div>
                    <div className="col-md-4">
                        <p className={`all marg-res-videos ${dataLenght == 0 ? "disabled" : ""}`} data-bs-toggle="modal" data-bs-target="#browse-all">Browse All</p>
                    </div>

                    {dataLenght !== 0 && <div className="swipper-list">
                        <Swiper
                            slidesPerView={4}
                            //  centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            {listVideos && listVideos.map((template: any, index: any) => (
                                <SwiperSlide>
                                    <div className="video-container-swiper" key={`video${index}`}>

                                        <label className="videos">
                                            <div className="video-wrapper ">
                                                <span className="type">Agent Contact</span>
                                                <span className="autre">
                                                    <svg width="18" height="6" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.25 5.25C1.63125 5.25 1.10156 5.02969 0.660938 4.58906C0.220313 4.14844 0 3.61875 0 3C0 2.38125 0.220313 1.85156 0.660938 1.41094C1.10156 0.970313 1.63125 0.75 2.25 0.75C2.86875 0.75 3.39844 0.970313 3.83906 1.41094C4.27969 1.85156 4.5 2.38125 4.5 3C4.5 3.61875 4.27969 4.14844 3.83906 4.58906C3.39844 5.02969 2.86875 5.25 2.25 5.25ZM9 5.25C8.38125 5.25 7.85156 5.02969 7.41094 4.58906C6.97031 4.14844 6.75 3.61875 6.75 3C6.75 2.38125 6.97031 1.85156 7.41094 1.41094C7.85156 0.970313 8.38125 0.75 9 0.75C9.61875 0.75 10.1484 0.970313 10.5891 1.41094C11.0297 1.85156 11.25 2.38125 11.25 3C11.25 3.61875 11.0297 4.14844 10.5891 4.58906C10.1484 5.02969 9.61875 5.25 9 5.25ZM15.75 5.25C15.1313 5.25 14.6016 5.02969 14.1609 4.58906C13.7203 4.14844 13.5 3.61875 13.5 3C13.5 2.38125 13.7203 1.85156 14.1609 1.41094C14.6016 0.970313 15.1313 0.75 15.75 0.75C16.3687 0.75 16.8984 0.970313 17.3391 1.41094C17.7797 1.85156 18 2.38125 18 3C18 3.61875 17.7797 4.14844 17.3391 4.58906C16.8984 5.02969 16.3687 5.25 15.75 5.25Z" fill="white" />
                                                    </svg>
                                                </span>

                                                <Stream controls autoplay={false} src={template.video_url} className="custom-stream-gener" />
                                                <h1>My Video 1</h1>
                                                <p>03 May 2024</p>

                                            </div>
                                        </label>
                                    </div>
                                </SwiperSlide>))}
                        </Swiper>

                    </div>}
                </div>
            </div>


            <div className="modal fade" id="dropModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">Confirmation</h5>

                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this video?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" style={{ float: "right" }} data-bs-dismiss="modal">
                                No
                            </button>
                            <button type="button" data-bs-dismiss="modal" style={{ float: "right" }} className="btn btn-danger" onClick={handleDelete}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="browse-all" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Recently Generated Video</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="selected-videos-agent  padd-modal">
                                        {listVideos && listVideos.map((template: any, index: any) => (
                                            <div key={`video${index}`} className="video-container">
                                                <label htmlFor={`video${index}`} className="videos">
                                                    <div className="video-wrapper ">
                                                        <span className="type">Agent Contact</span>
                                                        <span className="autre">
                                                            <svg width="18" height="6" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.25 5.25C1.63125 5.25 1.10156 5.02969 0.660938 4.58906C0.220313 4.14844 0 3.61875 0 3C0 2.38125 0.220313 1.85156 0.660938 1.41094C1.10156 0.970313 1.63125 0.75 2.25 0.75C2.86875 0.75 3.39844 0.970313 3.83906 1.41094C4.27969 1.85156 4.5 2.38125 4.5 3C4.5 3.61875 4.27969 4.14844 3.83906 4.58906C3.39844 5.02969 2.86875 5.25 2.25 5.25ZM9 5.25C8.38125 5.25 7.85156 5.02969 7.41094 4.58906C6.97031 4.14844 6.75 3.61875 6.75 3C6.75 2.38125 6.97031 1.85156 7.41094 1.41094C7.85156 0.970313 8.38125 0.75 9 0.75C9.61875 0.75 10.1484 0.970313 10.5891 1.41094C11.0297 1.85156 11.25 2.38125 11.25 3C11.25 3.61875 11.0297 4.14844 10.5891 4.58906C10.1484 5.02969 9.61875 5.25 9 5.25ZM15.75 5.25C15.1313 5.25 14.6016 5.02969 14.1609 4.58906C13.7203 4.14844 13.5 3.61875 13.5 3C13.5 2.38125 13.7203 1.85156 14.1609 1.41094C14.6016 0.970313 15.1313 0.75 15.75 0.75C16.3687 0.75 16.8984 0.970313 17.3391 1.41094C17.7797 1.85156 18 2.38125 18 3C18 3.61875 17.7797 4.14844 17.3391 4.58906C16.8984 5.02969 16.3687 5.25 15.75 5.25Z" fill="white" />
                                                            </svg>
                                                        </span>
                                                        <Stream autoplay={false} src={template.video_url} className="custom-stream-gener" />
                                                        <h1>My Video 1</h1>
                                                        <p>03 May 2024</p>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default RecentVideos;