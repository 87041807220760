import React, { useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectProperty } from "../../../Services/Generate";
import { Stream } from "@cloudflare/stream-react";
import DownloadVideo from "./download-video";
import ShareVideo from "./share-video";
import RecentVideos from "./recent-videos";
import Upgrade from "../../Settings/upgrade";
import { FetchSocialVideo, GenerateVideo, GenrateImageagent } from "../../../Services/GenerateVideo";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Url = process.env.REACT_APP_URl;

const AgentProfile: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>("");
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isSpinnert, setIsSpinnert] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [refValue, setRefValue] = useState<any>()
    const [selectTemplate, setSelectTemplate] = useState<any>("");
    const [tvideoUrl, setTvideoUrl] = useState("");
    const [change, setChange] = useState(false);
    const [curlValue, setCurlValue] = useState<any>("");
    const [inputData, setInputData] = useState<any>("");
    const [isDisabledApi, setIsDisabledApi] = useState<boolean>(true);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });

    }, [])


    const fetchVideo = async (event: any) => {
        try {
            setTvideoUrl("");
            setSelectTemplate(event.target.value)
            setIsSpinnert(true)
            FetchSocialVideo(event.target.value, "_collage").then((response) => {
                if (response.status === 200) {
                    setTvideoUrl(response.data);
                    setIsSpinnert(false)
                } else {
                    console.error('Failed to fetch video');
                }
            })
        } catch (error) {
            console.error('Error fetching video:', error);
        }
    };

    const generate = async () => {
        setIsSpinner(true)
        setVideoUrl("")
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        const p = await GenrateImageagent()
        try {
            if (p) {
                GenerateVideo(p, "agent", selectTemplate, selectProperty, refValue).then((response) => {
                    if (response.status === 200) {

                        setCurlValue(Url + "/generate_video")
                        setInputData(JSON.stringify({ data_images: p.data_images, size: "agent", template_name: selectTemplate, id: selectProperty, ref: refValue }));
                        setChange(true)
                        setIsDisabledApi(false)
                        setVideoUrl(response.data);
                        setIsSpinner(false)


                    } if (response.status === 401) {
                        setIsSpinner(false)
                        setShowMsgUpgrade("لديك ائتمان غير كاف. يرجى النقر فوق «الترقية» لطلب المزيد.")
                        setIsUpgrade(true)
                    }
                }).catch((error: any) => {
                    if (error.response.status === 401) {
                        setIsSpinner(false)
                        setShowMsgUpgrade(error.response.data.message)
                        setIsUpgrade(true)
                    } else {
                        setIsSpinner(false)
                        setShowMessage("Error")
                        setIsError(true)
                    }
                    console.error('Error fetching video:', error);
                })
            }
        } catch (error: any) {
            console.log('error', error)
        }
    };

    const divRef = useRef(null);
    const handleCopyApi = async () => {
        const div: any = divRef.current;
        if (div) {
            try {
                await navigator.clipboard.writeText(div.innerText);
                console.log("Content copied successfully!");
            } catch (err) {
                console.log("Unable to copy content");
            }
        }
    };

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row desc-property">
                <div className="card ">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <div className="title-card">
                                    فيديو ملف الوكيل (قديم)
                                </div>
                            </div>
                            <div className="col-md-5 mb-3 padd-24 search-generate">
                                {/* <SearchProperty /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <RecentVideos type="agent" Change={change} />
            </div>
            {properties == null ? <></> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body padd-24">
                                <label className="form-label" htmlFor="selectTypeOpt" style={{ marginTop: "24px" }}>اختر النموذج</label>
                                <CacheProvider value={cacheRtl}>
                                    <ThemeProvider theme={theme}>
                                        <FormControl fullWidth>
                                            <Select
                                                className="custom-select"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select' }}
                                                value={selectTemplate}
                                                onChange={fetchVideo}>
                                                <MenuItem value="" disabled>
                                                    اختر
                                                </MenuItem>
                                                <MenuItem value={"template_agent1"}>عرض الوكيل</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </ThemeProvider>
                                </CacheProvider>
                                <button type="button" className={`btn btn-primary generate marg-top20 ${(selectTemplate) ? '' : 'disabled'}`} onClick={generate} >أنشئ</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">
                                <div className="row mb-3">
                                    <h5 className="generate-h5">النموذج </h5>
                                    {(isSpinnert && tvideoUrl == "") ?
                                        <div className="card card-image-gener">
                                            <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                                <span className="visually-hidden">تحميل...</span>
                                            </div>  </div> : <div className="card card-image-gener"> {tvideoUrl && (<Stream controls autoplay={true} src={tvideoUrl} />
                                            )}</div>}
                                </div>
                                <div className="row" style={{ marginTop: "16px" }}>
                                    <div className="generate-h5" style={{ height: "30px" }}>الفيديو المُنشئ
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#api" className={`btn mb-2 api-button ${isDisabledApi ? "disabled" : ""}`} style={{ float: "left" }}>
                                            API
                                        </button>
                                        {isUpgrade ? <Upgrade /> : <></>}
                                    </div>
                                    {(isSpinner && videoUrl == "") ?
                                        <div className="card card-image-gener">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="spinner-border text-primary" role="status" style={{ marginRight: '16px', textAlign: 'center' }}>
                                                    <span className="visually-hidden">تحميل...</span>
                                                </div>
                                                <span className="para" style={{ padding: '20px' }}> </span>
                                            </div>
                                        </div> : <div className="card card-image-gener">
                                            {videoUrl == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> : (
                                                <Stream src={videoUrl} controls autoplay={true} />
                                            )}</div>}
                                </div>

                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <DownloadVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                    <ShareVideo videoUrl={videoUrl} isDisabled={isDisabledApi} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="modal fade" id="api" aria-hidden="true">
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-curl">

                                {curlValue && <pre>
                                    <div className="mb-3">
                                        <a id="button-addnew" onClick={handleCopyApi} style={{ float: "right", color: "white", border: " 1px solid #000000" }}><i className="fa-solid fa-copy"></i> Copy</a>
                                    </div>
                                    <code ref={divRef}>
                                        curl '{curlValue}' \<br />
                                        <span style={{ color: 'blue' }}> -H 'content-type:</span> application/json' \<br />
                                        <span style={{ color: 'blue' }}>-H 'Authorization</span>: Bearer {localStorage.getItem("usertoken") + "'"} {'\\'} <br />

                                        --data-raw '{JSON.stringify(inputData)}' \                                        <br />
                                        --compressed
                                    </code>
                                </pre>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgentProfile;