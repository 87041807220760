import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { useSprings, animated, config } from '@react-spring/web';
import { ProfileUser } from "../../../../Models/ProfileUser";
import { useLocation } from "react-router-dom";
import { generateAgentProfile, generateDescScenes } from "../../../../Services/GenerateVideo";
import { GetAccount } from "../../../../Services/Profile";
import 'react-h5-audio-player/lib/styles.css';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { setTimeout } from "timers/promises";
import { Stream } from "@cloudflare/stream-react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const AgentProfileNew2: React.FunctionComponent<{}> = (props) => {


    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [agentValue, setAgentValue] = useState<ProfileUser>();
    const [textValue1, setTextValue1] = useState<any>("With a passion for real estate and a commitment to exceptional service, [Name] have helped numerous clients find their dream homes.");
    const [textValue2, setTextValue2] = useState<any>("“BEST REALTOR”");
    const [textValue3, setTextValue3] = useState<any>("John D.");
    const [textValue4, setTextValue4] = useState<any>("Dubai Marina, Dubai");
    const videoRef = useRef<HTMLDivElement>(null);
    const locationState = useLocation();
    const elements = [
        { className: "v13_7" },
        { className: "v13_10", text: textValue1 },
        { className: "v13_11", text: textValue2 },
        { className: "v15_13", text: agentValue?.name },
        { className: "v13_12", text: textValue4 },
        { className: "v15_14", text: textValue3 }
    ];
    const [springs, api] = useSprings(elements.length, index => ({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: async next => {
            while (1) {
                await next({ opacity: 1, transform: 'translateY(0)' });
                await next({ opacity: 1, transform: 'translateY(0)', delay: 2000 });
                await next({ opacity: 0, transform: 'translateY(-20px)' });
            }
        },
        config: config.molasses,
        delay: index * 500 // Delay each animation by 500ms more than the previous one
    }));
    const [listScenes, setListScenes] = useState<any>([]);
    const [listTemplates, setListTemplates] = useState<any>([]);
    const [listMusics, setListMusics] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSucces, setIsSucces] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [generatedVideo, setGeneratedVideo] = useState<any>();

    useEffect(() => {
        setListTemplates(locationState.state.template);
        setListMusics(locationState.state.music);
        GetAccount().then((data: any) => {
            setAgentValue(data);
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        if (locationState.state.script === "Autogenerate") {
            generateDescScenes({ "scenes": locationState.state.scenes }).then((resp) => {
                setListScenes([{
                    "videoUrl": "https://www.api-v1.coralytics.com/api/images/templates-template_1.mp4",
                    "duration": "30s",
                    "name": "Scene 1",
                    "description": "Description for Scene 1",
                    "_id": "669a43b2b9c2775db687ffad"
                }])
            }).catch((error) => {
                setListScenes([{
                    "videoUrl": "https://www.api-v1.coralytics.com/api/images/templates-template_1.mp4",
                    "duration": "30s",
                    "name": "Scene 1",
                    "description": "Description for Scene 1",
                    "_id": "669a43b2b9c2775db687ffad"
                }])
            })
        } else {
            setListScenes(locationState.state.scenes)
        }

    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleScriptChange = (index: number, newScript: string) => {
        setListScenes((prevScenes: any) => {
            const updatedScenes = [...prevScenes];
            updatedScenes[index] = { ...updatedScenes[index], script: newScript };
            return updatedScenes;
        });
    };

    const handleChangePhotoAgent = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;
            setAgentValue(prevOptions => ({
                ...prevOptions,
                standing_portrait: base_64,
            }));
        }
    }

    const GenerateVideo = () => {

        const transformedScenes = listScenes.map((scene: any) => ({
            src: scene.videoUrl,
            script: scene.script,
            // order: scene.order
        }));

        const transformedTemplates = listTemplates.map((template: any) => ({
            name: template.name,
            images: [agentValue?.standing_portrait],
            texts: [
                textValue1, textValue2, textValue3, textValue4
            ]
        }));

        const transformedMusic = listMusics[0].MusucsUrl;

        // Objet final
        const finalObject = {
            scenes: transformedScenes,
            templates: transformedTemplates,
            music: transformedMusic,
            voiceover: locationState.state.voice_over,
            language: locationState.state.lang,
            type: "post"

        };
        setIsError(false)
        setOpen(true);
        generateAgentProfile(finalObject).then((resp) => {
            setIsSucces(true)
            setGeneratedVideo("3be16e4dd8455b8135cad6bdcad08693")
        }).catch((error) => {
            setIsSucces(false)
            setOpen(false);
            setShowMessage("Failed to Generate Video ")
            setIsError(true)
        })
        // setTimeout(() =>{
        //     setIsError(false);
        // }, 4000);
    }

    return (

        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card agent-profile">
                <div className="card-body padd-40">

                    <div className="row">
                        <div className="col-md-7 mb-3">
                            <h1 className="title-agent">Edit your Scenes</h1>
                            <p className="para-agent">Let’s create some magical videos today</p>
                        </div>
                        <div className="col-md-5 mb-3 d-flex justify-content-end">
                            {/* <button className="create" type="button">
                                Generate Video
                            </button> */}
                        </div>
                    </div>

                    <div className="swipper-list">
                        <Swiper
                            slidesPerView={4}
                            // centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                        ><>
                                {listScenes.map((scene: any, index: any) => (
                                    <SwiperSlide>
                                        <div className="video-container-swiper" key={index} >
                                            <label className="videos">
                                                <div className="video-wrapper ">
                                                    <span className="type">{"Stock Scenes"}</span>
                                                    <span className="autre">
                                                        {scene.order ? scene.order : ""}
                                                    </span>
                                                    <video controls className="custom-stream-gener">
                                                        <source src={scene.videoUrl} type="video/mp4"></source>
                                                    </video>
                                                    <h1>{scene.name}</h1>
                                                    <h1 style={{ left: "auto", right: "15px" }}>{scene.duration}</h1>
                                                    <p>{scene.description}</p>
                                                </div>
                                            </label>
                                            <div className="card-desc">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Enter a script..."
                                                    style={{ minHeight: "100px" }}
                                                    value={scene.script}
                                                    onChange={(e) => handleScriptChange(index, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                {listTemplates.map((template: any, index: any) => (
                                    <SwiperSlide>
                                        <div className="video-container-swiper" key={index}>
                                            <label className="videos">
                                                <div className="video-wrapper ">
                                                    <span className="type">{template.type}</span>
                                                    <video controls className="custom-stream-gener">
                                                        <source src={template.videoUrl} type="video/mp4"></source>
                                                    </video>
                                                    <h1>{template.name}</h1>
                                                    <h1 style={{ left: "auto", right: "15px" }}>{template.duration}</h1>
                                                    <p>{template.description}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </SwiperSlide>))}
                            </>
                        </Swiper>
                    </div>

                    <div className="row mrg-t-50">
                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                            <h2>Video Expected Duration: 40.0s</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5">
                            <div className="image-template">
                                <img src={agentValue?.standing_portrait == "" ? "/assets/images/LogoUser.jfif" : agentValue?.standing_portrait} />
                                <input type="file" id="file-upload" className="replace-input" onChange={handleChangePhotoAgent} />
                                <label htmlFor="file-upload" className="replace-label">Replace</label>
                            </div>

                            <label htmlFor="Name Agent" className="form-label mt-3"> Text 1</label>
                            <textarea className="form-control " placeholder="Enter a description..." style={{ minHeight: "50px" }} value={textValue1} onChange={(event: any) => { setTextValue1(event.target.value) }} />

                            <label htmlFor="Description" className="form-label mt-3">Text 2</label>
                            <textarea className="form-control " placeholder="Enter a description..." style={{ minHeight: "50px" }} value={textValue2} onChange={(event: any) => { setTextValue2(event.target.value) }} />

                            <label htmlFor="Text 1" className="form-label mt-3">Text 3</label>
                            <textarea className="form-control " placeholder="Enter a description..." style={{ minHeight: "50px" }} value={textValue3} onChange={(event: any) => { setTextValue3(event.target.value) }} />

                            <label htmlFor="Text 2" className="form-label mt-3">Text 4</label>
                            <textarea className="form-control " placeholder="Enter a description..." style={{ minHeight: "50px" }} value={textValue4} onChange={(event: any) => { setTextValue4(event.target.value) }} />

                        </div>
                        <div className="col-md-7">
                            <div className="card-image-template" >
                                <div className="v13_8" ref={videoRef}>
                                    <animated.div key={"stable"} className="v13_9" ></animated.div>
                                    {springs.map((styles, index) => {
                                        const element = elements[index];
                                        return element.text ? (
                                            <animated.span key={index} className={element.className} style={styles}>
                                                {element.text}
                                            </animated.span>
                                        ) : (
                                            <animated.img key={index} className="v13_7" src={agentValue?.standing_portrait == "" ? "/assets/images/LogoUser.jfif" : agentValue?.standing_portrait} style={styles}></animated.img>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex align-items-center justify-content-center mt-4">
                        <button className={`create mt-4`} type="button" onClick={GenerateVideo}>
                            Generate Video
                        </button>
                    </div>
                    {isError && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                        {showMessage}
                    </div>}

                </div>
            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                   {isSucces==true && <div className="card-spinner">
                        <Stream src={generatedVideo} width="100%" height="auto" controls muted={true} autoplay={false} />
                    </div>}
                    {isSucces == false && <div className="card-spinner">
                        <CircularProgress color="secondary" />
                        <h1>Video is Being Generated</h1>
                        <p>You will receive a notification when video is Generated</p>
                    </div>}

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default AgentProfileNew2;