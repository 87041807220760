import { SelectProperty } from "../../../Services/Generate";
import ResidentialTypeSelect from "./residential-type-select";
import { useEffect,  useState } from "react";
import { BuildingService } from "../../../Services/Understand";
import JSONView from 'react-json-view';
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";


const BuildingFacility: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setloading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [understandImages, setUnderstandImages] = useState<any>('');
    const [headersValue, setHeadersValue] = useState<any>({ "content-type": "application/json" });
    const [inputData, setInputData] = useState<any>([
        {
            "id": 1,
            "src": "https://www.api-v1.coralytics.com/api/images/66950f8a6d5564984bbab005-1_1.jpeg"
        },
        {
            "id": 2,
            "src": "https://www.api-v1.coralytics.com/api/images/66950f8a6d5564984bbab005-2_1.jpeg"
        }
    ]);
    const [curlValue, setCurlValue] = useState<any>("building_facility");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setloading(false)
        });

    }, [])

  

    const UnderstandImagecHandler = (understandObj: any) => {
        setSelectProperty(understandObj.id)
        setIsSpinner(true)
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        setUnderstandImages('');
        setSelectImages(understandObj.images)
        const understandList: any = []
        for (let index = 0; index < understandObj.images.length; index++) {
            const element = understandObj.images[index];
            var obj = {
                "id": element.id,
                "src": element.src,
                "ext":element.ext

            };
            understandList.push(obj)
        }
        BuildingService(understandList).then((data) => {
            
            setUnderstandImages(data.data)
            
            setIsSpinner(false)
            setShowMessage('تم الفهم بنجاح!')
            setIsSuccess(true)
        }).catch(err => {
            if(err.response.status===401){
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            }else{
                setIsSpinner(false)
                setShowMessage("فشل الحصول على البيانات")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };
 
    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                    مرفق المبنى 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {properties == null ? <>تحميل ...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <ResidentialTypeSelect properties={properties} onSendResidential={UnderstandImagecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                <h5 className="generate-h5">الصورة المحددة</h5>
                                <div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                </div>
                                <div className="generate-h5" style={{ height: "30px" }}>الناتج
                                    {isSpinner && understandImages == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">تحميل...</span>
                                    </div>}
                                     <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade/> :<></>}

                                </div>
                                {understandImages == "" ? <div className="card card-image-gener">
                                <p className="para" style={{padding:"20px"}}>{showMsgUpgrade}</p>
                                </div> :
                                    <div className="card card-image-gener">
                                        <div className="understand-data"><JSONView src={understandImages} /></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}
        
        </div>
    )
}
export default BuildingFacility;