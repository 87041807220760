import React, { useEffect, useState } from "react"
import ContractCreator from "./contract-creator";
import { SelectProperty } from "../../../Services/Generate";
import OwnerLessor from "./owner-lessor";
import TenantInformation from "./tenant-information";
import PropertyIformation from "./property-information";
import ContractInformation from "./contract-information";
import AdditionalTerms from "./additional-terms";
import SearchById from "../../Properties/search-by-id";
import { DropContract, GenerateLeaseAgreementService, GetContractsService } from "../../../Services/GenerateLease";
import Pagination from "@mui/material/Pagination";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import UpdateContract from "./update-contract";

const Url = process.env.REACT_APP_URl;
const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const GenerateLeaseAgreement: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>("")
    const [ownerValue, setOwnerValue] = useState<any>("")
    const [tenantValue, setTenantValue] = useState<any>("")
    const [contractValue, setContractValue] = useState<any>("")
    const [dateValue, setDateValue] = useState<any>("")
    const [dataValue, setDataValue] = useState<any>()
    const [contractId, setContractId] = useState<any>("")
    const [contractNo, setContractNo] = useState<any>("")
    const [propertyUsage, setPropertyUsage] = useState<any>("")
    const [additionalTerms, setAdditionalTerms] = useState<any>("");
    const [propertyIformation, setPropertyIformation] = useState<any>("");
    const [change, setChange] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<number>(1);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [contentContract, setContentContract] = useState<any>([]);
    const [contractIdUpd, setContractIdUpd] = useState<any>("")
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        }).catch((error: any) => {

        })
        GetContractsService(contractId, 1).then((data) => {
            setDataValue(data)
            setLoading(false)
        }).catch((error: any) => {

        })
    }, [change])

    /*************Pagination*********** */

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setIsError(false)
        GetContractsService(contractId, value).then((data) => {
            setPage(Math.ceil(data.total / 8))
            setDataValue(data)
        }).catch(err => {
            setIsError(true)
            setShowMessage("Error");
        })

    };

    const GenerateContractHandler = (property_id: any, contract_date: any, contract_no: any) => {
        setDateValue(contract_date);
        setPropertyUsage(property_id);
        setContractNo(contract_no);
    }

    const GenerateOwnerHandler = (obj: any) => {
        setOwnerValue(obj)
    }

    const TenantInformationHandler = (obj: any) => {
        setTenantValue(obj)
    }

    const PropertyIformationHandler = (obj: any) => {
        setPropertyIformation(obj)

    }

    const ContractInformationHandler = (obj: any) => {
        setContractValue(obj)

    }

    const AdditionalTermsHandler = (obj: any) => {
        setAdditionalTerms(obj)
    }

    const handleSearchById = (idValue: any) => {
        setContractId(idValue)
        setChange(!change)
    }

    const formatDate = (inputDate: any) => {
        if (!inputDate) {
            return "";
        }
        const date = new Date(inputDate);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`
        return formattedDate;
    };

    const view = (lien: any) => {
        window.open(Url + lien, '_blank');
    };

    const drop = () => {
        DropContract(contractIdUpd).then((data) => {
            setShowMessage("تم الحذف بنجاح!")

            setIsSuccess(true)
            setChange(!change)
        }).catch(() => {
            setIsError(true)
            setShowMessage("تم فشل في الحذف!")
        })
        setTimeout(() => {
            setIsError(false);
            setIsSuccess(false);
        }, 3000);
    }


    const UpdateContractHandler = (isSuccessValue : boolean) => {
        if(isSuccessValue){
            setChange(!change)
            setShowMessage("تم التحديث بنجاح!")
            setIsSuccess(true)
        }else{
            setIsError(true)
            setShowMessage("تم فشل في التحديث!")
        }
        setTimeout(() => {
            setIsError(false);
            setIsSuccess(false);
        }, 2000);
    }
          

    const handleDownload = async (fileURL: any, id:any, owner:any, contractNo:any) => {
        try {
            const response = await fetch(Url + fileURL);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}-${owner}-${contractNo}.pdf`);
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const generate = () => {
        setIsSpinner(true)
        setIsSuccess(false)
        setIsError(false)
        const objData: any = [
            {
                "header":
                {
                    'date': formatDate(dateValue),
                    'contract_number': contractNo
                },
                "owner": ownerValue,
                "tenant": tenantValue,
                "property":
                {
                    'property_usage': {
                        propertyUsage
                    },
                    'plot_no': propertyIformation.plot_no,
                    'building_name': propertyIformation.building_name,
                    'property_type': propertyIformation.property_type,
                    'location': propertyIformation.location,
                    'makani_no': propertyIformation.makani_no,
                    'property_no': propertyIformation.property_no,
                    'property_area': propertyIformation.property_area,
                    'premises_no': propertyIformation.premises_no
                },
                "contract": contractValue
            },
            {
                "additional_terms": additionalTerms
            }
        ]
        GenerateLeaseAgreementService(objData).then((data) => {
            setIsSpinner(false)
            setChange(!change)
            setShowMessage("تم الإنشاء بنجاح!")
            setIsSuccess(true) 
        }).catch(() => {
            setIsError(true)
            setShowMessage("تم فشل في الإنشاء!")
        })
        setTimeout(() => {
            setIsError(false);
            setIsSuccess(false);
        }, 3000);
    }

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                منشئ عقد الإيجار
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-12 mb-3 ">
                                    <div className="title-card">
                                        منشئ عقد الإيجار
                                    </div>
                                </div>
                            </div>
                            <ContractCreator contentContract={contentContract} onGenerateContract={GenerateContractHandler} properties={properties} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                معلومات المالك / المؤجر
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        معلومات المالك / المؤجر
                                    </div>
                                </div>
                            </div>
                            <OwnerLessor onGenerateOwner={GenerateOwnerHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                معلومات المستأجر
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        معلومات المستأجر
                                    </div>
                                </div>
                            </div>
                            <TenantInformation onGenerateTenantInformation={TenantInformationHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                معلومات العقار
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        معلومات العقار
                                    </div>
                                </div>
                            </div>
                            <PropertyIformation  onGeneratePropertyIformation={PropertyIformationHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                معلومات العقد
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        معلومات العقد
                                    </div>
                                </div>
                            </div>
                            <ContractInformation contentContract={contentContract} onGenerateContractInformation={ContractInformationHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-md-12">
                    <div className="row disp-lease">
                        <div className="col-md-7 mb-3 ">
                            <div className="title-card">
                                شروط إضافية
                            </div>
                        </div>
                    </div>
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row disp-lease-none">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        شروط إضافية
                                    </div>
                                </div>
                            </div>
                            <AdditionalTerms  onGenerateAdditionalTerms={AdditionalTermsHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row  " >
                <div className="col-md-12" style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className={`btn btn-primary generate marg-top20 mb-4`}
                        style={{ width: "auto" }} onClick={generate} >أنشئ</button>
                    {isSpinner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginRight: "10px" }} role="status">
                        <span className="visually-hidden">تحميل...</span>
                    </span>}
                </div>
            </div>
            <div className="row card-bottom display-property">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="title-card">
                                        عقود مُنشأة
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ padding: "24px 0px 24px 30px" }}>
                                    <SearchById onSearchById={handleSearchById} placeholder="البحث برقم العقد" />
                                </div>
                            </div>
                            {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                                {showMessage}
                            </div>}
                            {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                                {showMessage}
                            </div>}
                            <div className="row " style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <div className="col-md-12 mb-4">
                                    <div className="table-responsive text-nowrap ">
                                        <table className="table" >
                                            <thead>
                                                <tr>
                                                    <th>هوية </th>
                                                    <th>تاريخ الإنشاء</th>
                                                    <th>رقم العقد</th>
                                                    <th>نهاية العقد</th>
                                                    <th>استخدام العقار</th>
                                                    <th>اسم المالك </th>
                                                    <th>اسم المستأجر</th>
                                                    <th>الموقع </th>
                                                    <th>إجراء </th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {dataValue && dataValue?.contracts.map((contract: any, index: any) => {
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{contract.date}</td>
                                                        <td>{contract.contract_id}</td>
                                                        <td>{contract.contract_end}</td>
                                                        <td>{contract.property_usage.propertyUsage.charAt(0).toUpperCase() + contract.property_usage.propertyUsage.slice(1)}</td>
                                                        <td>{contract.owner.charAt(0).toUpperCase() + contract.owner.slice(1)}</td>
                                                        <td>{contract.tenant_name.charAt(0).toUpperCase() + contract.tenant_name.slice(1)} </td>
                                                        <td>{contract.location.charAt(0).toUpperCase() + contract.location.slice(1)}</td>
                                                        <td>
                                                        <UpdateContract content={contract.content} contractId={contract._id}  properties={properties}  onUpdateContract={UpdateContractHandler}/>

                                                            <button type="button" onClick={() => view(contract.pdf)} style={{ marginRight: "10px" }} className="btn p-0  hide-arrow" >
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginRight: "10px" }} onClick={() => handleDownload(contract.pdf,contract._id,contract.owner,contract.contract_id)}>
                                                                <i className="fa-solid fa-download" style={{ width: "16px", height: "16px", color: "#716BE3" }}></i>
                                                            </button>
                                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginRight: "10px" }} onClick={(e) => { setContractIdUpd(contract._id) }} data-bs-toggle="modal"
                                                                data-bs-target="#dropModal">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        {dataValue?.contracts.length > 0 ? <CacheProvider value={cacheRtl}>
                                            <ThemeProvider theme={theme}><Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px", marginBottom: "25px" }} onChange={handleChangePagination} />
                                            </ThemeProvider>
                                        </CacheProvider>
                                            : <h3 className="empty"> لا توجد اي عقود</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rule-list">
                <div className="property-mobile card-bottom">
                    <div className="row mb-3">
                        <div className="col-12 title-card" style={{ paddingLeft: "11px" }}>
                            عقود مُنشأة
                        </div>
                    </div>
                    <div className="row mb-3">
                        <SearchById onSearchById={handleSearchById} placeholder="البحث برقم العقد" />
                    </div>
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}

                    {dataValue && dataValue?.contracts.map((contract: any, index: any) => {
                        return <div className="card mb-3" key={index}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col d-flex align-items-center" >
                                        <h3 className="title">{contract.contract_id}</h3>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn p-0  hide-arrow float-start" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: "pointer" }}>
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 11.5C11 10.66 11.675 10 12.5 10C13.325 10 14 10.675 14 11.5C14 12.325 13.325 13 12.5 13C11.675 13 11 12.34 11 11.5Z" fill="#282828" />
                                                <path d="M11 17.5C11 16.66 11.675 16 12.5 16C13.325 16 14 16.675 14 17.5C14 18.325 13.325 19 12.5 19C11.675 19 11 18.34 11 17.5Z" fill="#282828" />
                                                <path d="M11 5.5C11 4.66 11.675 4 12.5 4C13.325 4 14 4.675 14 5.5C14 6.325 13.325 7 12.5 7C11.675 7 11 6.34 11 5.5Z" fill="#282828" />
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu">
                                        <UpdateContract content={contract.content} contractId={contract._id}  properties={properties}  onUpdateContract={UpdateContractHandler}/>

                                            <li>
                                                <a className="dropdown-item" onClick={() => view(contract.pdf)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                    </svg>
                                                    <span className="align-middle" style={{ marginRight: "10px" }}>عرض</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" onClick={() => handleDownload(contract.pdf,contract._id,contract.owner,contract.contract_id)}>
                                                    <i className="fa-solid fa-download" style={{ width: "24px", height: "18px", color: "#716BE3" }}></i>
                                                    <span className="align-middle" style={{ marginRight: "10px" }}>تحميل </span>
                                                </a>

                                            </li>
                                            <li>
                                                <a className="dropdown-item" onClick={(e) => { setContractIdUpd(contract._id) }} data-bs-toggle="modal"
                                                    data-bs-target="#dropModal">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                    </svg>
                                                    <span className="align-middle" style={{ marginRight: "10px" }}>حذف</span>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <p className="rule-name"> <span className="version float-end" style={{ fontSize: "14px", marginLeft: "5px" }}>المالك : </span>{contract.owner.charAt(0).toUpperCase() + contract.owner.slice(1)}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="rule-name"><span className="version float-end" style={{ fontSize: "14px", marginLeft: "5px" }}>المستأجر : </span>{contract.tenant_name.charAt(0).toUpperCase() + contract.tenant_name.slice(1)}</p>
                                </div>
                                <div className="row date-create">
                                    <div className="col mb-2">
                                        <p className="float-end rule-name">{contract.property_usage.propertyUsage.charAt(0).toUpperCase() + contract.property_usage.propertyUsage.slice(1)}</p>
                                    </div>
                                    <div className="col mb-2">
                                        <p className="float-start">{contract.date}</p>
                                    </div>
                                </div>
                                <div className="row date-create">
                                    <div className="col mb-2">
                                        <p className="float-end rule-name">{contract.location.charAt(0).toUpperCase() + contract.location.slice(1)}</p>
                                    </div>
                                    <div className="col mb-2">
                                        <p className="float-start">{contract.contract_end}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    })
                    }
                    {dataValue?.contracts.length > 0 ? <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme}>
                            <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px", marginBottom: "25px" }} onChange={handleChangePagination} />
                        </ThemeProvider>
                    </CacheProvider>
                        : <div className="card mb-3">
                            <div className="card-body">
                                <div className="row mb-2 mt-2">
                                    <h3 className="empty"> لا توجد اي عقود</h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="modal fade" id="dropModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">تأكيد</h5>

                        </div>
                        <div className="modal-body">
                            <p>هل أنت متأكد أنك تريد حذف هذا العقد؟</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" style={{ float: "right" }} data-bs-dismiss="modal">
                                لا
                            </button>
                            <button type="button" data-bs-dismiss="modal" style={{ float: "right" }} className="btn btn-danger" onClick={drop}>نعم</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default GenerateLeaseAgreement;