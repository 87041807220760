import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, Slider } from "@mui/material";
import { Duplicate } from "../../Models/Duplicate";
import { GetDuplicateService, UpdateDuplicate } from "../../Services/Moderate";
import ModerateMain from "./moderate-main";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


const DuplicateSettings: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [duplicateValue, setDuplicateValue] = useState<Duplicate>({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>();
    const [selectDuplicate, setSelectDuplicate] = useState<any>([]);

    useEffect(() => {
        GetDuplicateService().then((data: any) => {
            setDuplicateValue(data);
            setSelectDuplicate(data.duplicates)
            setLoading(false)
        })
    }, [])

    const handleChangeDuplicateLocation = (event: any) => {
        setDuplicateValue(prevOptions => ({
            ...prevOptions,
            duplicateLocation: event.target.value,
        }));
    }

    const handleChangeDuplicateTime = (event: any) => {
        setDuplicateValue(prevOptions => ({
            ...prevOptions,
            duplicateTime: event.target.value,
        }));
    }

    const handleSelectDuplicate = (type: any) => {
        if (selectDuplicate.includes(type)) {
            setSelectDuplicate(selectDuplicate.filter((selected: any) => selected !== type));
        } else {
            setSelectDuplicate([...selectDuplicate, type]);
        }
    }

    const handleChangeDuplicateScore = (event: any) => {
        setDuplicateValue(prevOptions => ({
            ...prevOptions,
            duplicateScore: event.target.value,
        }));
    }
    const Save = () => {
        const id = duplicateValue?._id;
        const duplicate = {
            "accountId": duplicateValue.accountId,
            "duplicateTime": duplicateValue.duplicateTime,
            "duplicateTimeLimit": duplicateValue.duplicateLocationLimit,
            "duplicateLocation": duplicateValue.duplicateLocation,
            "duplicateLocationLimit": duplicateValue.duplicateLocationLimit,
            "duplicateScore": duplicateValue.duplicateScore,
            "duplicates": selectDuplicate
        }
        UpdateDuplicate(duplicate, id).then((data: any) => {
            setIsSuccess(true)
            setShowMessage("تم التحديث بنجاح!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("حدث خطأ أثناء تحديث التكرار ")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 3000)
    }
    return (
        <div className="container-xxl flex-grow-1 container-p-y moderate" >
            <ModerateMain change={false} />
            <div className="tab-content">
                {loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...    </div>) : <div className=" card-bottom">

                    <div className="card mb-3">
                        <div className="card-body padd-24">
                            <div className="row mb-4">
                                <div className="col-md-12 moderate-title">
                                    إعدادات النسخ
                                </div>
                            </div>
                            {isSuccess && <div className="alert alert-success alert-dismissible mt-4" role="alert">
                                {showMessage}
                            </div>}
                            {isError && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                                {showMessage}
                            </div>}
                            <div className="row col-users">

                                <div className="col-4">
                                    <label htmlFor="Duplicate Location" className="form-label ">تكرار الموقع </label>
                                    {duplicateValue?.duplicateLocation && <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="custom-select mb-3"
                                                    displayEmpty
                                                    onChange={handleChangeDuplicateLocation}
                                                    value={duplicateValue?.duplicateLocation}
                                                    inputProps={{ 'aria-label': 'Select' }}
                                                >
                                                    <MenuItem key={0} value="" disabled>
                                                        اختر
                                                    </MenuItem>

                                                    <MenuItem key={1} value="SubCommunity">
                                                        فرع من المجتمع
                                                    </MenuItem>
                                                    <MenuItem key={2} value="Community">
                                                        مجتمع
                                                    </MenuItem>
                                                    <MenuItem key={3} value="City">
                                                        مدينة
                                                    </MenuItem>

                                                </Select>
                                            </FormControl>
                                        </ThemeProvider>
                                    </CacheProvider>
                                    }
                                </div>

                                <div className="col-4">
                                    <label htmlFor="Duplicate Time" className="form-label "> تكرار الوقت</label>
                                    {duplicateValue?.duplicateTime && <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="custom-select mb-3"
                                                    displayEmpty
                                                    onChange={handleChangeDuplicateTime}
                                                    value={duplicateValue?.duplicateTime}
                                                    inputProps={{ 'aria-label': 'Select' }}
                                                >
                                                    <MenuItem key={0} value="" disabled>
                                                        اختيار
                                                    </MenuItem>

                                                    <MenuItem key={1} value="1 Week">
                                                        أسبوع واحد
                                                    </MenuItem>
                                                    <MenuItem key={2} value="1 Month">
                                                        شهر واحد
                                                    </MenuItem>
                                                    <MenuItem key={3} value="2 Month">
                                                        شهرين
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </ThemeProvider>
                                    </CacheProvider>}
                                </div>
                            </div>

                            <div className="row col-usersmb-3 mt-3">
                                <div className="col-8 overall">
                                    <label htmlFor="Overall Duplicate Score" className="form-label "> مجموع درجات التكرار </label>
                                    {duplicateValue?.duplicateScore && <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}><Slider aria-label="Image Score" style={{ color: "#716BE3", height: "12px" }} valueLabelDisplay="auto" value={duplicateValue?.duplicateScore} max={100} min={0} onChange={handleChangeDuplicateScore} />
                                        </ThemeProvider>
                                    </CacheProvider>}
                                </div>
                            </div>

                            <div className="row col-users ">
                                <div className="col-8 overall">
                                    <div className="form-check form-check-inline mb-3 mt-2" >
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={(event) => { handleSelectDuplicate("images") }} checked={selectDuplicate.includes("images")} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ marginRight: "20px" }}>صور</label>
                                    </div>
                                    <div className="form-check form-check-inline mb-3 mt-2" >
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox2" onChange={(event) => { handleSelectDuplicate("description") }} checked={selectDuplicate.includes("description")} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2" style={{ marginRight: "20px" }}>وصف</label>
                                    </div>
                                    <div className="form-check form-check-inline mb-3 mt-2" >
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox3" onChange={(event) => { handleSelectDuplicate("title") }} checked={selectDuplicate.includes("title")} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox3" style={{ marginRight: "20px" }}>عنوان</label>
                                    </div>
                                    <div className="form-check form-check-inline mb-3 mt-2" >
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox4" onChange={(event) => { handleSelectDuplicate("price") }} checked={selectDuplicate.includes("price")} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox4" style={{ marginRight: "20px" }}>السعر</label>
                                    </div>
                                    <div className="form-check form-check-inline mb-3 mt-2" >
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox5" onChange={(event) => { handleSelectDuplicate("size") }} checked={selectDuplicate.includes("size")} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox5" style={{ marginRight: "20px" }}>الحجم</label>
                                    </div>

                                </div>
                            </div>
                            <button type="button" className="btn btn-primary generate marg-top20" onClick={Save} >حفظ</button>
                        </div>
                    </div>

                </div>}
            </div>
        </div >
    )
}
export default DuplicateSettings


