import React, { useEffect, useState } from "react"
import { FormControl, MenuItem, Select } from "@mui/material";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const ContractCreator: React.FunctionComponent<{ onGenerateContract: any, properties: any, contentContract: any }> = (props) => {
    const [selectContractDate, setSelectContractDate] = useState<any>(props.contentContract[0]?.header?.date ? new Date(props.contentContract[0]?.header?.date) : "");
    const [contractValue, setContractValue] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.header?.contract_number : "");
    const [selectProperty, setSelectProperty] = useState<any>(props.contentContract[0] ? props.contentContract[0]?.property?.property_usage?.propertyUsage : "");

    useEffect(() => {
        props.onGenerateContract(selectProperty, selectContractDate, contractValue);

    }, [props.onGenerateContract]);


    const handleSelectChangeProperty = (event: any) => {
        setSelectProperty(event.target.value);
    }

    const handleSelectContractDate = (date: any) => {
        setSelectContractDate(date)
    }

    const handleContractNo = (event: any) => {
        setContractValue(event.target.value);
    }


    return (
        <>
            <div className="row mb-4 mt-4 padd-30">
                <div className="col-md-4 mb-3 ">
                    <label className="form-label" htmlFor="selectTypeOpt">Contract Date</label>
                    <div>
                        <DatePicker
                            className="form-control input "
                            selected={selectContractDate}
                            onChange={handleSelectContractDate}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Contract Date"
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3 ">
                    <label className="form-label" htmlFor="selectTypeOpt">Contract No.</label>
                    <input
                        type="text"
                        className="form-control input"
                        id="defaultFormControlInput"
                        value={contractValue}
                        placeholder="Contract No."
                        onChange={handleContractNo}
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="selectTypeOpt">Select Property Usage</label>
                    <FormControl fullWidth>
                        <Select
                            className="custom-select"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select' }}
                            value={selectProperty}
                            onChange={handleSelectChangeProperty}>
                            <MenuItem key={0} value="" disabled>
                                Select
                            </MenuItem>
                            < MenuItem key={1} value={"industrial"}>Industrial </MenuItem>
                            < MenuItem key={2} value={"commercial"}>Commercial </MenuItem>
                            <MenuItem key={3} value={"residential"}>Residential</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>
    )
}
export default ContractCreator;