import { FormControl, Select, MenuItem, Checkbox, ListItemText, SelectChangeEvent } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { DownloadXmlProperties, DownloadXmlProperty } from "../../Services/Properties";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});
const DownoaldXML: FunctionComponent<{ idProperty: any, toogle: any  }> = (props) => {

    const [selectedPlatform, setSelectPlatform] = useState<any[]>([]);
    const [platforms, setPlatforms] = useState<any>([{ name: "Coralytics", src: "/assets/images/icons/Coralytics.png" },{ name: "Propcrm", src: "/assets/images/icons/propcrm.png" }, { name: "Propertyfinder", src: "/assets/images/icons/finder.png" }, { name: "Bayut", src: "/assets/images/icons/bayut.png" }, { name: "Dubizzle", src: "/assets/images/icons/dubizzle.png" }]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);


    const handleChangePlatforme = (event: SelectChangeEvent<typeof selectedPlatform>) => {
        const {
            target: { value },
        } = event;
        console.log(event)

        setSelectPlatform(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    /************Download Xml************ */

    const handleDownload = async () => {
        setIsSpinner(true)
        if (props.idProperty) {
            for (let index = 0; index < selectedPlatform.length; index++) {
                const element = selectedPlatform[index];
                const xmldata = await DownloadXmlProperty([element], props.idProperty)
                console.log(xmldata)
                const blob = new Blob([xmldata], { type: 'text/xml' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${"Properties_" + element}.xml`;
                a.click();
                URL.revokeObjectURL(url);
                if (index == selectedPlatform.length - 1) {
                    setSelectPlatform([])
                    setIsSpinner(false)
                }
            }
        } else {
            for (let index = 0; index < selectedPlatform.length; index++) {
                const element = selectedPlatform[index];
                const xmldata = await DownloadXmlProperties([element])
                console.log(xmldata)
                const blob = new Blob([xmldata], { type: 'text/xml' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${"Properties_" + element}.xml`;
                a.click();
                URL.revokeObjectURL(url);
                if (index == selectedPlatform.length - 1) {
                    setSelectPlatform([])
                    setIsSpinner(false)
                }
            }
        }
    };

    return (<>
        <button type="button" className="btn btn-outline-primary download"
            style={{ float: "right" }} data-bs-toggle="modal"
            data-bs-target={`#${props.toogle}`}>تحميل XML
            {isSpinner == true && <div className="spinner-border text-primary" role="status" style={{ marginRight: "10px" }}>
                <span className="visually-hidden">Loading...</span>
            </div>}</button>
 
        <div className="modal fade" id={props.toogle} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">تنزيل ملف XML</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <label htmlFor="projectSelect" className="form-label">اختر المنصة</label>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            style={{ margin: "0 10px 0 10px" }}
                                            multiple
                                            value={selectedPlatform}
                                            onChange={handleChangePlatforme}
                                            renderValue={(selected) => selected.join(', ')}>
                                            {platforms.map((platform: any, index: any) => (
                                                <MenuItem value={platform.name} key={index}>
                                                    <Checkbox checked={selectedPlatform.indexOf(platform.name) > -1} />
                                                    <img src={platform.src} style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }} />
                                                    <ListItemText primary={platform.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                            </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" style={{ float: 'right' }} data-bs-dismiss="modal">
                            غلق
                        </button>
                        <button type="button" onClick={handleDownload} style={{ float: 'right' }} data-bs-dismiss="modal" className="btn btn-primary">تحميل</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default DownoaldXML;
