import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getCircleProgress } from '../../Services/Chart';

ChartJS.register(ArcElement, Tooltip, Legend);
 
const CircleProgressChart: React.FunctionComponent<{ type: any }> = (props) => {

  const [isDataSet, setIsDataSet] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<any>(null);
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    getCircleProgress().then((response) => { 
      if (props.type =="others_score") {
        setIsDataSet(true)
        setIsSpinner(false);
        setIsError(false);
        setPercentage(Math.round(response.currentMonth.totalScore));
      }else if(props.type=="compliance_score"){
        setIsDataSet(true)
        setIsSpinner(false);
        setIsError(false);
        setPercentage(Math.round(response.currentMonth.complianceScore));
      }
      else{
        setIsDataSet(true)
        setIsSpinner(false);
        setIsError(false);
        setPercentage(Math.round(response.currentMonth.qualityScore));
      }
    })
      .catch(err => {
        setIsError(true);
        setErrorMessage("Error");
      })
  }, [])

  const gradient = React.useMemo(() => {
    const ctx: any = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(160, 10, 170, 70);
    gradient.addColorStop(0, 'rgba(70, 180, 158, 1)');
    gradient.addColorStop(1, 'rgba(211, 70, 69, 1)');
    return gradient;
  }, []);

  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [gradient, 'rgba(195, 197, 212, 1)'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="donut-chart-container">
      {isSpinner == true && isError == false && <div style={{ marginTop: "50px", textAlign: "center" }}><div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
        <span className="visually-hidden">Loading...</span>
      </div> </div>}
      {isError == true && <h3 style={{ color: 'red', marginTop: "40px",fontSize:"20px", textAlign: "center" }}> {errorMessage}</h3>}
      {isSpinner == false && isDataSet == true && <> <Doughnut data={data} />
        <div className="score-number">{percentage}%</div> </>}
    </div>
  );
};

export default CircleProgressChart;
