import axios from "axios";

const Url = process.env.REACT_APP_URl;


export const GenerateLeaseAgreementService = async (generateObj: any) => {
  const apiUrl = Url + "/generatelease";
  const response = await axios.post(apiUrl, generateObj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const UpdateContractsService = async (generateObj: any,id:any) => {
  const apiUrl = Url + "/contracts";
  const response = await axios.put(`${apiUrl}/${id}`, generateObj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetContractsService = async (id: any, page: any) => {
  const apiUrl = Url + "/contracts";
  const obj = { "page": page, "perPage": 8, "contract_id": id }
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
export const UploadEmaratesIdService = async (obj: any) => {
  const apiUrl = Url + "/emarates_id";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
export const UploadTitleDeedService = async (obj: any) => {
  const apiUrl = Url + "/titel_deed";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
export const UploadTermsService = async (obj: any) => {
  const apiUrl = Url + "/upload_terms";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}


export const DropContract = async (id: any) => {
  const apiUrl = Url + "/contracts";
  const response = await axios.delete(`${apiUrl}/${id}`, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}