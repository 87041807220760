import React, { useEffect, useState } from "react"
import { UploadTitleDeedService } from "../../../Services/GenerateLease"


const PropertyIformation: React.FunctionComponent<{ onGeneratePropertyIformation: any }> = (props) => {

    const [plotNoValue, setPlotNoValue] = useState<any>("")
    const [location, setLocation] = useState<any>("")
    const [buildingName, setBuildingName] = useState<any>("")
    const [propertyNo, setPropertyNo] = useState<any>("")
    const [propertyType, setPropertyType] = useState<any>("")
    const [propertySize, setPropertySize] = useState<any>("")
    const [makaniNo, setMakaniNo] = useState<any>("")
    const [premisesNo, setPremisesNo] = useState<any>("")
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);


    useEffect(() => {
        const obj = {
            'plot_no': plotNoValue,
            'building_name': buildingName,
            'property_type': propertyType,
            'location': location,
            'makani_no': makaniNo,
            'property_no': propertyNo,
            'property_area': propertySize,
            'premises_no': premisesNo
        }
        props.onGeneratePropertyIformation(obj);
    }, [props.onGeneratePropertyIformation])

    const handlePlotNo = (event: any) => {
        setPlotNoValue(event.target.value);
    }
    const handleLocation = (event: any) => {
        setLocation(event.target.value);
    }
    const handleBuildingName = (event: any) => {
        setBuildingName(event.target.value);
    }
    const handlePropertyNo = (event: any) => {
        setPropertyNo(event.target.value);
    }
    const handlePropertyType = (event: any) => {
        setPropertyType(event.target.value);
    }
    const handlePropertySize = (event: any) => {
        setPropertySize(event.target.value);
    }
    const handleMakaniNo = (event: any) => {
        setMakaniNo(event.target.value);
    }
    const handlePremisesNo = (event: any) => {
        setPremisesNo(event.target.value);
    }

    const HandleChangeUpload = (event: any) => {

        setIsSpinner(true);
        setIsError(false);

        const file = event.target.files[0]

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const ext = file.type.split("/")[1];
            const image = {
                id: 1,
                src: base64,
                ext: ext
            };
            UploadTitleDeedService([image]).then((data) => {
                setIsSpinner(false)
                setPlotNoValue(data.data[0].content['Plot No'] ? data.data[0].content['Plot No'] : "");
                setBuildingName(data.data[0].content['Building Name'] ? data.data[0].content['Building Name'] : "");
                setPropertyNo(data.data[0].content['Property No'] ? data.data[0].content['Property No'] : "")
                setPropertyType(data.data[0].content['Property Type'] ? data.data[0].content['Property Type'] : "")
                setLocation(data.data[0].content['Community'] ? data.data[0].content['Community'] : "")
                setPropertySize(data.data[0].content['Area Sq Meter'] ? data.data[0].content['Area Sq Meter'] : "")
            })
                .catch((err) => {
                    setIsError(true);
                    setIsSpinner(false);
                    console.log("Error downloading file :", err);
                });
        };

        reader.onerror = () => {
            setIsError(true);
            setIsSpinner(false);
            console.log("Error reading file");
        };

        setTimeout(() => {
            setIsError(false);
        }, 5000);
    };


    return (
        <>
            {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                Upload failed
            </div>}
            <div className="row mb-3 mt-4 padd-30">
                <div className="container_image">
                    <input
                        type="file"
                        id="upload-title"
                        name="upload-title"
                        accept="image/*" onChange={HandleChangeUpload}
                        hidden
                    />
                    <label className="btn-upload" id="button-addnew" htmlFor="upload-title" role="button">
                        <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                        Upload Title Deed
                    </label>
                    {isSpinner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>}
                </div>

            </div>

            <div className="row padd-30 mb-4 ">
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt" >Plot No.</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={plotNoValue}
                        placeholder="Plot No."
                        onChange={handlePlotNo}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt" >Property Type</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={propertyType}
                        placeholder="Property Type"
                        onChange={handlePropertyType}
                    />

                    <label className="form-label" htmlFor="selectTypeOpt" >Location</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={location}
                        placeholder="Location"
                        onChange={handleLocation}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt" >Building Name</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={buildingName}
                        placeholder="Building Name"
                        onChange={handleBuildingName}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt" >Property No.</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={propertyNo}
                        placeholder="Property No."
                        onChange={handlePropertyNo}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt" >Premises No (DEWA)</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={premisesNo}
                        placeholder="Premises No"
                        onChange={handlePremisesNo}
                    />
                </div>
                <div className="col-md-4 ">

                    <label className="form-label" htmlFor="selectTypeOpt" >Makani No.</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={makaniNo}
                        placeholder="Makani No."
                        onChange={handleMakaniNo}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt" >Property Size (S.M)</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={propertySize}
                        placeholder="Property Size"
                        onChange={handlePropertySize}
                    />
                </div>

            </div>
        </>
    )
}
export default PropertyIformation;