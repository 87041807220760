import { SelectProperty } from "../../../Services/Generate";
import { useEffect, useState } from "react";
import { DocumentContentService } from "../../../Services/Understand";
import DcontentSelect from "./document-contentselect";
import JSONView from 'react-json-view';
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";


const DocumentType: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [loading, setloading] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [typeText, setTypeText] = useState<any>("وثيقة ");
    const [documentTypeData, setDocumentTypeData] = useState<any>("");
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>([
        {
            "id": 1,
            "src": "https://www.api-v1.coralytics.com/api/images/65958570e6cce9e9935689d1-0.jpeg",
        }
    ]);
    const [curlValue, setCurlValue] = useState<any>("detect_card_content");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setloading(false)
        })
    }, []);




    const UnderstandImagecHandler = (understandObj: any) => {
        setIsSpinner(true)
        setDocumentTypeData("")
        setIsUpgrade(false)
        setShowMsgUpgrade("")
        setSelectImages(understandObj.images)
        const understandList: any = []
        for (let index = 0; index < understandObj.images.length; index++) {
            const element = understandObj.images[index];
            var obj = {
                "id": element.id,
                "src": element.src,
                "ext": element.ext
            };
            understandList.push(obj)
        }
        DocumentContentService(understandList).then((data) => {
            
            
            setDocumentTypeData(data.data)
            setIsSpinner(false)
            setShowMessage('تم الفهم بنجاح!')

            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("فشل الحصول على البيانات")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };



    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        رخصة القيادة - المحتوى (الإمارات)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {properties == null ? <>تحميل ...</> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <DcontentSelect properties={properties} onSendResidential={UnderstandImagecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                <h5 className="generate-h5">{typeText}</h5>
                                <div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                </div>
                                <div className="generate-h5" style={{ height: "30px" }}>الناتج
                                    {isSpinner && documentTypeData == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">تحميل...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}

                                </div>
                                {documentTypeData == "" ? <div className="card card-image-gener">
                                    <p className="para" style={{ padding: "20px" }}>{showMsgUpgrade}</p>
                                </div> :
                                    <div className="card card-image-gener">
                                        <div className="understand-data"><JSONView src={documentTypeData} /></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}
export default DocumentType;