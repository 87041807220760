import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, Checkbox, FormControlLabel, Pagination } from "@mui/material";
import { AgentConsumption, GetAgentLogs, GetCreditScoreAgent, UpgradeCredit } from "../../Services/Account";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Credit from "./display_credit";
import Pricing from "./pricing";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const CreditConsumptionUser: React.FunctionComponent<{user :any}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [grouped, setGrouped] = useState(false);
    const [credit, setCredit] = useState([]);
    const [platform, setPlatform] = useState("All");
    const [open, setOpen] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [consumption, setConsumption] = useState("");
    const [creditScore, setCreditScore] = useState("");

    useEffect(() => {
        GetAgentLogs(platform, grouped, 1,props.user._id).then((data: any) => {
            setPage(Math.ceil(data.total / 8))
            setCredit(data.userLog);
            setIsSpinner(false)
            setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        GetCreditScoreAgent(props.user._id).then((data: any) => {
            setCreditScore(data.creditScore);
            // setLoading(false)
        }).catch(() => {
            console.log("error")
        })
        AgentConsumption(props.user._id).then((data: any) => {
            setConsumption(data.totalConsumedScore);
            // setLoading(false)
        }).catch(() => {
            console.log("error")
        })

    }, [change, grouped,props.user._id])

    const handleClickOpen = () => {
        UpgradeCredit().then((data: any) => {
            setOpen(true);
        }).catch(error => {
            console.log("error")
        })
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGrouped(event.target.checked);
    };

    const convertDate = (date: any) => {
        const date_ = date.split("T");
        const dateNew = date_[1].split(":");
        const dateValue = (date_[0].replace(/-/g, '/')) + ", " + dateNew[0] + ":" + dateNew[1]
        return dateValue;
    }
    const handleChangePlatforms = (event: any) => {
        setPlatform(event.target.value);
        setChange(!change)
    }

    /*************Pagination*********** */

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        GetAgentLogs(platform, grouped, value,props.user._id).then((data: any) => {
            setPage(Math.ceil(data.total / 8))
            setCredit(data.userLog)
        }).catch(err => {
            console.log("Error");
        })

    };

    return loading ? (<div> Loading ...</div>) : (
        <>
        
            <div className="card-credit">
                <div className="row padd-20">
                    <div className="col-3 ">
                        <h3 className="number-credit">{creditScore ? <Credit myFloatNumber={parseFloat(creditScore)}></Credit> : "0"}</h3>
                        <p>Credits Available</p>
                    </div>
                    <div className="col-3 ">
                        <h3 className="number-credit">{props.user.acquiredCredit ? props.user.acquiredCredit : 0 }</h3>
                        <p>Acquired Credit</p>
                    </div>
                  
                </div>
            </div>

            <div className="row col-users">
                <div className="col-8">
                    <div className="credit-title">
                        Spending History
                        <p>You Spent {consumption ? <Credit myFloatNumber={parseFloat(consumption)}></Credit> : "0"} Credits in last 30 days. </p>
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <FormControl fullWidth>
                        <Select
                            className="custom-select marg-credit"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Category' }}
                            value={platform}
                            onChange={handleChangePlatforms}
                        >
                            <MenuItem key={0} value="" disabled>
                                Select
                            </MenuItem>
                            <MenuItem key={11} value={"All"}>All </MenuItem>
                            <MenuItem key={1} value={"Moderate"}>Moderate </MenuItem>
                            <MenuItem key={14} value={"Delight"}>Delight </MenuItem>
                            <MenuItem key={2} value={"Understand"}>Understand</MenuItem>
                            <MenuItem key={3} value={"Enhance"}>Enhance</MenuItem>
                            <MenuItem key={4} value={"Generate"}>Generate</MenuItem>
                            <MenuItem key={5} value={"Monitor"}>Monitor</MenuItem>
                        </Select>

                        <FormControlLabel style={{ marginLeft: "5px" }} control={<Checkbox
                            checked={grouped}
                            onChange={handleChange}

                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Group by Cards" />
                    </FormControl>

                </div>
            </div>
            <div className="table-responsive text-nowrap display-property">
                <hr className="hr" style={{ marginBottom: "0px", marginTop: "20px" }}></hr>
                <table className="table" style={{ minHeight: "150px" }}>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Category</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {credit ? credit.map((obj: any, index: any) => {
                            return <tr key={"line_" + index}>
                                <td>
                                    {convertDate(obj.action_datetime)}
                                </td>
                                <td>
                                    {obj.action_name}
                                </td>
                                <td>
                                    <Credit myFloatNumber={parseFloat(obj.score_consumed)}></Credit>
                                </td>
                            </tr>
                        }) : <></>}
                    </tbody>
                </table>
                {isSpinner == true && <div className="d-flex justify-content-center">
                    <div className="col-auto">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                }
                {isSpinner == false && <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />}

                {credit.length == 0 && isSpinner == false && <h3 className="empty" > The list is empty</h3>}
                {/* {credit.length !== 0 && credit.length > 4 && <button type="button" className="btn btn-outline-primary button-rule mt-3 maxwidth-credit" >View More</button>} */}
            </div>
            <div className="rule-list">
                <div className="property-mobile" style={{ marginRight: "11px" }}>
                    {credit && credit.map((obj: any, index: any) => {
                        return <div className="card mb-3" key={index}>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col d-flex align-items-center" >
                                        <h3 className="title" style={{ fontSize: "20px" }}>{obj.score_consumed}</h3>
                                    </div>
                                </div>
                                <div className="row date-create" >
                                    <div className="col mb-3">
                                        <p className="float-start">{obj.action_name}</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="float-end"> {convertDate(obj.action_datetime)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    {isSpinner == true && <div className="d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    }
                    {credit.length == 0 && isSpinner == false && <h3 className="empty" > The list is empty</h3>}
                    {/* {credit.length !== 0 && <button type="button" className="btn btn-outline-primary button-rule mt-3 mb-3 maxwidth-credit" >View More</button>} */}

                </div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Upgrade Request Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Your upgrade request has been successfully registered. A member of our team will reach out shortly !
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CreditConsumptionUser
