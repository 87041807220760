import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react"
import { createProject, getProjects } from "../../../Services/Projects";

const UploadAudioTopics: React.FunctionComponent<{ onSendAudio: any }> = (props) => {

    const [selectedProjectId, setSelectedProjectId] = useState<any>("");
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [projectValue, setprojectValue] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [upload, setUpload] = useState(true);
    const [listAudios, setListAudios] = useState<any>([]);
    const [listTopics, setListTopics] = useState<any>([]);
    const [topicValue, setTopicValue] = useState("");

    useEffect(() => {
        const type : any="audio";
        getProjects(type).then((data) => {
            setProjects(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        });
    }, [change]);

    const handleFileChange = (event: any) => {
        const files: any = Array.from(event.target.files);
        setListAudios([])
        setSelectedFiles(files);
        setUpload(false)
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (event) => {
                setListAudios((prevSelectedAudios: any) =>
                    [...prevSelectedAudios, event?.target?.result]
                );
            };
        }
    };

    const handleSelectChangeProject = (event: any) => {
        setSelectedProjectId(event.target.value)
    }

    const handleCreateProject = (event: any) => {
        setprojectValue(event.target.value);
    }
    
    const handleCreateTopics =(event :any)=>{
        setTopicValue(event.target.value);
    }

    const AddTopics =()=>{
        setListTopics((prevState : any) => [...prevState, topicValue]);
        setTopicValue("")
    }

    const Understand = () => {
        const understandObj = {
            "audio": selectedFiles
                }
        const formData = new FormData();
        formData.append('project', selectedProjectId);
        formData.append('topics', listTopics);
        selectedFiles.forEach((file: any) => {
            formData.append(`audio`, file);
        });
        // setSelectedFiles([]);
        setListTopics([])
        // setSelectedProjectId ("")
        // setListAudios([])
        props.onSendAudio(formData, understandObj);
        
    };

    const create = () => {
        const project = {
            "accountId": "64c8ef881cfccb7ae502c64e",
            "projectName": projectValue,
            "projectType": "audio"
        }
        createProject(project).then((data) => {
            setChange(!change)
        }).catch(err => {
            console.log("Error");
        })

    }

    return loading ? <div className="card"></div> : (
        <div className="card">
            <div className="card-body padd-24">
                <label className="form-label" htmlFor="selectTypeOpt">Select Project</label>
                <FormControl fullWidth>
                    <Select
                        className="custom-select"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Choose' }}
                        value={selectedProjectId}
                        onChange={handleSelectChangeProject}
                    >
                        <MenuItem value="" disabled>
                            Select Project
                        </MenuItem>
                        {projects.map((project: any) => (
                            <MenuItem value={project._id} key={project._id}>{project.projectName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="row mt-3">
                <label className="form-label" htmlFor="topics">Topics</label>
                <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Topics"
                            aria-label="Topics"
                            value={topicValue}
                            aria-describedby="button-addnew"
                            onChange={handleCreateTopics}
                        />
                        <button className={`btn btn-outline-primary ${topicValue == "" ? 'disabled' : ''}`} onClick={AddTopics} type="button" id="button-addon2">Add</button>
                    </div>
                    {listTopics.length !=0 && <div className="selected-audios mt-3" style={{ height: 'auto' ,marginBottom:"0px"}}>
                                {listTopics && listTopics.map((topic: any, index: any) => (
                                    <div key={index}>
                                        <label htmlFor={index} className="">
                                            <span className="topic">{topic}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>}
                    </div>
                <div className="row mt-3">
                <div style={{display: "flex", alignItems: "center"}}>
                        <a className=" mb-3" id="button-addnew" onClick={(e) => { setShowInput(!showInput) }}><i className="fa-solid fa-plus"></i> Create New</a>
                        <div className="container_image mb-3" style={{marginLeft:"20px"}}>
                        <input
                            type="file"
                            id="input-file"
                            name="input-file"
                            accept="audio/*"
                            onChange={handleFileChange}
                            hidden
                        />
                        <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                            <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                            Upload Audio
                        </label>
                    </div>
                    </div>
                    {upload ? <></>
                        : <>
                            <div className="selected-audios" style={{ height: 'auto' }}>
                                {listAudios && listAudios.map((audio: any, index: any) => (
                                    <div key={index}>
                                        <input type="checkbox" id={index} value={index} />
                                        <label htmlFor={index} className="">
                                            <audio controls >
                                                <source src={audio} type="audio/mp3" key={index} />
                                            </audio>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </>}
                    {showInput && <div className="input-group  mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Create a project"
                            aria-label="Create a project"
                            aria-describedby="button-addnew"
                            onChange={handleCreateProject}
                        />
                        <button className="btn btn-outline-primary" onClick={create} type="button" id="button-addon2">Create</button>
                    </div>}
                  
                </div>
                
        <button type="button"  className={`btn btn-primary generate marg-top20 ${selectedFiles.length !== 0 && listTopics.length !==0 && selectedProjectId !== "" ? "" : "disabled"}`} onClick={Understand} >Understand</button> 
               
            </div>
        </div>
    )
}
export default UploadAudioTopics;