import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import DonutDelight from "../Charts/donut-delight";
import BarChart from "../Charts/bar-chart";
import { getProjects } from "../../Services/Projects";
import LineChartDelight from "../Charts/line-chart-delight";

const Insights: React.FunctionComponent<{}> = (props) => {

    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const type: any = "text";
        getProjects(type).then((data) => {
            setProjects(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        });
    }, [])

    const handleSelectChangeProject = (event: any) => {
        setSelectedProjectId(event.target.value);
    };

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (<div className="card-bottom">
        <div className="row row-over">
            <div className=" col-md-6 mb-3 moderate-title">
                Insights            </div>
            <div className="col-md-2 mb-3">
                <FormControl fullWidth>
                    <Select
                        className="custom-select"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Choose' }}
                    >
                        <MenuItem value="" disabled>
                            Select Date
                        </MenuItem>

                    </Select>
                </FormControl>
            </div>
            <div className="col-md-2 mb-3">
                <FormControl fullWidth>
                    <Select
                        className="custom-select"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Choose' }}>
                        <MenuItem value="" disabled>
                            Select Insights
                        </MenuItem>
                        <MenuItem value="emotion" >
                            Emotion
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-2 mb-3">
                <FormControl fullWidth>
                    <Select
                        className="custom-select"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Choose' }}
                        value={selectedProjectId}
                        onChange={handleSelectChangeProject}
                    >
                        <MenuItem value="" disabled>
                            Select Project
                        </MenuItem>
                        {projects.map((project: any) => (
                            <MenuItem value={project._id} key={project._id}>{project.projectName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-4  mb-3">
                <div className="card padding-chart height-chart-line-2">
                    <div className="card-body">
                        <div className="row mb-3">
                            <h3 className="title-charts">Net Sentiments Score</h3>
                        </div>
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DonutDelight />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8  mb-3">
                <div className="card padding-chart height-chart-line-2">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-md-9 ">
                                <h3 className="title-charts">Sentiment over time</h3>
                            </div>
                            <div className="col-md-3 display-select">
                                <FormControl fullWidth>
                                    <Select
                                        className="custom-select"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Sort By' }}
                                        value={"Sort By"}>
                                        <MenuItem value="" disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2022}>2022</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            < LineChartDelight />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-5 mb-3">
                <div className="card padding-chart height-chart-line-2">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-md-9 ">
                                <h3 className="title-charts">Sentiment by topic</h3>
                            </div>
                        </div>
                        <div className="row">
                            <BarChart />
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-7 mb-3 display-property">
                <div className="card ">
                    <div className="card-body padd-20" style={{ height: "444px" }}>
                        <div className="row  mb-2 mt-3">
                            <div className=" col-md-8 moderate-title">
                                Instances
                            </div>
                        </div>
                        <div className="row">
                            <div className="table-responsive text-nowrap ">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Insights</th>
                                            <th>Date Inserted</th>
                                            <th>User</th>
                                            <th>Project</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        <tr >
                                            <td>text</td>
                                            <td><span className="text-success fs-14" >Positve</span></td>
                                            <td>02 Sep 2023</td>
                                            <td>Mark Williams</td>
                                            <td>Agent Calls - Dubai Marina</td>
                                            <td>12</td>
                                        </tr>
                                        <tr >
                                            <td>audio</td>
                                            <td><span className="text-success fs-14" >Positve</span></td>
                                            <td>30 Jun 2023</td>
                                            <td>Alex Higgins</td>
                                            <td>Admin Calls - Dubai (ALL)</td>
                                            <td>54</td>
                                        </tr>
                                        <tr>
                                            <td>text</td>
                                            <td><span className="text-danger fs-14" >Negative</span></td>
                                            <td>13 Jun 2023</td>
                                            <td>Mark Til</td>
                                            <td>Customer Reviews</td>
                                            <td>123</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Mobile */}
        <div className="rule-list ">
            <div className="property-mobile">
                <h3 className="title-charts">  Instances</h3>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-12 d-flex align-items-center" >
                                <h3 className="title text-start ">Agent Calls - Dubai Marina</h3>
                            </div>
                        </div>
                        <div className="row date-create">
                            <div className="col mb-3">
                                <p className="float-start">ID: 12
                                    <svg width="6" height="6" viewBox="0 0 6 6" style={{ margin: "0 8px 0 8px" }} fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#8C8D96" />
                                    </svg> Postive Sentiment
                                </p>
                            </div>
                        </div>
                        <div className="row date-create">
                            <div className="col mb-3">
                                <div className="version float-start"><span className="cerc-delight " style={{ marginRight: '5px' }}></span>Mark Williams</div>
                            </div>
                            <div className="col mb-3">
                                <p className="float-end" style={{ opacity: 0.8 }}>02 Sep 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default Insights;