import React, { useEffect, useRef, useState } from "react"
import { GenerateRoomStaging, SelectProperty } from "../../../Services/Generate";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GenerateRoomSelect from "./generate-room-select";
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";
import { EnhanceImageResolution } from "../../../Services/Enhance";
import Fullscreen from "../../../Components/Generate/Fullscreen";
import DownloadImage from "../../download-image";


const Url = "https://www.api-v1.coralytics.com";
const RoomStaging: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [selectProperty, setSelectProperty] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSelectImage, setIsSelectImage] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [generatedimages, setGeneratedimages] = useState<any>([]);
    const [generatedimage, setGeneratedimage] = useState<any>("");
    const [checkedId, setCheckedId] = useState<any>(null);
    const [isFullScreen, setIsFullscreen] = useState(false);
    const [imagekey, setImagekey] = useState(1)
    const imageRef = useRef<any>();
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>({"id":"66aa4389abc0584db0db473a","environment":"interior","room_type":"Living Room","style":"Modern Farmhouse","images":[{"id":2,"src":"https://www.api-v1.coralytics.com/api/images/66aa4389abc0584db0db473a-2_2.jpg"}]});
    const [curlValue, setCurlValue] = useState<any>("generateimage");
    const [isDisabledApi, setIsDisabledApi] = useState<boolean>(false);
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const [isSpinnerDown, setIsSpinnerDown] = useState<boolean>(false);

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        });

    }, [])


    const handleCheckboxChange = (id: any) => {
        setCheckedId(id === checkedId ? null : id);
    };


    const handleSelectImages = (event: any) => {
        setIsSelectImage(!isSelectImage)
        const image: any = generatedimages.find((element: any) => Number.parseInt(element.id) == event.target.value)
        if (image) {
            setGeneratedimage(Url + image.src)
        } else {
            console.log("الصورة غير موجودة ")
        }
    }

    const handleCopy = () => {
        setIsSuccess(true)
        setShowMessage("تم نسخ المحتوى بنجاح!")
        setTimeout(() => {
            setIsSuccess(false)
        }, 3000);
    };

    const GenerateImagecHandler = (generateObj: any) => {
        setSelectProperty(generateObj.id)
        setIsSpinner(true)
        setIsUpgrade(false)
        setIsDisabledApi(false)
        setGeneratedimage("")
        setShowMsgUpgrade("")
        setImagekey(imagekey + 2)
        setGeneratedimages([]);
        setSelectImages(generateObj.images)
        GenerateRoomStaging(generateObj).then((data) => {
            
            for (let index = 0; index < data.imagelist.length; index++) {
                const element = data.imagelist[index];
                if (generateObj.images[0].id == element.id) {
                    setCheckedId(generateObj.images[0].id)
                    setGeneratedimage(Url + element.src)
                    break;
                }
            }
            setIsDisabledApi(true)
            setGeneratedimages(data.imagelist)
            setIsSpinner(false)
            setShowMessage('تم الإنشاء بنجاح! ')
            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("فشل في الحصول على كافة الخصائص")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };

    const handleDownloadEnhance = () => {
        setIsSpinnerDown(true)
        const Obj = {
            "id": "",
            "images": [{
                "id": 1,
                "src": generatedimage,
                "ext": "png"
            }]
        }
        EnhanceImageResolution(Obj).then(async (resp) => {
            try {
                const response = await fetch(resp.data[0].src);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `image-enhanced.${resp.data[0].src.split('.').pop().split(/\#|\?/)[0]}`;
                a.click();
                setIsSpinnerDown(false)
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        })

    }


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row  ">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        تنسيق الغرفة
                                    </div>
                                </div>
                                <div className="col-md-5 mb-3 padd-24 search-generate" >
                                    {/* <SearchProperty /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {properties == null ? <>تحميل ...</> :

                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <GenerateRoomSelect properties={properties} onGenerateImage={GenerateImagecHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                <h5 className="generate-h5">الصورة المحددة</h5>
                                {selectImages.length > 1 ? <div className="selected-images" >
                                    {selectImages.map((image: any, index: any) => (
                                        <div key={image.id}>
                                            <input type="checkbox" id={image.id} value={image.id} checked={checkedId === image.id} onChange={(event) => { handleSelectImages(event); handleCheckboxChange(image.id); }} />
                                            <label htmlFor={image.id} className="label1">
                                                <img src={image.src} className="image" key={image.id} /></label>
                                        </div>
                                    ))}
                                </div>
                                    : <div className="selected-images" >
                                        {selectImages.map((image: any, index: any) => (
                                            <div key={image.id}>
                                                <label htmlFor={image.id} className="label1">
                                                    <img src={image.src} className="image" key={image.id} /></label>
                                            </div>
                                        ))}
                                    </div>
                                }

                                <div className="generate-h5" style={{ height: "30px" }}>الصورة المنشأة
                                    {isSpinner && generatedimage == "" && <div className="spinner-border text-primary" role="status" style={{ marginRight: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">تحميل...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}

                                </div>



                                <div className="card card-image-gener">
                                    {generatedimage == "" ? <p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p> :
                                        <div className="card">
                                            <img src={`${generatedimage}?${new Date().getTime()}`} alt="Fullscreen Image" id='fullscreen-image' />

                                            <Fullscreen /></div>}
                                </div>

                                <div className="generate-edit" style={{ marginTop: "16px" }}>
                                    <button type="button" className={`btn mb-2 button-1 ${isDisabledApi ? "" : "disabled"}`} onClick={handleDownloadEnhance}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H12.5003C17.0253 1.04175 18.9587 2.97508 18.9587 7.50008V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29175C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V7.50008C17.7087 3.65841 16.342 2.29175 12.5003 2.29175H7.50033Z" fill="#716BE3" />
                                            <path d="M9.99987 12.7167C9.84154 12.7167 9.6832 12.6583 9.5582 12.5333L7.0582 10.0333C6.81654 9.79166 6.81654 9.39167 7.0582 9.15C7.29987 8.90833 7.69987 8.90833 7.94153 9.15L9.99987 11.2083L12.0582 9.15C12.2999 8.90833 12.6999 8.90833 12.9415 9.15C13.1832 9.39167 13.1832 9.79166 12.9415 10.0333L10.4415 12.5333C10.3165 12.6583 10.1582 12.7167 9.99987 12.7167Z" fill="#716BE3" />
                                            <path d="M10 12.7167C9.65833 12.7167 9.375 12.4334 9.375 12.0917V5.42505C9.375 5.08338 9.65833 4.80005 10 4.80005C10.3417 4.80005 10.625 5.08338 10.625 5.42505V12.0917C10.625 12.4417 10.3417 12.7167 10 12.7167Z" fill="#716BE3" />
                                            <path d="M9.99971 15.1916C8.24138 15.1916 6.47471 14.9083 4.79971 14.35C4.47471 14.2416 4.29971 13.8833 4.40804 13.5583C4.51638 13.2333 4.86637 13.05 5.19971 13.1666C8.29971 14.2 11.708 14.2 14.808 13.1666C15.133 13.0583 15.4914 13.2333 15.5997 13.5583C15.708 13.8833 15.533 14.2416 15.208 14.35C13.5247 14.9166 11.758 15.1916 9.99971 15.1916Z" fill="#716BE3" />
                                        </svg>
                                        راقي و تحميل
                                        {isSpinnerDown && <div className="spinner-border text-primary" role="status" style={{ width: "20px", height: "20px", textAlign: "center" }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                    </button>
                                    <DownloadImage image={generatedimage} isDisabledReplace={isDisabledApi} />

                                    <CopyToClipboard text={generatedimage} onCopy={handleCopy}>
                                        <button type="button" className={`btn mb-2 button-3 ${isDisabledApi ? "" : "disabled"}`}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.49996 18.9583H6.33329C2.67496 18.9583 1.04163 17.3249 1.04163 13.6666V10.4999C1.04163 6.84159 2.67496 5.20825 6.33329 5.20825H8.83329C9.17496 5.20825 9.45829 5.49159 9.45829 5.83325C9.45829 6.17492 9.17496 6.45825 8.83329 6.45825H6.33329C3.34996 6.45825 2.29163 7.51659 2.29163 10.4999V13.6666C2.29163 16.6499 3.34996 17.7083 6.33329 17.7083H9.49996C12.4833 17.7083 13.5416 16.6499 13.5416 13.6666V11.1666C13.5416 10.8249 13.825 10.5416 14.1666 10.5416C14.5083 10.5416 14.7916 10.8249 14.7916 11.1666V13.6666C14.7916 17.3249 13.1583 18.9583 9.49996 18.9583Z" fill="#716BE3" />
                                                <path d="M14.1667 11.7916H11.5C9.15837 11.7916 8.20837 10.8416 8.20837 8.49991V5.83324C8.20837 5.58324 8.35837 5.34991 8.59171 5.25824C8.82504 5.15824 9.09171 5.21658 9.27504 5.39158L14.6084 10.7249C14.7834 10.8999 14.8417 11.1749 14.7417 11.4082C14.65 11.6416 14.4167 11.7916 14.1667 11.7916ZM9.45837 7.34158V8.49991C9.45837 10.1582 9.84171 10.5416 11.5 10.5416H12.6584L9.45837 7.34158Z" fill="#716BE3" />
                                                <path d="M13 2.29175H9.66663C9.32496 2.29175 9.04163 2.00841 9.04163 1.66675C9.04163 1.32508 9.32496 1.04175 9.66663 1.04175H13C13.3416 1.04175 13.625 1.32508 13.625 1.66675C13.625 2.00841 13.3416 2.29175 13 2.29175Z" fill="#716BE3" />
                                                <path d="M5.83337 4.79175C5.49171 4.79175 5.20837 4.50841 5.20837 4.16675C5.20837 2.44175 6.60837 1.04175 8.33337 1.04175H10.5167C10.8584 1.04175 11.1417 1.32508 11.1417 1.66675C11.1417 2.00841 10.8584 2.29175 10.5167 2.29175H8.33337C7.30004 2.29175 6.45837 3.13341 6.45837 4.16675C6.45837 4.50841 6.17504 4.79175 5.83337 4.79175Z" fill="#716BE3" />
                                                <path d="M15.9917 14.7917C15.65 14.7917 15.3667 14.5084 15.3667 14.1667C15.3667 13.8251 15.65 13.5417 15.9917 13.5417C16.9417 13.5417 17.7084 12.7667 17.7084 11.8251V6.66675C17.7084 6.32508 17.9917 6.04175 18.3334 6.04175C18.675 6.04175 18.9584 6.32508 18.9584 6.66675V11.8251C18.9584 13.4584 17.625 14.7917 15.9917 14.7917Z" fill="#716BE3" />
                                                <path d="M18.3334 7.29174H15.8334C13.6167 7.29174 12.7084 6.38341 12.7084 4.16674V1.66674C12.7084 1.41674 12.8584 1.18341 13.0917 1.09174C13.325 0.991739 13.5917 1.05007 13.775 1.22507L18.775 6.22507C18.95 6.40007 19.0084 6.67507 18.9084 6.9084C18.8167 7.14174 18.5834 7.29174 18.3334 7.29174ZM13.9584 3.17507V4.16674C13.9584 5.69174 14.3084 6.04174 15.8334 6.04174H16.825L13.9584 3.17507Z" fill="#716BE3" />
                                            </svg>
                                            نسخ
                                        </button>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}
export default RoomStaging;