import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const getNotifications = async () => {
    const apiUrl=Url+"/allnotification";
    const response= await axios.get(apiUrl,{
     headers: {
       "Authorization": "Bearer "+localStorage.getItem("usertoken")
     }
   })
     return response.data;
   }

   export const UpdateNotifications = async (type :any) => {
     const apiUrl=Url+"/allnotification";
    const response= await axios.put(apiUrl,{type : type},{
     headers: {
       "Authorization": "Bearer "+localStorage.getItem("usertoken")
     }
   })
     return response.data;
   }
   