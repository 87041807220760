import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from 'react-audio-player';


const Instances: React.FunctionComponent<{}> = (props) => {

    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const playAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                (audioRef.current as HTMLAudioElement).pause();
            } else {
                (audioRef.current as HTMLAudioElement).play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className="display-items">
            <div className="card card-bottom">
                <div className="card-body padd-24">
                    <div className="row items-list mb-2">
                        <div className=" col-md-6 moderate-title">
                            Instances
                        </div>
                        <div className="col-md-2 mb-2">
                            <button type="button" className="btn btn-outline-primary button-rule"
                            >Export</button>
                        </div>
                        <div className="col-md-2 mb-2">
                            <button type="button" className="btn btn-outline-primary button-rule" style={{ float: 'right', background: "rgb(113, 107, 227)", color: "rgb(255, 255, 255)" }}>Add New Instance</button>
                        </div>
                        <div className="col-md-2 mb-2">
                            <button type="submit" className="filters" >
                                <span>Filters</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 6.45831H2.5C2.15833 6.45831 1.875 6.17498 1.875 5.83331C1.875 5.49165 2.15833 5.20831 2.5 5.20831H17.5C17.8417 5.20831 18.125 5.49165 18.125 5.83331C18.125 6.17498 17.8417 6.45831 17.5 6.45831Z" fill="#282828" />
                                    <path d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" fill="#282828" />
                                    <path d="M11.6667 14.7917H8.33337C7.99171 14.7917 7.70837 14.5084 7.70837 14.1667C7.70837 13.825 7.99171 13.5417 8.33337 13.5417H11.6667C12.0084 13.5417 12.2917 13.825 12.2917 14.1667C12.2917 14.5084 12.0084 14.7917 11.6667 14.7917Z" fill="#282828" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="margin-35 display-property">
                        <div className="table-responsive text-nowrap ">
                            <table className="table" style={{ minHeight: "150px" }}>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Instance</th> 
                                        <th>Sentiment</th>
                                        <th>Date Inserted</th>
                                        <th>User</th>
                                        <th>Project</th>
                                        <th>ID</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    <tr >
                                        <td>Text</td>
                                        <td>A quick brown...</td>
                                        <td><span className="text-danger fs-14 fw-lh">Negative</span></td>
                                        <td>13 Jun 2023</td>
                                        <td>Mark Williams</td>
                                        <td>Agent Calls - Dubai Marina</td>
                                        <td>123</td>
                                        <td>
                                            <button type="button" className="btn p-0  hide-arrow" data-bs-target="#viewModal" data-bs-toggle="modal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                </svg>

                                            </button>
                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginLeft: "10px" }} data-bs-toggle="modal"
                                                data-bs-target="#dropModal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>Audio</td>
                                        <td><div className="audio-player">
                                            <audio ref={audioRef} controls>
                                                <source src={'/assets/images/good-day.mp3'} type="audio/mpeg" />
                                            </audio>
                                            <button onClick={playAudio} className="play-button">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM12.2165 11.442L11.1498 12.0587L10.0832 12.6753C8.70817 13.467 7.58317 12.817 7.58317 11.2337V10.0003V8.76699C7.58317 7.17533 8.70817 6.53366 10.0832 7.32533L11.1498 7.94199L12.2165 8.55866C13.5915 9.35033 13.5915 10.6503 12.2165 11.442Z" fill="#282828" />
                                                </svg>  {isPlaying ? 'Pause' : 'Play'}

                                            </button>
                                        </div></td>
                                        <td><span className="text-success fs-14 fw-lh">Positve</span></td>
                                        <td>3 Aug 2023</td>
                                        <td>Luca Brecel</td>
                                        <td>Agent Calls - Downtown</td>
                                        <td>415</td>
                                        <td>
                                            <button type="button" className="btn p-0  hide-arrow" data-bs-target="#viewModal" data-bs-toggle="modal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                </svg>

                                            </button>
                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginLeft: "10px" }} data-bs-toggle="modal"
                                                data-bs-target="#dropModal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>Text</td>
                                        <td>A quick brown...</td>
                                        <td><span className="text-danger fs-14 fw-lh">Negative</span></td>
                                        <td>16 Sep 2023</td>
                                        <td>John Alley</td>
                                        <td>Admin Calls - Dubai (ALL)</td>
                                        <td>192</td>
                                        <td>
                                            <button type="button" className="btn p-0  hide-arrow" data-bs-target="#viewModal" data-bs-toggle="modal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                </svg>

                                            </button>
                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginLeft: "10px" }} data-bs-toggle="modal"
                                                data-bs-target="#dropModal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>Audio</td>
                                        <td> <div className="audio-player">
                                            <audio ref={audioRef} controls>
                                                <source src={'/assets/images/good-day.mp3'} type="audio/mpeg" />
                                            </audio>
                                            <button onClick={playAudio} className="play-button">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM12.2165 11.442L11.1498 12.0587L10.0832 12.6753C8.70817 13.467 7.58317 12.817 7.58317 11.2337V10.0003V8.76699C7.58317 7.17533 8.70817 6.53366 10.0832 7.32533L11.1498 7.94199L12.2165 8.55866C13.5915 9.35033 13.5915 10.6503 12.2165 11.442Z" fill="#282828" />
                                                </svg>  {isPlaying ? 'Pause' : 'Play'}
                                            </button>
                                        </div></td>
                                        <td><span className="text-success fs-14 fw-lh">Positve</span></td>
                                        <td>25 Oct 2023</td>
                                        <td>Alex Higgins</td>
                                        <td>Customer Reviews</td>
                                        <td>434</td>
                                        <td>
                                            <button type="button" className="btn p-0  hide-arrow" data-bs-target="#viewModal" data-bs-toggle="modal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z" fill="#8C8D96" />
                                                </svg>

                                            </button>
                                            <button type="button" className="btn p-0  hide-arrow" style={{ marginLeft: "10px" }} data-bs-toggle="modal"
                                                data-bs-target="#dropModal">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#D34645" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Mobile */}
                    <div className="rule-list ">
                    <div className="property-mobile">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row mb-2">
                                <div className="col-10 d-flex align-items-center" >
                                    <h3 className="title text-start " >Agent Calls - Dubai Marina</h3>
                                </div>
                                    <div className="col-2">
                                        <button type="button" className="btn p-0  hide-arrow float-end">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 11.5C11 10.66 11.675 10 12.5 10C13.325 10 14 10.675 14 11.5C14 12.325 13.325 13 12.5 13C11.675 13 11 12.34 11 11.5Z" fill="#282828" />
                                                <path d="M11 17.5C11 16.66 11.675 16 12.5 16C13.325 16 14 16.675 14 17.5C14 18.325 13.325 19 12.5 19C11.675 19 11 18.34 11 17.5Z" fill="#282828" />
                                                <path d="M11 5.5C11 4.66 11.675 4 12.5 4C13.325 4 14 4.675 14 5.5C14 6.325 13.325 7 12.5 7C11.675 7 11 6.34 11 5.5Z" fill="#282828" />
                                            </svg>

                                        </button>
                                    </div>
                                </div>
                                <div className="row date-create">
                                        <div className="col mb-3">
                                            <p className="float-start">ID: 89933
                                                <svg width="6" height="6" style={{margin:"0 8px 0 8px"}} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="#8C8D96" />
                                                </svg>
                                                Text   <svg width="6" height="6" viewBox="0 0 6 6"  style={{margin:"0 8px 0 8px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="#8C8D96" />
                                                </svg> Postive Sentiment
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <p className="rule-name" style={{background: "#F5F5F9"}}>A quick brown..</p>
                                        </div>
                                    </div>
                                    <div className="row date-create">
                                        <div className="col mb-3">
                                            <div className="version float-start"><span className="cerc-delight " style={{ marginRight: '5px' }}></span>Mark Williams</div>
                                        </div>
                                        <div className="col mb-3">
                                            <p className="float-end" style={{opacity: 0.8}}>13 Jun 2023</p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="viewModal" aria-hidden="true">
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">Instance Details</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card" style={{ background: "#F5F5F8" }}>
                                <div className="card-body padd-24">
                                    <p className="para fs-14">
                                        Pellentesque finibus enim id ullamcorper malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque metus dolor, blandit in mauris nec, bibendum dictum quam. Vestibulum ut blandit elit, ac gravida erat. Mauris accumsan accumsan velit, nec eleifend orci placerat sit amet. Donec et fringilla elit, eu varius turpis. Suspendisse nec diam fermentum, facilisis augue porta, malesuada nulla. Integer ac cursus quam. Nam mattis lorem ac ultricies blandit.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <h3 className="label-details">ID</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end">1234</h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="label-details">Type</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end">Text</h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>

                            <div className="row ">
                                <div className="col-md-6">
                                    <h3 className="label-details">Project Name</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end">Customer Reviews</h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="label-details">User</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end">Alex Higgins</h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="label-details">Date</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end">13 Jun, 2023</h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="label-details">Status</h3>
                                </div>
                                <div className="col-md-6 ">
                                    <h3 className="details-h float-end"><span className="badge bg-label-success">Done</span></h3>
                                </div>
                            </div>
                            <hr className="hr" style={{ opacity: 0.3, background: "#8C8D96" }}></hr>

                            <div className="row mb-3">
                                <h3 className="details-h float-start ">Topics</h3>
                            </div>
                            <div style={{ display: "inline-block" }}>
                                <span className="badge bg-label-secondary border-32 mb-3" style={{ marginRight: "10px" }}>Mortgage</span>
                                <span className="badge bg-label-secondary border-32 mb-3" style={{ marginRight: "10px" }}>Price</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Instances;