import { Badge } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Stream } from "@cloudflare/stream-react";
import { UpdateNotifications, getNotifications } from "../../Services/Notification";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Notifications: React.FunctionComponent<{}> = (props) => {

    const [notification, setNotification] = useState<any>(JSON.parse(localStorage.getItem("notification") || '{}'));
    const [count, setCount] = useState<any>(localStorage.getItem("total") || 0);
    const [change, setChange] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const interval = setInterval(fetchData, 4000);
        return () => {
            clearInterval(interval);
        };
    }, [change]);

    const convertDate = (date: any) => {
        const dateTime = new Date(date);
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const day = dateTime.getDate().toString().padStart(2, '0');
        const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
        const year = dateTime.getUTCFullYear().toString();

        const formattedDate = `${hours}:${minutes}, ${day}/${month}/${year}`;
        return formattedDate;

    };


    const handleClose = () => {
        setOpen(false);
         UpdateNotifications("video").then((resp) => {
             setChange(!change)
         }).catch((error) => {
             console.log("error")
         })
    };
    const fetchData = async () => {
        let totalCount = 0; 
    
        getNotifications()
            .then((resp) => {
                setNotification(resp);
    
                if (resp.notification_booster !== false) {
                    totalCount += resp.notification_booster.number;
                    localStorage.setItem("total", resp.notification_booster.number);
                }
                if (resp.notification_video !== false) {
                    totalCount += resp.notification_video.number;
                    localStorage.setItem("total", resp.notification_video.number);
                }
                if (resp.notifcation_generate_listings !== false) {
                    totalCount += resp.notifcation_generate_listings.number;
                    localStorage.setItem("total", resp.notifcation_generate_listings.number);
                }
    
                setCount(totalCount); 
                localStorage.setItem("total", totalCount.toString());
                localStorage.setItem("notification", JSON.stringify(resp));
            })
            .catch((erreur) => {
                console.log("error");
            });
    };
    

    const clearNotificationsBooster = () => {
        UpdateNotifications("boosting").then((resp) => {
            setChange(!change)
        }).catch((erreur) => {
            console.log("error")
        })
    }
    const clearNotificationsProperty = () => {
        UpdateNotifications("property").then((resp) => {
            setChange(!change)
        }).catch((erreur) => {
            console.log("error")
        })
    }
     const clearNotificationsVideo = () => {
         setOpen(true);
     }

    return (<>
        <span data-bs-toggle="dropdown" className="card-item-notif" aria-expanded="false" style={{ cursor: "pointer" }}>
            {(count === '0') ?
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M12.02 20.53C9.68999 20.53 7.35999 20.16 5.14999 19.42C4.30999 19.13 3.66999 18.54 3.38999 17.77C3.09999 17 3.19999 16.15 3.65999 15.39L4.80999 13.48C5.04999 13.08 5.26999 12.28 5.26999 11.81V8.91998C5.26999 5.19998 8.29999 2.16998 12.02 2.16998C15.74 2.16998 18.77 5.19998 18.77 8.91998V11.81C18.77 12.27 18.99 13.08 19.23 13.49L20.37 15.39C20.8 16.11 20.88 16.98 20.59 17.77C20.3 18.56 19.67 19.16 18.88 19.42C16.68 20.16 14.35 20.53 12.02 20.53ZM12.02 3.66998C9.12999 3.66998 6.76999 6.01998 6.76999 8.91998V11.81C6.76999 12.54 6.46999 13.62 6.09999 14.25L4.94999 16.16C4.72999 16.53 4.66999 16.92 4.79999 17.25C4.91999 17.59 5.21999 17.85 5.62999 17.99C9.80999 19.39 14.24 19.39 18.42 17.99C18.78 17.87 19.06 17.6 19.19 17.24C19.32 16.88 19.29 16.49 19.09 16.16L17.94 14.25C17.56 13.6 17.27 12.53 17.27 11.8V8.91998C17.27 6.01998 14.92 3.66998 12.02 3.66998Z" fill="#292D32" />
                    <path d="M13.88 3.93999C13.81 3.93999 13.74 3.92999 13.67 3.90999C13.38 3.82999 13.1 3.76999 12.83 3.72999C11.98 3.61999 11.16 3.67999 10.39 3.90999C10.11 3.99999 9.80999 3.90999 9.61999 3.69999C9.42999 3.48999 9.36999 3.18999 9.47999 2.91999C9.88999 1.86999 10.89 1.17999 12.03 1.17999C13.17 1.17999 14.17 1.85999 14.58 2.91999C14.68 3.18999 14.63 3.48999 14.44 3.69999C14.29 3.85999 14.08 3.93999 13.88 3.93999Z" fill="#292D32" />
                    <path d="M12.02 22.81C11.03 22.81 10.07 22.41 9.36999 21.71C8.66999 21.01 8.26999 20.05 8.26999 19.06H9.76999C9.76999 19.65 10.01 20.23 10.43 20.65C10.85 21.07 11.43 21.31 12.02 21.31C13.26 21.31 14.27 20.3 14.27 19.06H15.77C15.77 21.13 14.09 22.81 12.02 22.81Z" fill="#292D32" />
                </svg> : <Badge badgeContent={count} color="secondary">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M12.02 20.53C9.68999 20.53 7.35999 20.16 5.14999 19.42C4.30999 19.13 3.66999 18.54 3.38999 17.77C3.09999 17 3.19999 16.15 3.65999 15.39L4.80999 13.48C5.04999 13.08 5.26999 12.28 5.26999 11.81V8.91998C5.26999 5.19998 8.29999 2.16998 12.02 2.16998C15.74 2.16998 18.77 5.19998 18.77 8.91998V11.81C18.77 12.27 18.99 13.08 19.23 13.49L20.37 15.39C20.8 16.11 20.88 16.98 20.59 17.77C20.3 18.56 19.67 19.16 18.88 19.42C16.68 20.16 14.35 20.53 12.02 20.53ZM12.02 3.66998C9.12999 3.66998 6.76999 6.01998 6.76999 8.91998V11.81C6.76999 12.54 6.46999 13.62 6.09999 14.25L4.94999 16.16C4.72999 16.53 4.66999 16.92 4.79999 17.25C4.91999 17.59 5.21999 17.85 5.62999 17.99C9.80999 19.39 14.24 19.39 18.42 17.99C18.78 17.87 19.06 17.6 19.19 17.24C19.32 16.88 19.29 16.49 19.09 16.16L17.94 14.25C17.56 13.6 17.27 12.53 17.27 11.8V8.91998C17.27 6.01998 14.92 3.66998 12.02 3.66998Z" fill="#292D32" />
                        <path d="M13.88 3.93999C13.81 3.93999 13.74 3.92999 13.67 3.90999C13.38 3.82999 13.1 3.76999 12.83 3.72999C11.98 3.61999 11.16 3.67999 10.39 3.90999C10.11 3.99999 9.80999 3.90999 9.61999 3.69999C9.42999 3.48999 9.36999 3.18999 9.47999 2.91999C9.88999 1.86999 10.89 1.17999 12.03 1.17999C13.17 1.17999 14.17 1.85999 14.58 2.91999C14.68 3.18999 14.63 3.48999 14.44 3.69999C14.29 3.85999 14.08 3.93999 13.88 3.93999Z" fill="#292D32" />
                        <path d="M12.02 22.81C11.03 22.81 10.07 22.41 9.36999 21.71C8.66999 21.01 8.26999 20.05 8.26999 19.06H9.76999C9.76999 19.65 10.01 20.23 10.43 20.65C10.85 21.07 11.43 21.31 12.02 21.31C13.26 21.31 14.27 20.3 14.27 19.06H15.77C15.77 21.13 14.09 22.81 12.02 22.81Z" fill="#292D32" />
                    </svg></Badge>}
        </span>

        {(notification.notification_video === false && notification.notification_booster === false && notification.notification_moderate == false && notification.notifcation_generate_listings==false) ?
            <ul className="dropdown-menu notifications ">
                <small className="text-muted">لا توجد أي إشعارات</small>
            </ul> : <ul className="dropdown-menu notifications ">
                <div className="moderate-title" style={{ marginBottom: "12px" ,textAlign:"right"}}>
                إشعارات
                </div>
                {notification && notification.notification_video && <a  onClick={() => { clearNotificationsVideo() }}>
                    <div className='d-flex card-notif' >
                        <div className="rounded" style={{ background: "#DEF5E0" ,display: "flex"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#22BB33" />
                                <path d="M13.8796 3.93969C13.8096 3.93969 13.7396 3.92969 13.6696 3.90969C13.3796 3.82969 13.0996 3.76969 12.8296 3.72969C11.9796 3.61969 11.1596 3.67969 10.3896 3.90969C10.1096 3.99969 9.80963 3.90969 9.61963 3.69969C9.42963 3.48969 9.36963 3.18969 9.47963 2.91969C9.88963 1.86969 10.8896 1.17969 12.0296 1.17969C13.1696 1.17969 14.1696 1.85969 14.5796 2.91969C14.6796 3.18969 14.6296 3.48969 14.4396 3.69969C14.2896 3.85969 14.0796 3.93969 13.8796 3.93969Z" fill="#22BB33" />
                                <path d="M12.0195 22.8096C11.0295 22.8096 10.0695 22.4096 9.36953 21.7096C8.66953 21.0096 8.26953 20.0496 8.26953 19.0596H9.76953C9.76953 19.6496 10.0095 20.2296 10.4295 20.6496C10.8495 21.0696 11.4295 21.3096 12.0195 21.3096C13.2595 21.3096 14.2695 20.2996 14.2695 19.0596H15.7695C15.7695 21.1296 14.0895 22.8096 12.0195 22.8096Z" fill="#22BB33" />
                            </svg>
                        </div>
                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                            <div className="me-5">
                                <h6 className="mb-0">تم إنتاج الفيديو بنجاح</h6>
                                <small className="text-muted">{convertDate(notification.notification_video.date)}</small>
                            </div>
                        </div>
                    </div>
                    <div className='dropdown-hr'></div>
                </a>}
                {notification && notification.notification_booster && <Link to={`/ar/properties/${notification.notification_booster._id}`} onClick={() => { clearNotificationsBooster() }}>
                    <div className='d-flex card-notif' >
                        <div className="rounded" style={{ background: "#DEF5E0",display: "flex" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#22BB33" />
                                <path d="M13.8796 3.93969C13.8096 3.93969 13.7396 3.92969 13.6696 3.90969C13.3796 3.82969 13.0996 3.76969 12.8296 3.72969C11.9796 3.61969 11.1596 3.67969 10.3896 3.90969C10.1096 3.99969 9.80963 3.90969 9.61963 3.69969C9.42963 3.48969 9.36963 3.18969 9.47963 2.91969C9.88963 1.86969 10.8896 1.17969 12.0296 1.17969C13.1696 1.17969 14.1696 1.85969 14.5796 2.91969C14.6796 3.18969 14.6296 3.48969 14.4396 3.69969C14.2896 3.85969 14.0796 3.93969 13.8796 3.93969Z" fill="#22BB33" />
                                <path d="M12.0195 22.8096C11.0295 22.8096 10.0695 22.4096 9.36953 21.7096C8.66953 21.0096 8.26953 20.0496 8.26953 19.0596H9.76953C9.76953 19.6496 10.0095 20.2296 10.4295 20.6496C10.8495 21.0696 11.4295 21.3096 12.0195 21.3096C13.2595 21.3096 14.2695 20.2996 14.2695 19.0596H15.7695C15.7695 21.1296 14.0895 22.8096 12.0195 22.8096Z" fill="#22BB33" />
                            </svg>
                        </div>
                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                            <div className="me-5">
                                <h6 className="mb-0">  تم تعزيز القائمة بنجاح </h6>
                                <small className="text-muted">{convertDate(notification.notification_booster.date)}</small>
                            </div>
                        </div>
                    </div>
                    <div className='dropdown-hr'></div>
                </Link>}
                {notification && notification.notifcation_generate_listings && <Link to={`/ar/properties/${notification.notifcation_generate_listings._id}`} onClick={() => { clearNotificationsProperty() }}>
                    <div className='d-flex card-notif' >
                        <div className="rounded" style={{ background: "#DEF5E0",display: "flex" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#22BB33" />
                                <path d="M13.8796 3.93969C13.8096 3.93969 13.7396 3.92969 13.6696 3.90969C13.3796 3.82969 13.0996 3.76969 12.8296 3.72969C11.9796 3.61969 11.1596 3.67969 10.3896 3.90969C10.1096 3.99969 9.80963 3.90969 9.61963 3.69969C9.42963 3.48969 9.36963 3.18969 9.47963 2.91969C9.88963 1.86969 10.8896 1.17969 12.0296 1.17969C13.1696 1.17969 14.1696 1.85969 14.5796 2.91969C14.6796 3.18969 14.6296 3.48969 14.4396 3.69969C14.2896 3.85969 14.0796 3.93969 13.8796 3.93969Z" fill="#22BB33" />
                                <path d="M12.0195 22.8096C11.0295 22.8096 10.0695 22.4096 9.36953 21.7096C8.66953 21.0096 8.26953 20.0496 8.26953 19.0596H9.76953C9.76953 19.6496 10.0095 20.2296 10.4295 20.6496C10.8495 21.0696 11.4295 21.3096 12.0195 21.3096C13.2595 21.3096 14.2695 20.2996 14.2695 19.0596H15.7695C15.7695 21.1296 14.0895 22.8096 12.0195 22.8096Z" fill="#22BB33" />
                            </svg>
                        </div>
                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                            <div className="me-5">
                                <h6 className="mb-0">تم إنشاء العقار بنجاح </h6>
                                <small className="text-muted">{convertDate(notification.notifcation_generate_listings.date)}</small>
                            </div>
                        </div>
                    </div>
                    <div className='dropdown-hr'></div>
                </Link>}
                {notification && notification.notification_moderate && <Link to={`/ar/properties/${notification.notification_moderate._id}`} onClick={() => { clearNotificationsBooster() }}>
                    <div className='d-flex card-notif' >
                        <div className="rounded" style={{ background: "#DEF5E0",display: "flex" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#22BB33" />
                                <path d="M13.8796 3.93969C13.8096 3.93969 13.7396 3.92969 13.6696 3.90969C13.3796 3.82969 13.0996 3.76969 12.8296 3.72969C11.9796 3.61969 11.1596 3.67969 10.3896 3.90969C10.1096 3.99969 9.80963 3.90969 9.61963 3.69969C9.42963 3.48969 9.36963 3.18969 9.47963 2.91969C9.88963 1.86969 10.8896 1.17969 12.0296 1.17969C13.1696 1.17969 14.1696 1.85969 14.5796 2.91969C14.6796 3.18969 14.6296 3.48969 14.4396 3.69969C14.2896 3.85969 14.0796 3.93969 13.8796 3.93969Z" fill="#22BB33" />
                                <path d="M12.0195 22.8096C11.0295 22.8096 10.0695 22.4096 9.36953 21.7096C8.66953 21.0096 8.26953 20.0496 8.26953 19.0596H9.76953C9.76953 19.6496 10.0095 20.2296 10.4295 20.6496C10.8495 21.0696 11.4295 21.3096 12.0195 21.3096C13.2595 21.3096 14.2695 20.2996 14.2695 19.0596H15.7695C15.7695 21.1296 14.0895 22.8096 12.0195 22.8096Z" fill="#22BB33" />
                            </svg>
                        </div>
                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                            <div className="me-5">
                                <h6 className="mb-0">تم التعديل</h6>
                                <small className="text-muted">{convertDate(notification.notification_moderate.date)}</small>
                            </div>
                        </div>
                    </div>
                    <div className='dropdown-hr'></div>
                </Link>}
            </ul>
        }

         <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{ '& .MuiDialog-paper': { width: '100%'} }}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogContent>
                {notification.notification_video ? <div style={{ width: '100%', height: 'auto' }}>
                    <Stream src={notification.notification_video.video_url} width="100%" height="auto" controls muted={true} autoplay={false} />
                </div>:<></>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>إغلاق </Button>
            </DialogActions>
        </Dialog>

    </>)
}
export default Notifications