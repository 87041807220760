import React, { useState } from "react"

const DcontentSelect: React.FunctionComponent<{ properties: any, onSendResidential: any }> = (props) => {


    const [selectImages, setSelectImages] = useState<any>([]);
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [isDisabledSelect, setIsDisabledSelect] = useState<boolean>(false);
    const [upload, setUpload] = useState(true);


    const HandleChangeUpload = (event: any) => {
        setSelectImages([]);
        setIsDisabledSelect(!isDisabledSelect)
        setUpload(false)
        for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i + 1,
                    "src": base_64,
                    "ext":ext[1]
                }
                setSelectImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );
            };
        }
    }


    const Understand = () => {
        const understandObj = {
            "images": selectImages
        }
        setIsDisabledSelect(false)
        setIsDisabledUpload(false)
   
        props.onSendResidential(understandObj);
    };

    return (

        <div className="card">
            <div className="card-body padd-24">

                <div className="row mt-3">
                        <div className="container_image">
                        <input
                            type="file"
                            id="input-file"
                            name="input-file"
                            accept="image/*"
                            multiple
                            onChange={HandleChangeUpload}
                            hidden
                        />
                        <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                            <i className="fa-solid fa-upload" style={{ marginLeft: "5px" }}></i>
                            تحميل الصور
                        </label>
                    </div>
                        {upload ?<></>  : <>
                            <div className="selected-images" style={{marginBottom:"0px"}} >
                                {selectImages.map((image: any, index: any) => (
                                    <div key={image.id}>
                                        <label htmlFor={image.id} className="label1">
                                            <img src={image.src} className="image" key={image.id} /></label>
                                    </div>
                                ))}
                            </div>
                        </>}
                </div>
                <button type="button" className={`btn btn-primary generate marg-top20 ${selectImages.length == 0 ? 'disabled' : ''}`} onClick={Understand}>إفهم </button>
            </div>

        </div>
    )
}
export default DcontentSelect;