import React, { useEffect, useState } from "react";
import { UploadTermsService } from "../../../Services/GenerateLease";
const Url = process.env.REACT_APP_URl;

const AdditionalTerms: React.FunctionComponent<{ onGenerateAdditionalTerms: any }> = (props) => {

    const [additionalTerms1, setAdditionalTerms1] = useState<any>("")
    const [additionalTerms2, setAdditionalTerms2] = useState<any>("")
    const [additionalTerms3, setAdditionalTerms3] = useState<any>("")
    const [additionalTerms4, setAdditionalTerms4] = useState<any>("")
    const [additionalTerms5, setAdditionalTerms5] = useState<any>("")
    const [pdfUpload, setPdfUpload] = useState<any>("")
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);


    useEffect(() => {

        const obj = {
            "term1": additionalTerms1,
            "term2": additionalTerms2,
            "term3": additionalTerms3,
            "term4": additionalTerms4,
            "term5": additionalTerms5,
            "pdf": pdfUpload
        }
        props.onGenerateAdditionalTerms(obj);
    }, [props.onGenerateAdditionalTerms])


    const handleAdditionalTerms1 = (event: any) => {
        setAdditionalTerms1(event.target.value);
    }
    const handleAdditionalTerms2 = (event: any) => {
        setAdditionalTerms2(event.target.value);
    }
    const handleAdditionalTerms3 = (event: any) => {
        setAdditionalTerms3(event.target.value);
    }
    const handleAdditionalTerms4 = (event: any) => {
        setAdditionalTerms4(event.target.value);
    }
    const handleAdditionalTerms5 = (event: any) => {
        setAdditionalTerms5(event.target.value);
    }

    const HandleChangeUpload = (event: any) => {
        setPdfUpload("")
        const file = event.target.files[0];

        if (!file) {
            console.log("No files selected.");
            return;
        }

        setIsSpinner(true);
        setIsError(false);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64 = reader.result as string;
            const image = {
                "id": 1,
                "src": base64,
            }
            UploadTermsService([image]).then((data) => {
                setIsSpinner(false)
                setPdfUpload(Url + data.additional_terms ? Url + data.additional_terms : "")
            })
                .catch((err) => {
                    setIsError(true);
                    setIsSpinner(false);
                    console.log("Error downloading file :", err);
                });
        };

        reader.onerror = () => {
            setIsError(true);
            setIsSpinner(false);
            console.log("Error reading file.");
        };

        setTimeout(() => {
            setIsError(false);
        }, 5000);
    };

    const view = () => {
        window.open(pdfUpload, '_blank');
    };

    return (
        <>
            {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                Upload failed
            </div>}
            <div className="row mt-4 padd-30 mb-3 ">
                <div className="container_image">
                    <input
                        type="file"
                        id="input-file"
                        name="input-file"
                        accept=".pdf"
                        onChange={HandleChangeUpload}
                        hidden
                    />
                    <label className="btn-upload" id="button-addnew" htmlFor="input-file" role="button">
                        <i className="fa-solid fa-upload" style={{ marginRight: "5px" }}></i>
                        Upload Additional Terms
                    </label>
                    {isSpinner == true && <span className="spinner-border text-primary" style={{ width: "20px", height: "20px", marginLeft: "10px" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>}
                </div>
            </div>
            {pdfUpload !== "" && <div className="row mb-3" style={{ paddingLeft: "35px", paddingRight: "30px" }}>
                <button type="button" style={{ float: "left", textAlign: "left", color: "#000000" }}
                    className="btn p-0  hide-arrow" onClick={view}>Uploded File
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "5px" }} xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8334 9.16668L17.6667 2.33334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3334 5.66666V1.66666H14.3334" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.16663 1.66666H7.49996C3.33329 1.66666 1.66663 3.33332 1.66663 7.49999V12.5C1.66663 16.6667 3.33329 18.3333 7.49996 18.3333H12.5C16.6666 18.3333 18.3333 16.6667 18.3333 12.5V10.8333" stroke="#282828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>}

            <div className="row padd-30 mb-4 ">
                <div className="col-md-4">
                    <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 1</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={additionalTerms1}
                        placeholder="Additional Terms 1"
                        onChange={handleAdditionalTerms1}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 4</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={additionalTerms4}
                        placeholder="Additional Terms 4"
                        onChange={handleAdditionalTerms4}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 2</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={additionalTerms2}
                        placeholder="Additional Terms 2"
                        onChange={handleAdditionalTerms2}
                    />
                    <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 5</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={additionalTerms5}
                        placeholder="Additional Terms 5"
                        onChange={handleAdditionalTerms5}
                    />
                </div>
                <div className="col-md-4 ">
                    <label className="form-label" htmlFor="selectTypeOpt">Additional Terms 3</label>
                    <input
                        type="text"
                        className="form-control input mb-3"
                        id="defaultFormControlInput"
                        value={additionalTerms3}
                        placeholder="Additional Terms 3"
                        onChange={handleAdditionalTerms3}
                    />

                </div>

            </div>
        </>
    )
}
export default AdditionalTerms;