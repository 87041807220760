import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    plugins: {
        legend: {
            display: false
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
              },
        },
        y: {
            stacked: true,
            beginAtZero: true,
            grid: {
              display: false,
            },
        },
    },
   
};
const labels = ['Claims_Process', 'Customer Service', 'Policy_Coverage', 'Website_App'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Positive',
            data: [25, 88, 25, 18],
            borderColor: '#46B49E',
            backgroundColor: '#46B49E',
        },
        {
            label: 'Passive',
            data: [40, 19, 25, 28],
            borderColor: '#FF6F61',
            backgroundColor: '#FF6F61',
        },
        {
            label: 'Neutral',
            data: [55, 19, 25, 38],
            borderColor: '#C3C5D4',
            backgroundColor: '#C3C5D4',
        }
    ],
};
const BarChart: React.FunctionComponent<{}> = (props) => {

    return <div className="line-chart-container" style={{marginBottom:"30px"}}>
        <Bar options={options} data={data as any} />
        <div style={{ display: 'flex', justifyContent: 'start' }}>
        {data.datasets.map((label, index) => (
          <div key={index} style={{ margin: '10px',textAlign: 'center'}}>
            <span
              style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: label.backgroundColor,
                marginRight: '5px',
               
              }}
            ></span>
            {label.label}
          </div>
        ))}
      </div></div>
}
export default BarChart 