import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isLogged } from "./Authentication";
import LoginAr from "../Components-ar/loginAr";

interface AuthGuardProps {
  children: React.ReactNode;
}
const PublicPagesGuardAr : React.FC<AuthGuardProps> = ({  }) => {
  
  return !isLogged() ? <LoginAr/> : <Navigate to="/ar/properties" />;}

 
export default PublicPagesGuardAr;
