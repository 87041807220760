import React, { useEffect, useState } from "react";
import ChartHome from "./Charts/chart-home";
import { getmostusedconsumedcards, getoverallstats } from "../Services/Chart";

const Home: React.FunctionComponent<{}> = () => {

    const [topscoreconsumedused, setTopscoreconsumedused] = useState<any>({})
    const [stats, setStats] = useState<any>({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getmostusedconsumedcards().then((response: any) => {
            setTopscoreconsumedused(response)

            getoverallstats().then((response: any) => {
                setStats(response)

                setLoading(false)
            })

        })
    }, [])

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className=" col-md-10 moderate-title" style={{ marginBottom: "16px" }}>
                    الأرقام
                </div>

            </div>
            <div className="row ">

                <div className="col-md-3  mb-4">
                    <div className="card">
                        <div className="card-body overview">
                            <div className="card-title">
                                <div className="rounded backg-over-blue ">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.6667 3.33331H13.3333C7.5 3.33331 5 6.66665 5 11.6666V28.3333C5 33.3333 7.5 36.6666 13.3333 36.6666H26.6667C32.5 36.6666 35 33.3333 35 28.3333V11.6666C35 6.66665 32.5 3.33331 26.6667 3.33331ZM13.3333 20.4166H20C20.6833 20.4166 21.25 20.9833 21.25 21.6666C21.25 22.35 20.6833 22.9166 20 22.9166H13.3333C12.65 22.9166 12.0833 22.35 12.0833 21.6666C12.0833 20.9833 12.65 20.4166 13.3333 20.4166ZM26.6667 29.5833H13.3333C12.65 29.5833 12.0833 29.0166 12.0833 28.3333C12.0833 27.65 12.65 27.0833 13.3333 27.0833H26.6667C27.35 27.0833 27.9167 27.65 27.9167 28.3333C27.9167 29.0166 27.35 29.5833 26.6667 29.5833ZM30.8333 15.4166H27.5C24.9667 15.4166 22.9167 13.3666 22.9167 10.8333V7.49998C22.9167 6.81665 23.4833 6.24998 24.1667 6.24998C24.85 6.24998 25.4167 6.81665 25.4167 7.49998V10.8333C25.4167 11.9833 26.35 12.9166 27.5 12.9166H30.8333C31.5167 12.9166 32.0833 13.4833 32.0833 14.1666C32.0833 14.85 31.5167 15.4166 30.8333 15.4166Z" fill="#0066FF" />
                                    </svg>
                                </div>
                            </div>
                            <div className="card-figures">
                                <span className="fw-semibold marg-35">الصور المعالجة </span>
                                <div className="number-month marg-8 mb-2 "><h3 className="card-title h3">{stats.Pimages}</h3>
                                    {/* <small className="text-success">  +20% مقارنة بالشهر الماضي</small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3  mb-4">
                    <div className="card">
                        <div className="card-body overview">
                            <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="rounded backg-over-mauve ">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35.9333 17.9L33.6667 15.2667C33.2333 14.7667 32.8833 13.8333 32.8833 13.1667V10.3333C32.8833 8.56666 31.4333 7.11666 29.6667 7.11666H26.8333C26.1833 7.11666 25.2333 6.76666 24.7333 6.33333L22.1 4.06666C20.95 3.08333 19.0667 3.08333 17.9 4.06666L15.2833 6.35C14.7833 6.76666 13.8333 7.11666 13.1833 7.11666H10.3C8.53334 7.11666 7.08334 8.56666 7.08334 10.3333V13.1833C7.08334 13.8333 6.73334 14.7667 6.31667 15.2667L4.06667 17.9167C3.10001 19.0667 3.10001 20.9333 4.06667 22.0833L6.31667 24.7333C6.73334 25.2333 7.08334 26.1667 7.08334 26.8167V29.6667C7.08334 31.4333 8.53334 32.8833 10.3 32.8833H13.1833C13.8333 32.8833 14.7833 33.2333 15.2833 33.6667L17.9167 35.9333C19.0667 36.9167 20.95 36.9167 22.1167 35.9333L24.75 33.6667C25.25 33.2333 26.1833 32.8833 26.85 32.8833H29.6833C31.45 32.8833 32.9 31.4333 32.9 29.6667V26.8333C32.9 26.1833 33.25 25.2333 33.6833 24.7333L35.95 22.1C36.9167 20.95 36.9167 19.05 35.9333 17.9ZM27.8 20L25.85 25.9333C25.6 26.9167 24.55 27.7167 23.4833 27.7167H20.4C19.8667 27.7167 19.1167 27.5333 18.7833 27.2L16.3333 25.2833C16.2833 26.35 15.8 26.8 14.6167 26.8H13.8167C12.5833 26.8 12.0833 26.3167 12.0833 25.15V17.1833C12.0833 16.0167 12.5833 15.5333 13.8167 15.5333H14.6333C15.8667 15.5333 16.3667 16.0167 16.3667 17.1833V17.7833L19.6 12.9833C19.9333 12.4667 20.7833 12.1 21.5 12.3833C22.2833 12.65 22.7833 13.5167 22.6167 14.2833L22.2167 16.8833C22.1833 17.1167 22.2333 17.3333 22.3833 17.5C22.5167 17.65 22.7167 17.75 22.9333 17.75H26.1833C26.8167 17.75 27.35 18 27.6667 18.45C27.95 18.8833 28 19.4333 27.8 20Z" fill="#7E57C2" />
                                    </svg>
                                </div>
                            </div>
                            <div className="card-figures">

                                <span className="fw-semibold marg-35 ">مجموع القوائم</span>
                                <div className="number-month marg-8 mb-2 "><h3 className="card-title h3">{stats.Moderate}</h3>
                                    {/* <small className="text-success">  +6% مقارنة بالشهر الماضي</small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3  mb-4">
                    <div className="card">
                        <div className="card-body overview">
                            <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="rounded backg-over-danger ">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35.4167 30.7834L32.6667 31.4334C32.05 31.5834 31.5667 32.05 31.4334 32.6667L30.85 35.1167C30.5334 36.45 28.8334 36.8667 27.95 35.8167L22.9667 30.0834C22.5667 29.6167 22.7834 28.8834 23.3834 28.7334C26.3334 28.0167 28.9834 26.3667 30.9334 24.0167C31.25 23.6334 31.8167 23.5834 32.1667 23.9334L35.8667 27.6334C37.1334 28.9 36.6834 30.4834 35.4167 30.7834Z" fill="#FF6F61" />
                                        <path d="M4.50003 30.7834L7.25003 31.4334C7.86669 31.5834 8.35003 32.05 8.48336 32.6667L9.06669 35.1167C9.38336 36.45 11.0834 36.8667 11.9667 35.8167L16.95 30.0834C17.35 29.6167 17.1334 28.8834 16.5334 28.7334C13.5834 28.0167 10.9334 26.3667 8.98336 24.0167C8.66669 23.6334 8.10003 23.5834 7.75003 23.9334L4.05003 27.6334C2.78336 28.9 3.23336 30.4834 4.50003 30.7834Z" fill="#FF6F61" />
                                        <path d="M20 3.33331C13.55 3.33331 8.33331 8.54998 8.33331 15C8.33331 17.4166 9.04998 19.6333 10.2833 21.4833C12.0833 24.15 14.9333 26.0333 18.25 26.5166C18.8166 26.6166 19.4 26.6666 20 26.6666C20.6 26.6666 21.1833 26.6166 21.75 26.5166C25.0666 26.0333 27.9166 24.15 29.7166 21.4833C30.95 19.6333 31.6666 17.4166 31.6666 15C31.6666 8.54998 26.45 3.33331 20 3.33331ZM25.1 14.6333L23.7166 16.0166C23.4833 16.25 23.35 16.7 23.4333 17.0333L23.8333 18.75C24.15 20.1 23.4333 20.6333 22.2333 19.9166L20.5666 18.9333C20.2666 18.75 19.7666 18.75 19.4666 18.9333L17.8 19.9166C16.6 20.6166 15.8833 20.1 16.2 18.75L16.6 17.0333C16.6666 16.7166 16.55 16.25 16.3166 16.0166L14.9 14.6333C14.0833 13.8166 14.35 13 15.4833 12.8166L17.2666 12.5166C17.5666 12.4666 17.9166 12.2 18.05 11.9333L19.0333 9.96665C19.5666 8.89998 20.4333 8.89998 20.9666 9.96665L21.95 11.9333C22.0833 12.2 22.4333 12.4666 22.75 12.5166L24.5333 12.8166C25.65 13 25.9166 13.8166 25.1 14.6333Z" fill="#FF6F61" />
                                    </svg>
                                </div>
                            </div>
                            <div className="card-figures">
                                <span className="fw-semibold marg-35">الاعتمادات المتبقية</span>
                                <div className="number-month marg-8 mb-2 "><h3 className="card-title h3">{stats.remaining}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mb-4 back-mobile">
                    <ul className="p-0 m-0" style={{ listStyleType: "none" }}>
                        <li className=" mb-13 back-color">
                            <div className="row">
                                <div className="col-md-10"><h6 className="mb-0"> إنشاء قائمة بالذكاء الاصطناعي</h6></div>
                                <div className="col-md-2"><a href="/ar/generate/ai_generation">
                                    <svg width="20" style={{ transform: " rotate(270deg)" }} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 9.79164C10.675 9.79164 10.5167 9.7333 10.3917 9.6083C10.15 9.36664 10.15 8.96664 10.3917 8.72497L17.225 1.89164C17.4667 1.64997 17.8667 1.64997 18.1083 1.89164C18.35 2.1333 18.35 2.5333 18.1083 2.77497L11.275 9.6083C11.15 9.7333 10.9917 9.79164 10.8333 9.79164Z" fill="#282828" />
                                        <path d="M18.3333 6.29169C17.9917 6.29169 17.7083 6.00835 17.7083 5.66669V2.29169H14.3333C13.9917 2.29169 13.7083 2.00835 13.7083 1.66669C13.7083 1.32502 13.9917 1.04169 14.3333 1.04169H18.3333C18.675 1.04169 18.9583 1.32502 18.9583 1.66669V5.66669C18.9583 6.00835 18.675 6.29169 18.3333 6.29169Z" fill="#282828" />
                                        <path d="M12.5 18.9584H7.5C2.97501 18.9584 1.04167 17.025 1.04167 12.5V7.50002C1.04167 2.97502 2.97501 1.04169 7.5 1.04169H9.16667C9.50834 1.04169 9.79167 1.32502 9.79167 1.66669C9.79167 2.00835 9.50834 2.29169 9.16667 2.29169H7.5C3.65834 2.29169 2.29167 3.65835 2.29167 7.50002V12.5C2.29167 16.3417 3.65834 17.7084 7.5 17.7084H12.5C16.3417 17.7084 17.7083 16.3417 17.7083 12.5V10.8334C17.7083 10.4917 17.9917 10.2084 18.3333 10.2084C18.675 10.2084 18.9583 10.4917 18.9583 10.8334V12.5C18.9583 17.025 17.025 18.9584 12.5 18.9584Z" fill="#282828" />
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </li>
                        <li className="  mb-13  back-color">
                            <div className="row">
                                <div className="col-md-10"><h6 className="mb-0">تنسيق الغرفة</h6></div>
                                <div className="col-md-2"><a href="/ar/generate/room-staging">
                                    <svg width="20" style={{ transform: " rotate(270deg)" }} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 9.79164C10.675 9.79164 10.5167 9.7333 10.3917 9.6083C10.15 9.36664 10.15 8.96664 10.3917 8.72497L17.225 1.89164C17.4667 1.64997 17.8667 1.64997 18.1083 1.89164C18.35 2.1333 18.35 2.5333 18.1083 2.77497L11.275 9.6083C11.15 9.7333 10.9917 9.79164 10.8333 9.79164Z" fill="#282828" />
                                        <path d="M18.3333 6.29169C17.9917 6.29169 17.7083 6.00835 17.7083 5.66669V2.29169H14.3333C13.9917 2.29169 13.7083 2.00835 13.7083 1.66669C13.7083 1.32502 13.9917 1.04169 14.3333 1.04169H18.3333C18.675 1.04169 18.9583 1.32502 18.9583 1.66669V5.66669C18.9583 6.00835 18.675 6.29169 18.3333 6.29169Z" fill="#282828" />
                                        <path d="M12.5 18.9584H7.5C2.97501 18.9584 1.04167 17.025 1.04167 12.5V7.50002C1.04167 2.97502 2.97501 1.04169 7.5 1.04169H9.16667C9.50834 1.04169 9.79167 1.32502 9.79167 1.66669C9.79167 2.00835 9.50834 2.29169 9.16667 2.29169H7.5C3.65834 2.29169 2.29167 3.65835 2.29167 7.50002V12.5C2.29167 16.3417 3.65834 17.7084 7.5 17.7084H12.5C16.3417 17.7084 17.7083 16.3417 17.7083 12.5V10.8334C17.7083 10.4917 17.9917 10.2084 18.3333 10.2084C18.675 10.2084 18.9583 10.4917 18.9583 10.8334V12.5C18.9583 17.025 17.025 18.9584 12.5 18.9584Z" fill="#282828" />
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </li>
                        <li className="back-color">
                            <div className="row">
                                <div className="col-md-10"><h6 className="mb-0">تحسين جودة الصورة</h6></div>
                                <div className="col-md-2"><a href="/ar/enhance/image-super-resolution">
                                    <svg width="20" style={{ transform: " rotate(270deg)" }} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 9.79164C10.675 9.79164 10.5167 9.7333 10.3917 9.6083C10.15 9.36664 10.15 8.96664 10.3917 8.72497L17.225 1.89164C17.4667 1.64997 17.8667 1.64997 18.1083 1.89164C18.35 2.1333 18.35 2.5333 18.1083 2.77497L11.275 9.6083C11.15 9.7333 10.9917 9.79164 10.8333 9.79164Z" fill="#282828" />
                                        <path d="M18.3333 6.29169C17.9917 6.29169 17.7083 6.00835 17.7083 5.66669V2.29169H14.3333C13.9917 2.29169 13.7083 2.00835 13.7083 1.66669C13.7083 1.32502 13.9917 1.04169 14.3333 1.04169H18.3333C18.675 1.04169 18.9583 1.32502 18.9583 1.66669V5.66669C18.9583 6.00835 18.675 6.29169 18.3333 6.29169Z" fill="#282828" />
                                        <path d="M12.5 18.9584H7.5C2.97501 18.9584 1.04167 17.025 1.04167 12.5V7.50002C1.04167 2.97502 2.97501 1.04169 7.5 1.04169H9.16667C9.50834 1.04169 9.79167 1.32502 9.79167 1.66669C9.79167 2.00835 9.50834 2.29169 9.16667 2.29169H7.5C3.65834 2.29169 2.29167 3.65835 2.29167 7.50002V12.5C2.29167 16.3417 3.65834 17.7084 7.5 17.7084H12.5C16.3417 17.7084 17.7083 16.3417 17.7083 12.5V10.8334C17.7083 10.4917 17.9917 10.2084 18.3333 10.2084C18.675 10.2084 18.9583 10.4917 18.9583 10.8334V12.5C18.9583 17.025 17.025 18.9584 12.5 18.9584Z" fill="#282828" />
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row" >
                <div className="col-md-8  mb-4">
                    <div className="card padding-chart height-chart" >
                        <div className="card-body ">
                            <div className="row ">
                                <h3 className="title-chart">آخر الأحداث</h3>
                            </div>
                            < ChartHome />
                        </div>
                    </div>
                </div>
                {loading ? <div>تحميل ...</div> : (<div className="col-md-4  mb-4">
                    <div className="card padd-24">
                        <div className="card-body">
                            <h6 className=" col-title ">البطاقات الأكثر استخداما </h6>
                            <small className="fw-semibold-1">آخر 6 أشهر</small>
                            <ul className="p-0 m-0 mb-3 mt-3">
                                {topscoreconsumedused.topMostUsedCards.map((card: any, index: number) => (
                                    <li className="d-flex mb-13 card-market" key={index}>
                                        <div className="avatar me-3">
                                            <span className="avatar-initial">
                                                <img style={{
                                                    width: "32px",
                                                    height: "32px"
                                                }} src={"/assets/images/icons/" + (index + 1) + ".jpg"} /></span>
                                        </div>
                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                            <div className="me-5">
                                                <h6 className="mb-0">{card._id}</h6>
                                                <small className="text-muted" style={{ marginTop: "3px" }}>{card.count}</small>
                                                <small className="text-success">استعمال</small>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>)}
            </div>

            <div className="row card-bottom">
                <div className=" col-md-12 moderate-title" style={{ marginBottom: "16px" }}>
                    أعلى بطاقات الاستهلاك
                </div>
                {loading ? <div>تحميل ...</div> : topscoreconsumedused.topMostCreditConsumedCards.map((card: any, index: number) => (
                    <div className="col-md-3 mb-4" key={index}>
                        <div className="card" style={{ height: "194px" }} >
                            <div className="card-body">
                                <div className=" agenc-padd">
                                    <img width={60} height={60} src={"/assets/images/icons/" + (index + 1) + ".jpg"} />
                                    <span> {card._id}</span>
                                </div>
                                <div className=" allspop" style={{ background: "#F1F0FC" }}>
                                    <div className="agencies-list">
                                        <div className="number-listings marg-10">{card.totalScoreConsumed}</div>
                                        <small className="fw-semibold marg-10">الائتمان </small>
                                        <small className="text-success"> مستهلكة</small></div>
                                    <div className="agencies-list">
                                        <div className="number-listings marg-10">{card.count}</div>
                                        <small className="fw-semibold marg-10">الاستخدام  </small>
                                        <small className="text-success"> تردد</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    )
}
export default Home