import React from 'react';
import PublicRouterAr from './PublicRouterAr';
import PublicRouterEn from './PublicRouterEn';

const PublicRouter: React.FunctionComponent<{}> = (props) => {


    return (

        <div>
            <PublicRouterAr/>
            <PublicRouterEn/>
        </div >
    );
};

export default PublicRouter;