import { useEffect, useState } from "react";
import { Condition } from "../../Models/Rule";
import { getCategories, getConditions, getTypes } from "../../Services/Moderate";
import { FormControl, MenuItem, Select } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const AddConditionRule: React.FunctionComponent<{ onSaveCondition: any, onDeleteCondition: any, conditionId: any }> = (props) => {

    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState<any>([]);
    const [typeValue, setTypeValue] = useState<any>("");
    const [conditions, setConditions] = useState<any>([]);
    const [ConditionValue, setConditionValue] = useState<any>("");
    const [categories, setCategories] = useState<any>([]);
    const [CategoryValue, setCategoryValue] = useState<any>("");
    const [selectTypeValue, setSelectTypeValue] = useState<any>("");
    const [isButtonValidated, setIsButtonValidated] = useState(false);
    const [MaxValue, setMaxValue] = useState<any>();
    const [MinValue, setMinValue] = useState<any>();
    useEffect(() => {
        setLoading(true);
        getTypes().then((data) => {
            setTypes(data.types)
        }).catch(err => {
            console.log(err)
        })
    }, [props.conditionId])

    const handleChangeType = (event: any) => {
        setTypeValue(event.target.value)
        getConditions(event.target.value).then((data) => {
            setConditions(data.conditions)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleChangeCondition = (event: any) => {
        setConditionValue(event.target.value)
        getCategories(typeValue, event.target.value).then((data) => {
            setCategories(data.values.values)
            setSelectTypeValue(data.values.select_type)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleChangeCategory = (event: any) => {
        setCategoryValue(event.target.value)
    }
    const handleChangeMin = (event: any) => {
        setMinValue(event.target.value)
    }
    const handleChangeMax = (event: any) => {
        setMaxValue(event.target.value)
    }
    const Save = () => {
        setIsButtonValidated(true)
        if (selectTypeValue === "range") {
            const ConditionObj = {
                id: props.conditionId,
                type: typeValue,
                condition: ConditionValue,
                value: MinValue + "," + MaxValue
            };
            props.onSaveCondition(ConditionObj);
        } else {
            const updatedConditionObj = {
                id: props.conditionId,
                type: typeValue,
                condition: ConditionValue,
                value: CategoryValue
            };
            props.onSaveCondition(updatedConditionObj);
        }

    }

    const Delete = () => {
        const updatedConditionObj = {
            id: props.conditionId,
            type: typeValue,
            condition: ConditionValue,
            value: CategoryValue
        };
        console.log("click delete", updatedConditionObj)
        props.onDeleteCondition(updatedConditionObj);
    }

    return (
        <div className="card mb-2">
            <div className="card-body back-modal">
                <div className="row">
                    <div className="col-md-12">
                        <CacheProvider value={cacheRtl}>
                            <ThemeProvider theme={theme}>
                                <FormControl fullWidth>
                                    <Select
                                        className="custom-select  mb-2"
                                        displayEmpty
                                        value={typeValue}
                                        onChange={handleChangeType}
                                        style={{ fontSize: "14px" }}
                                        inputProps={{ 'aria-label': 'Select' }}>
                                        <MenuItem value="" disabled>
                                            النوع
                                        </MenuItem>
                                        {types && types.map((typeObj: any, index: any) => (
                                            <MenuItem style={{ fontSize: "14px" }} key={typeObj} value={typeObj}>
                                                {typeObj}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </CacheProvider>
                    </div>
                    <div className="col-md-6 mb-2">
                        <CacheProvider value={cacheRtl}>
                            <ThemeProvider theme={theme}>
                                <FormControl fullWidth>
                                    <Select
                                        className="custom-select"
                                        displayEmpty
                                        style={{ fontSize: "14px" }}
                                        value={ConditionValue}
                                        onChange={handleChangeCondition}
                                        inputProps={{ 'aria-label': 'Select' }}>
                                        <MenuItem value="" disabled>
                                            اختر الحالة
                                        </MenuItem>
                                        {conditions && conditions.map((conditionObj: any, index: any) => (
                                            <MenuItem style={{ fontSize: "14px" }} key={conditionObj} value={conditionObj}>
                                                {conditionObj}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </CacheProvider>
                    </div>
                    <div className="col-md-6 mb-2">
                        {selectTypeValue === "list_select" ? (
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <FormControl fullWidth>
                                        <Select
                                            className={`custom-select ${isButtonValidated ? "disabled" : ""}`}
                                            displayEmpty
                                            value={CategoryValue}
                                            onChange={handleChangeCategory}
                                            style={{ fontSize: "14px" }}
                                            inputProps={{ 'aria-label': 'Select' }}>
                                            <MenuItem value="" disabled>
                                            فئة
                                            </MenuItem>
                                            {categories && categories.map((categoryObj: any, index: any) => (
                                                <MenuItem style={{ fontSize: "14px" }} key={index} value={categoryObj}>
                                                    {categoryObj}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ThemeProvider>
                            </CacheProvider>
                        ) : selectTypeValue === "text" ? (
                            <input type="text" value={CategoryValue} onChange={handleChangeCategory} className={`form-control mb-2 ${isButtonValidated ? "disabled" : ""}`} placeholder=" إضافة نص" />
                        ) : selectTypeValue === "number" ? (
                            <input type="number" value={CategoryValue} onChange={handleChangeCategory} min={0} className={`form-control mb-2 ${isButtonValidated ? "disabled" : ""}`} placeholder="إدخال عدد" />
                        ) : selectTypeValue === "range" ? (<div className="d-flex">
                            <input type="number" value={MinValue} onChange={handleChangeMin} min={0} className={`form-control mb-2 ${isButtonValidated ? "disabled" : ""}`} placeholder="الحد الأدنى" style={{ width: "50%" }} />
                            <input type="number" value={MaxValue} onChange={handleChangeMax} min={0} className={`form-control mb-2 ${isButtonValidated ? "disabled" : ""}`} placeholder="الحد الأقصى" style={{ width: "50%" }} />
                        </div>
                        ) : (
                            <input type="text" value={CategoryValue} onChange={handleChangeCategory} className={`form-control mb-2 ${isButtonValidated ? "disabled" : ""}`} placeholder="فئة" />
                        )}

                    </div>
                    <div className="mb-2">
                        <button type="button" id="save-cond" className={`btn btn-outline-primary button-rule ${isButtonValidated ? 'validated' : ''} ${ConditionValue && typeValue ? "" : "disabled"}`}
                            style={{ float: "right", width: "100px" }} onClick={Save}>حفظ</button>
                        <button type="button" id="delete-cond" className={`btn btn-outline-primary button-rule ${isButtonValidated ? "" : 'disabled'}`}
                            style={{ float: "right", width: "100px" }} onClick={Delete}>حذف</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default AddConditionRule;