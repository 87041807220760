import React, { useEffect, useState } from "react";
import { GetEndpoints } from "../../Services/Account";

const AgentPlusMain: React.FunctionComponent<{}> = (props) => {

    const [ListingGenerationEndpoints, setListingGenerationEndpoints] = useState(null);
    const [superResolutionEndpoints, setSuperResolutionEndpoints] = useState(null);
    const [removeWatermarkEndpoints, setRemoveWatermarkEndpoints] = useState(null);
    const [descriptionEndpoints, setDescriptionEndpoints] = useState(null);
    const [generateQRCodeEndpoints, setGenerateQRCodeEndpoints] = useState(null);
    const [titleEndpoints, setTitleEndpoints] = useState(null);
    const [roomStagingEndpoints, setRoomStagingEndpoints] = useState(null);
    const [listingVideoEndpoints, setListingVideoEndpoints] = useState(null);
    const [leaseAgreementEndpoints, setLeaseAgreementEndpoints] = useState(null);
    const [LowLightEnhancementEndpoints, setLowLightEnhancementEndpoints] = useState(null);
    const [skyReplacementBlueSkyEndpoints, setSkyReplacementBlueSkyEndpoints]= useState(null);
    const [skyReplacementDuskShotEndpoints,setSkyReplacementDuskShotEndpoints]= useState(null);
    const [resizeImageEndpoints, setResizeImageEndpoints] = useState(null);
    const [compressImageEndpoints, setCompressImageEndpoints] = useState(null);


    useEffect(() => {
        fetchEndpoints("AI Listing Generation", setListingGenerationEndpoints);
        fetchEndpoints("Image Super Resolution", setSuperResolutionEndpoints);
        fetchEndpoints("Remove Watermark", setRemoveWatermarkEndpoints);
        fetchEndpoints("Description Generation", setDescriptionEndpoints);
        fetchEndpoints("Generate QR Code", setGenerateQRCodeEndpoints);
        fetchEndpoints("Title Generation", setTitleEndpoints);
        fetchEndpoints("Room Staging", setRoomStagingEndpoints);
        fetchEndpoints("Listing Video", setListingVideoEndpoints);
        fetchEndpoints("Generate Lease Agreement", setLeaseAgreementEndpoints);
        fetchEndpoints("Low Light Enhancement", setLowLightEnhancementEndpoints);
        fetchEndpoints("Sky Replacement Blue Sky", setSkyReplacementBlueSkyEndpoints);
        fetchEndpoints("Sky Replacement Dusk Shot", setSkyReplacementDuskShotEndpoints);
        fetchEndpoints("Resize Image", setResizeImageEndpoints);
        fetchEndpoints("Compress Image", setCompressImageEndpoints);

    }, []);

    const fetchEndpoints = async (name: any, setter: any) => {
        try {
            const data = await GetEndpoints(name);
            console.log(data);
            setter(data);
        } catch (error) {
            console.error("Error fetching endpoints:", error);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y ">

            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/agent-plus/ai_generation`} className={`card-body generate-card generate-card-height `}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3333 2.66669H10.6667C6 2.66669 4 5.33335 4 9.33335V22.6667C4 26.6667 6 29.3334 10.6667 29.3334H21.3333C26 29.3334 28 26.6667 28 22.6667V9.33335C28 5.33335 26 2.66669 21.3333 2.66669ZM10.6667 16.3334H16C16.5467 16.3334 17 16.7867 17 17.3334C17 17.88 16.5467 18.3334 16 18.3334H10.6667C10.12 18.3334 9.66667 17.88 9.66667 17.3334C9.66667 16.7867 10.12 16.3334 10.6667 16.3334ZM21.3333 23.6667H10.6667C10.12 23.6667 9.66667 23.2134 9.66667 22.6667C9.66667 22.12 10.12 21.6667 10.6667 21.6667H21.3333C21.88 21.6667 22.3333 22.12 22.3333 22.6667C22.3333 23.2134 21.88 23.6667 21.3333 23.6667ZM24.6667 12.3334H22C19.9733 12.3334 18.3333 10.6934 18.3333 8.66669V6.00002C18.3333 5.45335 18.7867 5.00002 19.3333 5.00002C19.88 5.00002 20.3333 5.45335 20.3333 6.00002V8.66669C20.3333 9.58669 21.08 10.3334 22 10.3334H24.6667C25.2133 10.3334 25.6667 10.7867 25.6667 11.3334C25.6667 11.88 25.2133 12.3334 24.6667 12.3334Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                AI Listing Generation
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Create the entire listing using AI (Image editing, description, title, moderation etc..)</p>
                            <br /><p className="end_points">  {ListingGenerationEndpoints} {ListingGenerationEndpoints == 1 ? "Credit" : "Credits"} / 1 Listing</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/agent-plus/generate-description`} className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3333 2.66669H10.6667C6 2.66669 4 5.33335 4 9.33335V22.6667C4 26.6667 6 29.3334 10.6667 29.3334H21.3333C26 29.3334 28 26.6667 28 22.6667V9.33335C28 5.33335 26 2.66669 21.3333 2.66669ZM10.6667 16.3334H16C16.5467 16.3334 17 16.7867 17 17.3334C17 17.88 16.5467 18.3334 16 18.3334H10.6667C10.12 18.3334 9.66667 17.88 9.66667 17.3334C9.66667 16.7867 10.12 16.3334 10.6667 16.3334ZM21.3333 23.6667H10.6667C10.12 23.6667 9.66667 23.2134 9.66667 22.6667C9.66667 22.12 10.12 21.6667 10.6667 21.6667H21.3333C21.88 21.6667 22.3333 22.12 22.3333 22.6667C22.3333 23.2134 21.88 23.6667 21.3333 23.6667ZM24.6667 12.3334H22C19.9733 12.3334 18.3333 10.6934 18.3333 8.66669V6.00002C18.3333 5.45335 18.7867 5.00002 19.3333 5.00002C19.88 5.00002 20.3333 5.45335 20.3333 6.00002V8.66669C20.3333 9.58669 21.08 10.3334 22 10.3334H24.6667C25.2133 10.3334 25.6667 10.7867 25.6667 11.3334C25.6667 11.88 25.2133 12.3334 24.6667 12.3334Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Generate Description
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Generate property description using property details and details in images.</p>
                            <br /> <p className="end_points">{descriptionEndpoints} {descriptionEndpoints == 1 ? "Credit" : "Credits"} / 1 Text</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/agent-plus/generate-title`} className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-red">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.1333 2.66669H12.8667C11.48 2.66669 10.3467 3.78669 10.3467 5.17335V6.42669C10.3467 7.81335 11.4667 8.93335 12.8533 8.93335H19.1333C20.52 8.93335 21.64 7.81335 21.64 6.42669V5.17335C21.6533 3.78669 20.52 2.66669 19.1333 2.66669Z" fill="#FF6F61" />
                                    <path d="M22.9867 6.42664C22.9867 8.54664 21.2534 10.28 19.1334 10.28H12.8667C10.7467 10.28 9.01339 8.54664 9.01339 6.42664C9.01339 5.67997 8.21339 5.21331 7.54672 5.55997C5.66672 6.55997 4.38672 8.54664 4.38672 10.8266V23.3733C4.38672 26.6533 7.06672 29.3333 10.3467 29.3333H21.6534C24.9334 29.3333 27.6134 26.6533 27.6134 23.3733V10.8266C27.6134 8.54664 26.3334 6.55997 24.4534 5.55997C23.7867 5.21331 22.9867 5.67997 22.9867 6.42664ZM16.5067 22.6H10.6667C10.1201 22.6 9.66672 22.1466 9.66672 21.6C9.66672 21.0533 10.1201 20.6 10.6667 20.6H16.5067C17.0534 20.6 17.5067 21.0533 17.5067 21.6C17.5067 22.1466 17.0534 22.6 16.5067 22.6ZM20.0001 17.2666H10.6667C10.1201 17.2666 9.66672 16.8133 9.66672 16.2666C9.66672 15.72 10.1201 15.2666 10.6667 15.2666H20.0001C20.5467 15.2666 21.0001 15.72 21.0001 16.2666C21.0001 16.8133 20.5467 17.2666 20.0001 17.2666Z" fill="#FF6F61" />
                                </svg>

                            </div>
                            <h2>
                                Generate Title
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> Generate property title using property details and details in images.</p>
                            <br /><p className="end_points"> {titleEndpoints} {titleEndpoints == 1 ? "Credit" : "Credits"} / 1 Text </p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/agent-plus/generate-QR-code`} className={`card-body generate-card generate-card-height `}>
                            <div className="rounded backg-orange">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44007 25.3467L3.4134 25.3733C3.0534 24.5867 2.82673 23.6933 2.7334 22.7067C2.82673 23.68 3.08007 24.56 3.44007 25.3467Z" fill="#FCAF3C" />
                                    <path d="M12.0005 13.84C13.7531 13.84 15.1738 12.4193 15.1738 10.6667C15.1738 8.9141 13.7531 7.49335 12.0005 7.49335C10.2479 7.49335 8.82715 8.9141 8.82715 10.6667C8.82715 12.4193 10.2479 13.84 12.0005 13.84Z" fill="#FCAF3C" />
                                    <path d="M21.587 2.66669H10.4137C5.56033 2.66669 2.66699 5.56002 2.66699 10.4134V21.5867C2.66699 23.04 2.92033 24.3067 3.41366 25.3734C4.56033 27.9067 7.01366 29.3334 10.4137 29.3334H21.587C26.4403 29.3334 29.3337 26.44 29.3337 21.5867V18.5334V10.4134C29.3337 5.56002 26.4403 2.66669 21.587 2.66669ZM27.1603 16.6667C26.1203 15.7734 24.4403 15.7734 23.4003 16.6667L17.8537 21.4267C16.8137 22.32 15.1337 22.32 14.0937 21.4267L13.6403 21.0534C12.6937 20.2267 11.187 20.1467 10.1203 20.8667L5.13366 24.2134C4.84033 23.4667 4.66699 22.6 4.66699 21.5867V10.4134C4.66699 6.65335 6.65366 4.66669 10.4137 4.66669H21.587C25.347 4.66669 27.3337 6.65335 27.3337 10.4134V16.8134L27.1603 16.6667Z" fill="#FCAF3C" />
                                </svg>

                            </div>
                            <h2>
                                Generate QR code
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>A feature that allows you to generate QR codes to easily and quickly
                                encode data and links.</p>
                            <br /><p className="end_points">{generateQRCodeEndpoints} {generateQRCodeEndpoints == 1 ? "Credit" : "Credits"} / 1 Image </p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/agent-plus/room-staging`} className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-blue">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10682 25.8933C6.96016 25.8933 6.80016 25.8533 6.66682 25.7867L3.70682 24.3067C3.37349 24.1333 3.16016 23.7867 3.16016 23.4133V19.7067C3.16016 19.16 3.61349 18.7067 4.16016 18.7067C4.70682 18.7067 5.16016 19.16 5.16016 19.7067V22.7867L7.56016 24C8.05349 24.24 8.25349 24.84 8.00016 25.3333C7.82682 25.68 7.48016 25.8933 7.10682 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 30.3333C15.9203 30.3333 15.8403 30.32 15.7736 30.3066C15.6936 30.2933 15.6136 30.2533 15.5336 30.2133L12.5869 28.7466C12.0936 28.4933 11.8936 27.8933 12.1336 27.4C12.3869 26.9066 12.9869 26.7066 13.4803 26.9466L15.0003 27.7066V25.6266C15.0003 25.08 15.4536 24.6266 16.0003 24.6266C16.5469 24.6266 17.0003 25.08 17.0003 25.6266V27.7066L18.5203 26.9466C19.0136 26.7066 19.6136 26.9066 19.8669 27.4C20.1203 27.8933 19.9203 28.4933 19.4136 28.7466L16.4669 30.2133C16.3869 30.2533 16.3069 30.28 16.2269 30.3066C16.1469 30.32 16.0803 30.3333 16.0003 30.3333Z" fill="#5782C2" />
                                    <path d="M24.8936 25.8933C24.5336 25.8933 24.1736 25.6933 24.0002 25.3466C23.7469 24.8533 23.9469 24.2533 24.4536 24L26.8669 22.8V19.72C26.8669 19.1733 27.3202 18.72 27.8669 18.72C28.4136 18.72 28.8669 19.1733 28.8669 19.72V23.4266C28.8669 23.8 28.6536 24.1466 28.3202 24.32L25.3336 25.7866C25.1869 25.8533 25.0402 25.8933 24.8936 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 19.2267C15.4536 19.2267 15.0003 18.7733 15.0003 18.2267V15.1467L12.5869 13.9467C12.0936 13.6933 11.8936 13.0933 12.1336 12.6C12.3869 12.1067 12.9869 11.9067 13.4803 12.1467L16.0003 13.4L18.5203 12.1467C19.0136 11.9067 19.6136 12.0933 19.8669 12.6C20.1203 13.0933 19.9203 13.6933 19.4136 13.9467L17.0003 15.1467V18.2267C17.0003 18.7733 16.5469 19.2267 16.0003 19.2267Z" fill="#5782C2" />
                                    <path d="M4.14648 13.2933C3.59982 13.2933 3.14648 12.84 3.14648 12.2933V8.61332V8.59999C3.14648 8.51999 3.15982 8.43999 3.17315 8.37332C3.19982 8.25332 3.23982 8.15999 3.29315 8.06665C3.33315 7.99999 3.38648 7.94665 3.43982 7.89332C3.49315 7.83999 3.55982 7.78665 3.62648 7.74665C3.65315 7.73332 3.69315 7.70665 3.71982 7.69332L6.66648 6.21332C7.15982 5.97332 7.75982 6.17332 7.99982 6.66665C8.25315 7.15999 8.05315 7.75999 7.55982 7.99999L6.38648 8.58665L7.55982 9.17332C8.05315 9.42665 8.25315 10.0267 7.99982 10.52C7.75982 11.0133 7.15982 11.2133 6.66648 10.9733L5.14648 10.2133V12.2933C5.14648 12.8533 4.70648 13.2933 4.14648 13.2933Z" fill="#5782C2" />
                                    <path d="M18.9604 5.14666C18.8137 5.14666 18.6537 5.10666 18.5204 5.04L16.0004 3.78666L13.4804 5.04C12.9871 5.29333 12.3871 5.09333 12.1471 4.6C11.8937 4.10666 12.0937 3.50666 12.5871 3.25333L15.5471 1.77333C15.8271 1.62666 16.1604 1.62666 16.4404 1.77333L19.4004 3.25333C19.8937 3.50666 20.0937 4.10666 19.8537 4.6C19.6804 4.94666 19.3337 5.14666 18.9604 5.14666Z" fill="#5782C2" />
                                    <path d="M27.8529 13.2933C27.3063 13.2933 26.8529 12.84 26.8529 12.2933V10.2133L25.3329 10.9733C24.8396 11.2133 24.2396 11.0133 23.9996 10.52C23.7463 10.0267 23.9463 9.42665 24.4529 9.17332L25.6263 8.58665L24.4396 7.99999C23.9463 7.75999 23.7463 7.15999 23.9996 6.66665C24.2529 6.17332 24.8396 5.97332 25.3329 6.21332L28.2796 7.67999C28.3063 7.69332 28.3463 7.70665 28.3729 7.73332C28.4529 7.77332 28.5196 7.83999 28.5863 7.90665C28.6263 7.95999 28.6663 8.01332 28.7063 8.06665C28.7596 8.15999 28.7996 8.25332 28.8263 8.35999C28.8396 8.43999 28.8529 8.51999 28.8529 8.58665V8.59999V12.28C28.8529 12.8533 28.3996 13.2933 27.8529 13.2933Z" fill="#5782C2" />
                                </svg>

                            </div>
                            <h2>
                                Room Staging
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Transform and enhance the visual appeal of rooms to make them more inviting and attractive for various purposes.</p>
                            <br /><p className="end_points"> {roomStagingEndpoints} {roomStagingEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/agent-plus/listing-video`} className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-marron">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5867 2.66666H17V10.6667V11.6667V18.3333H29.3333V11.6667V10.6667V10.4133C29.3333 5.55999 26.44 2.66666 21.5867 2.66666Z" fill="#C29157" />
                                    <path d="M2.66699 13.6667V20.3333V21V21.5867C2.66699 26.44 5.56033 29.3333 10.4137 29.3333H15.0003V21V20.3333V13.6667H2.66699Z" fill="#C29157" />
                                    <path d="M15.0003 2.66666V11.6667H2.66699V10.4133C2.66699 5.55999 5.56033 2.66666 10.4137 2.66666H15.0003Z" fill="#C29157" />
                                    <path d="M29.3333 20.3333V21.5867C29.3333 26.44 26.44 29.3333 21.5867 29.3333H17V20.3333H29.3333Z" fill="#C29157" />
                                </svg>
                            </div>
                            <h2>
                                Listing Video
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Create captivating video content to showcase properties and listings, making them more engaging for potential buyers or renters.</p>
                            <br /><p className="end_points">{listingVideoEndpoints} {listingVideoEndpoints == 1 ? "Credit" : "Credits"} / 1 Video</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/agent-plus/generate-lease-agreement`} className="card-body generate-card generate-card-height">
                            <div className="rounded backg-mauve">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0003 2.66669C8.64033 2.66669 2.66699 8.64002 2.66699 16C2.66699 23.36 8.64033 29.3334 16.0003 29.3334C23.3603 29.3334 29.3337 23.36 29.3337 16C29.3337 8.64002 23.3603 2.66669 16.0003 2.66669ZM16.3203 20.3334C17.3603 20.3334 18.387 19.9467 19.1737 19.2534C19.587 18.8934 20.227 18.9334 20.587 19.3467C20.947 19.76 20.907 20.4 20.4937 20.76C19.3337 21.7867 17.8537 22.3467 16.3203 22.3467C12.827 22.3467 9.98699 19.5067 9.98699 16.0134C9.98699 12.52 12.827 9.68002 16.3203 9.68002C17.8537 9.68002 19.347 10.24 20.4937 11.2667C20.907 11.6267 20.947 12.2667 20.587 12.68C20.2137 13.0934 19.587 13.1334 19.1737 12.7734C18.387 12.08 17.3737 11.6934 16.3203 11.6934C13.9337 11.6934 11.987 13.64 11.987 16.0267C11.987 18.4134 13.9337 20.3334 16.3203 20.3334Z" fill="#7E57C2" />
                                </svg>
                            </div>
                            <h2>
                                Generate Lease Agreement <br />(Dubai)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>

                            </h2>
                            <p>Generate Lease Agreements in seconds using OCR to
                                automatically extract data from ID documents and title deeds.</p>
                            <br /><p className="end_points">{leaseAgreementEndpoints} {leaseAgreementEndpoints == 1 ? "Credit" : "Credits"} / 1 Document Generated</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/agent-plus/image-super-resolution' className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.88 14.2929H19.76V4.69287C19.76 2.45287 18.5467 1.99954 17.0667 3.67954L16 4.89287L6.97335 15.1595C5.73335 16.5595 6.25335 17.7062 8.12002 17.7062H12.24V27.3062C12.24 29.5462 13.4533 29.9995 14.9333 28.3195L16 27.1062L25.0267 16.8395C26.2667 15.4395 25.7467 14.2929 23.88 14.2929Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Image Super Resolution
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Enhance the quality of your images by increasing their resolution and clarity.</p>
                            <br /><p className="end_points">{superResolutionEndpoints} {superResolutionEndpoints == 1 ? "Credit" : "Credits"} / 1 Image </p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/agent-plus/remove-watermark' className="card-body generate-card enhance-card-height">
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Remove Watermark (Dubai)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Erase any watermarks or logos from images to regain the original content.</p>
                            <br /><p className="end_points">{removeWatermarkEndpoints} {removeWatermarkEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href='/agent-plus/remove-watermrk-universal' className={`card-body generate-card enhance-card-height `}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 29.3337C19.4533 29.3337 19 28.8803 19 28.3337V3.66699C19 3.12033 19.4533 2.66699 20 2.66699C20.5467 2.66699 21 3.12033 21 3.66699V28.3337C21 28.8803 20.5467 29.3337 20 29.3337Z" fill="#46B49E" />
                                    <path d="M24 26.6663H20V5.33301H24C26.9467 5.33301 29.3333 7.71967 29.3333 10.6663V21.333C29.3333 24.2797 26.9467 26.6663 24 26.6663Z" fill="#46B49E" />
                                    <path d="M8.00008 5.33301C5.05341 5.33301 2.66675 7.71967 2.66675 10.6663V21.333C2.66675 24.2797 5.05341 26.6663 8.00008 26.6663H14.6667C15.4001 26.6663 16.0001 26.0663 16.0001 25.333V6.66634C16.0001 5.93301 15.4001 5.33301 14.6667 5.33301H8.00008ZM10.3334 20.6663C10.3334 21.213 9.88008 21.6663 9.33341 21.6663C8.78675 21.6663 8.33341 21.213 8.33341 20.6663V11.333C8.33341 10.7863 8.78675 10.333 9.33341 10.333C9.88008 10.333 10.3334 10.7863 10.3334 11.333V20.6663Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Remove Watermark (Universal)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Erase any watermarks or logos from images to regain the original content.</p>
                            <br /><p className="end_points">{removeWatermarkEndpoints} {removeWatermarkEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/agent-plus/sky-replacement-blue-sky' className={`card-body generate-card enhance-card-height `}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            Sky Replacement - Blue Sky
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Feature that enhances the sky in your images, making it more vibrant and visually appealing by adjusting colors, contrast, and brightness.</p>
                            <br /><p className="end_points">{skyReplacementBlueSkyEndpoints} {skyReplacementBlueSkyEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/agent-plus/sky-replacement-dusk-shot' className={`card-body generate-card enhance-card-height`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                            Sky Replacement - Dusk Shot 
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Feature that enhances the sky in your images, making it more vibrant and visually appealing by adjusting colors, contrast, and brightness.</p>
                            <br /><p className="end_points">{skyReplacementDuskShotEndpoints} {skyReplacementDuskShotEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row card-bottom">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href='/agent-plus/low-light-enhancement' className={`card-body generate-card enhance-card-height`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.3334 12.1999C25.2534 7.35991 20.7467 4.57324 16.0001 4.57324C13.6267 4.57324 11.3201 5.26658 9.21341 6.55991C7.10675 7.86658 5.21341 9.77324 3.66675 12.1999C2.33341 14.2932 2.33341 17.6932 3.66675 19.7866C6.74675 24.6399 11.2534 27.4132 16.0001 27.4132C18.3734 27.4132 20.6801 26.7199 22.7867 25.4266C24.8934 24.1199 26.7867 22.2132 28.3334 19.7866C29.6667 17.7066 29.6667 14.2932 28.3334 12.1999ZM16.0001 21.3866C13.0134 21.3866 10.6134 18.9732 10.6134 15.9999C10.6134 13.0266 13.0134 10.6132 16.0001 10.6132C18.9867 10.6132 21.3867 13.0266 21.3867 15.9999C21.3867 18.9732 18.9867 21.3866 16.0001 21.3866Z" fill="#46B49E" />
                                    <path d="M16 12.1865C13.9066 12.1865 12.2 13.8932 12.2 15.9999C12.2 18.0932 13.9066 19.7999 16 19.7999C18.0933 19.7999 19.8133 18.0932 19.8133 15.9999C19.8133 13.9065 18.0933 12.1865 16 12.1865Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Low Light Enhancement
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Feature that enhances images taken in low-light conditions, improving visibility and clarity without compromising the natural look of the photo.</p>
                            <br /><p className="end_points">{LowLightEnhancementEndpoints} {LowLightEnhancementEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-3 ">
                    <div className="card">
                        <a href="/agent-plus/resize-image" className={`card-body generate-card enhance-card-height`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Resize Image
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Easily adjust the dimensions of your images to meet your specific needs without
                                compromising on quality.</p>
                            <br /><p className="end_points">{resizeImageEndpoints} {resizeImageEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-3 ">
                    <div className="card">
                        <a href="/agent-plus/compress-image" className={`card-body generate-card enhance-card-height`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.3334 6.66699V9.33366C29.3334 11.7737 28.2267 13.0937 26.0001 13.2937C25.7867 13.3203 25.5601 13.3337 25.3334 13.3337H6.66675C6.44008 13.3337 6.21341 13.3203 6.00008 13.2937C3.77341 13.0937 2.66675 11.7737 2.66675 9.33366V6.66699C2.66675 4.00033 4.00008 2.66699 6.66675 2.66699H25.3334C28.0001 2.66699 29.3334 4.00033 29.3334 6.66699Z" fill="#46B49E" />
                                    <path d="M7.33333 15C6.6 15 6 15.6 6 16.3333V25.3333C6 28 6.66667 29.3333 10 29.3333H22C25.3333 29.3333 26 28 26 25.3333V16.3333C26 15.6 25.4 15 24.6667 15H7.33333ZM18.4267 21H13.5733C13.0267 21 12.5733 20.5467 12.5733 20C12.5733 19.4533 13.0267 19 13.5733 19H18.4267C18.9733 19 19.4267 19.4533 19.4267 20C19.4267 20.5467 18.9733 21 18.4267 21Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Compress Image
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Reduce the size of your images without affecting the quality, making it easy
                                to share them online or save storage space on your devices.</p>
                            <br /><p className="end_points">{compressImageEndpoints} {compressImageEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>

                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgentPlusMain