import React, { useEffect, useState } from 'react';
import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

export const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
          display: false
      }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
        stacked: true,
        grid: {
            display: false,
          },
    },
    y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
    },
},
}; 
const labels = ['Morgage Requests', 'Unavailabe Property', 'Property Mismatch', 'Price', 'Location'];

export const data = {
  labels,
  datasets: [
    {
      label: 'topic',
      data: [255,198,25,78,178],
      borderColor: '#716BE3',
      backgroundColor: '#716BE3',
    }
  ],
};
const HorizontalBarChart: React.FunctionComponent<{}> = (props) => {


     return <div  className="line-chart-container">
  <Bar options={options} data={data as any} /></div>
}
export default HorizontalBarChart 