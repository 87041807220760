import { useEffect, useState } from "react";
import RecentVideos from "./recent-videos";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { GetAgentMusics, GetAgentScenes, GetAgentTemplate } from "../../../../Services/GenerateVideo";
import { useNavigate } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#716BE3',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#716BE3',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#716BE3',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#716BE3',
            zIndex: 1,
            fontSize: 18,
            fontWeight: 700,
        },
        '& .QontoStepIcon-circle-1': {
            width: 24,
            height: 24,
            borderRadius: '12px',
            backgroundColor: '#E3E3E3',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',

        },
    }),
);

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className="QontoStepIcon-circle-1">
                    <Check className="QontoStepIcon-completedIcon" />
                </div>
            ) : (
                <div className="QontoStepIcon-circle-1">
                    <div className="QontoStepIcon-circle" />
                </div>
            )}
        </QontoStepIconRoot>
    );
}

const AgentProfileNew1: React.FunctionComponent<{}> = (props) => {

    const [nameValue, setNameValue] = useState<any>(JSON.parse(localStorage.getItem('profile') || ""))
    const [listTemplates, setListTemplates] = useState<any>([]);
    const [listMusics, setListMusics] = useState<any>([]);
    const [listScenes, setListScenes] = useState<any>([]);
    const navigate = useNavigate();
    const [sceneOrder, setSceneOrder] = useState<{ [key: string]: number }>({});
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const steps = ['Select Scenes', 'Select Templates', 'Select Music', 'Select Features'];
    const [selectedScenes, setSelectedScenes] = useState<any>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
    const [selectedMusic, setSelectedMusic] = useState<any>([]);
    const [activeStep, setActiveStep] = useState<any>(0)
    const [voiceOver, setVoiceOver] = useState<string>();
    const [language, setLanguage] = useState<string>("English");
    const [script, setScript] = useState<string>();

    useEffect(() => {
        GetAgentTemplate().then((data) => {
            setListTemplates(data)
            setLoading(false)
        }).catch(() => {
            console.log("Error")
        })

        GetAgentMusics().then((data) => {
            setListMusics(data)
            setLoading(false)
        }).catch(() => {
            console.log("Error")
        })

        GetAgentScenes().then((data) => {
            setListScenes(data);
            setLoading(false)
        }).catch(() => {
            console.log("Error")
        })
    }, [])

    const handleCheckboxChangeTemplate = (template: any) => {
        setSelectedTemplate((prevSelectedTemplates: any) => {
            let updatedSelectedTemplates;

            // Vérifier si le template est déjà sélectionné
            if (prevSelectedTemplates.includes(template)) {
                // Supprimer le template de la sélection
                updatedSelectedTemplates = prevSelectedTemplates.filter((selectedTemplate: any) => selectedTemplate !== template);
            } else {
                // Ajouter le template à la sélection
                updatedSelectedTemplates = [...prevSelectedTemplates, template];
            }

            // Mettre à jour activeStep en fonction de la nouvelle sélection
            if (updatedSelectedTemplates.length !== 0) {
                setActiveStep(2);
            } else {
                setActiveStep(1);
            }

            return updatedSelectedTemplates;
        });
    };

    const handleCheckboxChangeMusic = (music: any) => {
        setSelectedMusic((prevSelectedMusics: any) => {
            const updatedSelectedMusics = prevSelectedMusics.includes(music) ? [] : [music];

            // Mettre à jour activeStep en fonction de la nouvelle sélection
            if (updatedSelectedMusics.length !== 0) {
                setActiveStep(3);
            } else {
                setActiveStep(2);
            }

            return updatedSelectedMusics;
        });
    };

    const handleCheckboxChangeScenes = (scene: any) => {
        setSelectedScenes((prevSelected: any) => {
            const isSelected = prevSelected.some((s: any) => s._id === scene._id);
            let updatedSelectedScenes;
            let updatedSceneOrder = { ...sceneOrder };

            if (isSelected) {
                updatedSelectedScenes = prevSelected.filter((s: any) => s._id !== scene._id);
                // Réorganiser l'ordre des scènes restantes
                updatedSelectedScenes.forEach((s: any, index: any) => {
                    updatedSceneOrder[s._id] = index + 1;
                });
                console.log("if", updatedSelectedScenes)
                delete updatedSceneOrder[scene._id];
            } else {
                const newScene = { ...scene, order: prevSelected.length + 1 };
                updatedSelectedScenes = [...prevSelected, newScene];
                updatedSceneOrder[scene._id] = updatedSelectedScenes.length;
                console.log("else", updatedSelectedScenes[0].order)
            }

            setSceneOrder(updatedSceneOrder);

            if (updatedSelectedScenes.length !== 0) {
                setActiveStep(1);
            } else {
                setActiveStep(0);
            }

            return updatedSelectedScenes;
        });
    };

    const handleVoiceOverChange = (event: any) => {
        setVoiceOver(event.target.value);
    };

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value);
    };

    const handleScriptChange = (event: any) => {
        setScript(event.target.value);
        setActiveStep(4);
    };

    const Next = () => {
        const obj = {
            scenes: selectedScenes,
            template: selectedTemplate,
            music: selectedMusic,
            voice_over: voiceOver,
            lang: language,
            script: script
        }
        navigate('/agent-profile/generate-video', { state: obj });
    }

    return loading ? <div className="container-xxl flex-grow-1 container-p-y"></div> : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card agent-profile">
                <div className="card-body padd-40">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h1 className="title-agent">Hello, {nameValue.family_name}</h1>
                            <p className="para-agent">Let’s create some magical videos today</p>
                        </div>

                    </div>
                    <RecentVideos type="agent" Change={change} />
                    <div className="stack-agent">
                        <Stack sx={{ width: '100%' }} spacing={4}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon} >{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                    </div>

                    <div className="row">
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <h2>Select Video Scenes</h2>
                        </div>
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    placeholder="Keywords Search"
                                    className="select-duration"
                                    sx={{ margin: "8px" }}
                                    inputProps={{ 'aria-label': 'weight' }}
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">Select Duration</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    className="select-duration"
                                    label="Select Duration"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="swipper-list">
                        {listScenes.length !== 0 && <Swiper
                            slidesPerView={3}
                            //  centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                }
                            }}
                        >
                            {listScenes.map((scene: any, index: any) => (
                                <SwiperSlide>
                                    <div className="video-container-swiper" key={`scene_${scene._id}`}>
                                        <div className="checkbox checkbox-info checkbox-circle">
                                            <input
                                                id={`checkbox_scene${index}`}
                                                className="styled"
                                                type="checkbox"
                                                checked={selectedScenes.some((s: any) => s._id === scene._id)}
                                                onChange={() => handleCheckboxChangeScenes(scene)}
                                            />
                                            <label htmlFor={`checkbox_scene${index}`}></label>
                                        </div>

                                        <label className="videos">
                                            <div className="video-wrapper ">
                                                <span className="type">{"Stock Scenes"}</span>
                                                <span className="autre">
                                                    {sceneOrder[scene._id] ? sceneOrder[scene._id] : ""}
                                                </span>
                                                <video controls className="custom-stream-gener">
                                                    <source src={scene.videoUrl} type="video/mp4"></source>
                                                </video>
                                                <h1>{scene.name}</h1>
                                                <h1 style={{ left: "auto", right: "15px" }}>{scene.duration}</h1>
                                                <p>{scene.description}</p>

                                            </div>
                                        </label>
                                    </div>
                                </SwiperSlide>))}
                        </Swiper>}
                        <p className={`browse-scenes mt-2 ${listScenes.length == 0 ? "disabled" : ""}`} data-bs-toggle="modal" data-bs-target="#browse-scenes-all" >Browse All</p>

                    </div>

                    <div className="row mrg-t-50">
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <h2>Select Video Template</h2>
                        </div>
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">Color Theme</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    className="select-duration"
                                    label="Color Theme"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4 form-control-wrapper mb-3">
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">Template Type</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    className="select-duration"
                                    label="Template Type"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="swipper-list">
                        {listTemplates.length !== 0 && <Swiper
                            slidesPerView={3}
                            // centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                }
                            }}
                        >
                            {listTemplates.map((template: any, index: any) => (
                                <SwiperSlide>
                                    <div className="video-container-swiper" key={`template${template._id}`}>
                                        <div className="checkbox checkbox-info checkbox-circle" >
                                            <input id={`checkbox_template_${index}`} className="styled" type="checkbox" checked={selectedTemplate.includes(template)}
                                                onChange={() => handleCheckboxChangeTemplate(template)} />
                                            <label htmlFor={`checkbox_template_${index}`}>
                                            </label>
                                        </div>
                                        <label className="videos">
                                            <div className="video-wrapper ">
                                                <span className="type">{template.type}</span>
                                                <video controls className="custom-stream-gener">
                                                    <source src={template.videoUrl} type="video/mp4"></source>
                                                </video>
                                                <h1>{template.name}</h1>
                                                <h1 style={{ left: "auto", right: "15px" }}>{template.duration}</h1>
                                                <p>{template.description}</p>
                                            </div>
                                        </label>
                                    </div>
                                </SwiperSlide>))}

                        </Swiper>}
                        <p className={`browse-scenes mt-2 ${listTemplates.length == 0 ? "disabled" : ""}`} data-bs-toggle="modal" data-bs-target="#browse-template-all">Browse All</p>

                    </div>

                    <div className="row mrg-t-50">
                        <div className="col-md-12 d-flex align-items-center">
                            <h2>Select Background Music</h2>
                        </div>
                    </div>
                    <div className="swipper-list">
                        {listMusics.length !== 0 && <Swiper
                            slidesPerView={3}
                            // centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                }
                            }}
                        >
                            {listMusics.map((music: any, index: any) => (
                                <SwiperSlide>
                                    <div className="video-container-swiper" key={`music${index}`}>
                                        <div className="checkbox checkbox-info checkbox-circle" >
                                            <input id={`checkbox_music_${index}`} className="styled" type="checkbox" checked={selectedMusic.includes(music)}
                                                onChange={() => handleCheckboxChangeMusic(music)} />
                                            <label htmlFor={`checkbox_music_${index}`}>
                                            </label>
                                        </div>
                                        <label className="videos">
                                            <div className="video-wrapper ">
                                                <img src={music.image} className="custom-stream-gener-music" />
                                                <h2 className="title-music">{music.name}</h2>
                                                <AudioPlayer
                                                    src={music.MusucsUrl}
                                                    className="audio-player"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>}
                        <p className={`browse-scenes mt-2 ${listMusics.length == 0 ? "disabled" : ""}`} data-bs-toggle="modal" data-bs-target="#browse-music-all" >Browse All</p>
                    </div>

                    <div className="row mrg-t-50 mb-4">
                        <div className="col-md-12 d-flex align-items-center">
                            <h2>Select Features</h2>
                        </div>
                    </div>
                    {/* Voice over */}
                    <div className="row features mb-3">
                        <div className="col-md-3 mb-3">
                            <h3>Voice over</h3>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="voiceOver"
                                    id="None"
                                    value="None"
                                    checked={voiceOver === 'None'}
                                    onChange={handleVoiceOverChange}
                                />
                                <label className="form-check-label label-p" htmlFor="None">
                                    None
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="voiceOver"
                                    id="Male"
                                    value="Male"
                                    checked={voiceOver === 'Male'}
                                    onChange={handleVoiceOverChange}
                                />
                                <label className="form-check-label label-p" htmlFor="Male">
                                    Male
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="voiceOver"
                                    id="Female"
                                    value="Female"
                                    checked={voiceOver === 'Female'}
                                    onChange={handleVoiceOverChange}
                                />
                                <label className="form-check-label label-p" htmlFor="Female">
                                    Female
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* Language */}
                    <div className="row features mb-3">
                        <div className="col-md-3 mb-3">
                            <h3>Language</h3>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="language"
                                    id="English"
                                    value="English"
                                    checked={language === 'English'}
                                    onChange={handleLanguageChange}
                                />
                                <label className="form-check-label label-p" htmlFor="English">
                                    English
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="language"
                                    id="French"
                                    value="French"
                                    checked={language === 'French'}
                                    onChange={handleLanguageChange}
                                    disabled
                                />
                                <label className="form-check-label label-p" htmlFor="French">
                                    French
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input "
                                    type="radio"
                                    name="language"
                                    id="Arabic"
                                    value="Arabic"
                                    checked={language === 'Arabic'}
                                    onChange={handleLanguageChange}
                                    disabled
                                />
                                <label className="form-check-label label-p" htmlFor="Arabic">
                                    Arabic
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* Script */}
                    <div className="row features mb-4">
                        <div className="col-md-3 mb-3">
                            <h3>Script</h3>
                        </div>
                        <div className="col-md-3  mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="Manual"
                                    id="Manual"
                                    value="Manual"
                                    checked={script === 'Manual'}
                                    onChange={handleScriptChange}
                                />
                                <label className="form-check-label label-p" htmlFor="Manual">
                                    Manual
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3  mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="Autogenerate"
                                    id="Autogenerate"
                                    value="Autogenerate"
                                    checked={script === 'Autogenerate'}
                                    onChange={handleScriptChange}
                                />
                                <label className="form-check-label label-p" htmlFor="Autogenerate">
                                    Autogenerate
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="row d-flex align-items-center justify-content-center mt-t">
                        <button className={`create ${selectedMusic.length != 0 && selectedScenes != 0 && selectedTemplate != 0 && language && voiceOver && script ? "" : "disabled"}`} type="button" onClick={Next}>
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="browse-scenes-all" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Video Scenes</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="selected-videos-agent padd-modal">
                                        {listScenes && listScenes.map((scene: any, index: any) => (
                                            <div key={`video${index}`} className="video-container">
                                                <label htmlFor={`video${index}`} className="videos">
                                                    <div className="video-wrapper ">
                                                        <span className="type">{"Stock Scenes"}</span>
                                                        <span className="autre">
                                                            {sceneOrder[scene._id] ? sceneOrder[scene._id] : ""}
                                                        </span>

                                                        <video controls className="custom-stream-gener">
                                                            <source src={scene.videoUrl} type="video/mp4"></source>
                                                        </video>
                                                        <h1>{scene.name}</h1>
                                                        <h1 style={{ left: "auto", right: "15px" }}>{scene.duration}</h1>
                                                        <p>{scene.description}</p>

                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="browse-template-all" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Video Template</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="selected-videos-agent padd-modal">
                                        {listTemplates && listTemplates.map((template: any, index: any) => (
                                            <div key={`video${index}`} className="video-container">
                                                <label htmlFor={`video${index}`} className="videos">
                                                    <div className="video-wrapper ">
                                                        <span className="type">{template.type}</span>
                                                        <video controls className="custom-stream-gener">
                                                            <source src={template.videoUrl} type="video/mp4"></source>
                                                        </video>
                                                        <h1>{template.name}</h1>
                                                        <h1 style={{ left: "auto", right: "15px" }}>{template.duration}</h1>
                                                        <p>{template.description}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="browse-music-all" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Music</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="selected-videos-agent padd-modal">
                                        {listMusics && listMusics.map((music: any, index: any) => (
                                            <div key={`video${index}`} className="video-container">
                                                <label htmlFor={`video${index}`} className="videos">
                                                    <div className="video-wrapper ">
                                                        <img src={music.image} className="custom-stream-gener-music" />
                                                        <h2 className="title-music">{music.name}</h2>
                                                        <AudioPlayer
                                                            src={music.MusucsUrl}
                                                            className="audio-player"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgentProfileNew1;
