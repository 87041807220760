import React, { FunctionComponent, useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { getAgentListings, getPropertiesById } from "../../Services/Properties";
import { useLocation } from "react-router-dom";
import { PropertiesObj } from "../../Models/PropertiesObj";
import PropertyDesc from "../Properties/property-desc";
import AdDetails from "../Properties/ad-details";
import Duplicates from "../Properties/duplicates";


const Url = process.env.REACT_APP_URl;


const ItemDetails: FunctionComponent<{}> = (props) => {
    let location = useLocation();
    const idValue = location.pathname.split('/');
    const [propertyValue, setPropertyValue] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [agentListingsCount, setAgentListingsCount] = useState<any>();


    useEffect(() => {
        const id: any = idValue[4];
        getPropertiesById(id).then((data) => {
            setPropertyValue(data)
            setLoading(false) 
            getAgentListings(data.user.companyName).then((data) => {
                setAgentListingsCount(data.count)
            })
        }).catch((error) => {
            console.log("error")
        })

    }, [])



    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> تحميل ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row card-bottom">
                <div className="col-md-8">
                    <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body">
                            {propertyValue.content.images ? <div className="sliderProp">
                                <ImageGallery items={propertyValue.content.images.map((m: any) => ({ "original": m.src, "thumbnail": m.src }))}
                                    showThumbnails={true}
                                    showPlayButton={false}
                                />
                            </div> : <div className="sliderProp"></div>}
                            <PropertyDesc property={propertyValue} />
                        </div>
                    </div>
                    {propertyValue.content.floorPlan && propertyValue.content.floorPlan.length!=0 && propertyValue.content.floorPlan[0].src!=="" ? <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5>مخطط الطابق</h5>
                            <div className="view_floorplan">
                            <img src={propertyValue.content.floorPlan[0].src} title="floor plan"/>
                            </div>
                        </div>
                    </div>:<></>}
                    {propertyValue.content.videoTourUrl && <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> فيديو العقار</h5>
                            <div className="view_video">
                            <iframe src={propertyValue.content.videoTourUrl.src} title="360° Virtual tours"> </iframe>
                            </div>
                        </div>
                    </div>}
                    {propertyValue.content.view360 && <div className="card mb-4 padd-32 slider-mobile">
                        <div className="card-body ad-details" >
                            <h5> عرض 360</h5>
                            <div className="view_video">
                            <iframe src={propertyValue.content.view360.src} title="360° Virtual tours"></iframe>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="col-md-4">
                    <div className="card padd-20">
                        <div className="card-body">
                            <div className="d-flex desc-agent">
                                <div className="d-flex align-items-start justify-content-between" >
                                    <img src={propertyValue.content.company ? propertyValue.content.company.logo.src : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNkSceN7Yy0h33pjiGTEK34jilQGsBGwOMr798npqgg&s"} className="image-agent" />
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.4rem" }}>
                                    <div>  <h5>
                                        {propertyValue.content.company ? propertyValue.content.company.name : ""}
                                    </h5>
                                        <p>{agentListingsCount ? agentListingsCount : 0} القوائم </p>
                                        {/* <a>عرض الملف</a> */}
                                    </div> 
                                </div>
                            </div>
                            <hr className="hr"></hr>
                            <div className=" d-flex agent">
                                <div className="d-flex align-items-start justify-content-between img-agent " >
                                    <img src={propertyValue.content.agent ? propertyValue.content.agent.photo.src : "https://cdn-icons-png.freepik.com/256/3135/3135715.png?semt=ais_hybrid"} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ marginRight: "0.8rem" }} >
                                    <div>   <h5>
                                        {propertyValue.content.agent ? propertyValue.content.agent.name : ""}
                                    </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdDetails property={propertyValue} />
                    <Duplicates duplicate={propertyValue.found_duplicates} />

                </div>
            </div>
        </div>
    )

}
export default ItemDetails;