import React from 'react';

interface MyComponentProps {
  myFloatNumber: number;
}

const Credit: React.FC<MyComponentProps> = ({ myFloatNumber }) => {
  const integerPart = Math.floor(myFloatNumber);
  const decimalPart = myFloatNumber - integerPart;

  return (
    <span>
      {` ${
        integerPart === 0
          ? "1"
          : decimalPart < 0.5
          ? `${integerPart}`
          : `${integerPart}`
      }`}
    </span>
  );
};

export default Credit;