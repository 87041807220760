import React from "react";

const Duplicates: React.FunctionComponent<{ duplicate: any }> = (props) => {


    return (
        <div className="card padd-24-20">
            <div className="card-body ad-details">
                <h5>مكررات </h5>
                <div className="row">
                    <p>عدد  {props.duplicate ? <span style={{ color: "#282828" }}>
                        {props.duplicate.count}
                    </span> : <span style={{ color: "#282828" }}>
                        0
                    </span>}</p>
                </div>
                <hr className="hr"></hr>
                {props.duplicate ? <div className="duplicates">
                    {props.duplicate.length >0 && props.duplicate.map((duplicate: any, index: any) => (
                        <div> <a href={`/ar/properties/${duplicate.property_id}`} className="duplicate"> تكرار {index + 1}</a></div>
                    ))
                    }
                </div> : <></>}
            </div>
        </div>
    )
}
export default Duplicates
