import { useEffect, useState } from "react";
import { SelectProperty } from "../../../Services/Generate";
import EnhanceTextSelect from "./enhance-text-select";
import { RemoveReplaceService } from "../../../Services/Enhance";
import JSONView from 'react-json-view';
import Upgrade from "../../Settings/upgrade";
import ViewCurl from "../../view-curl";

const RemoveReplacePII: React.FunctionComponent<{}> = (props) => {

    const [properties, setProperties] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<any>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<any>();
    const [typeText, setTypeText] = useState<any>("text");
    const [documentTypeData, setDocumentTypeData] = useState<any>('');
    const [headersValue, setHeadersValue] = useState<any>({"content-type": "application/json"});
    const [inputData, setInputData] = useState<any>([{"id":0,"text":"Classy and Spacious 3BR Apartment with Marina Views and Luxurious Amenities in 23 Marina, Dubai"}]);
    const [curlValue, setCurlValue] = useState<any>("pii_removal");
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");

    useEffect(() => {
        SelectProperty().then((data) => {
            setProperties(data.r)
            setLoading(false)
        })
    }, []);

    const DocumentTypeHandler = (understandObj: any) => {
        setTypeText(understandObj.type)
        setIsSpinner(true)
        setIsUpgrade(false)
        setDocumentTypeData('')
        setShowMsgUpgrade("")
        setDocumentType(understandObj.text)
        const data = [{
            "id": understandObj.id,
            "text": understandObj.text,
        }]
        RemoveReplaceService(data).then((data) => {
            setDocumentTypeData(data.data)
            setIsSpinner(false)
            setShowMessage('Enhanced Successfully !')
            setIsSuccess(true)
        }).catch(err => {
            if (err.response.status === 401) {
                setIsSpinner(false)
                setShowMsgUpgrade(err.response.data.message)
                setIsUpgrade(true)
            } else {
                setIsSpinner(false)
                setShowMessage("Error")
                setIsError(true)
            }
        })
        setTimeout(() => {
            setIsSuccess(false)
            setIsError(false)
        }, 3000);
    };


    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row " style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <div className="title-card">
                                        Remove / Replace PII (Personaly identifiable information)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {properties == null ? <>Loading ...</> :
                <div className="row card-bottom">
                    {isSuccess && <div className="alert alert-success alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible" role="alert">
                        {showMessage}
                    </div>}
                    <div className="col-md-5 mb-3">
                        <EnhanceTextSelect properties={properties} onSendDocumentType={DocumentTypeHandler} />
                    </div>

                    <div className="col-md-7 mb-3">
                        <div className="card ">
                            <div className="card-body padd-24">

                                {typeText == "desc" && <h5 className="generate-h5">Description</h5>}
                                {typeText == "title" && <h5 className="generate-h5">Title</h5>}
                                {typeText == "text" && <h5 className="generate-h5">Text</h5>}
                                <div className="select-text" >
                                    <p className="para_generate">{documentType}</p>
                                </div>
                                <div className="generate-h5" style={{ height: "30px" }}>Output
                                    {isSpinner && documentTypeData == "" && <div className="spinner-border text-primary" role="status" style={{ marginLeft: "16px", textAlign: "center" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                    <ViewCurl headersValue={headersValue} inputData={inputData}  curl={curlValue}/>
                                    {isUpgrade ? <Upgrade /> : <></>}

                                </div>

                                {documentTypeData == "" ? <div className="card card-image-gener"><p className="para" style={{ padding: "20px", textAlign: "center" }}>{showMsgUpgrade}</p></div> :
                                    <div className="card card-image-gener">
                                        <div className="understand-data"><JSONView src={documentTypeData} /></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}
export default RemoveReplacePII;