import React from "react";


const SearchById: React.FunctionComponent<{ onSearchById: any ,placeholder:any }> = (props) => {

    const handleSearchById = (event: any) => {
        props.onSearchById(event.target.value)
    }

    return (
        <div className="input-group input-group-merge search-property">
            <span className="input-group-text" id="basic-addon-search31" style={{borderTopLeftRadius: "0px",borderBottomLeftRadius: "0px",borderTopRightRadius:"5px",borderBottomRightRadius:"5px"}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.58366 18.125C4.87533 18.125 1.04199 14.2917 1.04199 9.58335C1.04199 4.87502 4.87533 1.04169 9.58366 1.04169C14.292 1.04169 18.1253 4.87502 18.1253 9.58335C18.1253 14.2917 14.292 18.125 9.58366 18.125ZM9.58366 2.29169C5.55866 2.29169 2.29199 5.56669 2.29199 9.58335C2.29199 13.6 5.55866 16.875 9.58366 16.875C13.6087 16.875 16.8753 13.6 16.8753 9.58335C16.8753 5.56669 13.6087 2.29169 9.58366 2.29169Z" fill="#282828" />
                    <path d="M18.3335 18.9583C18.1752 18.9583 18.0169 18.9 17.8919 18.775L16.2252 17.1083C15.9835 16.8667 15.9835 16.4667 16.2252 16.225C16.4669 15.9833 16.8669 15.9833 17.1085 16.225L18.7752 17.8917C19.0169 18.1333 19.0169 18.5333 18.7752 18.775C18.6502 18.9 18.4919 18.9583 18.3335 18.9583Z" fill="#282828" />
                </svg>
            </span>
            <input
                type="text"
                className="form-control"
                placeholder={props.placeholder}
                aria-label="Search"
                aria-describedby="basic-addon-search31"
                onChange={handleSearchById}
            />
        </div>
    )
}
export default SearchById;