
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const Pricing: React.FunctionComponent<{ idUser: any }> = (props) => {

    const [open, setOpen] = useState(false);

    const handlePyment = async (value: any) => {
        const stripe = await loadStripe('pk_live_51PFD6iGzYmczk5yZEgPY10US28UcaFf1YEysZP9SVH0KSeNRGtwt0JtHal3YiXVwq9ZaBOBow5iw6MCs2E62ZwA400A2R7JaDG');
        const { data: { sessionId } } = await axios.post("https://www.payment.coralytics.com/create-checkout-session", {
            accountId: props.idUser,
            priceId: value
        });
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error(error);
            }
        } else {
            console.error('Stripe has not loaded');
        }
    };

    return (
        <>
            <button type="button" className="btn upgrade" onClick={() => setOpen(true)}>Upgrade</button>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                style={{ background: "rgba(236, 241, 247, 1)" }}>
                <DialogTitle id="customized-dialog-title">
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="pricing-box">
                        <button className="button-pricing marg-bott"><span>PRICING</span></button>
                        <h2 className="title-h2"> Pick a plan</h2>
                        <div className="row">
                            
                            <div className="col-md-4 mb-3">
                                <div className="card">
                                    <div className="card-body card-pricing-1 " style={{ background: "none", borderRadius: "18px" }}>
                                        <button className="button-pricing-1 mar-bt-20">
                                            <span>Agent Basic</span></button>
                                        <h3>$19.99 /<span>mo</span></h3>
                                        <p><span className="font-weight-700" style={{ color: "#000000" }}>1000 </span> credits/month</p>
                                        <div className="plan" onClick={() => handlePyment("price_1PY8P1GzYmczk5yZ1azBHSUp")}>
                                            <h3>
                                                Purchase annual plan
                                            </h3>
                                            <p>
                                                Billed once per year
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card">
                                    <div className="card-body card-pricing">
                                        <button className="button-pricing mar-bt-20"><span>Agent Plus</span></button>
                                        <h3>$39.99 /<span>mo</span></h3>
                                        <p><span className="font-weight-700">3000 </span>credits/month</p>
                                        <div className="plan" style={{ border: "1px solid #FFF" }} onClick={() => handlePyment("price_1PY8QMGzYmczk5yZT7QWtp5h")}>
                                            <h3 style={{ color: "#FFF" }}>
                                                Purchase annual plan
                                            </h3>
                                            <p style={{ color: "#ECE5FF" }}>
                                                Billed once per year
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card">
                                    <div className="card-body card-pricing-1 " style={{ background: "none", borderRadius: "18px" }}>
                                        <button className="button-pricing-1 mar-bt-20"> <span>Agent Pro</span></button>
                                        <button className="button-pricing mar-bt-20"><span>Most People</span></button>
                                        <h3>$99.99 /<span>mo</span></h3>
                                        <p><span className="font-weight-700" style={{ color: "#000000" }}>8000 </span> credits/month</p>
                                        <div className="plan" onClick={() => handlePyment("price_1PY8TwGzYmczk5yZSXkVdOvn")}>
                                            <h3>
                                                Purchase annual plan
                                            </h3>
                                            <p>
                                                Billed once per year
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Pricing;