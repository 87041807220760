export class LineChart{
    type?:string;
    labels?: string[];
    datasets?: LineData[] = [];

    constructor(type:any,labels:any){
        this.type=type;
        this.labels = labels;
        this.datasets = new Array();
    }
    
    pushDataSet(dataObj: LineData): void {
        if (this.datasets) {
            this.datasets.push(dataObj);
          } else {
            console.error("datasets is undefined");
          }
      }
}

export class LineData{
    label?: string;
    data?: number[];
    backgroundColor?: string;
    borderColor?: string;
    fill? :boolean;
    radius?: number;
    tension?: number;
    pointRadius?: number;

    constructor(label : any,data : any,backgroundColor : any,borderColor : any,fill : any,radius : any,tension : any,pointRadius : any){
        this.label=label;
        this.data = data;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.fill=fill;
        this.radius=radius;
        this.tension=tension;
        this.pointRadius=pointRadius;
    }
}