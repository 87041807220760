import axios from "axios";

const Url = process.env.REACT_APP_URl;

  export const getChartLine = () => {
    const apiUrl = Url+"/chart-data";
    return axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    }).then((response) => {
        return response.data
      });
  }

  
  export const getCharHome = () => {
    const apiUrl = Url+"/chart-consumption";
    return axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    }).then((response) => {
        return response.data
      });
  }
  export const getmostusedconsumedcards = () => {
    const apiUrl = Url+"/mostusedcards";
    return axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    }).then((response) => {
        return response.data
      });
  }
  export const getoverallstats = () => {
    const apiUrl = Url+"/overall-state";
    return axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    }).then((response) => {
        return response.data
      });
  }

  export const getCircleProgress = () => {
    const apiUrl = Url+"/scorescompare";
    return axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    }).then((response) => {
        return response.data
      });
  }