import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const UnderstandMain: React.FunctionComponent<{}> = (props) => {

    const pathname = useLocation().pathname.split('/')
    const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));
  

    return (
        <ul className="nav nav-tabs flex-wrap" role="tablist">
            <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Image Content') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
                <Link to="/understand/image-content" className={[pathname[2] == "image-content" ? "active" : "", "nav-link"].join(" ")}
                    role="tab">
                    Image Content
                </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Image Compliance') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
                <Link to="/understand/image-compliance" className={[pathname[2] == "image-compliance" ? "active" : "", "nav-link"].join(" ")}
                    role="tab"
                >
                    Image Compliance
                </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Text Content and Compliance') || platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
                <Link to="/understand/text" className={[pathname[2] == "text" ? "active" : "", "nav-link"].join(" ")}
                    role="tab" >
                    Text
                </Link>

            </li>
            <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Document Content and Compliance') || platform?.understand?.includes('all') || platform?.understand?.includes('Emirates ID - Content (UAE)'))) ? "" : "disabled"} `}>
                <Link to="/understand/document" className={[pathname[2] == "document" ? "active" : "", "nav-link"].join(" ")}
                    role="tab"
                >
                    Document
                </Link>
            </li>
            <li className={`nav-item ${storedPlatforms.some((platform : any) => platform.understand && (platform?.understand?.includes('Audio Content and Compliance')||platform?.understand?.includes('all'))) ? "" : "disabled"} `}>
                <Link to="/understand/audio" className={[pathname[2] == "audio" ? "active" : "", "nav-link"].join(" ")}
                    role="tab"
                >
                    Audio
                </Link>
            </li>
        </ul>

    )
}
export default UnderstandMain